import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import milestoneDataTransform from './transforms/clinicalMilestone.transform';

const clinicalMilestonesDataHttpRequest = ((params) => {
  const apiName = 'clinicalMilestonesDetails';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/clinical-milestones`;
  generatedHttpThunk.transform = milestoneDataTransform;
  return generatedHttpThunk.generateThunk(params);
})();

export default clinicalMilestonesDataHttpRequest;
