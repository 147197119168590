import React, { useState } from 'react';
import SidebarPopup from 'components/sidebarPopup/SidebarPopup';
import NavigationBarItem from 'components/NavigationBarItem/NavigationBarItem';
import CompassErrorBoundary from 'components/Error/CompassErrorBoundary';
import { MY_FILTERS_TYPE, MY_TRIALS_TYPE } from './constants/constants';
import MyTrialsSection from '../../section/11_MyTrialsSection/MyTrials.section';
import MyFiltersSection from '../../section/12_MyFiltersSection/MyFilters.section';

import './UserPreference.scss';

function UserPreference() {
  const [sidebarVisible, setSidebarVisible] = useState({
    isVisible: false,
    headerText: '',
  });

  const menuItems = [
    {
      label: MY_TRIALS_TYPE,
      command: () => {
        setSidebarVisible({ isVisible: true, headerText: MY_TRIALS_TYPE });
      },
    },
    {
      label: MY_FILTERS_TYPE,
      command: () => {
        setSidebarVisible({ isVisible: true, headerText: MY_FILTERS_TYPE });
      },
    },
  ];

  let headerTemplate = null;

  if (sidebarVisible.isVisible) {
    headerTemplate = (
      <div className="row">
        <div className="col-12 user-pref-menu d-flex justify-content-start align-items-center">
          <h2
            className={
              sidebarVisible.headerText === MY_TRIALS_TYPE ? 'active' : ''
            }
            onClick={() =>
              setSidebarVisible({
                isVisible: true,
                headerText: MY_TRIALS_TYPE,
              })
            }
            data-testid="my-trials-tab"
          >
            {MY_TRIALS_TYPE}
          </h2>

          <h2
            className={
              sidebarVisible.headerText === MY_FILTERS_TYPE ? 'active' : ''
            }
            onClick={() =>
              setSidebarVisible({
                isVisible: true,
                headerText: MY_FILTERS_TYPE,
              })
            }
            data-testid="my-filters-tab"
          >
            {MY_FILTERS_TYPE}
          </h2>
        </div>
      </div>
    );
  }

  return (
    <>
      <NavigationBarItem dropdownItems={menuItems} label={<>Preferences</>} />
      <SidebarPopup
        isVisible={sidebarVisible.isVisible}
        setIsVisible={setSidebarVisible}
        headerTemplate={headerTemplate}
        maskClassName="user-pref-sidebar"
        isRefreshOnClose
      >
        {sidebarVisible.headerText === MY_TRIALS_TYPE && (
          <CompassErrorBoundary>
            <MyTrialsSection
              setSidebarVisible={(visibleFlag) =>
                setSidebarVisible({
                  isVisible: visibleFlag,
                  type: MY_TRIALS_TYPE,
                })
              }
            />
          </CompassErrorBoundary>
        )}
        {sidebarVisible.headerText === MY_FILTERS_TYPE && (
          <CompassErrorBoundary>
            <MyFiltersSection
              setSidebarVisible={(visibleFlag) =>
                setSidebarVisible({
                  isVisible: visibleFlag,
                  type: MY_FILTERS_TYPE,
                })
              }
            />
          </CompassErrorBoundary>
        )}
      </SidebarPopup>
    </>
  );
}

export default UserPreference;
