import axios from 'axios';
import { generateHttpActions } from '../../../utility';

function createSavedFiltersHttpRequest(moduleName, filterName, filterString) {
  const httpActions = generateHttpActions('createSavedFilters');
  const resource = 'filter';

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      let response = null;

      response = await axios.post(resource, {
        filterName,
        moduleName,
        filterString: JSON.stringify(filterString),
      });

      dispatch(httpActions.success(response));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default createSavedFiltersHttpRequest;
