import ThunkGenerator from './thunkGenerator.abstract';

class SiteTrialThunkGenerator extends ThunkGenerator {
  generateThunk() {
    this._logger('Generation Requested');

    const httpRequest = (
      siteId,
      trialId,
      requestParameters = {},
      additionalParams = {},
    ) => {
      const resource = this._resource
        .replace(':trialId', trialId)
        .replace(':siteId', siteId);

      const dispatchFn = this.generateDispatcher(
        resource,
        requestParameters,
        additionalParams,
      );

      return dispatchFn;
    };

    return httpRequest;
  }
}

export default SiteTrialThunkGenerator;
