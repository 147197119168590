import { QUADRANT } from 'dashboards/PortfolioManagement/dataTables/ActivationsEnrollmentsToPlanTable/constants';
import { isNil } from 'lodash';

export const getPointPositions = (data) => {
  const filteredData = data.filter((item) => {
    const {
      actualSiteActivated,
      plannedSiteActivatedToDate,
      actualPatientsEnrolled,
      plannedPatientsEnrolledToDate,
    } = item;
    return (
      !isNil(actualSiteActivated) &&
      !isNil(plannedSiteActivatedToDate) &&
      !isNil(actualPatientsEnrolled) &&
      !isNil(plannedPatientsEnrolledToDate)
    );
  });
  const addedPositionData = filteredData.map((item) => {
    const {
      actualSiteActivated,
      plannedSiteActivatedToDate,
      actualPatientsEnrolled,
      plannedPatientsEnrolledToDate,
    } = item;
    const yPercentage =
      (actualSiteActivated / plannedSiteActivatedToDate) * 100;
    const xPercentage =
      (actualPatientsEnrolled / plannedPatientsEnrolledToDate) * 100;
    const xPosition = xPercentage > 200 ? 200 : xPercentage;
    const yPosition = yPercentage > 200 ? 200 : yPercentage;
    const position = {
      x: xPosition,
      y: yPosition,
    };

    const addedPositionPoint = {
      ...position,
      ...item,
    };
    return addedPositionPoint;
  });
  return addedPositionData;
};

export const getPointQuadrant = (data) =>
  data.map((point) => {
    let quadrant;
    switch (true) {
      case point.x >= 100 && point.y >= 100:
        quadrant = QUADRANT.AHEAD_AHEAD;
        break;
      case point.x < 100 && point.y < 100:
        quadrant = QUADRANT.BEHIND_BEHIND;
        break;
      case point.x >= 100 && point.y < 100:
        quadrant = QUADRANT.BEHIND_AHEAD;
        break;
      default:
        quadrant = QUADRANT.AHEAD_BEHIND;
    }
    const addedQuadrantPoint = { ...point, quadrant };
    return addedQuadrantPoint;
  });
