import { get } from 'lodash';
import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import DiversityEnrollmentFunnel from './utility/DiversityEnrollmentFunnel.abstract';

export const diversityTransform = (responseData, _, additionalParams) => {
  const responseArray = responseData.values;

  const activeTabValue = get(additionalParams, 'activeTabValue', null);

  // Generate Percentage Data
  const groupPercentage = new DiversityEnrollmentFunnel('percentage');
  groupPercentage.xAxisLabel = activeTabValue;
  groupPercentage.yAxisLabel = 'Percentage';
  const chartDataPercentage = groupPercentage.generateChart(responseArray);
  const tableDataPercentage = groupPercentage.generateTable(responseArray);

  // Generate Count Data
  const groupCount = new DiversityEnrollmentFunnel('count');
  groupCount.xAxisLabel = activeTabValue;
  groupCount.yAxisLabel = 'Count';
  const chartDataCount = groupCount.generateChart(responseArray);
  const tableDataCount = groupCount.generateTable(responseArray);

  return {
    chartDataPercentage,
    chartDataCount,
    tableDataPercentage,
    tableDataCount,
  };
};

const raceEnrollmentFunnelHttpRequest = (() => {
  const apiName = 'diversityEnrollmentFunnel';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/diversity-enrollment-funnel`;
  generatedHttpThunk.transform = diversityTransform;
  return generatedHttpThunk.generateThunk();
})();

export default raceEnrollmentFunnelHttpRequest;
