import {
  CRITICAL_MILESTONES,
  DELIVERY_GOALS_MILESTONES,
} from './milestone/Milestones.constants';
import {
  RAG_STATUS,
  RAG_DIRECTION,
  RAG_COLOR,
  RAG_DIRECTION_MAP,
  GRAY_OV_RAG_MAP,
} from './rag/rag.constants';

import { TIMELINE_ITEMS } from './timeline/timeline.constants';

import {
  LEARNING_SITE_FROM_EDC,
  LEARNING_SITE_FROM_RAG,
  LEARNING_SITE_FROM_HELP,
  LEARNING_SITE_FROM_ENROLMENT,
  LEARNING_SITE_TRUST_CENTER,
} from './learningSite';

import GENERIC_ERROR_MSG from './error';
import DM_DEEP_DIVE_REPOSITORY from './DmDeepDiveRepository';
import TIER_1 from './Tier';

export { SHORT_MONTH_NAME, LONG_MONTH_NAME } from './months';

export { CRITICAL_MILESTONES, DELIVERY_GOALS_MILESTONES };

export {
  RAG_STATUS,
  RAG_DIRECTION_MAP,
  RAG_DIRECTION,
  RAG_COLOR,
  GRAY_OV_RAG_MAP,
};

export { TIMELINE_ITEMS };

export const NOT_AVAILABLE_PLACEHOLDER = 'N/A';

export {
  LEARNING_SITE_FROM_EDC,
  LEARNING_SITE_FROM_RAG,
  LEARNING_SITE_FROM_HELP,
  LEARNING_SITE_FROM_ENROLMENT,
  LEARNING_SITE_TRUST_CENTER,
  DM_DEEP_DIVE_REPOSITORY,
  TIER_1,
};

export { GENERIC_ERROR_MSG };

export {
  OKTA_GROUP_NAMES,
  APP_ENV,
  SPARK_DEV_ENV,
  SPARK_TEST_ENV,
  SPARK_PROD_ENV,
} from './oktaGroups';

export {
  METADATA_SHEET_OPTIONS,
  getMetadataSheetData,
  getExportDateTime,
  getExportSheetDate,
} from './exportTableList.constants';
