import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';

const clinicalMilestonesSummaryDataHttpRequest = (() => {
  const apiName = 'clinicalMilestonesSummary';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/milestones-summary`;
  return generatedHttpThunk.generateThunk();
})();

export default clinicalMilestonesSummaryDataHttpRequest;
