import generateMedianCardData from './generateCardData';

const transform = (data) => {
  const medianCardData = generateMedianCardData(data);
  return {
    ...data,
    medianCardData,
  };
};
export default transform;
