import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const trialRosterHttpRequest = (() => {
  const apiName = 'roster';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/trial/:trialId/roster`;
  return generatedHttpThunk.generateTrialThunk();
})();

export default trialRosterHttpRequest;
