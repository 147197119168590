import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import queryResolutionTimeTransform from './utility/queryResolutionTime.transform';
import crfSortTransform from '../../__utility__/crfSort.transform';

export const queryResolutionTimeChartHttpRequest = (() => {
  const apiName = 'queryResolutionTimeChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-query-resolution-chart/trial/:trialId`;
  generatedHttpThunk.transform = queryResolutionTimeTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export const queryResolutionTimeTableHttpRequest = (() => {
  const apiName = 'queryResolutionTimeTable';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource =
    'trial-management/edc-query-resolution-table/trial/:trialId';
  generatedHttpThunk.transform = crfSortTransform;
  return generatedHttpThunk.generateTrialThunk();
})();
