import getContrastColour from 'assets/stylesheets/base/colorContrast';
import {
  aqua,
  aqua40,
  blue,
  blue40,
  gray3,
  gray4,
  newBackground,
  purple,
  purple40,
} from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';
import { orderBy } from 'lodash';
import { roundOff } from 'utility/math/valueRounder';

const COLOR_LIST = [aqua, gray3, aqua40, gray4, purple, purple40, blue, blue40];

const treeMapChartData = {
  chart: {
    height: 90,
    backgroundColor: newBackground,
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      type: 'treemap',
      layoutAlgorithm: 'squarified',
      data: [],
      dataLabels: {
        enabled: true,
        formatter() {
          return `${this.key} (${this.point.node.val} trials)`;
        },
        style: {
          ...styleComponent('sp'),
          textOutline: 0,
          textOverflow: 'ellipsis',
        },
      },
    },
  ],
  title: {
    text: '',
  },
  exporting: {
    enabled: false,
  },
  tooltip: {
    pointFormatter() {
      return this.dataLabel.textStr;
    },
  },
};

function configureTreeMap(data) {
  const chartData = { ...treeMapChartData };
  const sorteData = orderBy(data, 'trialCount', ['desc']);

  chartData.series[0].data = sorteData.map((trialData, index) => {
    const colorIndex = index % COLOR_LIST.length;
    const treeMapConfig = {
      name: trialData.opTau,
      value: roundOff(trialData.trialCount, 1),
      color: COLOR_LIST[colorIndex],
      dataLabels: {
        color: getContrastColour(COLOR_LIST[colorIndex]),
      },
    };
    return treeMapConfig;
  });

  return chartData;
}

export default configureTreeMap;
