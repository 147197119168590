import { gray2, white } from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';

export const ragColumnSeriesTemplate = ({ color, data, name }) => ({
  type: 'column',
  name,
  color,
  data,
  yAxis: 0,
  pointWidth: 40,
  dataLabels: {
    enabled: true,
    color: white,
    style: {
      ...styleComponent('tag'),
      textOutline: 0,
    },
  },
});

export const medianLineSeriesTemplate = ({ data, name }) => ({
  type: 'line',
  color: gray2,
  label: { enabled: false },
  marker: { symbol: 'diamond' },
  dataLabels: { enabled: false },
  yAxis: 1,
  name,
  data,
});
