import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import { addFileName } from '../../../../../utility';
import transform from './transform/performanceOvertTimeBox.transform';

const cleTimesPerformanceOverTimeBoxTransform = (data, requestParams) => {
  const { time } = requestParams;
  const transformedChartData = transform(data, time === 'yearly');
  const chartDataWithFilename = addFileName(
    transformedChartData,
    'cycleStagePerformanceOverTime',
  );
  const transformedData = {
    chartData: chartDataWithFilename,
    tableData: data,
  };
  return transformedData;
};

const performanceOverTimeCalendarBoxHttpRequest = (() => {
  const apiName = 'cycleTimesPerformanceOverTimeCalendarBoxView';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/over-time-box-plot-view`;
  generatedHttpThunk.transform = cleTimesPerformanceOverTimeBoxTransform;
  return generatedHttpThunk.generateThunk();
})();
export default performanceOverTimeCalendarBoxHttpRequest;
