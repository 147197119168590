import { find } from '@okta/okta-auth-js';
import {
  gray3,
  gray2,
  aqua,
  aqua20,
  aqua60,
} from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';
import { flattenDeep, get, map, max, sum, sumBy, uniq } from 'lodash';
import { labelNullFormatter, valueNullFormatter } from 'utility/nullFormatter';
import zeroNullFilterer from 'utility/generateHighChart/columnChart/utility/zeroNullFilterer';
import { roundOff } from 'utility/math';
import generateScrollbarEventHighchart from 'utility/highcharts/generateScrollbarEventHighchart';
import {
  queryAgingLabelFormatter,
  queryAgingTooltipFormatter,
} from './utility/formatter';
import primaryCategoryFilterer from './utility/filterPrimaryCategoryNulls';

class QueryAgingStackedColumn {
  _legendStyleOptions = { ...styleComponent('cap'), color: gray2 };

  _CHART_CONFIGURATION = {
    title: { text: '' },
    chart: {
      events: {
        ...generateScrollbarEventHighchart(14),
      },
      type: 'column',
    },

    legend: {
      enabled: true,
      align: 'left',
      verticalAlign: 'top',
      margin: 12,
      itemStyle: this._legendStyleOptions,
    },
  };

  isStacked = true;

  _isStackLabels = false;

  _xAxisLabel = null;

  _hasXAxisLegend = false;

  _yAxisLabel = null;

  _colorList = [aqua20, aqua60, aqua];

  #showTotal = true;

  set xAxisLabel(name) {
    if (typeof name === 'string') this._xAxisLabel = name;
    else this._xAxisLabel = '';
  }

  get xAxisLabel() {
    return this._xAxisLabel;
  }

  set hasXAxisLegend(value) {
    this._hasXAxisLegend = value;
  }

  get hasXAxisLegend() {
    return this._hasXAxisLegend;
  }

  set yAxisLabel(name) {
    if (typeof name === 'string') this._yAxisLabel = name;
    else this._yAxisLabel = '';
  }

  get yAxisLabel() {
    return this._yAxisLabel;
  }

  set showTotal(value) {
    this.#showTotal = value;
  }

  get showTotal() {
    return this.#showTotal;
  }

  static _getCategoryName(data, categoryType) {
    const categoryNames = map(data, (category) =>
      labelNullFormatter(get(category, categoryType, null)),
    );
    return categoryNames;
  }

  constructor(chartType) {
    this._chartType = chartType;
  }

  _getUniquePrimaryCategories(data) {
    const uniqueValues = QueryAgingStackedColumn._getCategoryName(
      data,
      'primaryCategoryName',
    );

    return uniqueValues;
  }

  _getExportConfig() {
    const exporting = {
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen'],
        },
      },
    };
    return exporting;
  }

  _getUniqueSecondaryCategories(data) {
    const uniqueValues = data.map((item) => {
      const primaryValues = get(item, 'primaryCategoryValues', []);
      const secondaryCategories = QueryAgingStackedColumn._getCategoryName(
        primaryValues,
        'secondaryCategory',
      );
      return secondaryCategories;
    });

    return uniq(flattenDeep(uniqueValues));
  }

  getTooltipFormatter(chartType) {
    const showTotal = this.#showTotal;

    return queryAgingTooltipFormatter(chartType, showTotal);
  }

  _getLabelFormatter(chartType) {
    const isStackLabels = this.isStacked;
    const that = this;

    return queryAgingLabelFormatter(chartType, isStackLabels, that);
  }

  _getXAxis(data) {
    const nullFilteredData = primaryCategoryFilterer(data);
    const categories = this._getUniquePrimaryCategories(nullFilteredData);
    const xAxis = {
      categories,
      left: '0.5%',
      title: {
        text: this._xAxisLabel,
        style: { color: gray3 },
      },
      lineColor: gray3,
      labels: {
        enabled: this._hasXAxisLegend,
        style: {
          color: gray3,
          fontSize: '10px',
        },
      },
    };
    return xAxis;
  }

  _getYAxis(chartType) {
    const yAxis = {
      title: { text: this._yAxisLabel, style: { color: gray3 } },

      stackLabels: {
        enabled: true,
        style: {
          color: gray3,
          fontSize: '10px',
          fontWeight: 0,
          textOutline: 0,
        },
        y: 0,
        verticalAlign: 'top',
        formatter() {
          return chartType === 'count' ? this.total : null;
        },
      },
      breakSize: 120,
      max: this._maxTotal + this._maxTotal * 0.1,
    };

    return yAxis;
  }

  _getSeries(data, type) {
    const uniqueSecondaryCategories = this._getUniqueSecondaryCategories(data);

    const series = uniqueSecondaryCategories.map((secondaryCategory) => {
      const seriesData = data.reduce((dataValue, item) => {
        const primaryCategoryValues = get(item, 'primaryCategoryValues', []);
        const filterCondition = { secondaryCategory };
        const primaryValueItem = find(primaryCategoryValues, filterCondition);
        let numericValue = valueNullFormatter(get(primaryValueItem, type, 0));
        numericValue = roundOff(numericValue);
        const totalValue = sum(map(primaryCategoryValues, type, 0));

        const metaData = { secondaryCategory, totalValue, dataItem: item };

        if (zeroNullFilterer(metaData)) {
          dataValue.push({
            y: numericValue,
            metaData: zeroNullFilterer(metaData),
          });
        }
        return dataValue;
      }, []);

      const seriesTotal = sumBy(seriesData, 'y');

      const name = secondaryCategory;
      const seriesItem = { name, data: seriesData, seriesTotal };
      return seriesItem;
    });

    const updatedSeries = series.map((category, index) => {
      const categoryName = get(category, 'name');
      const color = this._getColorForChart(categoryName, index);
      const dataWithColor = {
        ...category,
        color,
        legendIndex: index,
      };
      return dataWithColor;
    });
    return updatedSeries;
  }

  _getColorForChart(secondaryCategory, index) {
    return this._colorList[index % this._colorList.length];
  }

  _initializeMaxTotal(data) {
    const allBarHeights = data.map((category) => {
      const primaryCategoryValues = get(category, 'primaryCategoryValues', []);
      const barHeight = primaryCategoryValues.reduce(
        (prevValuesSum, categoryValues) =>
          prevValuesSum +
          Math.abs(get(categoryValues, `${[this._chartType]}`, 0)),
        0,
      );
      return barHeight;
    });
    const maxBarHeight = max(allBarHeights);
    this._maxTotal = maxBarHeight;
  }

  generateChart(data) {
    this._initializeMaxTotal(data);
    const xAxis = this._getXAxis(data);
    const yAxis = this._getYAxis(this._chartType);
    const series = this._getSeries(data, this._chartType);
    const tooltipFormatter = this.getTooltipFormatter(this._chartType);
    const labelFormatter = this._getLabelFormatter(this._chartType);
    const exporting = this._getExportConfig();

    const chartData = {
      ...this._CHART_CONFIGURATION,
      xAxis,
      yAxis,
      series,
      exporting,
      tooltip: { formatter: tooltipFormatter, outside: true },
      plotOptions: {
        column: { stacking: this.isStacked ? 'normal' : null },
        series: {
          dataLabels: {
            enabled: true,
            useHTML: true,
            formatter: labelFormatter,
            verticalAlign: 'top',
          },
        },
      },
      labels: { y: 0 },
    };
    return chartData;
  }
}

export default QueryAgingStackedColumn;
