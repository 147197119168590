const drawDiamondIcon = (
  backgroundColor,
  borderColor,
  isDashed,
  text = 'NA',
) => {
  const svgString = `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 38 38" enable-background="new 0 0 38 38" xml:space="preserve" width="38" height="38">
  <defs>
  <style type="text/css">
         @import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);

 </style>
</defs>
  <rect x="0.585786" y="18.8529" width="26" height="26" rx="3" transform="rotate(-45 0.585786 18.8529)" fill="${backgroundColor}" stroke="${borderColor}" stroke-width="2" ${
    isDashed ? 'stroke-dasharray="4 4"' : ''
  }/>
  <text x="50%" y="21" dominant-baseline="middle" text-anchor="middle" font-family="'Open Sans', sans-serif" font-size="12" fill="black">${text}</text>
  </svg>`;

  return `data:image/svg+xml;base64,${window.btoa(svgString)}`;
};

export default drawDiamondIcon;
