import { cloneDeep, get } from 'lodash';

class FixedLengthQueue {
  _queue = [];

  constructor(length = 10) {
    this._length = length;
  }

  get queue() {
    return cloneDeep(this._queue);
  }

  push(id, data = null, comparisonKey = 'id') {
    const existingIndex = this._queue.findIndex(
      (element) => get(element.data, comparisonKey) === id,
    );
    if (existingIndex !== -1) {
      this.deleteElement(existingIndex);
    } else {
      if (this._queue.length >= this._length) this.pop();
      this._queue.push({ id, data });
    }
  }

  pop() {
    this._queue.shift();
  }

  deleteElement(index) {
    if (index >= 0 && index < this._queue.length) {
      this._queue.splice(index, 1);
    }
  }
}
export default FixedLengthQueue;
