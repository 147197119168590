export const SUMMARY_CARD_RAG_COLORS = [
  {
    colorClass: 'green',
    key: 'green',
    ragTitle: 'Green',
    count: null,
    percentage: null,
  },
  {
    colorClass: 'amber',
    key: 'amber',
    ragTitle: 'Amber',
    count: null,
    percentage: null,
  },
  {
    colorClass: 'red',
    key: 'red',
    ragTitle: 'Red',
    count: null,
    percentage: null,
  },
  {
    colorClass: 'gray',
    key: 'gray',
    ragTitle: 'Gray',
    count: null,
    percentage: null,
  },
];

export const STUDY_RAG_BY_CATEGORY_GROUP_OPTIONS = [
  { label: 'Group by TAU', value: 'groupedByTau', title: 'TAU' },
  { label: 'Group by OpTAU', value: 'groupedByOpTau', title: 'OpTAU' },
  { label: 'Group by Tier', value: 'groupedByTier', title: 'Tier' },
  { label: 'Group by Phase', value: 'groupedByPhase', title: 'Phase' },
  { label: 'Group by CRO', value: 'groupedByCro', title: 'CRO' },
];
