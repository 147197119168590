import {
  aqua,
  aqua60,
  darkAqua,
  darkPurple,
  gray1,
  gray2,
  gray3,
  purple,
} from 'assets/stylesheets/base/_colors';
import LEGEND_TYPE from 'constants/legendType';

export const TIME_FRAME = {
  LAST_12_MONTHS: 'Last 12 months',
  LAST_24_MONTHS: 'Last 24 months',
  LAST_36_MONTHS: 'Last 36 months',
};

export const AMENDMENT_GROUP = {
  GROUP_BY_TAU: 'Group by TAU',
  GROUP_BY_CRO: 'Group by CRO',
  GROUP_BY_TIER: 'Group by Tier',
  GROUP_BY_PHASE: 'Group by Phase',
};

export const AMENDMENT_GROUP_DATA = {
  'Group by TAU': {
    dataKey: 'groupedByTau',
    dataLabel: 'TAU',
  },
  'Group by CRO': {
    dataKey: 'groupedByCro',
    dataLabel: 'CRO',
  },
  'Group by Tier': {
    dataKey: 'groupedByTier',
    dataLabel: 'Tier',
  },
  'Group by Phase': {
    dataKey: 'groupedByPhase',
    dataLabel: 'Phase',
  },
};

export const CARD_ATTRIBUTES = {
  AVG: {
    title: 'Average amendments (per trial per year)',
    tableVisibilityKey: 'IS_AVG_TABLE_VISIBLE',
    selectedGroupKey: 'AVG_SELECTED_GROUP',
    dataKey: 'averageAmendments',
    legendList: [],
  },
  SUBSTANTIAL: {
    title: 'Substantial amendments',
    tableVisibilityKey: 'IS_SUBSTANTIAL_TABLE_VISIBLE',
    selectedGroupKey: 'SUBSTANTIAL_SELECTED_GROUP',
    dataKey: 'substantialAmendments',
    legendList: [],
  },
  PRE_FSI: {
    title: 'Pre-FSI amendments',
    tableVisibilityKey: 'IS_PRE_FSI_TABLE_VISIBLE',
    selectedGroupKey: 'PRE_FSI_SELECTED_GROUP',
    dataKey: 'preFSIAmendments',
    legendList: [],
  },
  PRIMARY_REASONS: {
    title: 'Primary rationales',
    tableVisibilityKey: 'IS_PRIMARY_REASONS_TABLE_VISIBLE',
    selectedGroupKey: 'PRIMARY_REASONS_SELECTED_GROUP',
    dataKey: 'mostFrequentReasons',
    legendList: [
      {
        color: purple,
        text: 'Regulatory agency request to amend',
        type: LEGEND_TYPE.CIRCLE,
      },
      {
        color: gray3,
        text: 'Improve Recruitment',
        type: LEGEND_TYPE.CIRCLE,
      },
      {
        color: darkPurple,
        text: 'Recruitment difficulty',
        type: LEGEND_TYPE.CIRCLE,
      },
      {
        color: gray2,
        text: 'IRB/EC requested change',
        type: LEGEND_TYPE.CIRCLE,
      },
      {
        color: aqua,
        text: 'Inconsistency and/or error in protocol',
        type: LEGEND_TYPE.CIRCLE,
      },
      {
        color: purple,
        text: 'Investigator/site feedback',
        type: LEGEND_TYPE.CIRCLE,
      },
      {
        color: gray1,
        text: 'New data available (other than safety data)',
        type: LEGEND_TYPE.CIRCLE,
      },
      {
        color: aqua60,
        text: 'Protocol Review Issues',
        type: LEGEND_TYPE.CIRCLE,
      },
      {
        color: darkAqua,
        text: 'Other (please specify)',
        type: LEGEND_TYPE.CIRCLE,
      },
    ],
  },
  MEDIAN_TIME: {
    title: 'Time from PA to first protocol amendment',
    tableVisibilityKey: 'IS_MEDIAN_TIME_TABLE_VISIBLE',
    selectedGroupKey: 'MEDIAN_TIME_SELECTED_GROUP',
    dataKey: 'medianDateToFirstAmendment',
    legendList: [],
  },
};

export const TABLE_LABELS = {
  POST_FSI: 'Post-FSI',
  PRE_FSI: 'Pre-FSI',
  NON_SUBSTANTIAL: 'Non-Substantial',
  SUBSTANTIAL: 'Substantial',
  NOT_SPECIFIED: 'Not specified',
};

export const TABLE_MAX_ROWS = 6;
