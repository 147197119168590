import { aqua, gray2 } from 'assets/stylesheets/base/_colors';
import { SHORT_UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';

export default class PlotLineGenerator {
  _plannedLSIplotLineConfig = {
    color: gray2,
    dashStyle: 'LongDash',
  };

  _forecastLSIplotLineConfig = {
    color: aqua,
    dashStyle: 'LongDash',
  };

  _getPlannedLSIPlotLine(position) {
    return {
      value: new Date(
        new DateStringTransform(position).formatter(SHORT_UI_DISPLAY_DATE),
      ),
      ...this._plannedLSIplotLineConfig,
    };
  }

  _getForecastLSIPlotLine(position) {
    return {
      value: new Date(
        new DateStringTransform(position).formatter(SHORT_UI_DISPLAY_DATE),
      ),
      ...this._forecastLSIplotLineConfig,
    };
  }
}
