import {
  downDirection,
  flatDirection,
  upDirection,
  redRectangle,
  amberRectangle,
  greenRectangle,
  ragGreenUp,
  ragGreenFlat,
  ragGreenDown,
  ragAmberUp,
  ragAmberFlat,
  ragAmberDown,
  ragRedUp,
  ragRedFlat,
  ragRedDown,
  ragRed,
  ragGreen,
  ragAmber,
  ragGrayUp,
  ragGrayFlat,
  ragGrayDown,
  ragGray,
  ragAmberDiamond,
  ragRedDiamond,
  ragGreenDiamond,
  ragGrayDiamond,
  redGrayRag,
  amberGrayRag,
  greenGrayRag,
} from '../../assets/icons/index';

export const RAG_DIRECTION = {
  UP: upDirection,
  DOWN: downDirection,
  FLAT: flatDirection,
};
export const RAG_COLOR = {
  RED: redRectangle,
  AMBER: amberRectangle,
  GREEN: greenRectangle,
};

export const RAG_DIRECTION_MAP = {
  RED: {
    UP: ragRedUp,
    DOWN: ragRedDown,
    FLAT: ragRedFlat,
    NA: ragRed,
    OVERRIDDEN: redGrayRag,
  },
  AMBER: {
    UP: ragAmberUp,
    DOWN: ragAmberDown,
    FLAT: ragAmberFlat,
    NA: ragAmber,
    OVERRIDDEN: amberGrayRag,
  },
  GREEN: {
    UP: ragGreenUp,
    DOWN: ragGreenDown,
    FLAT: ragGreenFlat,
    NA: ragGreen,
    OVERRIDDEN: greenGrayRag,
  },
  GRAY: {
    UP: ragGrayUp,
    DOWN: ragGrayDown,
    FLAT: ragGrayFlat,
    NA: ragGray,
  },
};

export const GRAY_OV_RAG_MAP = {
  RED: redGrayRag,
  AMBER: amberGrayRag,
  GREEN: greenGrayRag,
  NA: ragGray,
};

export const RAG_STATUS = {
  GREEN: 'Green',
  AMBER: 'Amber',
  RED: 'Red',
  GRAY: 'Gray',
};

export const RAG_TREND = {
  GREEN: {
    label: 'Green',
    icon: ragGreenDiamond,
  },
  AMBER: {
    label: 'Amber',
    icon: ragAmberDiamond,
  },
  RED: {
    label: 'Red',
    icon: ragRedDiamond,
  },
  GRAY: {
    label: 'Gray',
    icon: ragGrayDiamond,
  },
};
