import { aqua } from 'assets/stylesheets/base/_colors';
import SiteDrillDownGenerator from './siteDrillDownGenerator.abstract';

class ScreenFailureGenerator extends SiteDrillDownGenerator {
  generateSeries(dataArray, dataKey) {
    const dataSeries = super.generateSeries(dataArray, dataKey);
    const screenFailureChartData = [
      {
        name: 'All sites',
        colorByPoint: false,
        color: aqua,
        data: dataSeries,
      },
    ];
    return screenFailureChartData;
  }
}

export default ScreenFailureGenerator;
