import axios from 'axios';
import { generateHttpActions } from '../../../../utility';

function trialListDataHttpRequest() {
  const httpActions = generateHttpActions('trialListFilters');
  const resource = 'trial-management/trial-filters';

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const response = await axios.get(resource);

      dispatch(httpActions.success(response.data));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default trialListDataHttpRequest;
