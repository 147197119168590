import { compact, findIndex, get, groupBy, map, orderBy } from 'lodash';

class ListDataTransform {
  removeUnusedKey(trialRecords, requiredKeys = []) {
    const defaultItem = { label: null, value: null };
    const updateTrialRecords = trialRecords.map((trialRecord) => {
      const updateTrialRecord = {};
      requiredKeys.forEach((requiredKey) => {
        const value = get(trialRecord, requiredKey, defaultItem);
        updateTrialRecord[requiredKey] = value;
      });
      return updateTrialRecord;
    });
    return updateTrialRecords;
  }

  customSortGroupsTrials(groupedRecords, customOrder = []) {
    const sortedGroupRecords = orderBy(groupedRecords, ['key']);
    const customOrderGroupRecords = [];
    customOrder.forEach((customOrderKey) => {
      const index = findIndex(sortedGroupRecords, { key: customOrderKey });
      if (index >= 0) {
        customOrderGroupRecords.push(sortedGroupRecords[index]);
        sortedGroupRecords.splice(index, 1);
      }
    });

    customOrderGroupRecords.push(...sortedGroupRecords);
    return customOrderGroupRecords;
  }

  groupTrials(trialRecords, groupByKey) {
    const groups = groupBy(trialRecords, `${groupByKey}.value`);
    const groupedRecords = Object.keys(groups).map((key) => {
      const records = groups[key];
      const sortedRecords = orderBy(
        records,
        ['isBookmarked.value', 'protocolNumberHarmonized.value'],
        ['desc', 'asc'],
      );
      const label = get(records, `[0].${groupByKey}.label`);
      const returnObject = { label, key, records: sortedRecords };
      return returnObject;
    });
    return groupedRecords;
  }

  getGroupedData(trialRecords, groupOrdering = {}) {
    const groupingKeys = ['tier', 'phase', 'productName', 'ragStatus'];
    const groupedRecords = {};

    groupingKeys.forEach((groupingKey) => {
      const orderList = get(groupOrdering, groupingKey, []);
      const orderStringList = compact(map(orderList, 'value', null));
      const groupedRecordList = this.groupTrials(trialRecords, groupingKey);
      const sortedRecords = this.customSortGroupsTrials(
        groupedRecordList,
        orderStringList,
      );
      groupedRecords[groupingKey] = sortedRecords;
    });

    return groupedRecords;
  }

  static transform = (response) => {
    const listDataTransformObject = new ListDataTransform();

    const trialRecords = get(response, 'records', []);
    const groupOrdering = get(response, 'metadata.groupOrder', {});

    const requiredKey = [
      'protocolNumberHarmonized',
      'ragStatus',
      'ragDirection',
      'productName',
      'tags',
      'countries',
      'nextMilestone',
      'isBookmarked',
      'tier',
      'phase',
      'isOverriddenRag',
    ];
    const sensitizedRecords = listDataTransformObject.removeUnusedKey(
      trialRecords,
      requiredKey,
    );
    const groupedRecords = listDataTransformObject.getGroupedData(
      sensitizedRecords,
      groupOrdering,
    );

    const updatedResponse = groupedRecords;

    return updatedResponse;
  };
}

export default ListDataTransform;
