import axios from 'axios';
import { generateHttpActions } from '../../../utility';

const transformFilters = (response) => {
  const transformedData = response.data.map((moduleData) => {
    const tranformedFilters = moduleData.filters.map((savedFilter) => {
      const modifiedFilterJson = JSON.parse(savedFilter.filterJson);
      return { ...savedFilter, filterJson: modifiedFilterJson };
    });
    return { ...moduleData, filters: tranformedFilters };
  });
  return {
    ...response,
    data: transformedData,
  };
};

function listSavedFiltersHttpRequest() {
  const httpActions = generateHttpActions('listSavedFilters');
  const resource = 'filter';

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const response = await axios.get(resource);

      const transformedResponse = transformFilters(response.data);
      dispatch(httpActions.success({ data: transformedResponse }));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default listSavedFiltersHttpRequest;
