import { roundOff } from 'utility/math/valueRounder';

class QuadrantLabel {
  static _labelPosition = [
    {
      x: 5,
      y: 195,
    },
    {
      x: 195,
      y: 195,
    },
    {
      x: 5,
      y: 5,
    },
    {
      x: 195,
      y: 5,
    },
  ];

  constructor(quadrantCountValues, total) {
    this._quadrantCountValues = quadrantCountValues;
    this._total = total;
  }

  _getSeriesData() {
    const labelSeries = this._quadrantCountValues.map(
      (quadrantCount, index) => {
        const percentage = (quadrantCount / this._total) * 100;
        const roundedPercentage = roundOff(percentage, 1);
        const quadrantLabelSeriesData = {
          ...QuadrantLabel._labelPosition[index],
          percentage: `${roundedPercentage}%`,
          count: `trials (${quadrantCount}/${this._total})`,
        };
        return quadrantLabelSeriesData;
      },
    );

    return labelSeries;
  }

  generate() {
    const quadrantLabelSeries = [
      {
        color: 'transparent',
        data: this._getSeriesData(),
        enableMouseTracking: false,
      },
    ];
    return quadrantLabelSeries;
  }
}

export default QuadrantLabel;
