import { get, orderBy } from 'lodash';
import EdcColumn from 'utility/generateHighChart/columnChart/EdcColumn.abstract';
import EdcChartDataGenerator from '../../../utility/chartData.transform';
import { CHART_TYPES } from '../../../utility/constant';

function queryStatusTransform(data) {
  const responseArray = get(data, 'values', []);
  const queryStatusModifiedData = new EdcChartDataGenerator(
    responseArray,
    'count',
    CHART_TYPES.LOW_HIGH,
  ).generate();
  const queryStatus = new EdcColumn('count');
  queryStatus.xAxisLabel = 'Sites';
  queryStatus.yAxisLabel = 'Open queries / subject';
  const sortedQueryStatusData = orderBy(
    queryStatusModifiedData,
    [
      (site) => get(site, 'data[0].y', null),
      (siteName) => get(siteName, 'siteName', null),
    ],
    ['desc', 'asc'],
  );
  const chartData = queryStatus.generateChart(sortedQueryStatusData);

  return {
    chartData,
    cardList: get(data, 'cardList', []),
  };
}

export default queryStatusTransform;
