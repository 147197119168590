import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import transform from './utility/transformComplexMilestones';

const complexMilestonesDataHttpRequest = (() => {
  const apiName = 'complexMilestones';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/milestones/trial/:trialId`;
  generatedHttpThunk.secondaryResource = `trial-management/enrollment-forecast/trial/:trialId`;
  generatedHttpThunk.transform = transform;
  return generatedHttpThunk.generateTrialThunk();
})();

export default complexMilestonesDataHttpRequest;
