import React, { useCallback } from 'react';
import { Sidebar } from 'primereact/sidebar';
import PropTypes from 'prop-types';
import { SafeHtmlElements } from '../SafeComponents';

function SidebarPopup({
  children,
  isVisible,
  setIsVisible,
  headerText,
  maskClassName,
  className,
  headerTemplate,
  isRefreshOnClose,
}) {
  const renderHeader = () => {
    if (!headerTemplate) {
      return (
        <div className="w-100">
          {typeof headerText === 'string' ? (
            <SafeHtmlElements tagName="h2">{headerText}</SafeHtmlElements>
          ) : (
            headerText
          )}
        </div>
      );
    }
    return <div className="w-100">{headerTemplate}</div>;
  };

  const onHideSidebar = useCallback(() => {
    setIsVisible({ isVisible: false, type: '' });
    if (isRefreshOnClose) {
      window.location.reload();
    }
  }, [isRefreshOnClose, window]);

  return (
    <Sidebar
      maskClassName={maskClassName}
      className={className}
      visible={isVisible}
      position="right"
      onHide={onHideSidebar}
      icons={renderHeader}
      blockScroll
      baseZIndex={10000}
    >
      {children}
    </Sidebar>
  );
}

SidebarPopup.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  headerText: PropTypes.node,
  setIsVisible: PropTypes.func.isRequired,
  children: PropTypes.node,
  maskClassName: PropTypes.string,
  className: PropTypes.string,
  headerTemplate: PropTypes.node,
  isRefreshOnClose: PropTypes.bool,
};

SidebarPopup.defaultProps = {
  maskClassName: '',
  className: '',
  headerText: '',
  children: null,
  headerTemplate: null,
  isRefreshOnClose: false,
};
export default SidebarPopup;
