const GLOBAL_FILTER_SECTION_ID = 'GLOBAL_FILTER_SECTION';
const USER_TRIALS_FILTER_SECTION_ID = 'USER_TRIALS_FILTER';
const PROTOCOL_AMENDMENT_SECTION_ID = 'PROTOCOL_AMENDMENT_SECTION';
const ACTIVE_CARD = 'ACTIVE_CARD';
const AVG_CARD = 'AVG';
const SUBSTANTIAL_CARD = 'SUBSTANTIAL';
const PRE_FSI_CARD = 'PRE_FSI';
const MEDIAN_TIME_CARD = 'MEDIAN_TIME';
const PRIMARY_REASONS_CARD = 'PRIMARY_REASONS';

const TRIAL_PERFORMANCE_ACTIVE_CARD = 'TRIAL_PERFORMANCE_ACTIVE_CARD';
const ACTIVATIONS_CARD = 'ACTIVATIONS';
const ENROLLMENTS_CARD = 'ENROLLMENTS';
const ACTIVE_TRIAL_PERFORMANCE_SECTION_ID = 'ACTIVE_TRIAL_PERFORMANCE_SECTION';

const CYCLE_TIMES_SECTION_ID = {
  RAG_STATUS_SECTION: 'RAG_STATUS_SECTION',
  CYCLE_STAGE_PERFORMANCE_SECTION: 'PERFORMANCE_OVER_TIME',
  CYCLE_STAGE_CATEGORY_SECTION: 'PERFORMANCE_CATEGORY',
  CYCLE_STAGE_CYCLE_BY_CYCLE: 'CYCLE_BY_CYCLE',
};

const RAG_STATUS_BY_CYCLE_STAGE_SECTION_ID =
  'RAG_STATUS_BY_CYCLE_STAGE_SECTION';

const PORTFOLIO_OVERVIEW = 'Overview';

const PORTFOLIO_DIVERSITY_PAGE_SECTION_ID =
  'PORTFOLIO_DIVERSITY_PAGE_SECTION_ID';

const PORTFOLIO_DIVERSITY_TAB = 'PORTFOLIO_DIVERSITY_TAB';

const PORTFOLIO_TRIAL_SUMMARY_TAB = 'PORTFOLIO_TRIAL_SUMMARY_TAB';

const PORTFOLIO_DIVERSITY_DROPDOWN_COUNTRIES =
  'PORTFOLIO_DIVERSITY_DROPDOWN_COUNTRIES';

const CYCLE_TIMES_DRILLDOWN_TARGETS = {
  MEETING_TARGET: 'MEETING_TARGET',
  NOT_MEETING_TARGET: 'NOT_MEETING_TARGET',
  UNKNOWN: 'UNKNOWN',
};

// Trial performance
export const ACTIVE_TRIAL_PERFORMANCE_SECTION_IDS = {
  STUDY_RAG_BY_CATEGORY: 'ATP_STUDY_RAG_BY_CATEGORY_SECTION_ID',
};

const TRIAL_DELIVERY_GOALS_SECTION_ID = 'TRIAL_DELIVERY_GOALS_SECTION_ID';

export const TRIAL_DELIVERY_GOALS_SECTION_IDS = {
  MILESTONES: 'TRIAL_DELIVERY_GOALS_MILESTONES',
  GPT: 'TRIAL_DELIVERY_GOALS_GPT',
};

export {
  ACTIVE_CARD,
  AVG_CARD,
  SUBSTANTIAL_CARD,
  PRE_FSI_CARD,
  PRIMARY_REASONS_CARD,
  MEDIAN_TIME_CARD,
  TRIAL_PERFORMANCE_ACTIVE_CARD,
  ACTIVATIONS_CARD,
  ENROLLMENTS_CARD,
  PROTOCOL_AMENDMENT_SECTION_ID,
  GLOBAL_FILTER_SECTION_ID,
  USER_TRIALS_FILTER_SECTION_ID,
  ACTIVE_TRIAL_PERFORMANCE_SECTION_ID,
  RAG_STATUS_BY_CYCLE_STAGE_SECTION_ID,
  CYCLE_TIMES_SECTION_ID,
  PORTFOLIO_OVERVIEW,
  PORTFOLIO_DIVERSITY_PAGE_SECTION_ID,
  PORTFOLIO_DIVERSITY_TAB,
  PORTFOLIO_TRIAL_SUMMARY_TAB,
  PORTFOLIO_DIVERSITY_DROPDOWN_COUNTRIES,
  TRIAL_DELIVERY_GOALS_SECTION_ID,
  CYCLE_TIMES_DRILLDOWN_TARGETS,
};
