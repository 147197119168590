import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import Generator from './utility/chartTransformer';

const studyRagOverTimeHttpRequest = ((params) => {
  const apiName = `studyRagOverTime`;
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/study-rag-details-over-time`;
  generatedHttpThunk.transform = Generator.transformData;
  return generatedHttpThunk.generateThunk(params);
})();

export default studyRagOverTimeHttpRequest;
