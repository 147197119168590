import DropOutGenerator from './01_dropout.generator';
import EnrollmentGenerator from './02_enrollment.generator';
import ScreenFailureGenerator from './03_screenFailure.generator';
import ScreeningGenerator from './04_screening.generator';

const siteFactory = (selector) => {
  switch (selector) {
    case 'screening': {
      return ScreeningGenerator;
    }
    case 'dropout':
    case 'completion': {
      return DropOutGenerator;
    }
    case 'enrollment': {
      return EnrollmentGenerator;
    }
    case 'screeningFailure': {
      return ScreenFailureGenerator;
    }
    default:
      throw new Error();
  }
};

export default siteFactory;
