import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import crfEnteredTransform from './utility/crfEntered.transform';

const crfEnteredChartHttpRequest = (() => {
  const apiName = 'crfsEnteredChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/edc-crf-entered-site-chart/site/:siteId/trial/:trialId`;
  generatedHttpThunk.transform = crfEnteredTransform;
  return generatedHttpThunk.generateSiteTrialThunk();
})();

export default crfEnteredChartHttpRequest;
