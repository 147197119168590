import { isNil, orderBy } from 'lodash';

export const getCategoryAndIndexByPaToFirstAmendmentDays = (
  paToFirstAmendmentDays,
) => {
  const range = 30;
  const groupIndex = Math.ceil(paToFirstAmendmentDays / range);

  const maxDayCount = groupIndex * range;
  const minDayCount = maxDayCount - (range - 1);

  return {
    index: groupIndex,
    categoryName: `${minDayCount}-${maxDayCount}`,
  };
};

const categorizePaToFirstAmendmentMediansData = (data) => {
  const validData = data.filter(
    (item) =>
      !isNil(item.paToFirstAmendmentDays) && item.paToFirstAmendmentDays >= 0,
  );

  const groupedData = validData.reduce((result, item) => {
    const { index, categoryName } = getCategoryAndIndexByPaToFirstAmendmentDays(
      item.paToFirstAmendmentDays,
    );

    const nextResult = [...result];

    if (!nextResult.find((_resultItem) => _resultItem.index === index)) {
      nextResult.push({
        index,
        name: categoryName,
        value: 0,
      });
    }

    const existingResultItemIndex = nextResult.findIndex(
      (_resultItem) => _resultItem.index === index,
    );

    nextResult[existingResultItemIndex].value += item.trialCount;

    return nextResult;
  }, []);

  return orderBy(groupedData, 'index', 'asc');
};

export default categorizePaToFirstAmendmentMediansData;
