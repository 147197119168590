import axios from 'axios';
import { get } from 'lodash';
import { generateHttpActions } from '../../utility';
import getAccessibleModules from './transform/moduleAccess.transform';

function moduleAccessHttpRequest() {
  const httpActions = generateHttpActions('moduleAccess');
  const resource = `user-management/user/access-list`;
  const functionName = 'myTrialsListHttpRequest';
  const isDevMode = !!localStorage.getItem(functionName);

  return async (dispatch) => {
    dispatch(httpActions.start());

    try {
      let response = null;

      if (isDevMode) {
        const payload = JSON.parse(localStorage.getItem(functionName));
        response = { data: payload };
      } else {
        response = await axios.get(resource);
      }

      const moduleList = get(response, 'data.data.modules', []);
      const isAdmin = get(response, 'data.data.isAdmin', false);
      const userProfile = get(response, 'data.data.userProfile', {});
      const accessControl = getAccessibleModules(moduleList, isAdmin);
      const data = { ...accessControl, userProfile };

      dispatch(httpActions.success({ data }));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default moduleAccessHttpRequest;
