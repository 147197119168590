import * as baseColors from 'assets/stylesheets/base/_colors';

export const LEGEND_CONFIG = {
  enabled: false,
};

export const DATA_VISUALIZATION_COLORS_CONFIG = [
  baseColors.darkAqua,
  baseColors.gray1,
  baseColors.fujiPurple,

  baseColors.aqua,
  baseColors.gray2,
  baseColors.darkPurple,

  baseColors.aqua60,
  baseColors.gray3,
  baseColors.purple,

  baseColors.aqua40,
  baseColors.gray4,
  baseColors.purple60,

  baseColors.aqua20,
  baseColors.gray5,
  baseColors.purple40,

  baseColors.gray6,
  baseColors.purple20,
];

export const TITLE_CONFIG = {
  align: 'center',
  verticalAlign: 'bottom',
  margin: 85,
  y: 10,
  floating: true,
  style: {
    color: baseColors.gray3,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'none',
    textTransform: 'none',
  },
};

export const DATA_POINT_WIDTH = 40;
export const SELECTION_POINT_WIDTH = 60;
