import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import transform from './transform/DistributionByGroupDrilldown.transform';

const distributionByGroupDrilldownHttpRequest = (() => {
  const apiName = 'distributionByGroupDrilldown';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/diversity-distribution-by-group-drilldown`;
  generatedHttpThunk.transform = transform;
  return generatedHttpThunk.generateThunk();
})();

export default distributionByGroupDrilldownHttpRequest;
