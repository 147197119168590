import { get, isNil } from 'lodash';
import { roundOff } from 'utility/math';

const extractOnlyGivenProperties = (
  dataObjectArray,
  requiredKeys,
  emptyDataPlaceholder = [],
) => {
  if (Array.isArray(dataObjectArray) && Array.isArray(requiredKeys)) {
    const requiredKeysOnlyArray = dataObjectArray.map((dataObject) => {
      const requiredKeysOnly = {};
      requiredKeys.forEach((keyItem) => {
        const { label, value } = keyItem;
        const { precision, fixedTrailingPoints } = keyItem;
        let returnValue = get(dataObject, value);
        if (typeof returnValue === 'number' && !isNil(precision)) {
          const roundedValue = roundOff(returnValue, precision);
          returnValue = fixedTrailingPoints
            ? roundedValue.toFixed(fixedTrailingPoints)
            : roundedValue;
          requiredKeysOnly[label] = returnValue;
        } else requiredKeysOnly[label] = returnValue;
      });
      return requiredKeysOnly;
    });
    if (requiredKeysOnlyArray.length === 0) return emptyDataPlaceholder;
    return requiredKeysOnlyArray;
  }
  return dataObjectArray;
};
export default extractOnlyGivenProperties;
