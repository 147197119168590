import { get } from 'lodash';
import DiversityOverTime from '../utility/DiversityOverTime.abstract';

const transform = (responseData) => {
  const emptyDateRange = {
    begin: null,
    end: null,
  };
  const dateRange = get(responseData, 'metadata.dateRange', emptyDateRange);

  const rangeStartDate = dateRange.begin && new Date(dateRange.begin);
  const rangeEndDate = dateRange.end && new Date(dateRange.end);

  if (!dateRange.begin) {
    const todayDate = new Date();
    dateRange.begin = rangeEndDate || todayDate;
    dateRange.begin.setFullYear(dateRange.begin.getFullYear() - 5);
  }

  if (!dateRange.end) {
    const todayDate = new Date();
    dateRange.end =
      !rangeStartDate && !rangeEndDate
        ? todayDate
        : rangeStartDate || todayDate;
    dateRange.end.setFullYear(dateRange.end.getFullYear() + 5);
  }

  const responseArray = get(responseData, 'values', []);

  // Generate Percentage Data
  const groupPercentage = new DiversityOverTime('percentage');
  groupPercentage.xAxisLabel = 'Time';
  groupPercentage.yAxisLabel = 'Percentage';
  const chartDataPercentage = groupPercentage.generateChart(responseArray);
  const tableDataPercentage = groupPercentage.generateTable(responseArray);

  // Generate Count Data
  const groupCount = new DiversityOverTime('count');
  groupCount.xAxisLabel = 'Time';
  groupCount.yAxisLabel = 'Count';
  const chartDataCount = groupCount.generateChart(responseArray);
  const tableDataCount = groupCount.generateTable(responseArray);

  return {
    dateRange,
    chartDataPercentage,
    chartDataCount,
    tableDataPercentage,
    tableDataCount,
  };
};

export default transform;
