import { find, forOwn, map, startCase } from 'lodash';

const NOT_HEADER_ATTRIBUTES = ['category', 'trialId'];
const transform = (data, requestParams) => {
  const displayType = requestParams.displayType.toLowerCase();
  const headers = [];
  const transformedData = map(data[displayType].records, (site) => {
    let newSiteData = {};
    forOwn(site, (keyValue, key) => {
      newSiteData[key] = keyValue.value;
      if (!NOT_HEADER_ATTRIBUTES.some((attribute) => attribute === key)) {
        const normalCaseKey = startCase(key);
        const isKeyExisted = find(headers, (object) => object.field === key);
        if (!isKeyExisted) {
          headers.push({ label: normalCaseKey, field: key });
        }
      }
    });
    newSiteData = { ...newSiteData, headers };
    return newSiteData;
  });
  return transformedData;
};

export default transform;
