import { get, orderBy } from 'lodash';
import CountryDrillDown from '../drilldown/02_countryDrillDown';

const countryTransform = (data, params) => {
  const { selectedDriverCard } = params;
  const countryDrillDown = new CountryDrillDown(selectedDriverCard);

  const dataByCountry = orderBy(
    get(data, 'dataByCountry', []),
    (country) => get(country, 'country.label', null),
    'asc',
  );

  const dataBySite = orderBy(
    get(data, 'dataBySite', []),
    (site) => get(site, 'siteName.label', null),
    'asc',
  );

  const medianByCountryCurrentNo = get(data, 'medianByCountryCurrentNo', null);
  const medianByCountryRate = get(data, 'medianByCountryRate', null);

  countryDrillDown.siteData = dataBySite;
  countryDrillDown.countryData = dataByCountry;
  countryDrillDown.medianCurrentNo = medianByCountryCurrentNo;
  countryDrillDown.medianRate = medianByCountryRate;

  const countryChartData = countryDrillDown.generateGraph();
  return countryChartData;
};

export default countryTransform;
