const getAccessibleModules = (moduleList, isAdmin) => {
  const accessControl = {
    trialManagement: moduleList.includes('TRIAL_MANAGEMENT'),
    siteHealth:
      moduleList.includes('TRIAL_MANAGEMENT') ||
      moduleList.includes('SITE_HEALTH'),
    trialPortfolio: moduleList.includes('PORTFOLIO_HEALTH'),
    userManagement: isAdmin,
  };
  return accessControl;
};
export default getAccessibleModules;
