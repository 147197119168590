import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';

const cycleTimesPerformanceByTrialCalendarHttpRequest = (() => {
  const apiName = 'cycleTimesPerformanceByTrialCalendar';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/cycle-by-cycle-details`;
  return generatedHttpThunk.generateThunk();
})();

export default cycleTimesPerformanceByTrialCalendarHttpRequest;
