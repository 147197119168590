import React from 'react';
import Loader from 'components/Loader';
import Error from 'components/Error';
import { useThunkSelector } from 'utility/redux';

const UserManagement = React.lazy(() =>
  import('../../dashboards/UserManagement/UserManagement'),
);

function UserManagementRoute() {
  const pageAccess = useThunkSelector('moduleAccess');
  return (
    <React.Suspense fallback={<Loader className="my-5 py-5" />}>
      {pageAccess.isLoading && <Loader className="my-5 py-5" />}
      {pageAccess.isError && <Error error={pageAccess.error} />}

      {pageAccess.isSuccess && (
        <>
          {pageAccess.payload.userManagement && <UserManagement />}
          {!pageAccess.payload.userManagement && <p>No Access</p>}
        </>
      )}
    </React.Suspense>
  );
}

export default UserManagementRoute;
