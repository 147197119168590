import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';

const portfolioFiltersDataHttpRequest = (() => {
  const apiName = 'portfolioFilters';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/global-filters`;
  return generatedHttpThunk.generateThunk();
})();

export default portfolioFiltersDataHttpRequest;
