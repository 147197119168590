import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import queryStatusTransform from './utility/queryStatus.transform';
import crfSortTransform from '../../__utility__/crfSort.transform';

export const queryStatusChartHttpRequest = (() => {
  const apiName = 'queryStatusChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource =
    'trial-management/edc-query-status-chart/trial/:trialId';
  generatedHttpThunk.transform = queryStatusTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export const queryStatusTableHttpRequest = (() => {
  const apiName = 'queryStatusTable';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-query-status-table/trial/:trialId`;
  generatedHttpThunk.transform = crfSortTransform;
  return generatedHttpThunk.generateTrialThunk();
})();
