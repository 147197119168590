import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import transform from './utility/protocolDeviations.transform';

const protocolDeviationsDataHttpRequest = (() => {
  const apiName = 'protocolDeviations';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/site/:siteId/trial/:trialId/protocol-deviations`;
  generatedHttpThunk.transform = transform;
  return generatedHttpThunk.generateSiteTrialThunk();
})();

export default protocolDeviationsDataHttpRequest;
