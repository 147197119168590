import { get, orderBy } from 'lodash';
import EdcColumn from 'utility/generateHighChart/columnChart/EdcColumn.abstract';
import EdcChartDataGenerator from '../../../utility/chartData.transform';
import { CHART_TYPES } from '../../../utility/constant';

function crfEntryTimeTransform(data) {
  const responseArray = get(data, 'values', []);
  const modifiedData = new EdcChartDataGenerator(
    responseArray,
    'count',
    CHART_TYPES.LOW_HIGH,
  ).generate();
  const groupPercentage = new EdcColumn('count');
  groupPercentage.yAxisLabel = 'Days from visit to CRF entry';
  groupPercentage.xAxisLabel = 'Sites';
  const sortedPercentageData = orderBy(
    modifiedData,
    [
      (site) => get(site, 'data[0].y', null),
      (siteName) => get(siteName, 'siteName', null),
    ],
    ['desc', 'asc'],
  );
  const chartData = groupPercentage.generateChart(sortedPercentageData);
  return {
    chartData,
    cardList: get(data, 'cardList', []),
  };
}

export default crfEntryTimeTransform;
