import { gray1, takedaRed } from 'assets/stylesheets/base/_colors';

const drawViewIcon = (color) => {
  const svgString = `
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.00078 5L1.00078 10L0.300781 9.3L4.60078 5L0.300781 0.7L1.00078 0L6.00078 5Z" fill="${color}"/>
</svg>
`;

  return `data:image/svg+xml;base64,${window.btoa(svgString)}`;
};

export const chevronIcon = drawViewIcon(gray1);
export const chevronIconRed = drawViewIcon(takedaRed);
