export const DELIVERY_GOALS_MILESTONES = {
  GPT_GOAL: 'GPT goal',
  MAJOR_MILESTONE: 'Major milestone',
  CRITICAL_INFLECTION: 'Critical inflection',
};

export const CRITICAL_MILESTONES = {
  PA: 'PA',
  PS: 'PS',
  FSI: 'FSI',
  LSI: 'LSI',
  FSO: 'FSO',
  LSO: 'LSO',
  KA: 'KA',
  TLF: 'TLF',
  DBL: 'DBL',
  CSR: 'CSR',
  FSA: 'FSA',
  FSS: 'FSS',
};

export const MILESTONE_OPTIONS = [
  { label: 'PS', value: 'PS' },
  { label: 'PA', value: 'PA' },
  { label: 'FSA', value: 'FSA' },
  { label: 'FSS', value: 'FSS' },
  { label: 'FSI', value: 'FSI' },
  { label: 'LSI', value: 'LSI' },
  { label: 'LSO', value: 'LSO' },
  { label: 'DBL', value: 'DBL' },
  { label: 'KA', value: 'KA' },
  { label: 'TLF', value: 'TLF' },
  { label: 'CSR', value: 'CSR' },
];

export const DEFAULT_MILESTONE_RANGE = {
  BEGIN_MILESTONE: {
    value: 'PS',
  },
  END_MILESTONE: {
    value: 'CSR',
  },
};
