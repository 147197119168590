import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import { globalFilterSelector } from 'utility/redux/globalFilter.selector';
import ListDataTransform from './utility/02_trialListGrouping.transform';

export const trialListDataHttpRequest = (() => {
  const apiName = 'trialList';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/trials`;
  generatedHttpThunk.globalFilterSelector = globalFilterSelector;
  generatedHttpThunk.syntheticResponse = {};
  return generatedHttpThunk.generatePortfolioThunk();
})();

export const numTrialsHttpRequest = (() => {
  const apiName = 'numTrials';
  const syntheticResponse = {
    data: {
      data: { metadata: { totalRows: 0 } },
    },
  };
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/trials`;
  generatedHttpThunk.globalFilterSelector = globalFilterSelector;
  generatedHttpThunk.syntheticResponse = syntheticResponse;
  return generatedHttpThunk.generatePortfolioThunk();
})();

export const trialCardListDataHttpRequest = (() => {
  const apiName = 'trialCardList';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/trials`;
  generatedHttpThunk.globalFilterSelector = globalFilterSelector;
  generatedHttpThunk.transform = ListDataTransform.transform;

  generatedHttpThunk.syntheticResponse = {};
  return generatedHttpThunk.generatePortfolioThunk();
})();
