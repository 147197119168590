import trialListFiltersHttpRequest from './01_trialListFilters.thunk';
import {
  trialListDataHttpRequest,
  trialCardListDataHttpRequest,
  numTrialsHttpRequest,
} from './02_trialListData.thunk';
import trialKeyFactsHttpRequest from './03_trialKeyFactsData.thunk';
import trialEnrollmentDataHttpRequest from './04_trialEnrolmentData.thunk';
import trialRosterHttpRequest from './05_trialRosterData.thunk';
import trialVendorHttpRequest from './06_trialVendorData.thunk';
import enrollmentSummaryHttpRequest from './10_enrolmentSummary.thunk';
import trialActivationDriversDataHttpRequest from './26_activationDriversData.thunk';
import trialEnrollmentDriversDataHttpRequest from './25_enrolmentDriversData.thunk';
import risksAndMitigationHttpRequest from './17_risksAndMitigations.thunk';
import ragStatusDescriptionHttpRequest from './23_ragStatusDescription.thunk';

import {
  protocolAmendmentsDataHttpRequest,
  protocolAmendmentsSummaryDataHttpRequest,
  protocolAmendmentsTopReasonsDataHttpRequest,
} from './09_protocolAmendments.thunk';
import {
  drillDownScreeningDataHttpRequest,
  drillDownScreeningFailureDataHttpRequest,
  drillDownDropoutDataHttpRequest,
  drillDownActivationDetailHttpRequest,
  drillDownEnrollmentDataHttpRequest,
  drillDownActivationDataHttpRequest,
  drillDownCompletionDataHttpRequest,
} from './08_enrollmentDrivers.thunk';
import complexMilestonesDataHttpRequest from './12_complexMilestones.thunk';
import userTrialsFilterCheckHttpRequest from './13_userTrialsCheck.thunk';
import enrollmentForecastSummaryDataHttpRequest from './14_enrollmentForecast.thunk';
import {
  listSavedFiltersHttpRequest,
  deleteSavedFiltersHttpRequest,
} from '../../../../store/thunks';
import trialDataSourcesHttpRequest from './18_trialDataSources.thunk';
import cycleTimesHttpRequest from './19_cycleTimes.thunk';
import longitudinalRagHttpRequest from './20_longitudinalRag.thunk';
import siteLeaderboardHttpRequest from './21_siteLeaderboard.sample.thunk';
import gptGoalsSidebarHttpRequest from './22_gptGoalsSidebar.thunk';

import edcHeaderHttpRequest from './16_edcReadiness/00_EdcHeader/edcHeader.thunk';
import edcSummaryCardsHttpRequest from './16_edcReadiness/01_edcSummaryCards/edcSummaryCards.thunk';
import {
  crfEnteredChartHttpRequest,
  crfEnteredTableHttpRequest,
} from './16_edcReadiness/02_edcDataEntry/01_crfEntered/crfEntered.thunk';
import {
  crfEntryTimeChartHttpRequest,
  crfEntryTimeTableHttpRequest,
} from './16_edcReadiness/02_edcDataEntry/02_crfEntryTime/crfEntryTime.thunk';
import {
  queryStatusChartHttpRequest,
  queryStatusTableHttpRequest,
} from './16_edcReadiness/03_edcDataCleaning/01_queryStatus/queryStatus.thunk';
import {
  queryAgingChartHttpRequest,
  queryAgingTableHttpRequest,
} from './16_edcReadiness/03_edcDataCleaning/02_queryAging/queryAging.thunk';
import {
  queryResolutionTimeChartHttpRequest,
  queryResolutionTimeTableHttpRequest,
} from './16_edcReadiness/03_edcDataCleaning/03_queryResolutionTime/queryResolutionTime.thunk';
import {
  crfsVerifiedChartHttpRequest,
  crfsVerifiedTableHttpRequest,
} from './16_edcReadiness/03_edcDataCleaning/04_crfsVerified/crfsVerified.thunk';
import {
  crfFrozenTableHttpRequest,
  crfFrozenChartHttpRequest,
} from './16_edcReadiness/04_edcPreLock/02_crfsFrozen/crfsFrozen.thunk';
import {
  crfsSignedChartHttpRequest,
  crfsSignedTableHttpRequest,
} from './16_edcReadiness/04_edcPreLock/01_crfsSigned/crfsSigned.thunk';

import { edcSidePanelHttpRequest } from './16_edcReadiness/05_edcSidePanel/edcSidePanel.thunk';

export {
  trialListFiltersHttpRequest,
  trialListDataHttpRequest,
  trialCardListDataHttpRequest,
  trialKeyFactsHttpRequest,
  trialEnrollmentDataHttpRequest,
  trialRosterHttpRequest,
  trialVendorHttpRequest,
  enrollmentSummaryHttpRequest,
  drillDownActivationDetailHttpRequest,
  drillDownScreeningDataHttpRequest,
  drillDownScreeningFailureDataHttpRequest,
  drillDownDropoutDataHttpRequest,
  drillDownCompletionDataHttpRequest,
  drillDownEnrollmentDataHttpRequest,
  drillDownActivationDataHttpRequest,
  protocolAmendmentsDataHttpRequest,
  protocolAmendmentsSummaryDataHttpRequest,
  protocolAmendmentsTopReasonsDataHttpRequest,
  complexMilestonesDataHttpRequest,
  userTrialsFilterCheckHttpRequest,
  enrollmentForecastSummaryDataHttpRequest,
  trialEnrollmentDriversDataHttpRequest,
  trialActivationDriversDataHttpRequest,
  listSavedFiltersHttpRequest,
  deleteSavedFiltersHttpRequest,
  risksAndMitigationHttpRequest,
  trialDataSourcesHttpRequest,
  cycleTimesHttpRequest,
  longitudinalRagHttpRequest,
  siteLeaderboardHttpRequest,
  gptGoalsSidebarHttpRequest,
  ragStatusDescriptionHttpRequest,
  numTrialsHttpRequest,
  edcHeaderHttpRequest,
  edcSummaryCardsHttpRequest,
  crfEnteredChartHttpRequest,
  crfEnteredTableHttpRequest,
  crfEntryTimeChartHttpRequest,
  crfEntryTimeTableHttpRequest,
  queryStatusChartHttpRequest,
  queryStatusTableHttpRequest,
  queryAgingChartHttpRequest,
  queryAgingTableHttpRequest,
  queryResolutionTimeChartHttpRequest,
  queryResolutionTimeTableHttpRequest,
  crfsVerifiedChartHttpRequest,
  crfsVerifiedTableHttpRequest,
  crfFrozenTableHttpRequest,
  crfFrozenChartHttpRequest,
  crfsSignedChartHttpRequest,
  crfsSignedTableHttpRequest,
  edcSidePanelHttpRequest,
};

export const API_LIST = [
  // trialListFiltersHttpRequest,
  trialListDataHttpRequest,
  trialCardListDataHttpRequest,
  trialKeyFactsHttpRequest,
  // trialEnrollmentDataHttpRequest,
  trialRosterHttpRequest,
  trialVendorHttpRequest,
  enrollmentSummaryHttpRequest,
  // drillDownActivationDetailHttpRequest,
  drillDownScreeningDataHttpRequest,
  drillDownScreeningFailureDataHttpRequest,
  drillDownDropoutDataHttpRequest,
  drillDownCompletionDataHttpRequest,
  drillDownEnrollmentDataHttpRequest,
  drillDownActivationDataHttpRequest,
  protocolAmendmentsDataHttpRequest,
  protocolAmendmentsSummaryDataHttpRequest,
  protocolAmendmentsTopReasonsDataHttpRequest,
  complexMilestonesDataHttpRequest,
  // userTrialsFilterCheckHttpRequest,
  // enrollmentForecastSummaryDataHttpRequest,
  trialEnrollmentDriversDataHttpRequest,
  trialActivationDriversDataHttpRequest,
  risksAndMitigationHttpRequest,
  trialDataSourcesHttpRequest,
  cycleTimesHttpRequest,
  longitudinalRagHttpRequest,
  siteLeaderboardHttpRequest,
  gptGoalsSidebarHttpRequest,
  ragStatusDescriptionHttpRequest,
  numTrialsHttpRequest,
  edcHeaderHttpRequest,
  edcSummaryCardsHttpRequest,
  crfEnteredChartHttpRequest,
  crfEnteredTableHttpRequest,
  crfEntryTimeChartHttpRequest,
  crfEntryTimeTableHttpRequest,
  queryStatusChartHttpRequest,
  queryStatusTableHttpRequest,
  queryAgingChartHttpRequest,
  queryAgingTableHttpRequest,
  queryResolutionTimeTableHttpRequest,
  crfsVerifiedChartHttpRequest,
  crfsVerifiedTableHttpRequest,
  queryResolutionTimeChartHttpRequest,
  crfFrozenTableHttpRequest,
  crfFrozenChartHttpRequest,
  crfsSignedChartHttpRequest,
  crfsSignedTableHttpRequest,
  edcSidePanelHttpRequest,
];
