import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Badge } from 'primereact/badge';
import { BETA_ITEMS, SUB_MENU_PATH } from '../constants/routeDetails';
import { clip } from '../../../assets/icons';

/**
 *
 * @param {'PortfolioHealthMenu'|'Help'} parentName - should be values of `MENU_ID`
 * @returns
 */
const useMenuSubItems = (parentName) => {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * @type {import('primereact/menuitem').MenuItem[]}
   */
  const items = useMemo(
    () =>
      SUB_MENU_PATH[parentName]
        .filter((item) => !item.disabled)
        .map((item) => ({
          template: (templateItem, options) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              role="menuitem"
              tabIndex={options.tabIndex}
              className={options.className}
              target={templateItem.target}
              onClick={options.onClick}
            >
              {!!item.newTab && item.leftIcon && (
                <img
                  data-testid="new-tab-icon"
                  src={clip}
                  className="me-2"
                  alt="clip"
                />
              )}
              <span className={options.labelClassName}>
                {item.name}
                {BETA_ITEMS.includes(item.name) && (
                  <Badge
                    data-testid="beta-badge"
                    className="ms-1"
                    value="Beta"
                    severity="success"
                  />
                )}
              </span>
              {!!item.newTab && !item.leftIcon && (
                <img
                  data-testid="new-tab-icon"
                  src={clip}
                  className="ms-2"
                  alt="clip"
                />
              )}
            </a>
          ),
          command: () => {
            if (item.newTab) {
              window.open(item.path, '_blank');
              return;
            }
            navigate(item.path);
          },
          className: location.pathname === item.path ? 'active' : '',
        })),
    [parentName, location.pathname, navigate],
  );

  return items;
};

export default useMenuSubItems;
