import {
  gray3,
  ragGreen as colorRagGreen,
  ragRed as colorRagRed,
  ragYellow as colourRagYellow,
} from 'assets/stylesheets/base/_colors';

const DIRECTION = {
  up: `
  <path d="M11.5 8.5V10H20.9425L8.5 22.4425L9.5575 23.5L22 11.0575V20.5H23.5V8.5H11.5Z" fill="white"/>
`,
  flat: `
  <path d="M17.5 8.5L16.4275 9.54475L22.1125 15.25H7V16.75H22.1125L16.4275 22.4298L17.5 23.5L25 16L17.5 8.5Z" fill="white"/>
`,
  down: `
  <path d="M11.9997 22.6666V21.3333H20.393L9.33301 10.2733L10.273 9.33325L21.333 20.3933V11.9999H22.6663V22.6666H11.9997Z" fill="white"/>
`,
};

const drawRag = (color = gray3, direction = null) => {
  const directionHTML = DIRECTION[direction];
  const svgString = `
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" fill="${color}"/>
    ${directionHTML}
  </svg>
`;

  return `data:image/svg+xml;base64,${window.btoa(svgString)}`;
};

export const ragGreenUp = drawRag(colorRagGreen, 'up');
export const ragGreenFlat = drawRag(colorRagGreen, 'flat');
export const ragGreenDown = drawRag(colorRagGreen, 'down');

export const ragAmberUp = drawRag(colourRagYellow, 'up');
export const ragAmberFlat = drawRag(colourRagYellow, 'flat');
export const ragAmberDown = drawRag(colourRagYellow, 'down');

export const ragRedUp = drawRag(colorRagRed, 'up');
export const ragRedFlat = drawRag(colorRagRed, 'flat');
export const ragRedDown = drawRag(colorRagRed, 'down');

export const ragGrayUp = drawRag(gray3, 'up');
export const ragGrayFlat = drawRag(gray3, 'flat');
export const ragGrayDown = drawRag(gray3, 'down');

export const ragRed = drawRag(colorRagRed);
export const ragAmber = drawRag(colourRagYellow);
export const ragGreen = drawRag(colorRagGreen);
export const ragGray = drawRag(gray3);
