export const GPT_GOALS_STATUS_COLOR = {
  Missed: 'red',
  'Achieved early': 'green',
  'Achieved late': 'green',
  'Achievable early': 'green',
  'Achievable late': 'green',
  Achieved: 'green',
  'On track': 'green',
  'At risk': 'amber',
  'Not applicable': 'gray',
  N_A: 'gray',
};

export const GPT_GOALS_STATUS_DESCRIPTION = {
  ACHIEVED_EARLY: 'Achieved early',
  ACHIEVED_LATE: 'Achieved late',
  ACHIEVABLE_EARLY: 'Achievable early',
  ACHIEVABLE_LATE: 'Achievable late',
  ACHIEVED: 'Achieved',
  ON_TRACK: 'On track',
  MISSED: 'Missed',
  AT_RISK: 'At risk',
  NOT_APPLICABLE: 'Not applicable',
  N_A: 'N_A',
};
