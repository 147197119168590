import {
  gray2,
  ragGreen,
  ragGreen60,
  ragRed,
  ragRed60,
  aqua,
  aqua20,
  purple,
} from 'assets/stylesheets/base/_colors';
import StackedColumn from '../../../../../../../utility/generateHighChart/columnChart/StackedColumn.abstract';

class GptGoalsChart extends StackedColumn {
  isStacked = true;

  _colorList = [ragRed, ragGreen];

  set isCompleted(value) {
    this._isCompleted = value;
  }

  get isCompleted() {
    return this._isCompleted;
  }

  _getColorForChart(secondaryCategory) {
    const secondaryCategoryLowerCase = secondaryCategory.toLowerCase();
    if (this._isCompleted)
      return this._getCompletedChartColor(secondaryCategoryLowerCase);
    return this._getProjectedChartColor(secondaryCategoryLowerCase);
  }

  _getCompletedChartColor(secondaryCategory) {
    switch (secondaryCategory) {
      case 'achieved on time':
        return ragGreen60;
      case 'achieved early':
        return ragGreen;
      case 'achieved late':
        return ragRed60;
      case 'missed':
        return ragRed;
      default:
        return gray2;
    }
  }

  _getProjectedChartColor(secondaryCategory) {
    switch (secondaryCategory) {
      case 'at risk':
        return gray2;
      case 'on track':
        return aqua;
      case 'not applicable':
        return aqua20;
      case 'missed':
        return purple;
      default:
        return gray2;
    }
  }
}

export default GptGoalsChart;
