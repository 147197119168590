import { get, isNil } from 'lodash';

function primaryCategoryFilterer(dataObject) {
  const filteredData = dataObject.filter((item) => {
    const primaryCategoryValues = get(item, 'primaryCategoryValues');
    const primaryValues = primaryCategoryValues.filter((secondaryCategory) => {
      if (isNil(secondaryCategory.count) && isNil(secondaryCategory.percentage))
        return null;
      return secondaryCategory;
    });
    if (primaryValues.length === 0) return null;
    return primaryValues;
  });
  return filteredData;
}

export default primaryCategoryFilterer;
