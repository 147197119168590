import { gray2, gray4, gray6 } from 'assets/stylesheets/base/_colors';
import { diamondGray, diamondWhite } from 'assets/icons/diamond';
import { roundOff } from 'utility/math/valueRounder';
import { stopGray } from 'assets/icons';
import { isNil, set, cloneDeep } from 'lodash';
import DIAMOND_BASE_CONFIG from 'dashboards/PortfolioManagement/charts/diamondChartBaseConfig';

const SCATTER_CHART_CONFIGURATION = {
  chart: {
    height: 60,
    type: 'scatter',
    backgroundColor: gray6,
  },
  series: [
    {
      // starting-background-line
      name: '',
      type: 'line',
      color: gray4,
      marker: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
    },
    {
      // ending-background-line
      name: '', //
      type: 'line',
      color: gray4,
      marker: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
    },
    {
      // data line
      name: '', //
      type: 'line',
      color: gray2,
      marker: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
    },
    {
      // stop-points
      name: '', //
      tooltip: {
        pointFormatter() {
          return false;
        },
      },
      dataLabels: {
        enabled: false,
      },
      color: gray2,
      marker: {
        symbol: `url(${stopGray})`,
      },
    },
  ],
  ...DIAMOND_BASE_CONFIG,
};

const valueSeriesDataGenerator = (currentValue, valueData, diamondSymbol) =>
  !isNil(currentValue)
    ? {
        name: '',
        tooltip: {
          pointFormatter() {
            return false;
          },
        },
        dataLabels: {
          format: `<span style="color: ${gray2}; z-index:1">{point.x}</span>`,
        },
        data: valueData,
        color: gray2,
        marker: {
          symbol: `url(${diamondSymbol})`,
        },
      }
    : {};

const getChartData = (val1, val2, precision = 1) => {
  const yCoordinate = 1;
  const minVal = Math.min(val1, val2);
  const maxVal = Math.max(val1, val2);
  const assumedRange = maxVal - minVal;
  const range = assumedRange === 0 ? 10 : assumedRange;
  const stopInitialXCoordinate = minVal - 0.15 * range;
  const stopFinalXCoordinate = maxVal + 0.15 * range;
  const stopPointsHalfRange =
    (stopFinalXCoordinate - stopInitialXCoordinate) / 2.0;
  const startBgLineX1Coordinate = stopInitialXCoordinate - stopPointsHalfRange;
  const endBgLineX2Coordinate = stopFinalXCoordinate + stopPointsHalfRange;
  const startingBackgroundLineData = [
    [startBgLineX1Coordinate, yCoordinate],
    [stopInitialXCoordinate, yCoordinate],
  ];
  const endingBackgroundLineData = [
    [stopFinalXCoordinate, yCoordinate],
    [endBgLineX2Coordinate, yCoordinate],
  ];
  const lineData = [
    [stopInitialXCoordinate, yCoordinate],
    [stopFinalXCoordinate, yCoordinate],
  ];
  const stopPointsData = [
    [stopInitialXCoordinate, yCoordinate],
    [stopFinalXCoordinate, yCoordinate],
  ];
  const val1Data = [[roundOff(val1, precision), yCoordinate]];
  const val2Data = [[roundOff(val2, precision), yCoordinate]];

  const chartData = cloneDeep(SCATTER_CHART_CONFIGURATION);
  set(chartData, 'series[0].data', startingBackgroundLineData);
  set(chartData, 'series[1].data', endingBackgroundLineData);
  set(chartData, 'series[2].data', lineData);
  set(chartData, 'series[3].data', stopPointsData);
  const roundedOffVal1 = roundOff(val1, precision);
  const roundedOffVal2 = roundOff(val2, precision);
  set(
    chartData,
    'series[4]',
    valueSeriesDataGenerator(roundedOffVal1, val1Data, diamondGray),
  );
  set(
    chartData,
    'series[5]',
    valueSeriesDataGenerator(roundedOffVal2, val2Data, diamondWhite),
  );

  return chartData;
};

export default getChartData;
