import { AMENDMENT_GROUP_DATA } from 'dashboards/PortfolioManagement/constants/protocolAmendments.constants';
import { find, map, sortBy } from 'lodash';
import { DATA_POINT_WIDTH } from './chart/data';
import getGroupedByData from './getGroupedByData';

export const getCategoryData = (categoryData) => {
  const sortedCategoryData = sortBy(categoryData, 'groupedKey');
  const categories = map(sortedCategoryData, 'groupedKey');
  const mostFrequentReasons = [
    ...new Set(
      sortedCategoryData.reduce(
        (reasons, dataPoint) => [
          ...reasons,
          ...map(dataPoint.primaryReasons, 'reasonTitle'),
        ],
        [],
      ),
    ),
  ];

  const data = mostFrequentReasons.map((reason) => {
    const dataSet = sortedCategoryData.map((categoryDataPoint) => {
      const point = find(categoryDataPoint.primaryReasons, {
        reasonTitle: reason,
      });
      return point ? point.percentageOfAmendments : 0;
    });
    return {
      name: reason,
      data: dataSet,
      pointPadding: 0.4,
      pointWidth: DATA_POINT_WIDTH,
      dataLabels: {
        enabled: true,
      },
      stack: 'primaryReasons',
    };
  });
  return {
    categories,
    data,
  };
};

export const getStackedChartDataPoints = (key, data) => {
  const { croData, phaseData, tauData, tierData } = getGroupedByData(
    data,
    key,
    getCategoryData,
  );
  return {
    groupedByCro: {
      categories: croData.categories,
      data: croData.data,
      xLabel: AMENDMENT_GROUP_DATA['Group by CRO'].dataLabel,
    },
    groupedByPhase: {
      categories: phaseData.categories,
      data: phaseData.data,
      xLabel: AMENDMENT_GROUP_DATA['Group by Phase'].dataLabel,
    },
    groupedByTau: {
      categories: tauData.categories,
      data: tauData.data,
      xLabel: AMENDMENT_GROUP_DATA['Group by TAU'].dataLabel,
    },
    groupedByTier: {
      categories: tierData.categories,
      data: tierData.data,
      xLabel: AMENDMENT_GROUP_DATA['Group by Tier'].dataLabel,
    },
  };
};
