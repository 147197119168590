import DateStringTransform from 'utility/date/dateString.utility';
import { get, sortBy, isNil } from 'lodash';
import EdcLine from 'utility/generateHighChart/columnChart/utility/edcLine.abstract';

const crfsCountPercentTransform = (
  data,
  countYAxisLabel,
  percentYAxisLabel,
) => {
  const responseArray = get(data, 'values', []);
  const filteredDataXAxis = responseArray.filter((site) => !isNil(site.date));

  const sortedResponseData = sortBy(
    filteredDataXAxis,
    (site) => new DateStringTransform(site.date).dateObject,
  );

  const countGroupLine = new EdcLine(countYAxisLabel);
  countGroupLine.xAxisKey = 'date';
  countGroupLine.yAxisKey = 'count';
  const countChartData = countGroupLine.generateChart(sortedResponseData);

  const percentGroupLine = new EdcLine(percentYAxisLabel);
  percentGroupLine.xAxisKey = 'date';
  percentGroupLine.yAxisKey = 'percent';
  const percentChartData = percentGroupLine.generateChart(sortedResponseData);

  return {
    countChartData,
    percentChartData,
    cardList: get(data, 'cardList', []),
  };
};

export default crfsCountPercentTransform;
