import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import transform from './utility/22_gptGoalsSideBar/gptGoalsSidebar.transform';

const gptGoalsSidebarHttpRequest = (() => {
  const apiName = 'gptGoalsSidebar';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/gpt-goals/trial/:trialId`;
  generatedHttpThunk.transform = transform;
  return generatedHttpThunk.generateTrialThunk();
})();

export default gptGoalsSidebarHttpRequest;
