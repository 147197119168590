import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import enrollmentMedianCardsTransform from './utility/enrollmentMedianCardsTransform';

const enrollmentMetricsDataHttpRequest = (() => {
  const apiName = 'enrollmentMetrics';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource =
    'site-health/site/:siteId/trial/:trialId/recruitment-metrics';
  generatedHttpThunk.transform = enrollmentMedianCardsTransform;
  return generatedHttpThunk.generateSiteTrialThunk();
})();

export default enrollmentMetricsDataHttpRequest;
