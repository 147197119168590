const METRICS_WITH_HUNDRETHS_PLACE_PRECISION = [
  'Enrollment',
  'Screening events',
];
const getMetricPrecisionValue = (metricName) => {
  const doesMetricHAveHundredthsPlacePrecision =
    METRICS_WITH_HUNDRETHS_PLACE_PRECISION.includes(metricName);
  return doesMetricHAveHundredthsPlacePrecision
    ? { cardValuePrecision: 2, cardValueFixedPrecisionPoints: 2 }
    : { cardValuePrecision: 1, cardValueFixedPrecisionPoints: null };
};
export default getMetricPrecisionValue;
