import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { SafeHtmlElements } from 'components/SafeComponents';
import { Toast } from 'primereact/toast';
import { bookmark, bookmarkSelected } from '../../assets/icons';
import TrialBookMarkHttpRequest from './utility/http.utility';

function Bookmark({
  trialId,
  isBookmarked,
  onClose,
  afterConfirm,
  performAfterConfirmAction,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const toast = useRef(null);
  const trialBookMarkHttpRequest = new TrialBookMarkHttpRequest(trialId);

  const showErrorToast = (detail) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail });
  };

  const onConfirmation = async () => {
    setIsLoading(true);
    if (!isBookmarked) {
      try {
        await trialBookMarkHttpRequest.mark();
      } catch (error) {
        showErrorToast('Failed to add a bookmark.');
      }
    } else {
      try {
        await trialBookMarkHttpRequest.unMark();
        if (performAfterConfirmAction) await afterConfirm();
      } catch (error) {
        showErrorToast('Failed to remove a bookmark.');
      }
    }
    onClose({ reload: true });
    setIsLoading(false);
    setShowDialog(false);
  };

  const onCancelCancelation = useCallback(() => {
    setShowDialog(false);
    onClose({ reload: false });
  }, []);

  const onClickHandler = useCallback(() => {
    setShowDialog(true);
  }, []);

  return (
    <div onClick={(clickEvent) => clickEvent.stopPropagation()}>
      <img
        src={isBookmarked ? bookmarkSelected : bookmark}
        alt="isBookmarked"
        role="presentation"
        data-testid="bookmark-icon"
        onClick={onClickHandler}
      />
      {showDialog && (
        <div>
          <ConfirmationDialog
            isLoading={isLoading}
            content={
              <p>
                You are&nbsp;
                {isBookmarked ? 'removing' : 'saving'}
                &nbsp;
                <SafeHtmlElements tagName="span">{trialId}</SafeHtmlElements>
                &nbsp;trial {isBookmarked ? 'from' : 'to'} &quot;My Trials&quot;
              </p>
            }
            onConfirmation={onConfirmation}
            onCancel={onCancelCancelation}
          />

          <Toast ref={toast} />
        </div>
      )}
    </div>
  );
}

Bookmark.propTypes = {
  isBookmarked: PropTypes.bool.isRequired,
  trialId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  afterConfirm: PropTypes.func,
  performAfterConfirmAction: PropTypes.bool,
};

Bookmark.defaultProps = {
  onClose: () => {},
  afterConfirm: () => {},
  performAfterConfirmAction: false,
};

export default Bookmark;
