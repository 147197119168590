import { takedaRed } from 'assets/stylesheets/base/_colors';

const drawViewIcon = (color) => {
  const svgString = `
 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5332 4.04604C13.8608 4.99331 14.8817 6.30882 15.4698 7.83C15.5095 7.93985 15.5095 8.06015 15.4698 8.17C14.8817 9.69118 13.8608 11.0067 12.5332 11.954C11.2056 12.9012 9.62953 13.4387 7.99979 13.5C6.37005 13.4387 4.79398 12.9012 3.46639 11.954C2.1388 11.0067 1.11787 9.69118 0.529787 8.17C0.490071 8.06015 0.490071 7.93985 0.529787 7.83C1.11787 6.30882 2.1388 4.99331 3.46639 4.04604C4.79398 3.09878 6.37005 2.56129 7.99979 2.5C9.62953 2.56129 11.2056 3.09878 12.5332 4.04604ZM1.53479 8C2.54979 10.535 5.34979 12.5 7.99979 12.5C10.6498 12.5 13.4498 10.535 14.4648 8C13.4498 5.465 10.6498 3.5 7.99979 3.5C5.34979 3.5 2.54979 5.465 1.53479 8ZM6.33308 5.50559C6.82642 5.17595 7.40644 5 7.99979 5C8.79544 5 9.5585 5.31607 10.1211 5.87868C10.6837 6.44129 10.9998 7.20435 10.9998 8C10.9998 8.59334 10.8238 9.17336 10.4942 9.66671C10.1646 10.1601 9.69602 10.5446 9.14784 10.7716C8.59966 10.9987 7.99646 11.0581 7.41452 10.9424C6.83257 10.8266 6.29802 10.5409 5.87847 10.1213C5.45891 9.70176 5.17319 9.16721 5.05743 8.58527C4.94168 8.00333 5.00109 7.40013 5.22815 6.85195C5.45521 6.30377 5.83973 5.83524 6.33308 5.50559ZM6.88865 9.66294C7.21755 9.8827 7.60423 10 7.99979 10C8.53022 10 9.03893 9.78929 9.414 9.41421C9.78907 9.03914 9.99979 8.53043 9.99979 8C9.99979 7.60444 9.88249 7.21776 9.66273 6.88886C9.44296 6.55996 9.13061 6.30362 8.76515 6.15224C8.3997 6.00087 7.99757 5.96126 7.60961 6.03843C7.22164 6.1156 6.86528 6.30608 6.58557 6.58579C6.30587 6.86549 6.11539 7.22186 6.03822 7.60982C5.96105 7.99778 6.00065 8.39991 6.15203 8.76537C6.3034 9.13082 6.55975 9.44318 6.88865 9.66294Z" fill="${color}"/>
</svg>
`;

  return `data:image/svg+xml;base64,${window.btoa(svgString)}`;
};

export const viewIcon = drawViewIcon('#15141F');
export const hightlightedViewIcon = drawViewIcon(takedaRed);
