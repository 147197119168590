import PropTypes from 'prop-types';
import React from 'react';
import { isNil } from 'lodash';
import { NOT_AVAILABLE_PLACEHOLDER } from 'constants';
import { roundOff } from 'utility/math/valueRounder';
import ICON_MAP from './iconsMap.constant';

function TrendMeasurement(props) {
  const { magnitude, unit, precision } = props;
  const { placeholder, separator } = props;
  const { className, onClick, dataTestId, key } = props;

  const { isTrendingUp, showTrendIcon, showColor } = props;
  const { fixedTrailingPoints } = props;

  const roundedMagnitude = (() => {
    if (
      isNil(magnitude) ||
      Number.isNaN(magnitude) ||
      !Number.isFinite(magnitude)
    )
      return null;
    if (!isNil(fixedTrailingPoints))
      return roundOff(magnitude, precision).toFixed(fixedTrailingPoints);
    return roundOff(magnitude, precision);
  })();

  let magnitudeClass = '';
  if (!isNil(roundedMagnitude)) {
    switch (true) {
      case roundedMagnitude === 0: {
        magnitudeClass = 'zero';
        break;
      }
      case roundedMagnitude > 0: {
        magnitudeClass = 'positive';
        break;
      }
      case roundedMagnitude < 0: {
        magnitudeClass = 'negative';
        break;
      }
      default:
        break;
    }
  }

  const trendIcon = ICON_MAP[isTrendingUp];
  const displayMeasurementClassName = showColor
    ? `${className} magnitude-${magnitudeClass} trend-measurement`
    : `${className} trend-measurement`;

  const absoluteRoundedMagnitude = Math.abs(roundedMagnitude);
  const displayedMagnitude = showColor
    ? absoluteRoundedMagnitude
    : roundedMagnitude;

  if (isNil(roundedMagnitude) && !isNil(unit)) {
    return (
      <p
        className={displayMeasurementClassName}
        onClick={onClick}
        data-testid={dataTestId}
        key={key}
      >
        <span className="magnitude placeholder">{placeholder}</span>
        <span className="separator">{separator}</span>
        <span className="unit">{unit}</span>
      </p>
    );
  }
  if (!isNil(roundedMagnitude) && isNil(unit)) {
    return (
      <p
        className={`${displayMeasurementClassName} d-flex align-items-center`}
        onClick={onClick}
        data-testid={dataTestId}
        key={key}
      >
        <span className="magnitude me-1">{displayedMagnitude}</span>
        {showTrendIcon && trendIcon && displayedMagnitude !== 0 && (
          <img src={trendIcon} alt={magnitudeClass} />
        )}
      </p>
    );
  }
  if (isNil(roundedMagnitude) && isNil(unit)) {
    return (
      <p
        className={displayMeasurementClassName}
        onClick={onClick}
        data-testid={dataTestId}
        key={key}
      >
        <span className="magnitude placeholder">{placeholder}</span>
      </p>
    );
  }
  return (
    <p
      className={`${displayMeasurementClassName} d-flex align-items-center`}
      onClick={onClick}
      data-testid={dataTestId}
      key={key}
    >
      <span className="magnitude">{displayedMagnitude}</span>
      <span className="separator">{separator}</span>
      <span className="unit me-1">{unit}</span>
      {showTrendIcon && trendIcon && displayedMagnitude !== 0 && (
        <img src={trendIcon} alt={magnitudeClass} />
      )}
    </p>
  );
}

TrendMeasurement.propTypes = {
  magnitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  precision: PropTypes.number,
  unit: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  separator: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  key: PropTypes.string,
  showTrendIcon: PropTypes.bool,
  showColor: PropTypes.bool,
  isTrendingUp: PropTypes.bool,
  fixedTrailingPoints: PropTypes.number,
};
TrendMeasurement.defaultProps = {
  magnitude: null,
  unit: null,
  precision: 1,
  placeholder: NOT_AVAILABLE_PLACEHOLDER,
  className: '',
  separator: <>&nbsp;</>,
  onClick: null,
  dataTestId: '',
  key: '',
  showTrendIcon: false,
  isTrendingUp: null,
  showColor: false,
  fixedTrailingPoints: null,
};
export default TrendMeasurement;
