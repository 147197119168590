import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import gptDataTransform from './transforms/gptGoals.transform';

const gptGoalsDataHttpRequest = ((params) => {
  const apiName = 'gptGoalsDetails';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/trial-delivery-gpt-goals`;
  generatedHttpThunk.transform = gptDataTransform;
  return generatedHttpThunk.generateThunk(params);
})();

export default gptGoalsDataHttpRequest;
