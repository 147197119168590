import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { myTrialsListHttpRequest } from 'store/thunks';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import SearchBar from 'components/CompassFilter/elements/SearchBar';
import { generateSafeCell, SafeHtmlElements } from 'components/SafeComponents';
import Error from 'components/Error';
import Loader from 'components/Loader';
import { useSectionState, useThunkSelector } from 'utility/redux';
import { useNavigate } from 'react-router';
import { compassStore, compassActions } from 'store';
import { DataListFilter } from 'utility';
import { TrialSingularOrPlural } from 'utility/getPluralsOrSingular';
import BookmarkTemplate from './columns/Bookmark.column';

import './MyTrialsSection.scss';

function MyTrialsSection({ setSidebarVisible }) {
  const { dispatch } = compassStore;

  const sectionId = 'MY_TRAILS_SECTION_ID';
  const myTrialsResponse = useThunkSelector('myTrialsList', 'records');

  const [filteredTrials, setFilteredTrials] = useState([]);

  const searchTerm = useSectionState(sectionId, 'search');

  const [searchText, setSearchText] = useState(searchTerm);

  const dataListFilter = useRef(new DataListFilter([]));

  useEffect(() => {
    if (myTrialsResponse.isSuccess) {
      const { payload: recordList } = myTrialsResponse;
      dataListFilter.current.dataList = recordList;
      dataListFilter.current.searchFields = ['protocolNumberHarmonized'];
      const searchQuery = { searchTerm };
      const filteredTrialsList = dataListFilter.current.search(searchQuery);
      setFilteredTrials(filteredTrialsList);
    }
  }, [myTrialsResponse.payloadHash, searchTerm]);

  useEffect(() => {
    dispatch(myTrialsListHttpRequest());
  }, []);

  const navigate = useNavigate();

  const setBlockPreferences = (type, value) => {
    const preferencesConfig = { type, value, sectionId };
    dispatch(compassActions.preferencesConfigAction(preferencesConfig));
  };

  const onRowClick = (onClickEvent) => {
    const path = `/trial-management/details/${onClickEvent.data.protocolNumberHarmonized}`;
    const classNames = new Array(
      ...onClickEvent.originalEvent.target.classList,
    );
    if (classNames.includes('bookmark-column')) {
      return;
    }

    setSidebarVisible(false);
    navigate(path);
  };

  const onSearchChangeHandler = useCallback((searchEvent) => {
    setSearchText(searchEvent);
  }, []);

  const onSearchSubmitHandler = useCallback((searchEvent) => {
    setBlockPreferences('search', searchEvent);
  }, []);

  const trailOverviewNavigationHandler = useCallback((clickEvent) => {
    clickEvent.preventDefault();
    const path = '/trial-management/overview';
    navigate(path);
    setSidebarVisible(false);
  }, []);

  return (
    <div className="row g-0">
      <div className="col-lg-4 col-12">
        <SearchBar
          onChange={onSearchChangeHandler}
          searchValue={searchText}
          placeholderText="Search"
          onSubmitSearch={onSearchSubmitHandler}
        />
      </div>
      <div className="col-12 my-2">
        {myTrialsResponse.isError && <Error error={myTrialsResponse.error} />}
        {myTrialsResponse.isLoading && <Loader className="my-5 py-5" />}
        {myTrialsResponse.isSuccess && (
          <div className="row g-0">
            <div className="col-12 mt-2 total-my-trials">
              <SafeHtmlElements tagName="span">
                {filteredTrials.length}
              </SafeHtmlElements>
              &nbsp;
              {new TrialSingularOrPlural().getWord(filteredTrials.length)}
            </div>
            <div className="col-12 mt-3">
              <DataTable
                value={filteredTrials}
                rows={10}
                paginator
                scrollable
                responsiveLayout="scroll"
                className="user-preferences-my-trials-table clickable-row-datatable"
                data-testid="user-preferences-my-trials-table"
                onRowClick={onRowClick}
              >
                <Column
                  field="bookmark.value"
                  className="bookmark-column"
                  body={BookmarkTemplate}
                  frozen
                />
                <Column
                  field="protocolNumberHarmonized"
                  header="Protocol ID"
                  sortable
                  frozen
                  body={generateSafeCell('protocolNumberHarmonized')}
                />
                <Column
                  field="phase"
                  header="Phase"
                  sortable
                  body={generateSafeCell('phase')}
                />
                <Column
                  field="tier"
                  header="Tier"
                  sortable
                  body={generateSafeCell('tier')}
                />
                <Column
                  field="tau"
                  header="TAU"
                  sortable
                  body={generateSafeCell('tau')}
                  className="tau-column"
                />
                <Column
                  field="protocolStatus"
                  header="Protocol status"
                  sortable
                  body={generateSafeCell('protocolStatus')}
                />
              </DataTable>
            </div>
          </div>
        )}
      </div>
      <div className="col-12 pb-5" onClick={() => setSidebarVisible(false)}>
        <p>
          Click the bookmark icons next to trial Protocol IDs to add / remove
          trials from My Trials. Navigate to the&nbsp;
          <a
            onClick={trailOverviewNavigationHandler}
            href="./#"
            data-testid="navigate-trial-overview"
          >
            Trial Overview
          </a>
          &nbsp;screen for a full list of trials.
        </p>
      </div>
    </div>
  );
}

MyTrialsSection.propTypes = {
  setSidebarVisible: PropTypes.func.isRequired,
};

export default MyTrialsSection;
