export const TIMELINE_ITEMS = {
  // For COMPASSINS-1778: "Removing original plan"
  // ORIGINAL: 'Original plan',
  PLANNED: 'Current plan',
  ACTUAL_AND_FORECAST: 'Actual / forecast',
};

export const TIMELINE_TYPE = {
  SIMPLE: 'simple',
  COMPLEX: 'complex',
};

export const TEMP = null;
