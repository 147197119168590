import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import ErrorFallbackComponent from './ErrorFallbackComponent';

function CompassErrorBoundary(props) {
  const { children } = props;
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
      {children}
    </ErrorBoundary>
  );
}

CompassErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CompassErrorBoundary;
