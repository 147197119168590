import DIAMOND_BASE_CONFIG from 'dashboards/PortfolioManagement/charts/diamondChartBaseConfig';

const baseConfig = {
  chart: {
    height: 60,
    type: 'scatter',
  },
  ...DIAMOND_BASE_CONFIG,
};

export default baseConfig;
