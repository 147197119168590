import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const trialVendorHttpRequest = (() => {
  const apiName = 'venders';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/trial-vendors/trial/:trialId`;
  return generatedHttpThunk.generateTrialThunk();
})();

export default trialVendorHttpRequest;
