import {
  gray3,
  gray5,
  ragGreen,
  ragRed,
  ragYellow,
} from 'assets/stylesheets/base/_colors';

const drawRagTrend = (color = gray3) => {
  const svgString = `
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="9.07422" width="13.75" height="13.75" rx="2" transform="rotate(-45 0 9.07422)" fill="${color}"/>
    </svg>
  `;

  return `data:image/svg+xml;base64,${window.btoa(svgString)}`;
};

export const ragGreenDiamond = drawRagTrend(ragGreen);
export const ragRedDiamond = drawRagTrend(ragRed);
export const ragAmberDiamond = drawRagTrend(ragYellow);
export const ragGrayDiamond = drawRagTrend(gray5);
