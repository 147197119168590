import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const siteInfoTrialFiltersHttpRequest = (() => {
  const apiName = 'siteInfoTrialFilters';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/trial-filters`;
  return generatedHttpThunk.generateSiteThunk();
})();

export default siteInfoTrialFiltersHttpRequest;
