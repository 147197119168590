import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import crfEnteredTransform from './utility/crfEntered.transform';
import crfSortTransform from '../../__utility__/crfSort.transform';

export const crfEnteredChartHttpRequest = (() => {
  const apiName = 'crfEnteredChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-crfs-entered-chart/trial/:trialId`;
  generatedHttpThunk.transform = crfEnteredTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export const crfEnteredTableHttpRequest = (() => {
  const apiName = 'crfEnteredTable';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource =
    'trial-management/edc-crfs-entered-table/trial/:trialId';
  generatedHttpThunk.transform = crfSortTransform;
  return generatedHttpThunk.generateTrialThunk();
})();
