/* eslint-disable no-case-declarations */
/* eslint-disable max-lines-per-function */
import generateHash from '../hash';

const HTTP_INITIAL_STATE = {
  payload: null,
  isLoading: true,
  isBackgroundLoading: true,
  isSuccess: false,
  isError: false,
  error: null,
  payloadHash: null,
  updatedAt: +new Date(),
};

const generateHttpReducer = (key) => {
  const httpActions = {
    START: `${key}-:-START`,
    BACKGROUND_START: `${key}-:-BACKGROUND_START`,
    RESET: `${key}-:-RESET`,
    SUCCESS: `${key}-:-SUCCESS`,
    ERROR: `${key}-:-ERROR`,
    MOCK: `${key}-:-MOCK`,
  };

  const reducer = (storeState = HTTP_INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case httpActions.RESET:
        return HTTP_INITIAL_STATE;
      case httpActions.SUCCESS:
        const successData = {
          ...storeState,
          payload: action.payload.data,
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: null,
          payloadHash: null,
          updatedAt: +new Date(),
          isBackgroundLoading: false,
        };
        successData.payloadHash = generateHash(JSON.stringify(successData));
        return successData;
      case httpActions.ERROR:
        const errorData = {
          ...storeState,
          payload: null,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: action.error,
          payloadHash: 'error',
          updatedAt: +new Date(),
          isBackgroundLoading: false,
        };
        return errorData;
      case httpActions.MOCK:
        const mockData = {
          ...storeState,
          payload: action.payload.data,
          isLoading: true,
          isSuccess: true,
          isError: true,
          error: { message: 'mock' },
          payloadHash: null,
          updatedAt: +new Date(),
          isBackgroundLoading: true,
        };
        mockData.payloadHash = generateHash(JSON.stringify(mockData));
        return mockData;
      case httpActions.START:
        const startData = {
          ...storeState,
          payload: null,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: null,
          payloadHash: null,
          updatedAt: +new Date(),
          isBackgroundLoading: true,
        };
        return startData;
      case httpActions.BACKGROUND_START:
        const backgroundStartData = {
          ...storeState,
          isBackgroundLoading: true,
        };
        backgroundStartData.payloadHash = generateHash(
          JSON.stringify(backgroundStartData),
        );
        return backgroundStartData;
      default:
        return storeState;
    }
  };

  return reducer;
};

export default generateHttpReducer;
