import axios from 'axios';
import { generateHttpActions } from '../../../../utility';
import transformSeriesData from './utility/05_enrollmentAndScreening.transform';
import mockResponse from './utility/emptyResponse';

function siteTrialEnrollmentAndScreeningHttpRequest(siteId, trialId) {
  const httpActions = generateHttpActions('siteTrialEnrollmentAndScreening');
  const enrollmentResource = `site-health/site/${siteId}/trial/${trialId}/enrollment`;
  const screeningResource = `site-health/site/${siteId}/trial/${trialId}/screening`;
  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const enrollmentResponse = axios.get(enrollmentResource).then(
        (response) => response,
        () => mockResponse,
      );
      const screeningResponse = axios.get(screeningResource).then(
        (response) => response,
        () => mockResponse,
      );
      const combinedResponse = await Promise.all([
        enrollmentResponse,
        screeningResponse,
      ]);
      const transformedData = transformSeriesData(
        combinedResponse[0].data,
        combinedResponse[1].data,
      );

      dispatch(httpActions.success({ data: transformedData }));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default siteTrialEnrollmentAndScreeningHttpRequest;
