import React from 'react';
import { Provider } from 'react-redux';
import { portfolioManagementStore } from 'dashboards/PortfolioManagement/store';
import Loader from 'components/Loader';
import Error from 'components/Error';
import { useThunkSelector } from 'utility/redux';

const PortfolioHealth = React.lazy(() =>
  import('../../dashboards/PortfolioManagement/PortfolioManagement'),
);

function TrialPortfolioRoute() {
  const pageAccess = useThunkSelector('moduleAccess');

  return (
    <React.Suspense fallback={<Loader className="my-5 py-5" />}>
      <Provider store={portfolioManagementStore}>
        {pageAccess.isLoading && <Loader className="my-5 py-5" />}
        {pageAccess.isError && <Error error={pageAccess.error} />}
        {pageAccess.isSuccess && (
          <>
            {pageAccess.payload.trialPortfolio && <PortfolioHealth />}
            {!pageAccess.payload.trialPortfolio && <p>No Access</p>}
          </>
        )}
      </Provider>
    </React.Suspense>
  );
}

export default TrialPortfolioRoute;
