import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import transformTrialSummary from './utility/transformTrialSummary';

const siteInfoTrialSummaryHttpRequest = (() => {
  const apiName = 'siteInfoTrialSummary';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/site/:siteId/trial-summary`;
  generatedHttpThunk.transform = transformTrialSummary;
  return generatedHttpThunk.generateSiteThunk();
})();

export default siteInfoTrialSummaryHttpRequest;
