import crfsCountPercentTransform from '../../../__utility__/crfsCountPercentTransform';

const crfEnteredTransform = (data) => {
  const countYAxisLabel = 'CRFs entered';
  const percentYAxisLabel = '% CRFs entered';

  return crfsCountPercentTransform(data, countYAxisLabel, percentYAxisLabel);
};

export default crfEnteredTransform;
