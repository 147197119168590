import { PERCENT_COUNT_TOGGLE_OPTIONS } from '../../../constants/trialDeliveryGoal.constants';
import {
  CRITICAL_MILESTONES_COMPLETED_CARD,
  CRITICAL_MILESTONES_PROJECTED_CARD,
  CRITICAL_MILESTONES_SECTION_CONTENT,
} from './01_criticalMilestonesCard.constants';

const TDG_MILESTONE_SECTION_CONTENT = {
  label: 'Clinical milestones',
  key: 'CRITICAL_MILESTONES',
  value: CRITICAL_MILESTONES_SECTION_CONTENT,
  cards: [
    ...CRITICAL_MILESTONES_COMPLETED_CARD,
    ...CRITICAL_MILESTONES_PROJECTED_CARD,
  ],
};

export const TDG_MILESTONE_INITIAL_STATE = (() => {
  const { cards } = TDG_MILESTONE_SECTION_CONTENT;
  const filters = {
    GROUP: TDG_MILESTONE_SECTION_CONTENT.value.GROUP[1].value,
    DURATION: TDG_MILESTONE_SECTION_CONTENT.value.GROUP[1].duration[0].value,
  };
  const cardSelection = { IS_ACTIVE: {}, ACTIVE_TOGGLE: {} };
  cards.forEach(({ key }) => {
    cardSelection.IS_ACTIVE[key] = true;
    cardSelection.ACTIVE_TOGGLE[key] = PERCENT_COUNT_TOGGLE_OPTIONS[0].value;
  });

  const initialState = {
    ...filters,
    ...cardSelection,
  };

  return initialState;
})();

export default TDG_MILESTONE_SECTION_CONTENT;
