import GeneratePreferenceReducer from 'utility/redux/generatePreferenceReducer';
import INITIAL_STATE from './initialState';

const preferenceReducer = (() => {
  const generatePreferenceReducer = new GeneratePreferenceReducer('compass');
  generatePreferenceReducer.initialState = INITIAL_STATE;
  return generatePreferenceReducer.generator();
})();

export default preferenceReducer;
