import { aqua, aqua20 } from 'assets/stylesheets/base/_colors';
import Generator from './generator';

export default class ForecastCureGenerator extends Generator {
  _getForecastSeriesData(objectArray) {
    const forecastedKey = 'forecastEnrollmentMidQuantile';

    const enrolmentSeriesData = {
      forecast: this._getSeriesData(objectArray, forecastedKey),
    };

    return enrolmentSeriesData;
  }

  _generateAreaChart(objectArray) {
    const pt0Key = 'forecastEnrollmentUpperQuartile';
    const shadedAreaTopLimits = this._getSeriesData(objectArray, pt0Key, false);

    const pt1key = 'forecastEnrollmentLowerQuartile';
    const shadedAreaBottomLimits = this._getSeriesData(
      objectArray,
      pt1key,
      false,
    );

    const combinedAreaData = [];
    shadedAreaTopLimits.forEach((topPoint, index) => {
      combinedAreaData.push([
        topPoint.x,
        shadedAreaBottomLimits[index].y,
        topPoint.y,
      ]);
    });

    return combinedAreaData;
  }

  getSeries(objectArray) {
    const lineSeriesData = this._getForecastSeriesData(objectArray);
    const areaSeriesData = this._generateAreaChart(objectArray);

    const forecastedLine = {
      id: null,
      name: 'Forecast',
      type: 'line',
      data: lineSeriesData.forecast,
      color: aqua,
      enableMouseTracking: true,
      showInLegend: false,
      dashStyle: 'ShortDash',
      customTags: ['enrollment', 'forecast'],
      linkedTo: 'actualEnrollment',
      yAxis: 0,
    };

    const areaChart = {
      name: 'Forecast range',
      data: areaSeriesData,
      type: 'arearange',
      color: aqua20,
      zIndex: 0,
      showInLegend: false,
      linkedTo: 'actualEnrollment',
      customTags: ['enrollment', 'forecast_area_range'],
    };

    const forecastSeries = [areaChart, forecastedLine];

    return forecastSeries;
  }
}
