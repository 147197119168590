import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const keyPrincipalInvestigatorsHttpRequest = (() => {
  const apiName = 'siteInfoKeyPrincipalInvestigators';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/site/:siteId/investigators`;
  return generatedHttpThunk.generateSiteThunk();
})();

export default keyPrincipalInvestigatorsHttpRequest;
