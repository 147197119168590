import { get, isNil } from 'lodash';
import DateStringTransform from 'utility/date/dateString.utility';
import { SHORT_UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import QueryAging from './queryAgingChart.abstract';

function queryAgingScreening(values) {
  const transformedData = values.map((value) => {
    const calendarMonth = new DateStringTransform(
      get(value, 'calendarMonth', ''),
    ).formatter(SHORT_UI_DISPLAY_DATE);
    const agingData = {
      name: calendarMonth,
      primaryCategoryName: calendarMonth,
      primaryCategoryValues: [
        {
          secondaryCategory: 'Open 1-7 days',
          count: get(value, 'numQueries1to7', 0),
          percentage: get(value, 'percentQueries1to7', 0),
        },
        {
          secondaryCategory: 'Open 8-28 days',
          count: get(value, 'numQueries7to28', 0),
          percentage: get(value, 'percentQueries7to28', 0),
        },
        {
          secondaryCategory: 'Open > 28 days',
          count: get(value, 'numQueriesGt28', 0),
          percentage: get(value, 'percentQueriesGt28', 0),
        },
      ],
    };
    return agingData;
  });
  const transformedDataWithoutNullValues = transformedData.filter(
    (data) => !!data.primaryCategoryName,
  );
  return transformedDataWithoutNullValues;
}

function stackedChartDataTransform(responseData, _, additionalParams) {
  const responseArray = queryAgingScreening(get(responseData, 'values', []));
  const groupBy = get(additionalParams, 'groupBy', null);

  // Generate Percentage Data
  const groupPercentage = new QueryAging('percentage');
  groupPercentage.yAxisLabel = '% Queries open';
  groupPercentage.hasXAxisLegend = true;
  groupPercentage.showTotal = !isNil(groupBy);
  const chartDataPercentage = groupPercentage.generateChart(responseArray);

  // Generate Count Data
  const groupCount = new QueryAging('count');
  groupCount.yAxisLabel = 'Queries open';
  groupCount.hasXAxisLegend = true;
  groupCount.showTotal = !isNil(groupBy);
  const chartDataCount = groupCount.generateChart(responseArray);

  const returnData = {
    chartDataPercentage,
    chartDataCount,
    cardList: get(responseData, 'cardList', []),
  };
  return returnData;
}
export default stackedChartDataTransform;
