import { get, isNil, map, uniq, update } from 'lodash';
import { transformationFunctionWrapper } from 'utility/errors';
import { roundOff } from 'utility/math';

export const COLUMNS_WITH_PRECISION = [
  { key: 'l3mRate.label', precision: 2 },
  { key: 'totalRate.label', precision: 2 },
  { key: 'previousL3mRate.label', precision: 2 },
  { key: 'previousTotalRate.label', precision: 2 },
  { key: 'screeningRate.label', precision: 2 },
];

const applyPrecisionToColumns = (dataValues) => {
  const preciseDataValues = map(dataValues, (row) => {
    const preciseRow = { ...row };
    if (get(preciseRow, 'statusCategory.label') === 'Dormant')
      update(preciseRow, 'statusCategory', () => ({
        label: 'Active | Dormant',
        value: 'Active | Dormant',
      }));
    COLUMNS_WITH_PRECISION.forEach(({ key, precision }) => {
      update(preciseRow, key, (value) => roundOff(value, precision, null));
    });
    return preciseRow;
  });
  return preciseDataValues;
};

const extractUniqueCountryCodes = (data) => {
  const countryCodes = map(data, 'countryCode.label', null);
  const uniqueCountryCodes = uniq(countryCodes)
    .filter((countryCode) => !isNil(countryCode) && countryCode !== '')
    .sort(
      (firstCountryCodeValue, secondCountryCodeValue) =>
        firstCountryCodeValue - secondCountryCodeValue,
    )
    .map((countryCode) => ({ label: countryCode, value: countryCode }));
  return uniqueCountryCodes;
};

const transformSiteLeaderboardTable = (siteData) => ({
  tableData: applyPrecisionToColumns(siteData),
  countriesFilterOptions: [
    'All countries',
    ...extractUniqueCountryCodes(siteData),
  ],
});
export default transformationFunctionWrapper(transformSiteLeaderboardTable);
