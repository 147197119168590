import {
  aqua40,
  darkAqua,
  gray2,
  gray3,
  gray5,
} from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';
import { find, get, isNil, keyBy, map, values } from 'lodash';
import {
  UI_DISPLAY_DATE,
  SHORT_UI_DISPLAY_DATE,
} from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';
import { roundOff } from 'utility/math/valueRounder';
import METRICS_DATA_KEYS from './constants/enrollmentForecastMetricsConstants';

class EnrollmentForecastSummaryTransform {
  _xAxisLabel = 'Forcast LSI date';

  _yAxisLabel = 'Likelihood of meeting LSI in month';

  _serieslabel = 'Probability of forecast LSI';

  _maxColumnValueWithoutLabel = 1;

  constructor() {
    this._metrics = [];
    this._likelihoodByMonth = [];
  }

  set metrics(metrics) {
    this._metrics = metrics;
  }

  get metrics() {
    return this._metrics;
  }

  set likelihoodByMonth(likelihoodByMonth) {
    this._likelihoodByMonth = likelihoodByMonth;
  }

  get likelihoodByMonth() {
    return this._likelihoodByMonth;
  }

  _forecastMetricsValues() {
    const EMPTY_VALUE_UNIT_STRUCTURE = {
      value: null,
      valueUnit: null,
    };

    let forecastMetrics = {};

    METRICS_DATA_KEYS.forEach(({ metric, key }) => {
      const metricResponse = find(this._metrics, ['metric', metric]);
      forecastMetrics[key] = isNil(metricResponse)
        ? EMPTY_VALUE_UNIT_STRUCTURE
        : metricResponse;
    });

    const plannedLSIDate = new DateStringTransform(
      forecastMetrics.plannedLSI.value,
    ).formatter(UI_DISPLAY_DATE);

    const forecastLSIDate = new DateStringTransform(
      forecastMetrics.forecastLSI.value,
    ).formatter(UI_DISPLAY_DATE);

    const forecastProbabilityRange =
      get(forecastMetrics, 'modelInputSubjects.value', null) >= 20
        ? 'large'
        : 'small';
    const plannedLSIMonth = get(forecastMetrics, 'plannedLSI.value');
    const forecastLSIMonth = get(forecastMetrics, 'forecastLSI.value');

    const isForecastAndPlannedSame = plannedLSIMonth === forecastLSIMonth;
    forecastMetrics = {
      ...forecastMetrics,
      isForecastAndPlannedSame,
      plannedLSIDate,
      forecastLSIDate,
      forecastProbabilityRange,
    };
    return forecastMetrics;
  }

  _xAxisLabels() {
    const forecastMetrics = this._forecastMetricsValues();
    const xAxisDates = map(this._likelihoodByMonth, 'month', null);
    let xAxisLabels = [
      ...xAxisDates,
      forecastMetrics.plannedLSI.value,
      forecastMetrics.forecastLSI.value,
    ];
    xAxisLabels = xAxisLabels.sort(
      (firstLabelValue, secondLabelValue) =>
        new Date(firstLabelValue) - new Date(secondLabelValue),
    );
    xAxisLabels = xAxisLabels.map((date) => ({
      date: new DateStringTransform(date).formatter(SHORT_UI_DISPLAY_DATE),
    }));

    xAxisLabels = values(keyBy(xAxisLabels, 'date'));
    return xAxisLabels;
  }

  _generateYAxis() {
    const yAxis = {
      title: {
        text: this._yAxisLabel,
        style: {
          ...styleComponent('sp'),
          color: gray2,
        },
      },
      labels: {
        format: '{value}%',
        style: {
          ...styleComponent('sp'),
          color: gray2,
        },
      },
      max: 105,
    };
    return yAxis;
  }

  _generatePlannedLSIValue() {
    const xAxisLabelValues = this._xAxisLabels();
    const plannedLSIDate = new DateStringTransform(
      this._forecastMetricsValues().plannedLSI.value,
    ).formatter(SHORT_UI_DISPLAY_DATE);

    const plannedXValue = xAxisLabelValues.findIndex(
      (value) => value.date === plannedLSIDate,
    );
    return plannedXValue;
  }

  _generateForecastLSIValue() {
    const xAxisLabelValues = this._xAxisLabels();
    const forecastLSIDate = new DateStringTransform(
      this._forecastMetricsValues().forecastLSI.value,
    ).formatter(SHORT_UI_DISPLAY_DATE);

    const forecastXValue = xAxisLabelValues.findIndex(
      (value) => value.date === forecastLSIDate,
    );
    return forecastXValue;
  }

  _generateXAxis() {
    const xAxisLabelValues = this._xAxisLabels();

    const xAxisCategories = map(xAxisLabelValues, 'date', 'N/A');

    const xAxis = {
      categories: xAxisCategories,
      title: {
        text: 'Forecast LSI date',
        style: {
          ...styleComponent('sp'),
          color: gray2,
        },
      },
      labels: {
        style: {
          ...styleComponent('sp'),
          color: gray2,
        },
      },
    };
    return xAxis;
  }

  _generateBarColor(index, plannedXValue, forecastXValue) {
    if (index === forecastXValue) return darkAqua;
    if (index === plannedXValue) return gray3;
    return aqua40;
  }

  _generateSeriesColumnValues() {
    const plannedLSIValue = this._generatePlannedLSIValue();
    const forecastLSIValue = this._generateForecastLSIValue();
    const { forecastLSI, plannedLSI } = this._forecastMetricsValues();

    let columnSeries = map(
      this._likelihoodByMonth,
      'probabilityForecastLSIDate',
      null,
    );
    columnSeries = columnSeries.map((columnValue) =>
      !isNil(columnValue) ? Number(columnValue) : null,
    );
    let columnMonths = map(this._likelihoodByMonth, 'month', 0);
    columnMonths = columnMonths.map((date) =>
      new DateStringTransform(date).formatter(SHORT_UI_DISPLAY_DATE),
    );

    const forecastLSIMonth = new DateStringTransform(
      forecastLSI.value,
    ).formatter(SHORT_UI_DISPLAY_DATE);
    const plannedLSIMonth = new DateStringTransform(plannedLSI.value).formatter(
      SHORT_UI_DISPLAY_DATE,
    );

    if (columnMonths.indexOf(forecastLSIMonth) === -1) {
      columnSeries.splice(forecastLSIValue, 0, 0);
    }
    if (columnMonths.indexOf(plannedLSIMonth) === -1) {
      columnSeries.splice(plannedLSIValue, 0, 0);
    }
    columnSeries = columnSeries.map((columnValue, index) => {
      const barColor = this._generateBarColor(
        index,
        plannedLSIValue,
        forecastLSIValue,
      );

      const columnValueWithColor = {
        y: columnValue,
        color: barColor,
      };
      return columnValueWithColor;
    });
    return columnSeries;
  }

  _generateSeries(start, end) {
    const columnDataValues = this._generateSeriesColumnValues();
    const areaRange = this._generateAreaRange(start, end);
    const series = [
      {
        type: 'column',
        name: this._serieslabel,
        data: columnDataValues,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
        zIndex: 2,
      },
      {
        data: [
          { x: areaRange.fromXValue, y: 200 },
          { x: areaRange.toXValue, y: 200 },
        ],
        color: gray5,
        type: 'area',
        marker: {
          enabled: false,
        },
        zIndex: 1,
      },
    ];
    return series;
  }

  _generateTooltip() {
    const tooltip = {
      formatter() {
        const { y } = this.point;
        return `${this.x}<br>Probability of forecast LSI: ${roundOff(y, 1)}%`;
      },
    };
    return tooltip;
  }

  _generateAreaRange(start, end) {
    const series = this._likelihoodByMonth;
    const toRangeX = [];
    const fromRangeX = [];
    series.forEach(({ month }, index) => {
      if (+new Date(month) >= +new Date(start)) {
        fromRangeX.push(index);
      }
      if (+new Date(month) >= +new Date(end)) {
        toRangeX.push(index);
      }
    });

    let toXValue = toRangeX[0];
    let fromXValue = fromRangeX[0];
    if (isNil(toXValue) || isNil(fromXValue)) {
      toXValue = null;
      fromXValue = null;
    }
    return { toXValue, fromXValue };
  }

  _generateLikelihoodChartData(startDate, endDate) {
    const maxColumnValueWithoutLabel = this._maxColumnValueWithoutLabel;

    const likelihoodByMonthChartData = {
      title: { text: null },
      yAxis: this._generateYAxis(),
      xAxis: this._generateXAxis(),
      legend: {
        enabled: false,
      },
      series: this._generateSeries(startDate, endDate),
      tooltip: this._generateTooltip(),
      plotOptions: {
        series: {
          pointPadding: 0.1,
        },
        column: {
          dataLabels: {
            formatter() {
              if (Math.abs(this.y) < maxColumnValueWithoutLabel) return null;
              const label = `${roundOff(this.y, 1)}%`;
              return label;
            },
            enabled: true,
            align: 'center',
            allowOverlap: true,
            inside: false,
            color: gray2,
            style: {
              textOutline: 'none',
              ...styleComponent('cap'),
            },
          },
        },
      },
    };
    return likelihoodByMonthChartData;
  }
}
export default EnrollmentForecastSummaryTransform;
