const {
  REACT_APP_OKTA_CLIENT_ID,
  REACT_APP_OKTA_ISSUER,
  REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK,
  REACT_APP_OKTA_REDIRECT_URI,
  REACT_APP_OKTA_POST_LOGOUT_REDIRECT_URI,
} = process.env;

// eslint-disable-next-line
export default {
  oidc: {
    clientId: REACT_APP_OKTA_CLIENT_ID,
    issuer: REACT_APP_OKTA_ISSUER,
    redirectUri: REACT_APP_OKTA_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'groups', 'offline_access'],
    pkce: true,
    disableHttpsCheck: REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK,
    postLogoutRedirectUri: REACT_APP_OKTA_POST_LOGOUT_REDIRECT_URI,
    tokenManager: {
      autoRenew: true,
    },
  },
};
