import axios from 'axios';
import {
  errorRequestInterceptor,
  successRequestInterceptor,
} from './axiosRequestInterceptor';
import {
  errorResponseInterceptor,
  successResponseInterceptor,
} from './axiosResponseInterceptor';

const setupAxios = () => {
  if (axios.interceptors.request.handlers.length === 0) {
    axios.interceptors.request.use(
      successRequestInterceptor,
      errorRequestInterceptor,
    );

    axios.interceptors.response.use(
      successResponseInterceptor,
      errorResponseInterceptor,
    );
  }
};

export default setupAxios;
