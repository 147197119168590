import { update } from 'lodash';
import StatePersistance from 'utility/statePersistance';

class GeneratePreferenceReducer {
  #storeName = null;

  #initialState = null;

  constructor(storeName) {
    this.#storeName = storeName;
  }

  get getStoreName() {
    return this.#storeName;
  }

  get initialState() {
    return this.#initialState;
  }

  set initialState(initialStateValue) {
    this.#initialState = initialStateValue;
  }

  generator() {
    const statePersistance = new StatePersistance(this.getStoreName);
    statePersistance.initialState = this.#initialState;
    statePersistance.clearExpiredState();
    return (currentState = statePersistance.getStoreState(), action = {}) => {
      if (action.type === 'SET_PREFERENCES') {
        let { preferencesConfigMap } = currentState;
        const { sectionId } = action.preferencesConfig;
        const { type } = action.preferencesConfig;
        const { value } = action.preferencesConfig;
        if (!preferencesConfigMap[sectionId]) {
          preferencesConfigMap[sectionId] = {};
        }
        const updatePath = `${sectionId}.${type}`;

        preferencesConfigMap = update(
          preferencesConfigMap,
          updatePath,
          () => value,
        );

        const newPreferenceState = { ...currentState, preferencesConfigMap };
        statePersistance.setNewState(newPreferenceState);
        return newPreferenceState;
      }
      return currentState;
    };
  }
}

export default GeneratePreferenceReducer;
