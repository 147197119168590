import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import transform from './25_enrolmentDrivers/utility/enrolmentDriversMap.transform';

const trialEnrollmentDriversDataHttpRequest = (() => {
  const apiName = 'trialEnrollmentDrivers';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/enrollment-drivers/trial/:trialId`;
  generatedHttpThunk.transform = transform;
  return generatedHttpThunk.generateTrialThunk();
})();

export default trialEnrollmentDriversDataHttpRequest;
