import { gray6, takedaRed } from 'assets/stylesheets/base/_colors';
import { roundOff } from 'utility/math/valueRounder';
import {
  getBaseChartConfig,
  getFormattedLabel,
  getXAxisConfig,
  getYAxisConfig,
} from './chart/functions';
import {
  DATA_VISUALIZATION_COLORS_CONFIG,
  LEGEND_CONFIG,
  SELECTION_POINT_WIDTH,
  TITLE_CONFIG,
} from './chart/data';

const getStackedChartGeneratorFn = (data, cbFunction, selectedCategory) => {
  const { data: dataSet, xLabel, categories } = data;
  const selectedIndex = categories.reduce(
    (ind, currentCategory, index) =>
      currentCategory === selectedCategory ? index : ind,
    -1,
  );

  const backgroundCoverData = Array(categories.length).fill(0);
  const backgroundHighlightData = Array(categories.length).fill(0);
  const maxHeight = 120;
  if (selectedIndex !== -1) {
    const dataHeight = Array(categories.length).fill(0);
    dataSet.forEach((series) => {
      dataHeight.forEach((_current, index) => {
        dataHeight[index] += series.data[index];
      });
    });
    backgroundCoverData[selectedIndex] =
      dataHeight[selectedIndex] + maxHeight * 0.02;
    backgroundHighlightData[selectedIndex] = maxHeight * 0.02;
  }
  const yAxisLabel = '% of Amendments';
  const pointLabelSuffix = '%';
  const baseChartConfig = getBaseChartConfig(dataSet.length);
  return {
    ...baseChartConfig,
    title: {
      ...TITLE_CONFIG,
      text: xLabel,
    },
    colors: DATA_VISUALIZATION_COLORS_CONFIG,
    tooltip: { enabled: false },
    legend: LEGEND_CONFIG,
    xAxis: getXAxisConfig(categories),
    yAxis: getYAxisConfig(yAxisLabel, maxHeight),
    plotOptions: {
      column: {
        stacking: 'normal',
        grouping: false,
        shadow: false,
        borderWidth: 0,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      series: {
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
        cursor: 'pointer',
        states: {
          inactive: {
            opacity: 1,
          },
        },
        animation: false,
        dataLabels: {
          align: 'center',
          enabled: true,
          inside: true,
          allowOverlap: true,
          crop: true,
          style: {
            textOutline: 0,
          },
          formatter() {
            return getFormattedLabel(roundOff(this.y), pointLabelSuffix);
          },
        },
        point: {
          events: {
            click() {
              cbFunction(this.category);
            },
          },
        },
      },
    },
    series: [
      {
        name: 'BackgroundCover',
        states: { hover: { enabled: false } },
        dataLabels: {
          enabled: false,
        },
        data: backgroundCoverData,
        pointPadding: 0.1,
        pointWidth: SELECTION_POINT_WIDTH,
        color: gray6,
        stack: 'background',
        showInLegend: false,
        enableMouseTracking: false,
      },
      ...dataSet,
      {
        name: 'BackgroundHighlight',
        data: backgroundHighlightData,
        states: { hover: { enabled: false } },
        pointPadding: 0.1,
        pointWidth: SELECTION_POINT_WIDTH,
        color: takedaRed,
        dataLabels: {
          enabled: false,
        },
        stack: 'background',
        showInLegend: false,
        enableMouseTracking: false,
      },
    ],
  };
};

export default getStackedChartGeneratorFn;
