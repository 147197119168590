import { get } from 'lodash';
import PartDrillDown from '../drilldown/04_partDrillDown';

const partTransform = (data, params) => {
  const { selectedDriverCard } = params;

  const partDrillDownCurrent = new PartDrillDown(selectedDriverCard);

  const dataByPart = get(data, 'dataByPart', []);
  partDrillDownCurrent.partData = dataByPart;
  partDrillDownCurrent.chartText = 'Current plan to date';
  partDrillDownCurrent.rateChartType = 'plannedEnrollmentRate';
  partDrillDownCurrent.countChartType = 'plannedEnrolledCount';
  const partChartDataCurrent = partDrillDownCurrent.generateGraph();
  const partDataCurrentNumberCurrent = get(
    partChartDataCurrent,
    'currentNumber',
    {},
  );
  const partDataRateCurrent = get(partChartDataCurrent, 'rate', {});

  const partsChartData = {
    partDataCurrentNumberCurrent,
    partDataRateCurrent,
  };
  return partsChartData;
};

export default partTransform;
