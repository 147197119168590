import { isNil, get } from 'lodash';
import dayjs from 'dayjs';
import DateStringTransform from 'utility/date/dateString.utility';
import { SHORT_UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import DataListFilter from './uiFiltering/dataListFilter.utility';

export const filterChartByDate = (dateRange, data) => {
  // Step 1a: Validate dateRange
  if (isNil(dateRange)) return data;
  const { categories } = data;

  // Step 1b: Validate categories
  if (isNil(categories)) return data;
  if (!Array.isArray(categories)) return data;
  if (categories.length === 0) return data;

  // Step 2: Define Range
  const defaultStartDate = categories[0];
  const defaultEndDate = categories[categories.length - 1];
  const startDateString = dateRange.minDateString || defaultStartDate;
  const startDate = +new DateStringTransform().convertFormattedDate(
    startDateString,
  );
  const endDateString = dateRange.maxDateString || defaultEndDate;
  const endDate = +new DateStringTransform().convertFormattedDate(
    endDateString,
  );

  return {
    ...data,
    xAxis: {
      ...data.xAxis,
      min: startDate,
      max: endDate,
    },
  };
};

export const countNonNulls = (data) => {
  const count = data.filter((item) => !isNil(item)).length;
  return count;
};

export const filterTableByDate = (dateRange, data, dateKey) => {
  if (!dateRange) return data;
  const defaultStartDate = data[0][dateKey];
  const defaultEndDate = data.slice(-1)[dateKey];
  const startDateString = dateRange.minDateString || defaultStartDate;
  const endDateString = dateRange.maxDateString || defaultEndDate;

  const dataListFilter = new DataListFilter(data);
  const searchQuery = {
    dateFilterObject: {
      [dateKey]: {
        minDate: startDateString,
        maxDate: endDateString,
        inclusionType: '[]',
      },
    },
  };
  const filteredData = dataListFilter.search(searchQuery);
  return filteredData;
};

export const filterForecastEnrollmentDataByDate = (data) =>
  data.map((part) => {
    const partData = part.data.map((item) => {
      const dateStringTransform = new DateStringTransform();
      dateStringTransform.dateString = get(item, 'trialMonth');
      const trialDate = dateStringTransform.formatter(SHORT_UI_DISPLAY_DATE);
      const todayDate = dateStringTransform.getTodayDate(SHORT_UI_DISPLAY_DATE);
      if (dayjs(trialDate).isBefore(todayDate))
        return {
          ...item,
          forecastEnrollmentLowerQuartile: null,
          forecastEnrollmentMidQuantile: null,
          forecastEnrollmentUpperQuartile: null,
          isRange: false,
        };
      return { ...item };
    });
    return { ...part, data: partData };
  });
