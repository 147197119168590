import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import transform from './utility/cycleTimesFilterTransformer';

const cycleTimesFiltersHttpRequest = (() => {
  const apiName = 'cycleTimesFilters';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/cycle-time-filters`;
  generatedHttpThunk.transform = transform;
  return generatedHttpThunk.generateThunk();
})();

export default cycleTimesFiltersHttpRequest;
