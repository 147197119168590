import React from 'react';
import PropTypes from 'prop-types';
import Error from '.';

function ErrorFallbackComponent({ error }) {
  return <Error error={error} errorMessage={error?.message} />;
}

ErrorFallbackComponent.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

ErrorFallbackComponent.defaultProps = {
  error: {
    message: null,
  },
};

export default ErrorFallbackComponent;
