import { get } from 'lodash';
import configureTreeMap from '../../../../configuration/treeMapChart.config';

const transformTrialSummary = (data) => {
  const transformedData = {
    averageTimeToActivateInMonth: get(data, 'averageTimeToActivateInMonth'),
    trialDetail: {
      totalTrials: get(data, 'trialDetail.totalTrials'),
      treeMapData: configureTreeMap(
        get(data, 'trialDetail.trialCountPerOpTAU'),
      ),
    },
    medianEnrollmentContribution: {
      enrollmentDistributionLevel: get(data, 'medianEnrollmentContribution'),
    },
  };
  return transformedData;
};
export default transformTrialSummary;
