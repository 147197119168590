import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import transformSiteLeaderboardTable from './utility/21_siteLeaderboard.thunk.transform';

const siteLeaderboardHttpRequest = (() => {
  const apiName = 'siteLeaderboard';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/sites/trial/:trialId`;
  generatedHttpThunk.transform = transformSiteLeaderboardTable;
  return generatedHttpThunk.generateTrialThunk();
})();

export default siteLeaderboardHttpRequest;
