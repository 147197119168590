import { isNil } from 'lodash';

const customFilterBlankValue = (value, filters) => {
  const isBlankSelected = filters.includes('Blank');
  const isNotBlankSelected = filters.includes('Not blank');
  const blankReturnCondition = isBlankSelected && isNil(value);
  const notBlankReturnCondition = isNotBlankSelected && !isNil(value);
  const finalReturnCondition =
    filters.length > 0 ? blankReturnCondition || notBlankReturnCondition : true;
  return finalReturnCondition;
};
export default customFilterBlankValue;
