import * as baseColors from 'assets/stylesheets/base/_colors';

export const getFormattedLabel = (yValue, suffix) => {
  if (yValue !== 0) {
    return `${yValue}${suffix}`;
  }
  return '';
};

export const getBaseChartConfig = (datasetSize) => ({
  chart: {
    type: 'column',
    height: 460,
    marginTop: 50,
    marginBottom: 50,
    events: {
      beforePrint() {
        this.chartScrollTopPosition = document.documentElement.scrollTop;
      },
      afterPrint() {
        document.documentElement.scrollTop = this.chartScrollTopPosition;
      },
    },
    scrollablePlotArea: {
      minWidth: 75 * datasetSize,
    },
  },
  exporting: {
    enabled: true,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: null,
  },
});

export const getXAxisConfig = (categories = [], min = 0) => ({
  categories,
  min,
  ...(categories.length > 6 ? { max: 6 } : {}),
  scrollbar: {
    enabled: categories.length > 6,
  },
});

export const getYAxisConfig = (
  label,
  max,
  min = 0,
  color = baseColors.gray3,
  rotation = -90,
) => ({
  min,
  max,
  title: {
    text: label,
    style: {
      color,
    },
  },
  labels: {
    rotation,
    style: {
      color,
    },
  },
});

export const getToolTipConfig = (pointFormat, shared = true) => ({
  pointFormat,
  shared,
});
