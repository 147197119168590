import { roundOff } from 'utility/math/valueRounder';

export function dataLabelsFormatter() {
  if (this.y < 10) return null;
  const label = `${roundOff(this.y, 1)} %`;
  return label;
}

export function performanceByCategoryTooltipFormatter() {
  return `${this.x}<br> ${this.series.name}: ${roundOff(this.point.y, 1)}`;
}

export function performanceOverTimeRagTooltipFormatter() {
  const { year, quarter, y } = this.point;
  const yearText = roundOff(year, 1) || 'N/A';
  const quarterText = quarter ? ` Q${roundOff(quarter, 1)}` : '';
  return `${yearText}${quarterText}<br/>${this.series.name}: ${roundOff(y, 1)}`;
}

export function performanceOvertTimeBoxTooltipFormatter() {
  const { x } = this;
  const currentData = this.series.data.find(
    (boxPlotData) => boxPlotData.x === x,
  );
  const boxplotValues = currentData ? currentData.options : {};
  return `Maximum: ${roundOff(boxplotValues.high, 1)}<br>
            75 Percentile: ${roundOff(boxplotValues.q3, 1)}<br>
            Median Cycle Time: ${roundOff(boxplotValues.median, 1)}<br>
            25 Percentile: ${roundOff(boxplotValues.q1, 1)}<br>
            Minimum: ${roundOff(boxplotValues.low, 1)}<br>`;
}
