import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const SiteHealthFiltersHttpRequest = (() => {
  const apiName = 'siteHealthFilters';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/site-filters`;
  return generatedHttpThunk.generatePortfolioThunk();
})();

export default SiteHealthFiltersHttpRequest;
