import { get, isNil } from 'lodash';
import addCommas from 'utility/addCommaToNumbers/commaFunction';
import { roundOff } from 'utility/math/valueRounder';
import {
  BOXED_ICON_MAP,
  MOM_ICON_MAP,
  UNBOXED_ICON_MAP,
} from '../iconsMap.constant';

export const getRoundedMagnitude = (
  magnitude,
  precision,
  fixedTrailingPoints = null,
) => {
  if (
    isNil(magnitude) ||
    Number.isNaN(magnitude) ||
    !Number.isFinite(magnitude)
  )
    return null;
  if (isNil(fixedTrailingPoints)) return roundOff(magnitude, precision);
  return roundOff(magnitude, precision).toFixed(fixedTrailingPoints);
};

export const getMagnitudeClass = (roundedMagnitude) => {
  let magnitudeClass = '';
  if (!isNil(roundedMagnitude)) {
    switch (true) {
      case roundedMagnitude === 0: {
        magnitudeClass = 'zero';
        break;
      }
      case roundedMagnitude > 0: {
        magnitudeClass = 'positive';
        break;
      }
      case roundedMagnitude < 0: {
        magnitudeClass = 'negative';
        break;
      }
      default:
        break;
    }
  }
  return magnitudeClass;
};

export const setTrendIconAndClass = ({
  isTrendDynamic,
  magnitudeClass,
  dynamicTrendArrows,
  className,
  isBoxed,
  isTrendingUp,
  showColor,
}) => {
  let trendIcon;
  let displayMeasurementClassName;

  if (!isTrendDynamic) {
    const boxed = isBoxed ? 'boxed' : 'unboxed';
    trendIcon = isBoxed
      ? get(BOXED_ICON_MAP, `${isTrendingUp}`)
      : get(UNBOXED_ICON_MAP, `${isTrendingUp}.${magnitudeClass}`);
    displayMeasurementClassName = showColor
      ? `${className} magnitude-${magnitudeClass} ${boxed} display-measurement`
      : `${className} ${boxed} display-measurement`;
  } else {
    const isZero = magnitudeClass === 'zero';
    trendIcon = !isZero
      ? get(
          MOM_ICON_MAP,
          `${magnitudeClass}.${get(dynamicTrendArrows, magnitudeClass)}`,
        )
      : null;
    displayMeasurementClassName = `${className} trend-${get(
      dynamicTrendArrows,
      magnitudeClass,
    )} display-measurement`;
  }

  return { trendIcon, displayMeasurementClassName };
};

export const enableTrendIcon = ({
  showTrendIcon,
  trendIcon,
  displayedMagnitude,
}) => showTrendIcon && trendIcon && displayedMagnitude !== 0;

export const getCommaData = (enableAddCommas, displayedMagnitude) =>
  enableAddCommas ? addCommas(Number(displayedMagnitude)) : displayedMagnitude;
