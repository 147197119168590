import getAccessToken from '../auth';

export const successRequestInterceptor = (config) => {
  const { REACT_APP_API_ENDPOINT } = process.env;

  const baseUrl = REACT_APP_API_ENDPOINT || 'http://localhost:8080';

  // const baseUrl =
  //   REACT_APP_API_ENDPOINT || 'https://compass-api-dev.onetakeda.com/dashboard';
  // eslint-disable-next-line no-console
  console.info('BASE_URL:', baseUrl);

  if (config.url.startsWith('http')) {
    throw new Error('bad input');
  }

  const accessToken = getAccessToken();
  const updateConfig = {
    ...config,
    url: `${baseUrl}/${config.url}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return updateConfig;
};

export const errorRequestInterceptor = (error) => Promise.reject(error);
