import {
  aheadAhead,
  aheadBehind,
  behindAhead,
  behindBehind,
} from 'assets/icons';

export const QUADRANT = {
  AHEAD_AHEAD: {
    value: 'aheadAhead',
    label: 'Activations on plan x Enrollments on plan',
    icon: aheadAhead,
  },
  AHEAD_BEHIND: {
    value: 'aheadBehind',
    label: 'Activations on plan x Enrollments behind',
    icon: aheadBehind,
  },
  BEHIND_AHEAD: {
    value: 'behindAhead',
    label: 'Activations behind x Enrollments on plan',
    icon: behindAhead,
  },
  BEHIND_BEHIND: {
    value: 'behindBehind',
    label: 'Activations behind x Enrollments behind',
    icon: behindBehind,
  },
};

export const PLACEHOLDER_EXPORT = '';
