const mapFilterValuesToLabels = (allFilters) => {
  const labelMapper = {};
  allFilters.forEach((filterItem) => {
    const { columnDisplayValue, columnValues } = filterItem;
    const columnLabelValuePair = {};
    columnValues.forEach((optionItem) => {
      columnLabelValuePair[optionItem.value] = optionItem.label;
    });
    labelMapper[columnDisplayValue] = columnLabelValuePair;
  });
  return labelMapper;
};

export default mapFilterValuesToLabels;
