import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import crfVerifiedTransform from './utility/chart.transform';
import crfSortTransform from '../../__utility__/crfSort.transform';

export const crfsVerifiedChartHttpRequest = (() => {
  const apiName = 'crfsVerifiedChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-crfs-verified-chart/trial/:trialId`;
  generatedHttpThunk.transform = crfVerifiedTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export const crfsVerifiedTableHttpRequest = (() => {
  const apiName = 'crfsVerifiedTable';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-crfs-verified-table/trial/:trialId`;
  generatedHttpThunk.transform = crfSortTransform;
  return generatedHttpThunk.generateTrialThunk();
})();
