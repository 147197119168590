import {
  white,
  ragGreen,
  ragRed,
  gray3,
} from 'assets/stylesheets/base/_colors';
import EMPTY_DONUT_CHART_CONFIG from 'dashboards/PortfolioManagement/configuration/emptyDonutChart.config';
import { get, find, set, cloneDeep } from 'lodash';
import { valueNullFormatter } from 'utility/nullFormatter';
import getChartData from '../../../../configuration/diamondChartConfig/index.config';

const records = [
  {
    phase: null,
    trialNum: null,
    romeTarget: null,
    length: null,
    trend: null,
    trendColor: null,
  },
];

const CARDS = [
  {
    stage: 'PS_PA',
  },
  {
    stage: 'PA_FSA',
  },
  {
    stage: 'LSO_DBL',
  },
  {
    stage: 'DBL_KA',
  },
];
class DrilldownCycleTimesTransform {
  #CONFIGURATION = {
    exporting: {
      buttons: {
        contextButton: { enabled: false },
      },
    },
    chart: {
      type: 'pie',
      height: `200px`,
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      margin: [0, 0, 0, 0],
    },
    title: { text: '' },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: 120,
        borderColor: 'transparent',
        dataLabels: {
          enabled: true,
          crop: false,
          distance: 100,
          alignTo: 'plotEdges',
          connectorShape: 'crookedLine',
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
  };

  #getMetTargetItem(cardAttributes) {
    const trialNumMeet = valueNullFormatter(
      get(cardAttributes, 'trialNumMeet', null),
    );
    const trialRateMeet = valueNullFormatter(
      get(cardAttributes, 'trialRateMeet', null),
    );

    const metTarget = {
      name: '% trials meeting ROME target',
      y: trialNumMeet,
      color: ragGreen,
      z: 1,
      stage: cardAttributes.stage,
      dataLabels: {
        distance: 8,
        enabled: trialNumMeet > 0,
        isHTML: true,
        format: `${trialNumMeet} <br> (${trialRateMeet}%)`,
      },
    };

    return metTarget;
  }

  #getNotMetTargetItem(cardAttributes) {
    const trialNumNotMeet = valueNullFormatter(
      get(cardAttributes, 'trialNumNotMeet', null),
    );

    const trialRateNotMeet = valueNullFormatter(
      get(cardAttributes, 'trialRateNotMeet', null),
    );

    const notMetTarget = {
      name: '% trials not meeting ROME target',
      y: trialNumNotMeet,
      color: ragRed,
      z: 2,
      stage: cardAttributes.stage,
      dataLabels: {
        distance: 8,
        enabled: trialNumNotMeet > 0,
        isHTML: true,
        format: `${trialNumNotMeet} <br> (${trialRateNotMeet}%)`,
      },
    };

    return notMetTarget;
  }

  #getUnknownTargetItem(cardAttributes) {
    const unknownTargetCount = valueNullFormatter(
      get(cardAttributes, 'unknownTargetTrialCount', null),
    );

    const unknownTargetRate = valueNullFormatter(
      get(cardAttributes, 'unknownTrialRate', null),
    );

    const unknownTarget = {
      name: '% cycles where target is not available',
      y: unknownTargetCount,
      color: gray3,
      stage: cardAttributes.stage,
      dataLabels: {
        distance: 8,
        enabled: unknownTargetCount > 0,
        format: `${unknownTargetCount} (${unknownTargetRate}%)`,
      },
    };

    return unknownTarget;
  }

  #generateDiamondChartData(cardAttributes) {
    return getChartData(
      cardAttributes.prevMilestone ?? 'N/A',
      cardAttributes.nextMilestone ?? 'N/A',
    );
  }

  #getBorderColor(targetsValue) {
    if (targetsValue.every((target) => target)) return white;

    if (targetsValue.filter((target) => target).length === 2) {
      if (!targetsValue[0]) return ragGreen;
      if (!targetsValue[1]) return ragRed;
      return gray3;
    }
    return 'transparent';
  }

  #generateChartData(cardAttributes) {
    const metTarget = this.#getMetTargetItem(cardAttributes);
    const notMetTarget = this.#getNotMetTargetItem(cardAttributes);
    const unknownTarget = this.#getUnknownTargetItem(cardAttributes);

    const chartData = {
      ...this.#CONFIGURATION,
      series: [
        {
          minPointSize: 10,
          innerSize: '70%',
          zMin: 0,
          name: 'countries',
          cursor: 'pointer',
          data: [metTarget, notMetTarget, unknownTarget],
        },
      ],
    };
    const updatedChartData = cloneDeep(chartData);
    const isMetTargetAbsent = metTarget.y <= 0;
    const isNotMetTargetAbsent = notMetTarget.y <= 0;
    const isUnknownTargetAbsent = unknownTarget.y <= 0;

    set(
      updatedChartData,
      'plotOptions.pie.borderColor',
      this.#getBorderColor([
        isMetTargetAbsent,
        isNotMetTargetAbsent,
        isUnknownTargetAbsent,
      ]),
    );
    return updatedChartData;
  }

  generate(data) {
    const cards = CARDS.map((card) => {
      const cycleTimeCardData =
        find(data, (record) => record.stage === card.stage) ?? {};
      const chartData = this.#generateChartData(cycleTimeCardData);
      const diamondMilestoneChartData =
        this.#generateDiamondChartData(cycleTimeCardData);
      const cardData = {
        ...EMPTY_DONUT_CHART_CONFIG,
        ...card,
        records,
        ...cycleTimeCardData,
        diamondMilestoneChartData,
        chart: chartData,
      };

      return cardData;
    });
    return cards;
  }

  static transform(data) {
    const drilldownCycleTimesTransform = new DrilldownCycleTimesTransform();
    const returnData = drilldownCycleTimesTransform.generate(data);
    return returnData;
  }
}

export default DrilldownCycleTimesTransform;
