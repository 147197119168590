import { get, isNil, map } from 'lodash';
import { roundAllValuesInArray } from 'utility/math/valueRounder';
import { convertToArrayOfNumbers } from '../chartUtility';

export default class Generator {
  _getSeriesData(combinedData, key, notNullResultsFlag = true) {
    const rawData = map(combinedData, key);
    const numericData = convertToArrayOfNumbers(rawData);
    const roundedData = roundAllValuesInArray(numericData);
    let showForecastValues = false;
    const result = map(combinedData, (item, index) => {
      if (showForecastValues) return {};
      if (
        key === 'actualEnrolledCum' &&
        !isNil(get(item, 'forecastEnrollmentMidQuantile'))
      ) {
        showForecastValues = true;
      }
      return {
        x: +new Date(item.trialMonth),
        y: roundedData[index],
      };
    });
    let notNullResults = result;
    if (notNullResultsFlag)
      notNullResults = result.filter(({ y }) => !isNil(y));
    return notNullResults;
  }
}
