import { aqua, gray5, takedaRed } from 'assets/stylesheets/base/_colors';
import { roundOff } from 'utility/math/valueRounder';
import {
  DATA_POINT_WIDTH,
  LEGEND_CONFIG,
  SELECTION_POINT_WIDTH,
  TITLE_CONFIG,
} from './chart/data';
import {
  getBaseChartConfig,
  getFormattedLabel,
  getXAxisConfig,
  getYAxisConfig,
} from './chart/functions';

const getColumnChartGeneratorFn = (data, cbFunction, selectedCategory) => {
  const { data: dataSet, categories, legendLabel, xLabel, isPercentage } = data;
  const selectedIndex = categories.reduce(
    (ind, currentCategory, index) =>
      currentCategory === selectedCategory ? index : ind,
    -1,
  );
  const highestHeight = Math.max(...dataSet) + 10;
  const backgroundCoverData = Array(dataSet.length).fill(0);
  const backgroundHighlightData = Array(dataSet.length).fill(0);

  const leastDataPoint = Math.min(...dataSet);

  if (selectedIndex !== -1) {
    let height = highestHeight;
    if (dataSet[selectedIndex] < 0) height = -height;

    backgroundCoverData[selectedIndex] = dataSet[selectedIndex] + height * 0.02;
    backgroundHighlightData[selectedIndex] = height * 0.02;
  }

  const baseChartConfig = getBaseChartConfig(dataSet.length);
  const yAxisLabel = isPercentage ? '% of Amendments' : 'Amendments';
  const pointLabelSuffix = isPercentage ? '%' : '';
  return {
    ...baseChartConfig,
    title: {
      ...TITLE_CONFIG,
      text: xLabel,
    },
    legend: LEGEND_CONFIG,
    xAxis: getXAxisConfig(categories),
    yAxis: getYAxisConfig(
      yAxisLabel,
      highestHeight,
      leastDataPoint < 0 ? leastDataPoint * 1.5 : 0,
    ),
    tooltip: {
      pointFormat: '',
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
        grouping: false,
        shadow: false,
      },
      series: {
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
        pointWidth: DATA_POINT_WIDTH,
        animation: false,
        cursor: 'pointer',
        states: {
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          align: 'center',
          enabled: true,
          style: {
            textOutline: 0,
          },
          formatter() {
            return getFormattedLabel(roundOff(this.y, 1), pointLabelSuffix);
          },
        },
        point: {
          events: {
            click() {
              cbFunction(this.category);
            },
          },
        },
      },
    },
    series: [
      {
        name: 'BackgroundCover',
        enableMouseTracking: false,
        data: backgroundCoverData,
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        color: gray5,
        pointWidth: SELECTION_POINT_WIDTH,
      },
      {
        name: legendLabel,
        dataLabels: {
          enabled: true,
        },
        data: dataSet,
        legendIndex: 0,
        color: aqua,
      },
      {
        name: 'BackgroundHighlight',
        data: backgroundHighlightData,
        enableMouseTracking: false,
        showInLegend: false,
        dataLabels: {
          enabled: false,
        },
        color: takedaRed,
        pointWidth: SELECTION_POINT_WIDTH,
      },
    ],
  };
};

export default getColumnChartGeneratorFn;
