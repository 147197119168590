import { get } from 'lodash';
import tableDataTransform from './tableData.transform';
import Generator from './chartDataTransform/generator';
import {
  getPointPositions,
  getPointQuadrant,
} from './chartDataTransform/utility';

const transform = (data) => {
  const dataRecords = get(data, 'records', []);
  const addedPositionData = getPointPositions(dataRecords);
  const addedQuadrantData = getPointQuadrant(addedPositionData);

  const chartGenerator = new Generator(addedQuadrantData);
  const chartData = chartGenerator.generate();
  const tableData = tableDataTransform(addedQuadrantData);
  return {
    chartData,
    tableData,
  };
};
export default transform;
