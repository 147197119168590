import { isNil, orderBy, get } from 'lodash';

const generateBlankFilterOptions = (
  tableData,
  keyname = 'enrollmentCountToDate',
) => {
  const filterList = [];
  const areBlankValuesPresent = tableData.some((row) =>
    isNil(get(row, keyname)),
  );
  const areNonBlankValuesPresent = tableData.some(
    (row) => !isNil(get(row, keyname)),
  );
  if (areBlankValuesPresent)
    filterList.push({ label: 'Blank', value: 'Blank' });
  if (areNonBlankValuesPresent)
    filterList.push({ label: 'Not blank', value: 'Not blank' });
  return filterList;
};

const generateFilterOptions = (
  tableData,
  keyname = 'countryCode',
  isBlankFilter = false,
) => {
  const filterList = [];
  if (Array.isArray(tableData)) {
    if (isBlankFilter) return generateBlankFilterOptions(tableData, keyname);
    tableData.forEach((row) => {
      const temp = {
        label: get(row, keyname),
        value: get(row, keyname),
      };
      if (!isNil(get(row, keyname))) {
        const existingObject = filterList.find(
          (obj) => obj.label === temp.label && obj.value === temp.value,
        );
        if (!existingObject) {
          filterList.push(temp);
        }
      }
    });
    return orderBy(filterList, 'label', 'asc');
  }
  return filterList;
};

export default generateFilterOptions;
