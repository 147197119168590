export const STARTUP_STATISTICS_LABEL = {
  TIME_TO_ACTIVATE: 'Time to activation (selection to activation)',
  APPROVAL_TIME: 'IRB/EC approval time',
  FINAL_CONTRACT_RECEIVED: 'Final contract received from site',
};

export const STARTUP_STATISTICS_UNITS = 'month';

export const STAGE_LABELS = {
  SITE_SELECTION: 'Site selection',
  IRB_EC_SUBMISSION: 'IRB/EC submission',
  IRB_EC_APPROVAL: 'IRB/EC approval',
  SIV_CONDUCTED: 'SIV conducted',
  SITE_ACTIVATION: 'Site activation',
};
