import { gray2 } from 'assets/stylesheets/base/_colors';
import baseConfig from './base.data.config';
import { getLine, getRagDiamonds, getRagLabels } from './base.helpers.config';

const getChartData = (startRag, endRag, midRag, midLabels = []) => {
  const yCoordinate = 1;
  const initialPaddingLine = getLine(-5, 1, 0, 1, 'transparent');
  const endingPaddingLine = getLine(100, 1, 105, 1, 'transparent');
  const dataLine = getLine(0, 1, 100, 1, gray2);
  const startingRagDiamond = getRagDiamonds(startRag, 0, yCoordinate);
  const endingRagDiamond = getRagDiamonds(endRag, 100, yCoordinate);
  const midRagDiamond = getRagDiamonds(midRag, 50, yCoordinate);
  const preMidRagLabel = getRagLabels(midLabels[0], 25, yCoordinate);
  const postMidRagLabel = getRagLabels(midLabels[1], 75, yCoordinate);

  return {
    ...baseConfig,
    series: [
      {
        ...initialPaddingLine,
      },
      {
        ...endingPaddingLine,
      },
      {
        ...dataLine,
      },
      {
        ...startingRagDiamond,
      },
      {
        ...midRagDiamond,
      },
      {
        ...endingRagDiamond,
      },
      {
        ...preMidRagLabel,
      },
      {
        ...postMidRagLabel,
      },
    ],
  };
};

export default getChartData;
