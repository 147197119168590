import { utils, writeFile } from 'xlsx';
import { roundOff } from '../math/valueRounder';

class ExcelExporter {
  _fileName = `${+new Date()}.xlsx`;

  EXCEL_EXTENSION = '.xlsx';

  CSV_EXTENSION = '.csv';

  constructor(fileName, isCsv = false) {
    if (isCsv) this._fileName = fileName + this.CSV_EXTENSION;
    else this._fileName = fileName + this.EXCEL_EXTENSION;
    this._workbook = utils.book_new();
  }

  #dataSheetDataCleaner(workSheetJsonDataArray, useGivenPrecision = false) {
    const cleanDataArray = workSheetJsonDataArray.map((dataItem) => {
      const cleanDataItem = {};
      Object.keys(dataItem).forEach((objectKey) => {
        cleanDataItem[objectKey] = dataItem[objectKey];
        if (typeof dataItem[objectKey] === 'number' && !useGivenPrecision) {
          cleanDataItem[objectKey] = roundOff(dataItem[objectKey], 1);
        }
      });
      return cleanDataItem;
    });
    return cleanDataArray;
  }

  appendSheet(
    workSheetJsonData,
    sheetName = 'sheet',
    options = {},
    useGivenPrecision = false,
  ) {
    const cleanDataArray = this.#dataSheetDataCleaner(
      workSheetJsonData,
      useGivenPrecision,
    );
    const worksheet = utils.json_to_sheet(cleanDataArray);
    Object.keys(options).forEach((optionKey) => {
      worksheet[optionKey] = options[optionKey];
    });
    utils.book_append_sheet(this._workbook, worksheet, sheetName);
  }

  download() {
    writeFile(this._workbook, this._fileName);
  }
}

export default ExcelExporter;
