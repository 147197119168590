const TIMELINE_FILTER = {
  columnDisplayName: 'Last 12 months',
  columnDisplayValue: 'last12Months',
  columnValues: [
    {
      label: '12 months',
      value: 'LAST_12_MONTHS',
      isSelected: false,
      columnValuePosition: 1,
    },
    {
      label: '24 months',
      value: 'LAST_24_MONTHS',
      isSelected: false,
      columnValuePosition: 2,
    },
    {
      label: '36 months',
      value: 'LAST_36_MONTHS',
      isSelected: false,
      columnValuePosition: 3,
    },
  ],
};
const QUARTER_FILTER = {
  columnDisplayName: 'Quarter',
  columnDisplayValue: 'quarter',
  columnValues: [
    { label: '-', value: null, isSelected: false, columnValuePosition: 1 },
    { label: 'Q1', value: 1, isSelected: false, columnValuePosition: 1 },
    { label: 'Q2', value: 2, isSelected: false, columnValuePosition: 2 },
    { label: 'Q3', value: 3, isSelected: false, columnValuePosition: 3 },
    { label: 'Q4', value: 4, isSelected: false, columnValuePosition: 4 },
  ],
};
const yearFilterGenerator = (fromYear, toYear) => {
  const yearFilter = [];
  for (let year = fromYear; year <= toYear; year += 1) {
    const yearText = year.toString();
    const yearFilterOption = {
      label: yearText,
      value: yearText,
      isSelected: false,
    };
    yearFilter.push(yearFilterOption);
  }
  return yearFilter;
};

const CURRENT_YEAR = new Date().getFullYear();
const yearFilter = {
  columnDisplayName: 'Year',
  columnDisplayValue: 'year',
  columnValues: yearFilterGenerator(CURRENT_YEAR - 5, CURRENT_YEAR + 5),
};
const GROUP_BY_FILTER = {
  columnDisplayName: 'Group by',
  columnDisplayValue: 'groupBy',
  columnValues: [
    {
      value: 'tau',
      label: 'Group by TAU',
      isSelected: false,
      columnValuePosition: 1,
    },
    {
      value: 'cro',
      label: 'Group by CRO',
      isSelected: false,
      columnValuePosition: 2,
    },
    {
      value: 'tier',
      label: 'Group by Tier',
      isSelected: false,
      columnValuePosition: 3,
    },
    {
      value: 'phase',
      label: 'Group by Phase',
      isSelected: false,
      columnValuePosition: 4,
    },
    {
      value: 'optau',
      label: 'Group by OpTAU',
      isSelected: false,
      columnValuePosition: 5,
    },
  ],
};

const transform = (filterResponse) => {
  const filterResponseData = filterResponse.map((filter) => ({
    ...filter,
    columnValues: [...filter.columnValues],
  }));
  const cycleTimesFilterData = [];
  cycleTimesFilterData.push(
    filterResponseData.find((filter) => filter.columnDisplayValue === 'tier'),
  );
  cycleTimesFilterData.push(
    filterResponseData.find((filter) => filter.columnDisplayValue === 'phase'),
  );
  cycleTimesFilterData.push(
    filterResponseData.find(
      (filter) => filter.columnDisplayValue === 'milestone',
    ),
  );
  const ragStatusSection = {
    tierFilter: cycleTimesFilterData[0],
    phaseFilter: cycleTimesFilterData[1],
    fromMilestone: cycleTimesFilterData[2],
    toMilestone: cycleTimesFilterData[2],
    fromQuarter: QUARTER_FILTER,
    toQuarter: QUARTER_FILTER,
    fromYear: yearFilter,
    toYear: yearFilter,
    timelineFilter: TIMELINE_FILTER,
  };
  const cycleStageOvertimeSection = {
    tierFilter: cycleTimesFilterData[0],
    phaseFilter: cycleTimesFilterData[1],
    fromMilestone: cycleTimesFilterData[2],
    toMilestone: cycleTimesFilterData[2],
    fromQuarter: QUARTER_FILTER,
    toQuarter: QUARTER_FILTER,
    fromYear: yearFilter,
    toYear: yearFilter,
    timelineFilter: TIMELINE_FILTER,
  };
  const cycleStageCategorySection = {
    tierFilter: cycleTimesFilterData[0],
    phaseFilter: cycleTimesFilterData[1],
    fromMilestone: cycleTimesFilterData[2],
    toMilestone: cycleTimesFilterData[2],
    fromQuarter: QUARTER_FILTER,
    toQuarter: QUARTER_FILTER,
    fromYear: yearFilter,
    toYear: yearFilter,
    timelineFilter: TIMELINE_FILTER,
    GROUP_BY_FILTER,
  };

  const cycleStagePerformanceByTrialSection = {
    tierFilter: cycleTimesFilterData[0],
    phaseFilter: cycleTimesFilterData[1],
    fromMilestone: cycleTimesFilterData[2],
    toMilestone: cycleTimesFilterData[2],
  };
  return {
    ragStatusSection,
    cycleStageOvertimeSection,
    cycleStageCategorySection,
    cycleStagePerformanceByTrialSection,
  };
};
export default transform;
