import { get, isNil } from 'lodash';
import { aqua, gray2, gray5, ragRed } from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';
import {
  edcColumnLabelFormatter,
  edcColumnTooltipFormatter,
} from './utility/formatter';

class EdcColumn {
  _legendStyleOptions = { ...styleComponent('cap'), color: gray2 };

  _CHART_CONFIGURATION = {
    title: { text: '' },

    legend: {
      enabled: true,
      align: 'left',
      verticalAlign: 'top',
      itemStyle: this._legendStyleOptions,
    },
  };

  _xAxisLabel = null;

  _yAxisLabel = null;

  set xAxisLabel(name) {
    if (typeof name === 'string') this._xAxisLabel = name;
    else this._xAxisLabel = '';
  }

  get xAxisLabel() {
    return this._xAxisLabel;
  }

  set yAxisLabel(name) {
    if (typeof name === 'string') this._yAxisLabel = name;
    else this._yAxisLabel = '';
  }

  get yAxisLabel() {
    return this._yAxisLabel;
  }

  constructor(chartType) {
    this._chartType = chartType;
  }

  _getExportConfig() {
    const exporting = {
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen'],
        },
      },
    };
    return exporting;
  }

  _getChartConfig(data) {
    return {
      scrollablePlotArea: {
        minWidth: !isNil(data) ? 30 * data.length : 990,
      },
      type: 'column',
    };
  }

  getTooltipFormatter() {
    return edcColumnTooltipFormatter();
  }

  _getXAxis() {
    const xAxis = {
      title: { text: this._xAxisLabel },
      labels: { enabled: false },
      type: 'category',
      crosshair: true,
    };
    return xAxis;
  }

  _getYAxis() {
    const yAxis = {
      title: { text: this._yAxisLabel },
    };

    return yAxis;
  }

  _getBarColor = (value) => {
    let color = gray5;
    if (isNil(value)) color = aqua;
    else if (value === true) color = ragRed;
    else if (value === false) color = aqua;
    return color;
  };

  _getSeries(data) {
    const series = data.map((element) => {
      const legendName = get(element, 'name', '');
      const idValue = get(element, 'id', null);
      const dataPoint = get(element, 'data', []);
      const linkedTo = get(element, 'linkedTo', null);
      const isLowValue = get(element, 'isLow', null);
      const item = {
        name: legendName,
        id: idValue,
        color: this._getBarColor(isLowValue),
        data: dataPoint,
        linkedTo,
      };
      if (isNil(dataPoint[0].y) || isNil(dataPoint[0].name)) return null;

      return item;
    });
    const filteredSeries = series.filter((item) => !isNil(item));
    return filteredSeries;
  }

  _getLabelFormatter() {
    return edcColumnLabelFormatter();
  }

  generateChart(data) {
    const chart = this._getChartConfig(data);
    const xAxis = this._getXAxis();
    const yAxis = this._getYAxis();
    const series = this._getSeries(data);
    const exporting = this._getExportConfig();

    const tooltipFormatter = this.getTooltipFormatter();
    const labelFormatter = this._getLabelFormatter();
    const chartData = {
      ...this._CHART_CONFIGURATION,
      chart,
      xAxis,
      yAxis,
      series,
      exporting,
      tooltip: { formatter: tooltipFormatter },
      plotOptions: {
        column: {
          grouping: false,
          pointWidth: 15,
          pointPadding: 0.2,
        },
        series: {
          dataLabels: {
            enabled: true,
            allowOverlap: true,
            crop: false,
            overflow: 'none',
            useHTML: true,
            formatter: labelFormatter,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
    };
    return chartData;
  }
}

export default EdcColumn;
