import { get } from 'lodash';
import countryTransform from './country.transform';
import partTransform from './part.transform';
import siteTransform from './site.transform';

const transformDrillDownData = (data, params) => {
  const countryChartData = countryTransform(data, params);
  const siteChartData = siteTransform(data, params);
  let partChartData = {};

  const isTrialComplex = get(data, 'isComplex', false);

  if (isTrialComplex) {
    partChartData = partTransform(data, params);
  }

  const dataByCountry = get(data, 'dataByCountry', []);
  const dataBySite = get(data, 'dataBySite', []);
  const dataByPart = get(data, 'dataByPart', []);

  const { partDataCurrentNumberCurrent, partDataRateCurrent } = partChartData;

  const currentNumberData = {
    ...siteChartData.currentNumber,
    ...countryChartData.currentNumber,
    chartByPartCurrent: partDataCurrentNumberCurrent,
  };

  const rateData = {
    ...siteChartData.rate,
    ...countryChartData.rate,
    chartByPartCurrent: partDataRateCurrent,
  };

  const transformedData = {
    data: {
      currentNumber: currentNumberData,
      rate: rateData,
      siteTableData: dataBySite,
      countryTableData: dataByCountry,
      partTableData: dataByPart,
      isComplex: isTrialComplex,
    },
  };

  return transformedData;
};

export default transformDrillDownData;
