import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import stackedChartDataTransform from '../../utility/stackedChartData.transform';
import crfSortTransform from '../../__utility__/crfSort.transform';

export const queryAgingChartHttpRequest = (() => {
  const apiName = 'queryAgingChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource =
    'trial-management/edc-query-aging-chart/trial/:trialId';
  generatedHttpThunk.transform = stackedChartDataTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export const queryAgingTableHttpRequest = (() => {
  const apiName = 'queryAgingTable';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-query-aging-table/trial/:trialId`;
  generatedHttpThunk.transform = crfSortTransform;
  return generatedHttpThunk.generateTrialThunk();
})();
