import { get, isNil, map, uniq } from 'lodash';
import GptGoalsChart from './utility/gptGoalsChart.abstract';

const gptDataTransform = (gptData, requestParams) => {
  const gptRecords = get(gptData, 'records', []);
  const gptStatistics = get(gptData, 'statistics', []);
  const gptGoalStatus = map(gptRecords, 'status', []);
  const categories = map(gptRecords, 'primaryCategoryName', []);

  const uniqueGptGoalStatus = uniq(gptGoalStatus)
    .filter((gptStatus) => !isNil(gptStatus) && gptStatus.label !== '')
    .sort(
      (firstGptStatusValue, secondGptStatusValue) =>
        firstGptStatusValue - secondGptStatusValue,
    )
    .map((gptStatus) => ({ label: gptStatus, value: gptStatus }));

  const uniqueCatagories = uniq(categories)
    .filter((category) => !isNil(category) && category.label !== '')
    .sort(
      (firstCategoryValue, secondCategoryValue) =>
        firstCategoryValue - secondCategoryValue,
    )
    .map((category) => ({
      label: category,
      value: category,
    }));

  const gptGoalsChart = new GptGoalsChart('count');
  gptGoalsChart.isCompleted = requestParams.completed;
  gptGoalsChart.sortByOrderKey = 'name';
  gptGoalsChart.sortByOrderList = [
    'Achieved early',
    'Achieved On Time',
    'Achieved Late',
    'Missed',
  ];
  const chartData = gptGoalsChart.generateChart(gptStatistics);

  const transformedGptData = {
    ...gptData,
    chartData,
    uniqueGptGoalStatus,
    uniqueCatagories,
  };

  return transformedGptData;
};

export default gptDataTransform;
