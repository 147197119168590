import { TABLE_LABELS } from 'dashboards/PortfolioManagement/constants/protocolAmendments.constants';

export const getFSILabel = (value) => {
  if (value === true) {
    return TABLE_LABELS.POST_FSI;
  }
  if (value === false) {
    return TABLE_LABELS.PRE_FSI;
  }
  return value;
};

export const getSubstantialLabel = (value) => {
  if (value === true) {
    return TABLE_LABELS.SUBSTANTIAL;
  }
  if (value === false) {
    return TABLE_LABELS.NON_SUBSTANTIAL;
  }
  return TABLE_LABELS.NOT_SPECIFIED;
};
