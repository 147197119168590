const METADATA_SHEET_HEADER = '#-------------------';
const EXPORT_DATE_FORMAT = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: 'numeric',
};
const FILE_DATE_FORMAT = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
};

export const getMetadataSheetData = (
  listType,
  selectedFilters,
  search,
  countryFilters,
  exportDate,
) => {
  if (listType === 'Trial') {
    return [
      { [METADATA_SHEET_HEADER]: `# ${listType} Management List Export` },
      { [METADATA_SHEET_HEADER]: 'Global filter(s) used:' },
      {
        [METADATA_SHEET_HEADER]: Object.values(selectedFilters).join(', '),
      },
      { [METADATA_SHEET_HEADER]: `Search query: ${search}` },
      { [METADATA_SHEET_HEADER]: `Export date: ${exportDate}` },
      { [METADATA_SHEET_HEADER]: METADATA_SHEET_HEADER },
    ];
  }
  return [
    { [METADATA_SHEET_HEADER]: `# ${listType} Management List Export` },
    { [METADATA_SHEET_HEADER]: 'Global filter(s) used:' },
    {
      [METADATA_SHEET_HEADER]: Object.values(selectedFilters).join(', '),
    },
    { [METADATA_SHEET_HEADER]: `Search query: ${search}` },
    { [METADATA_SHEET_HEADER]: `Country Filters: ${countryFilters}` },
    { [METADATA_SHEET_HEADER]: `Export date: ${exportDate}` },
    { [METADATA_SHEET_HEADER]: METADATA_SHEET_HEADER },
  ];
};

export const METADATA_SHEET_OPTIONS = {
  '!cols': [{ wch: 25 }],
};

export const getExportDateTime = () =>
  new Date().toLocaleDateString('en-US', EXPORT_DATE_FORMAT);
export const getExportSheetDate = () =>
  new Date().toLocaleDateString('en-US', FILE_DATE_FORMAT);
