import { get, isNil } from 'lodash';

const {
  ragRed,
  ragGreen,
  ragYellow,
  gray3,
} = require('assets/stylesheets/base/_colors');

const COLOR_MAP = {
  RED: ragRed,
  GREEN: ragGreen,
  AMBER: ragYellow,
  GRAY: gray3,
};

const getColorByRagStatus = (ragStatus) => {
  let color = get(COLOR_MAP, ragStatus, null);
  if (isNil(color)) {
    color = COLOR_MAP.GRAY;
  }
  return color;
};

export default getColorByRagStatus;
