import { TIMELINE_ITEMS } from '../../../../../../constants';

const COMMON_TABLE_ITEMS = {
  actualVsOriginal: {
    code: 'actualVsOriginal',
    label: 'Actual vs Original plan difference (days)',
    type: 'CALCULATED',
  },
  actualVsCurrentPlan: {
    code: 'actualVsCurrentPlan',
    label: 'Actual vs Current plan difference (days)',
    type: 'CALCULATED',
  },
  originalVsCurrentPlan: {
    code: 'originalVsCurrentPlan',
    label: 'Original vs Current plan difference (days)',
    type: 'CALCULATED',
  },
  currentPlanVsActual: {
    code: 'currentPlanVsActual',
    label: 'Current vs Actual plan difference (days)',
    type: 'CALCULATED',
  },
  originalVsActual: {
    code: 'originalVsActual',
    label: 'Original vs Actual plan difference (days)',
    type: 'CALCULATED',
  },
};

export const TABLE_DATA_STRUCTURE = {
  originalMilestoneDate: {
    code: 'originalMilestoneDate',
    label: 'Original Plan',
    type: 'RAW',
  },
  plannedMilestoneDate: {
    code: 'plannedMilestoneDate',
    label: 'Current Plan',
    type: 'RAW',
  },
  actualMilestoneDate: {
    code: 'actualMilestoneDate',
    label: 'Actual',
    type: 'RAW',
  },
  currentPlanVsOriginal: {
    code: 'currentPlanVsOriginal',
    label: 'Current vs Original plan difference (days)',
    type: 'CALCULATED',
  },
  actualVsOriginal: {
    code: 'actualVsOriginal',
    label: 'Actual vs Original plan difference (days)',
    type: 'CALCULATED',
  },
  ...COMMON_TABLE_ITEMS,
};

export const COMPLEX_TABLE_DATA_STRUCTURE = {
  originalMilestoneDate: {
    code: 'originalMilestoneDate',
    label: TIMELINE_ITEMS.ORIGINAL,
    type: 'RAW',
  },
  plannedMilestoneDate: {
    code: 'plannedMilestoneDate',
    label: TIMELINE_ITEMS.PLANNED,
    type: 'RAW',
  },
  actualMilestoneDate: {
    code: 'actualMilestoneDate',
    label: TIMELINE_ITEMS.ACTUAL_AND_FORECAST,
    type: 'RAW',
  },
  currentPlanVsOriginal: {
    code: 'currentPlanVsOriginal',
    label: 'Current vs Original plan difference (days)',
    type: 'CALCULATED',
  },
  ...COMMON_TABLE_ITEMS,
};
