import React from 'react';
import { isNil, isNumber, omit } from 'lodash';
import PropTypes from 'prop-types';
import { roundOff } from 'utility/math/valueRounder';
import { DEFAULT_PLACE_HOLDER } from './constants/placeholders';

function SafeHtmlElements(props) {
  const { placeholder, precision } = props;
  const { className, onClick, tagName, arraySeparator } = props;

  const { children } = props;
  const { dataTestId } = props;
  const { fixedTrailingPoints } = props;

  const TagName = tagName;

  const remainingProps = omit(props, [
    'placeholder',
    'arraySeparator',
    'precision',
    'tagName',
    'children',
    'dataTestId',
    'className',
    'onClick',
  ]);

  const generatePlaceHolder = () => {
    if (typeof placeholder === 'object') {
      return placeholder;
    }

    if (isNil(placeholder)) {
      return (
        <TagName
          className={`${className} placeholder`}
          data-testid={dataTestId}
          {...remainingProps}
        >
          {DEFAULT_PLACE_HOLDER}
        </TagName>
      );
    }

    return (
      <TagName
        data-testid={dataTestId}
        className={`${className} placeholder`}
        {...remainingProps}
      >
        {placeholder}
      </TagName>
    );
  };

  if (isNil(children)) {
    return generatePlaceHolder();
  }

  if (Array.isArray(children)) {
    if (children.length === 0) {
      return generatePlaceHolder();
    }

    const stringArray = children.map((arrayItem) => {
      const joinAbleDataTypes = ['string', 'array', 'null', 'undefined'];
      if (joinAbleDataTypes.indexOf(typeof arrayItem) >= 0) {
        return `${arrayItem}`;
      }
      return `${placeholder}`;
    });
    const displayString = stringArray.join(arraySeparator);

    return (
      <TagName
        className={className}
        onClick={onClick}
        data-testid={dataTestId}
        {...remainingProps}
      >
        {displayString}
      </TagName>
    );
  }

  if (typeof children === 'object') {
    return children;
  }

  if (!isNil(children) && isNumber(children)) {
    const roundedNumber = roundOff(Number(children), precision);
    const displayValue = isNil(fixedTrailingPoints)
      ? roundedNumber
      : roundedNumber.toFixed(fixedTrailingPoints);
    return (
      <TagName
        className={className}
        onClick={onClick}
        data-testid={dataTestId}
        {...remainingProps}
      >
        {displayValue}
      </TagName>
    );
  }

  return (
    <TagName
      className={className}
      onClick={onClick}
      data-testid={dataTestId}
      {...remainingProps}
    >
      {children}
    </TagName>
  );
}

SafeHtmlElements.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  precision: PropTypes.node,
  tagName: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  arraySeparator: PropTypes.string,

  // Note: here we are manually handling all datatypes
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  fixedTrailingPoints: PropTypes.number,
};

SafeHtmlElements.defaultProps = {
  placeholder: DEFAULT_PLACE_HOLDER,
  precision: 1,
  className: '',
  tagName: 'p',
  onClick: null,
  dataTestId: null,
  arraySeparator: ', ',
  children: null,
  fixedTrailingPoints: null,
};

export default SafeHtmlElements;
