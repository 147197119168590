const transform = (payload) => {
  const driverMap = {};
  if (payload) {
    payload.forEach((driver) => {
      driverMap[driver.metricType] = driver;
    });
  }
  return {
    driverMap,
  };
};

export default transform;
