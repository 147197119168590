import { get } from 'lodash';
import { UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';
import { getFSILabel, getSubstantialLabel } from './common';
import getGroupedByData from '../getGroupedByData';

const getTableData = (categoryData) => {
  const overallData = categoryData.reduce((tableData, categoryInfo) => {
    const trialGroup = get(categoryInfo, 'groupedKey');
    const groupData = categoryInfo.trials.reduce((groupAmendments, trial) => {
      const { trialId } = trial;
      const trialAmendmentsData = trial.amendments.map((amendmentInfo) => ({
        group: trialGroup,
        trialId,
        ...amendmentInfo,
        dateOfAmendment: amendmentInfo.dateOfAmendment
          ? new DateStringTransform(amendmentInfo.dateOfAmendment).formatter(
              UI_DISPLAY_DATE,
            )
          : null,
        substantialFlag: getSubstantialLabel(amendmentInfo.substantialFlag),
        postFsiFlag: getFSILabel(amendmentInfo.postFsiFlag),
      }));
      return [...groupAmendments, ...trialAmendmentsData];
    }, []);

    return [...tableData, ...groupData];
  }, []);
  return overallData;
};

const getAmendmentData = (key, data) => {
  const { croData, phaseData, tauData, tierData } = getGroupedByData(
    data,
    key,
    getTableData,
  );

  return {
    groupedByTau: tauData,
    groupedByCro: croData,
    groupedByPhase: phaseData,
    groupedByTier: tierData,
  };
};

export default getAmendmentData;
