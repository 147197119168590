import { get } from 'lodash';
import getGroupedByData from '../getGroupedByData';

const getTableData = (categoryData) => {
  const overallData = categoryData.reduce((tableData, categoryInfo) => {
    const trialGroup = get(categoryInfo, 'groupedKey');
    const rawData = get(categoryInfo, 'primaryReasons', []);
    const groupData = rawData.map((amendmentInfo) => ({
      group: trialGroup,
      ...amendmentInfo,
    }));
    return [...tableData, ...groupData];
  }, []);
  return overallData;
};

const getMostFrequentReasonsData = (key, data) => {
  const { croData, phaseData, tauData, tierData } = getGroupedByData(
    data,
    key,
    getTableData,
  );

  return {
    groupedByTau: tauData,
    groupedByCro: croData,
    groupedByPhase: phaseData,
    groupedByTier: tierData,
  };
};

export default getMostFrequentReasonsData;
