import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import transform from './utility/transformer';

const protocolAmendmentsDataHttpRequest = (() => {
  const apiName = 'protocolAmendmentsDrilldown';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/protocol-amendments`;
  generatedHttpThunk.transform = transform;
  return generatedHttpThunk.generateThunk();
})();

export default protocolAmendmentsDataHttpRequest;
