import { get, isNil, orderBy } from 'lodash';
import { addFileName } from 'utility';
import DrillDown from './01_drilldown.abstract';
import generateTooltip from './utility/enrollmentDrilldownTooltip';
import siteFactory from './siteFactory';
import exportDrilldown from './utility/drilldownExporter';

class SiteDrillDown extends DrillDown {
  static _xAxis = {
    type: 'category',
    title: { text: 'Sites' },
    labels: { enabled: false },
    crosshair: true,
  };

  __generateYAxisPlotLines(rateKey, dataKey) {
    const avgLableText = this._generateAverageLineLabel('Site', rateKey);
    return [
      {
        value: dataKey,
        width: 1,
        label: {
          text: avgLableText,
          align: 'right',
          verticalAlign: 'top',
          useHTML: true,
          y: -8,
          x: -8,
        },
      },
    ];
  }

  _generateSiteSeries(dataKey) {
    const obj = new (siteFactory(this._selectedCard))();
    const enrollmentSeries = obj.generateSeries(this._siteData, dataKey);
    return enrollmentSeries;
  }

  _getExport(data, selectedCard, isRateType) {
    return {
      enabled: true,
      allowHTML: true,
      menuItemDefinitions: {
        downloadCSV: {
          onclick: () =>
            exportDrilldown(data, selectedCard, isRateType, 'site'),
          text: 'Download CSV',
        },
      },
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'separator', 'downloadCSV'],
        },
      },
    };
  }

  _generateSiteHighChart(rateKey, selectedCard) {
    const chart = {
      type: 'column',
      scrollablePlotArea: {
        minWidth: 30 * this._siteData.length,
      },
      marginRight: 50,
    };
    const dataKey =
      rateKey === `${selectedCard}Rate`
        ? this._medianRate
        : this._medianCurrentNo;

    const yAxisPlotLines = this.__generateYAxisPlotLines(rateKey, dataKey);

    const generateYAxis = () => ({
      title: {
        text: this._generateYAxisTitle(rateKey),
      },
      plotLines: yAxisPlotLines,
    });

    const addTooltip = () => ({
      shared: true,
      shape: 'square',
      distance: 20,
      formatter() {
        const { points } = this;
        const siteTooltip = generateTooltip(points, selectedCard, rateKey);
        return siteTooltip;
      },
    });

    const plotLines = [
      {
        value: dataKey,
        width: 1,
        label: {
          text: this._generateAverageLineLabel('Site', rateKey),
          align: 'right',
          verticalAlign: 'top',
          useHTML: true,
          y: -8,
          x: -8,
        },
      },
    ];

    const isRateType = rateKey === `${selectedCard}Rate`;
    const nullData = this._siteData.filter((row) =>
      isNil(get(row, `${rateKey}.value`)),
    );
    const notNullData = this._siteData.filter(
      (row) => !isNil(get(row, `${rateKey}.value`)),
    );
    const sortedData = orderBy(notNullData, `${rateKey}.value`, 'desc');

    const chartData = {
      chart,
      title: SiteDrillDown._title,
      accessibility: SiteDrillDown._accessibility,
      xAxis: SiteDrillDown._xAxis,
      yAxis: generateYAxis(),
      legend: SiteDrillDown._legend,
      plotOptions: this._plotOptions,
      plotLines,
      tooltip: addTooltip(),
      series: this._generateSiteSeries(rateKey),
      exporting: this._getExport(
        [...sortedData, nullData],
        selectedCard,
        isRateType,
      ),
    };
    return chartData;
  }

  generateGraph() {
    const highchartByRate = this._generateSiteHighChart(
      this._rateKey,
      this._selectedCard,
    );
    const highchartByCount = this._generateSiteHighChart(
      this._countKey,
      this._selectedCard,
    );
    const siteChart = 'site';
    const siteRateData = {
      chartBySite: addFileName(highchartByRate, siteChart),
    };
    const siteNumberData = {
      chartBySite: addFileName(highchartByCount, siteChart),
    };

    const graphs = {
      rate: siteRateData,
      currentNumber: siteNumberData,
    };

    return graphs;
  }
}

export default SiteDrillDown;
