import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import { globalFilterSelector } from 'utility/redux/globalFilter.selector';

const SiteCountHttpRequest = (() => {
  const apiName = 'siteCount';
  const syntheticResponse = {
    data: {
      data: { metadata: { count: 0 } },
    },
  };
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = 'site-health/sites';
  generatedHttpThunk.syntheticResponse = syntheticResponse;
  generatedHttpThunk.globalFilterSelector = globalFilterSelector;
  return generatedHttpThunk.generateSiteThunk();
})();

export default SiteCountHttpRequest;
