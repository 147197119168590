import {
  COMPASS_BACKEND_FORMAT,
  UI_DISPLAY_DATE,
  SHORT_UI_DISPLAY_DATE,
  MONTH_DATE_DISPLAY,
  YEAR_DISPLAY,
  MONTH_DISPLAY,
  DATE_DISPLAY,
  SHORT_MONTH_DISPLAY,
} from 'constants/date/dateFormats.constant';
import dayjs from 'dayjs';
import { isNil } from 'lodash';

import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc);
dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

class DateStringTransform {
  #dateJsObject = null;

  _initiateDateJsObject(dateString) {
    const timeZoneless = `${dateString}T00:00:00+00:00`;

    const dateJsObject = dayjs(
      timeZoneless,
      `${COMPASS_BACKEND_FORMAT}THH:mm:ssZ`,
      true,
    );

    if (dateJsObject.isValid()) this.#dateJsObject = dateJsObject;
    else this.#dateJsObject = null;
  }

  constructor(dateString) {
    this._initiateDateJsObject(dateString);
  }

  set dateString(dateString) {
    this._initiateDateJsObject(dateString);
  }

  get dateString() {
    return this.#dateJsObject;
  }

  set dateObject(dateObject) {
    const dateJsObject = dayjs(dateObject);
    if (dateJsObject.isValid()) this.#dateJsObject = dateJsObject;
    else this.#dateJsObject = null;
  }

  get dateObject() {
    return this.#dateJsObject;
  }

  getFormattedDate(today, format = COMPASS_BACKEND_FORMAT) {
    const todayObject = dayjs(today);
    const formattedDate = todayObject.format(format);
    return formattedDate;
  }

  convertFormattedDate(date) {
    return new Date(date.replace(/-/g, '/'));
  }

  getTodayDate(format = COMPASS_BACKEND_FORMAT) {
    const today = dayjs();
    const formattedDate = today.format(format);
    return formattedDate;
  }

  isValid() {
    return Boolean(this.#dateJsObject);
  }

  formatter(format) {
    const allowedDateFormats = [
      COMPASS_BACKEND_FORMAT,
      UI_DISPLAY_DATE,
      SHORT_UI_DISPLAY_DATE,
      MONTH_DATE_DISPLAY,
      YEAR_DISPLAY,
      MONTH_DISPLAY,
      SHORT_MONTH_DISPLAY,
      DATE_DISPLAY,
    ];

    if (!allowedDateFormats.includes(format)) return null;
    if (isNil(this.#dateJsObject)) return null;
    const dateString = this.#dateJsObject.utc().format(format);
    return dateString;
  }

  getQuarter() {
    if (isNil(this.#dateJsObject)) return null;
    const quarter = this.#dateJsObject.utc().quarter();
    return quarter;
  }

  toString() {
    return this.#dateJsObject;
  }
}

export default DateStringTransform;
