import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import activationsEnrollmentsToPlanTransform from './utility/activationsEnrollmentsToPlan.transform';

const activationEnrollmentsToPlanHttpRequest = (() => {
  const apiName = `activationsEnrollmentsToPlan`;
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/trial-graph/activationsxenrollments`;
  generatedHttpThunk.transform = activationsEnrollmentsToPlanTransform;
  return generatedHttpThunk.generateThunk();
})();

export default activationEnrollmentsToPlanHttpRequest;
