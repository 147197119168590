import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import React from 'react';
import { rightArrow, searchIcon } from 'assets/icons';
import './filterElements.scss';

function SearchBar({
  onChange,
  searchValue,
  placeholderText,
  onSubmitSearch,
  className,
}) {
  return (
    <div className={className}>
      <span className="p-input-icon-left w-100">
        <img src={searchIcon} alt="search" className="px-2" />
        <InputText
          value={searchValue}
          onChange={(changeEvent) => {
            if (changeEvent.target && changeEvent.target.value.length <= 200) {
              onChange(changeEvent.target.value);
            }
            if (!changeEvent.target.value) {
              onSubmitSearch('');
            }
          }}
          onKeyUp={(clickEvent) => {
            if (clickEvent.key === 'Enter') {
              onSubmitSearch(searchValue);
            }
          }}
          id="search-input"
          data-testid="search-input-box"
          className="p-1 px-4"
          placeholder={placeholderText}
        />
        <img
          src={rightArrow}
          alt="search"
          onClick={() => onSubmitSearch(searchValue)}
          className="px-2 search-submit cursor-pointer"
        />
      </span>
    </div>
  );
}
SearchBar.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmitSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  placeholderText: PropTypes.string,
};
SearchBar.defaultProps = {
  className: '',
  searchValue: '',
  placeholderText: '',
};
export default SearchBar;
