import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import crfEntryTimeTransform from './utility/crfEntry.transform';
import crfSortTransform from '../../__utility__/crfSort.transform';

export const crfEntryTimeChartHttpRequest = (() => {
  const apiName = 'crfEntryTimeChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-entry-time-chart/trial/:trialId`;
  generatedHttpThunk.transform = crfEntryTimeTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export const crfEntryTimeTableHttpRequest = (() => {
  const apiName = 'crfEntryTimeTable';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-entry-time-table/trial/:trialId`;
  generatedHttpThunk.transform = crfSortTransform;
  return generatedHttpThunk.generateTrialThunk();
})();
