import styleComponent from 'assets/stylesheets/base/_typography';
import { map } from 'lodash';
import { roundOff } from 'utility/math/valueRounder';
import {
  gray3,
  gray2,
  aqua,
  gray1,
} from '../../../../../../assets/stylesheets/base/_colors';

const topReasonsChartDataTransform = (topReasonsDataArray) => {
  const chartData = {
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      itemMarginTop: 10,
      itemMarginBottom: 10,
      itemPadding: 10,
      itemStyle: {
        color: gray1,
        ...styleComponent('cap'),
      },
    },
    chart: {
      type: 'column',
      marginTop: 80,
      scrollablePlotArea: {
        minWidth: 75 * topReasonsDataArray.length,
      },
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: map(topReasonsDataArray, 'rationale'),
      // labels: {
      //   useHTML: true,
      //   formatter: function() {
      //     return `${this.value}`
      //   }
      // }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Amendments',
        style: {
          color: gray3,
        },
      },
      labels: {
        rotation: -90,
        style: {
          color: gray3,
        },
      },
    },
    tooltip: {
      pointFormat:
        '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: 'number',
      },

      series: {
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
        pointWidth: 60,
      },
    },
    series: [
      {
        name: 'Substantial',
        data: map(topReasonsDataArray, (item) =>
          roundOff(item.substantialAmendmentCount, 1),
        ),
        legendIndex: 0,
        color: aqua,
      },
      {
        name: 'Non-substantial',
        data: map(topReasonsDataArray, (item) =>
          roundOff(item.nonSubstantialAmendmentCount, 1),
        ),
        legendIndex: 1,
        color: gray2,
      },
      {
        name: 'Not specified',
        data: map(topReasonsDataArray, (item) =>
          roundOff(item.unknownAmendmentCount, 1),
        ),
        legendIndex: 2,
        color: gray3,
      },
    ],
  };
  return { chartData };
};

export default topReasonsChartDataTransform;
