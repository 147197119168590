import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import stackedChartDataTransform from '../utility/stackedChartData.transform';

const queryAgingChartHttpRequest = (() => {
  const apiName = 'queryAgingChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource =
    'site-health/edc-query-aging-site-chart/site/:siteId/trial/:trialId';
  generatedHttpThunk.transform = stackedChartDataTransform;
  return generatedHttpThunk.generateSiteTrialThunk();
})();

export default queryAgingChartHttpRequest;
