import { AMENDMENT_GROUP_DATA } from 'dashboards/PortfolioManagement/constants/protocolAmendments.constants';
import { map, sortBy } from 'lodash';
import getGroupedByData from './getGroupedByData';

export const getCategoryData = (categoryData, dataKey) => {
  const sortedCategoryData = sortBy(categoryData, 'groupedKey');
  const allCategories = map(sortedCategoryData, 'groupedKey');
  const allData = map(sortedCategoryData, dataKey);
  const categories = [];
  const data = allData.reduce((validData, currentPoint, index) => {
    const currentCategory = allCategories[index];
    if (currentPoint !== null && currentCategory !== null) {
      categories.push(currentCategory);
      return [...validData, currentPoint];
    }
    return validData;
  }, []);
  return {
    categories,
    data,
  };
};

export const getColumnChartDataPoints = (
  key,
  data,
  legendLabel,
  dataKey = 'metricValue',
  isPercentage = false,
) => {
  const { croData, phaseData, tauData, tierData } = getGroupedByData(
    data,
    key,
    getCategoryData,
    dataKey,
  );

  return {
    groupedByCro: {
      categories: croData.categories,
      data: croData.data,
      legendLabel,
      xLabel: AMENDMENT_GROUP_DATA['Group by CRO'].dataLabel,
      isPercentage,
    },
    groupedByPhase: {
      categories: phaseData.categories,
      data: phaseData.data,
      legendLabel,
      xLabel: AMENDMENT_GROUP_DATA['Group by Phase'].dataLabel,
      isPercentage,
    },
    groupedByTau: {
      categories: tauData.categories,
      data: tauData.data,
      legendLabel,
      xLabel: AMENDMENT_GROUP_DATA['Group by TAU'].dataLabel,
      isPercentage,
    },
    groupedByTier: {
      categories: tierData.categories,
      data: tierData.data,
      legendLabel,
      xLabel: AMENDMENT_GROUP_DATA['Group by Tier'].dataLabel,
      isPercentage,
    },
  };
};
