import axios from 'axios';
import { generateHttpActions } from 'utility';

function dataSourcesHttpRequest() {
  const httpActions = generateHttpActions('dataSources');
  const resource = 'sources/application';

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const response = await axios.get(resource);

      dispatch(httpActions.success(response.data));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default dataSourcesHttpRequest;
