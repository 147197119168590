import { generateHttpActions } from 'utility';
import axios from 'axios';
import { isNil, map } from 'lodash';
import { AxiosHttpError } from 'utility/errors';
import transform from './utility/04_enrolmentCurveAndForecast';
import checkResponse from './utility/04_enrolmentCurveAndForecast/responses.check';
import {
  mockForecastPayload,
  mockSiteActivationResponse,
  mockEnrolmentResponse,
  mockScreeningResponse,
  mockDropoutResponse,
} from '../../../../utility/redux/emptyResponses';

function trialEnrollmentDataHttpRequest(trialId) {
  const httpActions = generateHttpActions('enrollmentCurve');
  const resourceEnrolment = `trial-management/monthly-enrollment/trial/${trialId}`;
  const resourceSiteActivation = `trial-management/site-activation/trial/${trialId}`;
  const resourceForecast = `trial-management/enrollment-forecast/trial/${trialId}`;
  const resourceScreening = `trial-management/monthly-screening/trial/${trialId}`;
  const resourceDropout = `trial-management/monthly-dropout/trial/${trialId}`;

  return async (dispatch) => {
    dispatch(httpActions.start());
    let responses = [];
    const responseErrors = [];
    let responsesFailureCount = 0;
    try {
      const resources = [
        resourceEnrolment,
        resourceSiteActivation,
        resourceForecast,
        resourceScreening,
        resourceDropout,
      ];
      const emptyResponsePayloads = [
        mockEnrolmentResponse,
        mockSiteActivationResponse,
        mockForecastPayload,
        mockScreeningResponse,
        mockDropoutResponse,
      ];
      const checkedResponses = resources.map((resource) =>
        checkResponse(axios.get(resource)),
      );
      responses = await Promise.all(checkedResponses);
      responses = responses.map((responseItem, index) => {
        const { response } = responseItem;
        if (isNil(response)) {
          responsesFailureCount += 1;
          responseErrors.push(responseItem.error);
          return emptyResponsePayloads[index];
        }
        return response;
      });

      const responsesData = map(responses, 'data');
      const transformData = transform(...responsesData);
      if (responsesFailureCount > 3) {
        const errorDetails = responseErrors[0].details;
        throw new AxiosHttpError(errorDetails);
      }
      dispatch(httpActions.success({ data: transformData }));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default trialEnrollmentDataHttpRequest;
