import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import cycleTimesTransform from './19_cycleTimes/utility/transform';

const CycleTimesHttpRequest = (() => {
  const apiName = 'cycleTimes';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/cycle-times/trial/:trialId`;
  generatedHttpThunk.transform = cycleTimesTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export default CycleTimesHttpRequest;
