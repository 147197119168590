import {
  gray2,
  ragGreen,
  ragRed,
  ragYellow,
} from 'assets/stylesheets/base/_colors';
import StackedColumn from '../../../../../../../utility/generateHighChart/columnChart/StackedColumn.abstract';

class ClinicalMilestonesChart extends StackedColumn {
  isStacked = true;

  _colorList = [ragRed, ragGreen];

  set isCompleted(value) {
    this._isCompleted = value;
  }

  get isCompleted() {
    return this._isCompleted;
  }

  _getColorForChart(secondaryCategory) {
    const secondaryCategoryLowerCase = secondaryCategory.toLowerCase();
    if (this._isCompleted)
      return this._getCompletedChartColor(secondaryCategoryLowerCase);
    return this._getProjectedChartColor(secondaryCategoryLowerCase);
  }

  _getCompletedChartColor(secondaryCategory) {
    switch (secondaryCategory) {
      case 'completed on plan':
        return ragGreen;
      case 'completed late':
        return ragRed;
      default:
        return gray2;
    }
  }

  _getProjectedChartColor(secondaryCategory) {
    switch (secondaryCategory) {
      case 'green':
        return ragGreen;
      case 'red':
        return ragRed;
      case 'amber':
        return ragYellow;
      default:
        return gray2;
    }
  }
}

export default ClinicalMilestonesChart;
