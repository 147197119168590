import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import { globalFilterSelector } from 'utility/redux/globalFilter.selector';

export const generateParams = (payload) => {
  const filters = payload.activeFilters;
  const { search, offset, sort, limit } = payload;
  const filterableColumns = [
    'siteName',
    'siteId',
    'principalInvestigator',
    'country',
    'activeTrialCount',
    'tau',
    'timeToActivate',
    'medianEnrollmentContributionValue',
  ];
  let searchString = null;
  if (search) {
    const searchJSON = {};
    filterableColumns.forEach((column) => {
      searchJSON[column] = [{ value: search, matchType: 'CONTAINS' }];
    });
    searchString = JSON.stringify(searchJSON);
  }

  const params = {
    filters: JSON.stringify(filters),
    offset,
    search: searchString,
    limit,
    sort: JSON.stringify(sort),
  };

  return params;
};

const SiteHealthListHttpRequest = (() => {
  const apiName = 'siteHealthList';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/sites`;
  generatedHttpThunk.syntheticResponse = {};
  generatedHttpThunk.globalFilterSelector = globalFilterSelector;
  return generatedHttpThunk.generateSiteThunk();
})();

export default SiteHealthListHttpRequest;
