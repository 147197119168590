import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const trialKeyFactsHttpRequest = (() => {
  const apiName = 'trialKeyFacts';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/trial/:trialId`;
  return generatedHttpThunk.generateTrialThunk();
})();

export default trialKeyFactsHttpRequest;
