import { useOktaAuth } from '@okta/okta-react';
import UserPreference from 'components/UserPreference/UserPreference';
import oktaConfig from 'oktaConfig';
import React, { useCallback, useEffect, useRef } from 'react';
import { compassStore } from 'store';
import { dataSourcesHttpRequest } from 'store/thunks';
import { useThunkSelector } from 'utility/redux';
import { Toast } from 'primereact/toast';
import AppHeroSubSection from './01_AppHero.subSection';
import NavigationOptionsSubSection from './02_NavigationOptions.subSection';

import HelpSubSection from './03_Help.subSection';
import RequiredAuth from '../../components/okta/SecureRoute';
import { MENU_ID } from './constants/routeDetails';
import useMenuSubItems from './hooks/useMenuSubItems';
import NavigationBarItem from '../../components/NavigationBarItem/NavigationBarItem';
import './_navigationBarSection.scss';

function NavigationBar() {
  const toast = useRef(null);
  const pageAccess = useThunkSelector('moduleAccess');
  const { oktaAuth } = useOktaAuth();
  const userManagementMenuItems = useMenuSubItems(MENU_ID.USER_MANAGEMENT);
  const showErrorToast = (detail) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail });
  };

  const logoutHandler = useCallback(() => {
    localStorage.clear();
    oktaAuth
      .revokeAccessToken()
      .then(() =>
        oktaAuth.signOut({
          postLogoutRedirectUri: oktaConfig.oidc.postLogoutRedirectUri,
          clearTokensBeforeRedirect: true,
        }),
      )
      .catch(() => {
        showErrorToast('Failed to logout.');
      });
  }, []);

  const store = compassStore;

  const { dispatch } = store;

  useEffect(() => {
    dispatch(dataSourcesHttpRequest());
  }, []);

  return (
    <div className="col-12 nav-menu-container">
      <div className="row d-flex justify-content-center g-0">
        <div className="col-lg-2 col-10 order-1 mt-2 mt-lg-0 d-flex justify-content-start align-items-center">
          <AppHeroSubSection />
        </div>
        <div className="col-lg-7 col-12 order-3 order-lg-2 d-flex justify-content-center justify-content-lg-start nav-menu">
          <NavigationOptionsSubSection />
        </div>
        <div className="col-lg-3 col-2 order-2 order-lg-3 d-flex justify-content-end align-items-center nav-menu">
          <UserPreference />
          <HelpSubSection />
          {pageAccess.isSuccess && pageAccess.payload.userManagement && (
            <NavigationBarItem
              label={<>Admin</>}
              dropdownItems={userManagementMenuItems}
            />
          )}
          <button
            type="button"
            className="btn logout-btn p-1 mx-2 cursor-pointer"
            onClick={logoutHandler}
          >
            Logout
          </button>
        </div>
      </div>
      <RequiredAuth />
      <Toast ref={toast} />
    </div>
  );
}

export default NavigationBar;
