import { UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';

const transform = (gptGoalsResponse) => {
  const gptGoalsData = gptGoalsResponse;
  const formattedData = gptGoalsData.map((gptGoal) => {
    const formattedTargetDate = new DateStringTransform(
      gptGoal.targetDate,
    ).formatter(UI_DISPLAY_DATE);
    return { ...gptGoal, formattedTargetDate };
  });
  return formattedData;
};

export default transform;
