import { get, isNil } from 'lodash';

const generateSortComparatorWithNull = (value, isAscOrder = true) => {
  const comparatorFunction = (seriesData) => {
    const data = get(seriesData, value, null);
    const updatedData = isNil(data) ? Number.NEGATIVE_INFINITY : data;
    const dataOrder = isAscOrder ? updatedData : -updatedData;
    return dataOrder;
  };
  return comparatorFunction;
};

export default generateSortComparatorWithNull;
