// Primary
export const takedaRed = '#450A0A';
export const takedaRed20 = '#F3EFEF';
export const takedaRedHover = '#721313';

// Neutral
export const black = '#000000';
export const white = '#ffffff';
export const blackLabel = '#002F36';

// RAG

export const ragYellow = '#eba902';
export const ragYellow120 = '#d29701';
export const ragYellow60 = '#efbd72';
export const ragYellow40 = '#f3cfa1';
export const ragYellow20 = '#f3cfa1';

export const ragGreen = '#649832';
export const ragGreen120 = ragGreen;
export const ragGreen60 = '#90B17A';
export const ragGreen40 = '#B2C7A5';
export const ragGreen20 = '#CFDBC8';

export const ragRed = '#ea5532';
export const ragRed120 = '#d14c2c';
export const ragRed60 = '#ee897a';
export const ragRed40 = '#ee897a';
export const ragRed20 = '#f6ccc8';

// Data visualization

export const darkBlue = '#3270A2';
export const blue = '#4191D2';
export const blue60 = '#80ACDB';
export const blue40 = '#A8C4E5';
export const blue20 = '#C9D9EE';

export const darkAqua = '#0a8b9e';
export const aqua = '#0c9cb1';
export const aqua80 = '#4191D2';
export const aqua60 = '#72b4c3';
export const aqua40 = '#a1c9d3';
export const aqua20 = '#c5dce3';
export const aqua10 = '#E3EBF7';

export const fujiPurple = '#8c81cb';
export const darkPurple = '#7a70b1';
export const purple = '#8c81cb';
export const purple60 = '#a9a2d6';
export const purple40 = '#c2bde1';
export const purple20 = '#d8d5eb';

export const gray1 = '#34373f';
export const gray2 = '#63666e';
export const gray3 = '#878B97';
export const gray4 = '#dddfe3';
export const gray5 = '#edf2f4';
export const gray6 = '#f2f6f7';

export const newBackground = '#f9fafa';
