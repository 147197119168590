import { each, forOwn, head, last, map } from 'lodash';
import { aqua, gray2, newBackground } from 'assets/stylesheets/base/_colors';
import { UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';

import { transformationFunctionWrapper } from '../../../../../../utility/errors';

const configureStepChart = (data) => ({
  chart: {
    type: 'area',
    spacingTop: 45,
  },

  title: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    tickPositions: [
      data.currPlanFirstDate.getTime(),
      data.todayDate ? data.todayDate.getTime() : null,
      data.currPlanLastDate.getTime(),
    ].filter((datePoint) => !!datePoint),
    labels: {
      format: '{value:%b %Y}',
    },
    plotLines: [
      {
        dashStyle: 'ShortDash',
        color: aqua,
        width: 1,
        value: data.todayDate,
        label: {
          text: 'Today',
          textAlign: 'center',
          verticalAlign: 'top',
          rotation: 0,
          y: -10,
        },
      },
    ],
  },
  yAxis: {
    title: {
      text: 'Sites',
    },
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    area: {
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    symbolRadius: 0,
    align: 'top',
    verticalAlign: 'top',
    floating: true,
    backgroundColor: newBackground,
    y: -40,
  },
  series: [
    {
      name: 'Actual',
      step: 'left',
      color: aqua,
      fillOpacity: 0.6,
      zIndex: 2,
      data: data.actualSitesActivatedCum,
    },
    {
      name: 'Current plan',
      step: 'left',
      color: gray2,
      fillOpacity: 0.3,
      data: data.currPlanSitesActivatedCum,
    },
  ],
});

const transform = (data) => {
  const stepChartData = {
    actualSitesActivatedCum: [],
    currPlanSitesActivatedCum: [],
  };

  each(data.data.dataCountryDrilldownDate, (date) => {
    stepChartData.currPlanSitesActivatedCum.push({
      x: new Date(date.month.value),
      y: date.currPlanSitesActivatedCum.value,
    });
    if (date.actualSitesActivatedCum.value > 0) {
      stepChartData.actualSitesActivatedCum.push({
        x: new Date(date.month.value),
        y: date.actualSitesActivatedCum.value,
      });
    }
  });
  stepChartData.todayDate =
    stepChartData.actualSitesActivatedCum.length > 0
      ? last(stepChartData.actualSitesActivatedCum).x
      : null;
  stepChartData.currPlanFirstDate = head(
    stepChartData.currPlanSitesActivatedCum,
  ).x;
  stepChartData.currPlanLastDate = last(
    stepChartData.currPlanSitesActivatedCum,
  ).x;

  const tableData = map(data.data.dataCountryDrilldownSite, (site) => {
    const newSite = {};
    forOwn(site, (value, key) => {
      if (key === 'siteActivationDate') {
        newSite[key] = new DateStringTransform(value.label).formatter(
          UI_DISPLAY_DATE,
        );
      } else if (key === 'siteId') {
        newSite[key] = value;
      } else {
        newSite[key] = value.label;
      }
    });
    return newSite;
  });
  const activationDetailData = {
    stepChart: configureStepChart(stepChartData),
    tableData,
  };
  return activationDetailData;
};

export default transformationFunctionWrapper(transform);
