import React from 'react';
import Loader from 'components/Loader';
import Error from 'components/Error';
import { useThunkSelector } from 'utility/redux';

const SiteHealth = React.lazy(() =>
  import('../../dashboards/SiteHealth/SiteHealth'),
);

function SiteHealthRoute() {
  const pageAccess = useThunkSelector('moduleAccess');

  return (
    <React.Suspense fallback={<Loader className="my-5 py-5" />}>
      {pageAccess.isLoading && <Loader className="my-5 py-5" />}
      {pageAccess.isError && <Error error={pageAccess.error} />}
      {pageAccess.isSuccess && (
        <>
          {pageAccess.payload.siteHealth && <SiteHealth />}
          {!pageAccess.payload.siteHealth && <p>No Access</p>}
        </>
      )}
    </React.Suspense>
  );
}

export default SiteHealthRoute;
