import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import crfSignedTransform from './utility/crfsSignedChart.transform';
import crfSortTransform from '../../__utility__/crfSort.transform';

export const crfsSignedChartHttpRequest = (() => {
  const apiName = 'crfsSignedChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-crfs-signed-chart/trial/:trialId`;
  generatedHttpThunk.transform = crfSignedTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export const crfsSignedTableHttpRequest = (() => {
  const apiName = 'crfsSignedTable';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-crfs-signed-table/trial/:trialId`;
  generatedHttpThunk.transform = crfSortTransform;
  return generatedHttpThunk.generateTrialThunk();
})();
