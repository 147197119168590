import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { APPLICATION_VERSION } from 'constants/appVersion.constant';
import { generateHttpReducer } from '../../../utility';
import portfolioManagementReducer from './reducers/reducer';

const portfolioManagementStore = configureStore({
  reducer: combineReducers({
    uiState: portfolioManagementReducer,
    http: combineReducers({
      trialDeliveryGoalsPage: combineReducers({
        landingTrialDeliveryGoals: generateHttpReducer(
          'landingTrialDeliveryGoals',
        ),
        gptGoalsSummary: generateHttpReducer('gptGoalsSummary'),
        clinicalMilestonesSummary: generateHttpReducer(
          'clinicalMilestonesSummary',
        ),

        gptGoalsDetails: generateHttpReducer('gptGoalsDetails'),
        clinicalMilestonesDetails: generateHttpReducer(
          'clinicalMilestonesDetails',
        ),

        gptGoalVarianceDetails: generateHttpReducer('gptGoalVarianceDetails'),
        milestoneVarianceDetails: generateHttpReducer(
          'milestoneVarianceDetails',
        ),
      }),
      activeTrialPerformancePage: combineReducers({
        tpcActivation: generateHttpReducer('tpcActivation'),
        tpcEnrollment: generateHttpReducer('tpcEnrollment'),
        activeTrialPerformance: generateHttpReducer('activeTrialPerformance'),
        activeTrialPerformanceSummary: generateHttpReducer(
          'activeTrialPerformanceSummary',
        ),
        activeTrialPerformanceSummaryByStatus: generateHttpReducer(
          'activeTrialPerformanceSummaryByStatus',
        ),
        trialsSummary: generateHttpReducer('trialsSummary'),
        trialsInfo: generateHttpReducer('trialsInfo'),
        activationsEnrollmentsToPlan: generateHttpReducer(
          'activationsEnrollmentsToPlan',
        ),
        studyRagOverTime: generateHttpReducer('studyRagOverTime'),
        studyRagByCategory: generateHttpReducer('studyRagByCategory'),
        studyRagByCategoryCalendar: generateHttpReducer(
          'studyRagByCategoryCalendar',
        ),

        studyRagSummaryCards: generateHttpReducer('studyRagSummaryCards'),
      }),

      cycleTimes: combineReducers({
        summary: generateHttpReducer('cycleTimesSummary'),
        statusByStage: combineReducers({
          summary: generateHttpReducer('cycleTimesStatusByStageSummary'),
          detail: generateHttpReducer('cycleTimesStatusByStageDetail'),
        }),
        performanceOverTime: combineReducers({
          boxView: generateHttpReducer('cycleTimesPerformanceOverTimeBoxView'),
          ragView: generateHttpReducer('cycleTimesPerformanceOverTimeRagView'),
          calendarBoxView: generateHttpReducer(
            'cycleTimesPerformanceOverTimeCalendarBoxView',
          ),
          calendarRagView: generateHttpReducer(
            'cycleTimesPerformanceOverTimeCalendarRagView',
          ),
        }),
        performanceByCategory: generateHttpReducer(
          'cycleTimesPerformanceByCategory',
        ),
        performanceByTrial: generateHttpReducer('cycleTimesPerformanceByTrial'),
        cycleTimesPerformanceByTrialCalendar: generateHttpReducer(
          'cycleTimesPerformanceByTrialCalendar',
        ),
        cycleTimesFilters: generateHttpReducer('cycleTimesFilters'),
      }),

      diversity: combineReducers({
        landingDiversity: generateHttpReducer('landingDiversity'),
        diversityDistributionByGroup: generateHttpReducer(
          'diversityDistributionByGroup',
        ),
        diversityEnrollmentFunnel: generateHttpReducer(
          'diversityEnrollmentFunnel',
        ),
        diversityTrendOverTimeCalendar: generateHttpReducer(
          'diversityTrendOverTimeCalendar',
        ),
        diversityTrendOverTime: generateHttpReducer('diversityTrendOverTime'),
        distributionByGroupDrilldown: generateHttpReducer(
          'distributionByGroupDrilldown',
        ),
      }),

      portfolioFilters: generateHttpReducer('portfolioFilters'),

      userTrialsFilter: generateHttpReducer('userTrialsFilterCheck'),

      protocolAmendmentsPage: combineReducers({
        protocolAmendmentsDrilldown: generateHttpReducer(
          'protocolAmendmentsDrilldown',
        ),

        protocolAmendmentsSummary: generateHttpReducer(
          'protocolAmendmentsSummary',
        ),
      }),
      savedFilters: combineReducers({
        listSavedFilters: generateHttpReducer('listSavedFilters'),
        createSavedFilters: generateHttpReducer('createSavedFilters'),
        deleteSavedFilters: generateHttpReducer('deleteSavedFilters'),
      }),

      bookmark: combineReducers({
        bookmarkTrials: generateHttpReducer('bookmarkTrials'),
        unBookmarkTrials: generateHttpReducer('unBookmarkTrials'),
      }),

      numTrials: generateHttpReducer('numTrials'),
    }),
  }),
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== APPLICATION_VERSION.ENV.PROD,
});

export default portfolioManagementStore;
