import axios from 'axios';
import { generateHttpActions } from '../../../utility';
import listSavedFiltersHttpRequest from './01_listSavedFilters.thunk';

function deleteSavedFiltersHttpRequest(filterPayload) {
  // Fix: thunk name
  const functionName = 'deleteSavedFilters';

  const httpActions = generateHttpActions(functionName);
  const resource = `filter/${filterPayload.filterName}/module/${filterPayload.module}`;

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const response = await axios.delete(resource, {});

      dispatch(httpActions.success(response));
      dispatch(listSavedFiltersHttpRequest());
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default deleteSavedFiltersHttpRequest;
