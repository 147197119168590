import { getSafePercentageValue } from 'utility/math';

const tableDataTransform = (data) => {
  const transformedData = data.map((trial) => {
    const {
      contractedSitesCount,
      quadrant,
      actualSiteActivated,
      actualPatientsEnrolled,
      plannedSiteActivatedToDate,
      plannedPatientsEnrolledToDate,
    } = trial;

    const {
      value: quadrantValue,
      label: quadrantText,
      icon: quadrantIcon,
    } = quadrant;

    return {
      ...trial,
      quadrantText,
      quadrantIcon,
      quadrantValue,
      totalPlannedSites: contractedSitesCount,
      activeSitesPercentage: getSafePercentageValue(
        actualSiteActivated,
        plannedSiteActivatedToDate,
      ),
      enrolledPercentage: getSafePercentageValue(
        actualPatientsEnrolled,
        plannedPatientsEnrolledToDate,
      ),
    };
  });
  return transformedData;
};

export default tableDataTransform;
