import { newBackground, gray3 } from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';

const axisLabelsStyle = {
  ...styleComponent('sp'),
  color: gray3,
};
const SITE_TRIAL_ENROLLMENT_CONFIG = {
  tooltip: {
    shared: true,
    xDateFormat: '%B %Y',
  },
  chart: {
    height: 300,
  },
  title: {
    text: '',
  },
  xAxis: {
    visible: true,
    crosshair: true,
    labels: {
      format: '{value: %b %Y}',
      style: axisLabelsStyle,
    },
    dateTimeLabelFormats: {
      month: '%b %Y',
      day: '%b %Y',
    },
    tickInterval: 24 * 3600 * 1000 * 30,
    type: 'datetime',
  },
  yAxis: {
    title: {
      text: 'Subjects / Events',
      style: {
        ...styleComponent('p'),
        color: gray3,
      },
    },
    labels: { style: axisLabelsStyle },
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    align: 'left',
    verticalAlign: 'top',
    layout: 'horizontal',
    padding: 10,
    spacingLeft: 0,
    backgroundColor: newBackground,
    labelFormatter() {
      const that = this;
      return `<span style="color:${that.color}">${that.name} </span>`;
    },
  },
  plotOptions: {
    series: {
      lineWidth: 3,
      marker: {
        enabled: false,
        symbol: 'circle',
      },
      label: {
        enabled: false,
      },
    },
  },
  time: {
    useUTC: false,
  },
};
export default SITE_TRIAL_ENROLLMENT_CONFIG;
