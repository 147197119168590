import { merge, keyBy, values, orderBy, get } from 'lodash';
import { SHORT_UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';
import styleComponent from '../../../../../../assets/stylesheets/base/_typography';
import { gray2 } from '../../../../../../assets/stylesheets/base/_colors';
import EnrolmentCurveGenerator from './utility/enrolmentCurve.generator';
import ActivationBarGenerator from './utility/activationBar.generator';
import ForecastCurveGenerator from './utility/forecastCurve.generator';
import ScreeningCurveGenerator from './utility/screeningCurve.generator';
import PlotLineGenerator from './utility/plotline.generator';
import EnrolmentCurveAndTable from './enrolmentCurveAndTableClass';
import DropoutCurve from './utility/dropoutCurve.generator';

class EnrolmentCurveChartTransform extends EnrolmentCurveAndTable {
  _combineData() {
    const REQUIRED_KEYS = {
      currPlanEnrolledCum: null,
      actualEnrolledCum: null,
      origPlanEnrolledCum: null,
      origPlanSitesActivatedCum: null,
      currPlanSitesActivatedCum: null,
      actualSitesActivatedCum: null,
      forecastEnrollmentLowerQuartile: null,
      forecastEnrollmentMidQuantile: null,
      forecastEnrollmentUpperQuartile: null,
      actualSubjectsScreenedCum: null,
      actualDropoutCum: null,
    };

    const dateCombinedData = merge(
      keyBy(this._enrolmentData, 'trialMonth'),
      keyBy(this._siteActivationData, 'trialMonth'),
      keyBy(this._forecastOverTime, 'trialMonth'),
      keyBy(this._screeningData, 'trialMonth'),
      keyBy(this._dropoutData, 'trialMonth'),
    );

    const dateCombinedDataValues = values(dateCombinedData);
    const sortedDateCombinedData = orderBy(
      dateCombinedDataValues,
      'trialMonth',
      ['asc'],
    );
    const combinedData = sortedDateCombinedData.map((keys) => ({
      ...REQUIRED_KEYS,
      ...keys,
    }));
    return combinedData;
  }

  _generateCategories(data) {
    const categories = data.map((element) => {
      const formattedCategory = new DateStringTransform(
        element.trialMonth,
      ).formatter(SHORT_UI_DISPLAY_DATE);
      return formattedCategory;
    });
    return categories;
  }

  _generateXAxis(plotLines) {
    const xAxisConfiguration = {
      type: 'datetime',
      tickInterval: 24 * 3600 * 1000 * 30,
      crosshair: true,
      visible: true,
      dateTimeLabelFormats: {
        month: '%b %Y',
        day: '%b %Y',
        year: '%b %Y',
      },
      labels: {
        allowOverlap: false,
        padding: 7,
        style: {
          ...styleComponent('sp'),
          color: gray2,
        },
      },
      plotLines,
    };

    return xAxisConfiguration;
  }

  _generateChart() {
    const combinedData = this._combineData();
    // Generate Enrolment Data
    const enrolmentCurveGenerator = new EnrolmentCurveGenerator();
    const enrolmentSeries = enrolmentCurveGenerator.getSeries(combinedData);

    // Generate Forecasted Data
    const forecastedSeries = new ForecastCurveGenerator().getSeries(
      combinedData,
    );

    const categories = this._generateCategories(combinedData);

    const activationSeries = new ActivationBarGenerator().getSeries(
      combinedData,
    );

    const screeningCurveGenerator = new ScreeningCurveGenerator();
    const screeningSeries = screeningCurveGenerator.getSeries(combinedData);

    const dropoutCurveGenerator = new DropoutCurve();
    const dropoutSeries = dropoutCurveGenerator.getSeries(combinedData);

    const series = [
      ...activationSeries,
      ...screeningSeries,
      ...enrolmentSeries,
      ...forecastedSeries,
      ...dropoutSeries,
    ];

    const plannedLSIDateString = get(this._plannedLSIDate, 'value', null);
    const forecastLSIDateString = get(this._forecastLSIDate, 'value', null);
    const plotLinesGenerator = new PlotLineGenerator();
    const xAxis = this._generateXAxis([
      plotLinesGenerator._getPlannedLSIPlotLine(plannedLSIDateString),
      plotLinesGenerator._getForecastLSIPlotLine(forecastLSIDateString),
    ]);
    const enrolmentCurveYAxis = [
      { title: { text: 'Subjects' }, plotLines: [] },
      { title: { text: 'Sites' }, opposite: true },
    ];
    const plotOptions = {
      series: { marker: { enabled: false } },
    };
    const exporting = {
      buttons: {
        contextButton: {
          x: -27,
          y: 5,
        },
      },
    };

    const chartData = {
      title: { text: null },
      categories,
      series,
      xAxis,
      yAxis: enrolmentCurveYAxis,
      exporting,
      plotOptions,
    };
    return chartData;
  }
}

export default EnrolmentCurveChartTransform;
