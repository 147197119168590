export const mockForecastPayload = {
  data: {
    data: {
      metrics: [],
      liklihoodByMonth: [],
      forecastedEnrollmentOverTime: [],
    },
    dataSource: [],
  },
};
export const mockSiteActivationResponse = {
  data: {
    data: [],
    dataSource: [],
  },
};
export const mockEnrolmentResponse = {
  data: {
    data: [],
    dataSource: [],
  },
};

export const mockScreeningResponse = {
  data: {
    data: [],
    dataSource: [],
  },
};
export const mockDropoutResponse = {
  data: {
    data: [],
    dataSource: [],
  },
};
