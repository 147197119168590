import { aqua } from 'assets/stylesheets/base/_colors';
import SiteDrillDownGenerator from './siteDrillDownGenerator.abstract';

class EnrollmentGenerator extends SiteDrillDownGenerator {
  generateSeries(dataArray, dataKey) {
    this.isDormantBasedBarColor = false;
    const dataSeries = super.generateSeries(dataArray, dataKey);
    const enrollmentChartData = [
      {
        name: 'All sites',
        colorByPoint: false,
        color: aqua,
        data: dataSeries,
      },
    ];
    return enrollmentChartData;
  }
}

export default EnrollmentGenerator;
