import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const siteInfoTrialListHttpRequest = (() => {
  const apiName = 'siteInfoTrialList';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/site/:siteId/trials`;
  return generatedHttpThunk.generateSiteThunk();
})();

export default siteInfoTrialListHttpRequest;
