import axios from 'axios';
import { generateHttpActions } from '../../../../utility';
import transform from './utility/14_enrollmentForecastSummary';

function enrollmentForecastSummaryDataHttpRequest(trialId) {
  const httpActions = generateHttpActions('enrollmentForecastSummary');
  const resource = `trial-management/enrollment-forecast/trial/${trialId}`;

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const response = await axios.get(resource);

      const transformedData = transform(response.data.data);
      dispatch(httpActions.success({ data: transformedData }));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default enrollmentForecastSummaryDataHttpRequest;
