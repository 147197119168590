import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const enrollmentSummaryHttpRequest = (() => {
  const apiName = 'enrollmentSummary';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/enrollment-summary/trial/:trialId`;
  return generatedHttpThunk.generateTrialThunk();
})();

export default enrollmentSummaryHttpRequest;
