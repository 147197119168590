import { purple20 } from 'assets/stylesheets/base/_colors';
import Generator from './generator';

export default class DropoutCurve extends Generator {
  static _lineChartConfig = {
    yAxis: 0,
    type: 'line',
    dashStyle: null,
    showInLegend: true,
    enableMouseTracking: true,
    customTags: ['dropout'],
  };

  getDropoutSeriesData(objectArray) {
    const dropoutSeriesData = this._getSeriesData(
      objectArray,
      'actualDropoutCum',
    );

    return dropoutSeriesData;
  }

  getSeries(objectArray) {
    const lineSeriesData = this.getDropoutSeriesData(objectArray);

    const screeningSeries = {
      id: 'dropout',
      name: 'Dropouts (Actual)',
      data: lineSeriesData,
      color: purple20,
      marker: { enabled: lineSeriesData.length === 1 },
      ...DropoutCurve._lineChartConfig,
    };

    return [screeningSeries];
  }
}
