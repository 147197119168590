import { isNil, keyBy, map, merge, orderBy, values } from 'lodash';
import { SHORT_UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';
import SITE_TRIAL_ENROLLMENT_CONFIG from '../../../configuration/SiteTrialEnrollment.config';
import { transformationFunctionWrapper } from '../../../../../utility/errors';
import { aqua, purple } from '../../../../../assets/stylesheets/base/_colors';

const isSinglePoint = (series, key) => {
  const seriesValues = series
    .map((dataPoint) => dataPoint[key])
    .filter((point) => !isNil(point));
  return seriesValues.length === 1;
};

const getMarker = (seriesData, key) => ({
  enabled: isSinglePoint(seriesData, key),
  symbol: 'circle',
});

const transformSeriesData = (enrollmentData, screeningData) => {
  const REQUIRED_KEYS = {
    actualEnrolledCumulative: null,
    actualScreenedCumulative: null,
  };
  const enrollmentDataList = enrollmentData.data;
  const screeningDataList = screeningData.data;

  const combinedData = merge(
    keyBy(enrollmentDataList, 'trialMonth'),
    keyBy(screeningDataList, 'trialMonth'),
  );
  const combinedValues = values(combinedData);
  const flattenedCombinedValues = combinedValues.map((value) => ({
    ...REQUIRED_KEYS,
    ...value,
  }));
  const sortedCombinedValues = orderBy(
    flattenedCombinedValues,
    ['trialMonth'],
    ['asc'],
  );

  const categories = map(sortedCombinedValues, 'trialMonth').map((date) =>
    new DateStringTransform(date).formatter(SHORT_UI_DISPLAY_DATE),
  );
  const series = [
    {
      type: 'line',
      color: aqua,
      name: 'Enrollment (Actual)',
      id: 'actualEnrollment',
      data: map(sortedCombinedValues, (item) => ({
        x: +new DateStringTransform().convertFormattedDate(item.trialMonth),
        y: item.actualEnrolledCumulative,
      })),
      marker: getMarker(sortedCombinedValues, 'actualEnrolledCumulative'),
    },
    {
      type: 'line',
      color: purple,
      name: 'Screening events (Actual)',
      id: 'actualScreening',
      data: map(sortedCombinedValues, (item) => ({
        x: +new DateStringTransform().convertFormattedDate(item.trialMonth),
        y: item.actualScreenedCumulative,
      })),
      marker: getMarker(sortedCombinedValues, 'actualScreenedCumulative'),
    },
  ];
  const transformedData = {
    chartData: {
      ...SITE_TRIAL_ENROLLMENT_CONFIG,
      series,
      xAxis: {
        ...SITE_TRIAL_ENROLLMENT_CONFIG.xAxis,
      },
      categories,
    },
  };
  return transformedData;
};
export default transformationFunctionWrapper(transformSeriesData);
