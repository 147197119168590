import { get } from 'lodash';

const { GENERIC_ERROR_MSG } = require('constants');

const formatHttpPostErrorMessage = (httpResponse) => {
  let errorMessage = GENERIC_ERROR_MSG;
  if (httpResponse.error) {
    if (get(httpResponse, 'error.details.response.data.description')) {
      errorMessage = get(
        httpResponse,
        'error.details.response.data.description',
      );
    }
  }
  return errorMessage;
};

export default formatHttpPostErrorMessage;
