import { get, includes, isNil } from 'lodash';
import DateStringTransform from '../date/dateString.utility';

class DataListFilter {
  #dataList = [];

  #searchFields = [];

  #defaultFilter = 'ALL';

  #defaultDateFilterObject = {
    maxDate: null,
    minDate: null,
    inclusionType: '[]',
  };

  constructor(dataList) {
    this.dataList = dataList;
  }

  get dataList() {
    return this.#dataList;
  }

  set dataList(dataItem) {
    if (Array.isArray(dataItem)) {
      this.#dataList = dataItem;
      this.#searchFields = Object.keys(get(dataItem, '[0]', []));
    }
  }

  get searchFields() {
    return this.#searchFields;
  }

  set searchFields(fieldList) {
    this.#searchFields = fieldList;
  }

  get defaultFilter() {
    return this.#defaultFilter;
  }

  set defaultFilter(defaultFilter) {
    this.#defaultFilter = defaultFilter;
  }

  _validateFilteringValues(filteringValues) {
    if (isNil(filteringValues)) return false;
    if (!Array.isArray(filteringValues)) return false;
    if (filteringValues.length === 0) return false;
    if (includes(filteringValues, this.#defaultFilter)) return false;
    return !includes(filteringValues, null);
  }

  search(searchQuery) {
    const searchTerm = get(searchQuery, 'searchTerm', null);
    const filterQuery = get(searchQuery, 'filterObject', {});
    const dateFilterQuery = get(searchQuery, 'dateFilterObject', {});

    const filteredRecords = this.#dataList.filter((recordItem) => {
      let isMatch = false;

      if (!isNil(searchTerm)) {
        this.#searchFields.forEach((comparisonKey) => {
          const comparisonText = get(recordItem, comparisonKey, '');
          const fullText =
            typeof comparisonText === 'string' ? comparisonText : '';

          const fullTextCaps = fullText.toString().toUpperCase();
          const searchTermCaps = searchTerm
            .toString()
            .replace(/\\/g, '\\\\')
            .toUpperCase();
          const isSubString = fullTextCaps.search(searchTermCaps) >= 0;
          isMatch = isMatch || isSubString;
        });
      } else {
        isMatch = true;
      }

      Object.keys(filterQuery).forEach((filterQueryKey) => {
        const filteringValues = filterQuery[filterQueryKey];
        if (!this._validateFilteringValues(filteringValues)) return;
        const filterQueryValue = get(recordItem, filterQueryKey, '');
        const isInList = includes(filteringValues, filterQueryValue);
        isMatch = isMatch && isInList;
      });

      Object.keys(dateFilterQuery).forEach((dateFilterQueryKey) => {
        const dateRange = get(
          dateFilterQuery,
          dateFilterQueryKey,
          this.#defaultDateFilterObject,
        );
        const recordDate = get(recordItem, dateFilterQueryKey, null);
        const minDateObject = new DateStringTransform(dateRange.minDate);
        const maxDateObject = new DateStringTransform(dateRange.maxDate);
        const recordDateObject = new DateStringTransform(recordDate);
        const inclusionType = get(
          dateRange,
          'inclusionType',
          this.#defaultDateFilterObject.inclusionType,
        );
        if (
          !minDateObject.isValid() ||
          !maxDateObject.isValid() ||
          !recordDateObject.isValid()
        )
          return;
        const isInRange = recordDateObject.dateObject.isBetween(
          minDateObject.dateObject,
          maxDateObject.dateObject,
          null,
          inclusionType,
        );
        isMatch = isMatch && isInRange;
      });
      return isMatch;
    });
    return filteredRecords;
  }
}

export default DataListFilter;
