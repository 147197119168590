import { gray2, white } from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';
import drawDiamonIcon from 'utility/iconGenerators/drawDiamondIcon';

export const getRagDiamonds = (ragText, xValue, yValue) =>
  ragText
    ? {
        name: '',
        tooltip: {
          pointFormatter() {
            return false;
          },
        },
        dataLabels: {
          enabled: false,
        },
        data: [[xValue, yValue]],
        color: gray2,
        marker: {
          symbol: `url(${drawDiamonIcon(
            white,
            gray2,
            false,
            ragText.toUpperCase(),
          )})`,
        },
      }
    : {};

export const getRagLabels = (label, xValue, yValue) =>
  label
    ? {
        name: '',
        tooltip: {
          pointFormatter() {
            return false;
          },
        },
        dataLabels: {
          format: `<span style="color: ${gray2}; z-index:1">${label}</span>`,
          style: {
            ...styleComponent('cap'),
          },
        },
        data: [[xValue, yValue]],
        color: gray2,
        marker: {
          enabled: false,
        },
      }
    : {};

export const getLine = (x1Value, y1Value, x2Value, y2Value, color) => ({
  name: '',
  type: 'line',
  color,
  marker: {
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  data: [
    [x1Value, y1Value],
    [x2Value, y2Value],
  ],
});
