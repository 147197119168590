import ThunkGenerator from './thunkGenerator.abstract';

class TrialThunkGenerator extends ThunkGenerator {
  generateThunk() {
    this._logger('Generation Requested');

    const httpRequest = (
      trialId,
      requestParameters = {},
      additionalParams = {},
    ) => {
      const resource = this._resource.replace(':trialId', trialId);
      const secondaryResource = this._secondaryResource.replace(
        ':trialId',
        trialId,
      );

      const dispatchFn = this.generateDispatcher(
        resource,
        requestParameters,
        additionalParams,
        secondaryResource,
      );

      return dispatchFn;
    };

    return httpRequest;
  }
}

export default TrialThunkGenerator;
