import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import crfSignedTransform from './utility/crfSigned.transform';

const crfSignedChartHttpRequest = (() => {
  const apiName = 'crfsSignedChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/edc-crfs-signed-chart/site/:siteId/trial/:trialId`;
  generatedHttpThunk.transform = crfSignedTransform;
  return generatedHttpThunk.generateSiteTrialThunk();
})();

export default crfSignedChartHttpRequest;
