import Error from 'components/Error';
import ErrorFallbackComponent from 'components/Error/ErrorFallbackComponent';
import Loader from 'components/Loader';
import SidebarPopup from 'components/sidebarPopup/SidebarPopup';
import { MENU_ID } from 'section/01_NavigationBarSection/constants/routeDetails';
import useMenuSubItems from 'section/01_NavigationBarSection/hooks/useMenuSubItems';
import NavigationBarItem from 'components/NavigationBarItem/NavigationBarItem';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useThunkSelector } from 'utility/redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { generateSafeCell } from 'components/SafeComponents';

function HelpSubSection() {
  const dataSources = useThunkSelector('dataSources');
  const helpMenuNavigationItems = useMenuSubItems(MENU_ID.HELP);

  const [sidebarVisible, setSidebarVisible] = useState({
    isVisible: false,
  });

  const helpMenuAdditionalItems = [
    {
      label: 'Data sources',
      command: () => {
        setSidebarVisible({ isVisible: true });
      },
    },
  ];

  const helpMenuItems = helpMenuNavigationItems.concat(helpMenuAdditionalItems);

  return (
    <>
      <NavigationBarItem dropdownItems={helpMenuItems} label="Help" />
      <SidebarPopup
        headerText="COMPASS data sources"
        isVisible={sidebarVisible.isVisible}
        setIsVisible={setSidebarVisible}
      >
        <div className="row g-0">
          <div className="col-12">
            {dataSources.isError && <Error error={dataSources.error} />}
            {dataSources.isLoading && <Loader className="my-5 py-5" />}
            {dataSources.isSuccess && (
              <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                <DataTable
                  value={dataSources.payload}
                  responsiveLayout="scroll"
                  rowClassName="cursor-default disable-hover"
                >
                  <Column
                    field="dataSource"
                    header="Data source"
                    body={generateSafeCell('dataSource')}
                  />
                  <Column
                    field="compassMetrics"
                    header="COMPASS metrics"
                    body={generateSafeCell('compassMetrics')}
                  />
                  <Column
                    field="datahubRefreshInterval"
                    header="Source to Datahub refresh"
                    body={generateSafeCell('datahubRefreshInterval')}
                  />
                  <Column
                    field="compassRefreshInterval"
                    header="Datahub to COMPASS refresh"
                    body={generateSafeCell('compassRefreshInterval')}
                  />
                </DataTable>
              </ErrorBoundary>
            )}
          </div>
        </div>
      </SidebarPopup>
    </>
  );
}

export default HelpSubSection;
