import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import longitudinalRagTransform from './utility/20_LongitudinalRag/longitudinalRag.transform';

const longitudinalRagHttpRequest = (() => {
  const apiName = 'longitudinalRag';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/rag-details/trial/:trialId`;
  generatedHttpThunk.transform = longitudinalRagTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export default longitudinalRagHttpRequest;
