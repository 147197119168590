import showFeature from 'utility/showFeature';

const VERSION_ID = '4.1.1';
const LAST_UPDATED = '2023-09-29';

const ENV = {
  DEV: 'DEV',
  TEST: 'TEST',
  DEMO: 'DEMO',
  PROD: 'PROD',
};

const IS_PRODUCTION = showFeature([ENV.PROD]);
const SHOW_IN_DEV_FEATURES =
  !IS_PRODUCTION &&
  localStorage &&
  localStorage.getItem('SHOW_IN_DEV_FEATURES');

export const APPLICATION_VERSION = {
  VERSION_ID,
  LAST_UPDATED,
  IS_PRODUCTION,
  SHOW_IN_DEV_FEATURES,
  ENV,
};

const displayApplicationVersion = () => {
  console.group('APPLICATION_VERSION');
  console.info('VERSION_ID:', APPLICATION_VERSION.VERSION_ID);
  console.info('LAST_UPDATED:', APPLICATION_VERSION.LAST_UPDATED);
  console.info('IS_PRODUCTION:', APPLICATION_VERSION.IS_PRODUCTION);
  console.info('ANALYTICS:', APPLICATION_VERSION.IS_GOOGLE_ANALYTICS_ENABLED);
  console.groupEnd();
};

export default displayApplicationVersion;
