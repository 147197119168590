const { get } = require('lodash');

const getGroupedByData = (data, key, getDataFunc, dataKey = null) => {
  const groupedByCroData = get(data, `${key}.drilldown.groupedByCro`);
  const groupedByPhaseData = get(data, `${key}.drilldown.groupedByPhase`);
  const groupedByTauData = get(data, `${key}.drilldown.groupedByTau`);
  const groupedByTierData = get(data, `${key}.drilldown.groupedByTier`);

  const croData = getDataFunc(groupedByCroData, dataKey);
  const phaseData = getDataFunc(groupedByPhaseData, dataKey);
  const tauData = getDataFunc(groupedByTauData, dataKey);
  const tierData = getDataFunc(groupedByTierData, dataKey);
  return {
    croData,
    phaseData,
    tauData,
    tierData,
  };
};

export default getGroupedByData;
