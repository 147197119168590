import { isNil } from 'lodash';
import { roundOff } from 'utility/math';

function addCommas(value, precisionDigits = 0) {
  if (Number.isNaN(value) || isNil(value)) {
    return null;
  }
  const valueToBeRounded = roundOff(value, precisionDigits, null);
  const formattedNumber = new Intl.NumberFormat('en-US').format(
    valueToBeRounded,
  );
  return formattedNumber;
}

export default addCommas;
