import { map } from 'lodash';
import { roundOff } from 'utility/math/valueRounder';
import protocolDeviationsOptions from '../../../../configuration/protocolDeviationsGraph.config';

const CHART_VALUE_PRECISION = 1;

const removeNullValues = (data, key) =>
  data.filter(
    (dataPoint) => dataPoint.deviationCategory && dataPoint[key] !== null,
  );

export const findMaxValue = (data, attributes = []) => {
  let maxValue = null;
  data.forEach((dataPoint) =>
    attributes.forEach((attribute) => {
      if (dataPoint[attribute] > maxValue || maxValue === null) {
        maxValue = dataPoint[attribute];
      }
    }),
  );
  return maxValue;
};

const generateBulletChartSeriesData = (data) =>
  data.map((currentMetrics) => {
    const seriesData = {
      y: roundOff(
        currentMetrics.deviationsNumberPerPatientCurrentSite,
        CHART_VALUE_PRECISION,
      ),
      target: roundOff(
        currentMetrics.deviationsNumberPerPatientTrialMedian,
        CHART_VALUE_PRECISION,
      ),
    };
    return seriesData;
  });

export const transformDeviationsChartData = (data) => {
  const filteredData = removeNullValues(data, 'deviationsNumberCurrentSite');
  const maxVerticalValue = findMaxValue(filteredData, [
    'deviationsNumberCurrentSite',
  ]);
  const maxChartYAxis = maxVerticalValue ? { max: maxVerticalValue * 1.5 } : {};
  const chartData = filteredData.map((dataPoint) =>
    roundOff(dataPoint.deviationsNumberCurrentSite, CHART_VALUE_PRECISION),
  );
  const chartprotocolDeviationsOptions = {
    ...protocolDeviationsOptions,
    chart: {
      ...protocolDeviationsOptions.chart,
      type: 'column',
      events: {},
      scrollablePlotArea: {
        minWidth: 200 * chartData.length,
      },
    },
    xAxis: {
      ...protocolDeviationsOptions.xAxis,
      categories: map(filteredData, 'deviationCategory'),
    },
    yAxis: {
      ...protocolDeviationsOptions.yAxis,
      title: {
        ...protocolDeviationsOptions.yAxis.title,
        text: 'Deviations',
      },
      ...maxChartYAxis,
    },
    series: [
      {
        name: 'Number of deviations',
        data: chartData,
      },
    ],
  };
  return chartprotocolDeviationsOptions;
};

export const transformDeviationsPerPatientChartData = (data) => {
  const filteredData = removeNullValues(
    data,
    'deviationsNumberPerPatientCurrentSite',
  );
  const maxVerticalValue = findMaxValue(filteredData, [
    'deviationsNumberPerPatientCurrentSite',
    'deviationsNumberPerPatientTrialMedian',
  ]);
  const maxChartYAxis = maxVerticalValue ? { max: maxVerticalValue * 1.5 } : {};
  const chartData = generateBulletChartSeriesData(filteredData);
  const chartprotocolDeviationsOptions = {
    ...protocolDeviationsOptions,
    chart: {
      ...protocolDeviationsOptions.chart,
      type: 'bullet',
      scrollablePlotArea: {
        minWidth: 200 * chartData.length,
      },
    },
    xAxis: {
      ...protocolDeviationsOptions.xAxis,
      categories: map(filteredData, 'deviationCategory'),
    },
    yAxis: {
      ...protocolDeviationsOptions.yAxis,
      title: {
        ...protocolDeviationsOptions.yAxis.title,
        text: 'Deviations per subject',
      },
      ...maxChartYAxis,
    },
    series: [
      {
        name: 'Deviations per subject',
        data: chartData,
      },
    ],
  };
  return chartprotocolDeviationsOptions;
};
