import { get } from 'lodash';

const getSelectedFilterLabels = (globalActiveFilters, filtersLabelMap) => {
  const selectedFiltersLabels = Object.keys(globalActiveFilters).map((key) => {
    const values = globalActiveFilters[key];
    const labels = values.map((value) =>
      get(filtersLabelMap, `${key}.${value}`),
    );
    return labels.join(', ');
  });
  return selectedFiltersLabels;
};
export default getSelectedFilterLabels;
