const DIAMOND_BASE_CONFIG = {
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    visible: false,
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    visible: false,
    labels: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  title: {
    text: null,
  },
  plotOptions: {
    scatter: {
      enableMouseTracking: false,
      showInLegend: 'false',
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    series: {
      enableMouseTracking: false,
      animation: false,
      showInLegend: 'false',
      dataLabels: {
        enabled: true,
        y: 25,
      },
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
};

export default DIAMOND_BASE_CONFIG;
