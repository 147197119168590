import { useEffect } from 'react';

/**
 * Trigger the `handler` function when the scrolling happens inside the `target` element
 * @param {Function} handler - The handler should be a memoized function (please use `useCallback`)
 * @param {Window | HTMLElement} [target=window]
 */
const useOnScroll = (handler, target = window) => {
  useEffect(() => {
    const handlerRef = handler;
    if (!target) {
      return null;
    }
    target.addEventListener('scroll', handlerRef);

    return () => {
      target.removeEventListener('scroll', handlerRef);
    };
  }, [handler, target]);
};

export default useOnScroll;
