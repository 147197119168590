import ThunkGenerator from './thunkGenerator.abstract';

class SiteThunkGenerator extends ThunkGenerator {
  _isGlobalFiltering = true;

  generateThunk() {
    this._logger('Generation Requested');

    const httpRequest = (
      siteId,
      requestParameters = {},
      additionalParams = {},
    ) => {
      const resource = this._resource.replace(':siteId', siteId);

      const dispatchFn = this.generateDispatcher(
        resource,
        requestParameters,
        additionalParams,
      );

      return dispatchFn;
    };

    return httpRequest;
  }
}

export default SiteThunkGenerator;
