import { STUDY_RAG_BY_CATEGORY_GROUP_OPTIONS } from 'dashboards/PortfolioManagement/constants/activeTrialPerformance.constants';
import { getDiversityInitialState } from 'dashboards/PortfolioManagement/constants/diversity.constants';
import { TDG_MILESTONE_INITIAL_STATE } from 'dashboards/PortfolioManagement/sections/08_TrialDeliveryGoalsMilestonesSection/constants';
import { TDG_GPT_INITIAL_STATE } from 'dashboards/PortfolioManagement/sections/09_TrialDeliveryGoalsGPTSection/constants';
import { GLOBAL_FILTER_VALUE } from 'store/reducers/multiDashboardState';
import {
  AMENDMENT_GROUP,
  TIME_FRAME,
} from '../../constants/protocolAmendments.constants';
import {
  ACTIVE_TRIAL_PERFORMANCE_SECTION_ID,
  ACTIVE_TRIAL_PERFORMANCE_SECTION_IDS,
  CYCLE_TIMES_SECTION_ID,
  GLOBAL_FILTER_SECTION_ID,
  PORTFOLIO_DIVERSITY_PAGE_SECTION_ID,
  PORTFOLIO_DIVERSITY_TAB,
  PORTFOLIO_TRIAL_SUMMARY_TAB,
  PROTOCOL_AMENDMENT_SECTION_ID,
  TRIAL_DELIVERY_GOALS_SECTION_ID,
  TRIAL_DELIVERY_GOALS_SECTION_IDS,
} from '../../constants/uiConfiguration.constants';

const INITIAL_STATE = {
  trialMilestone: [],
  preferencesConfigMap: {
    [PROTOCOL_AMENDMENT_SECTION_ID]: {
      ACTIVE_CARD: '',
      SELECTED_TIME_FRAME: TIME_FRAME.LAST_12_MONTHS,
      IS_AVG_TABLE_VISIBLE: true,
      IS_SUBSTANTIAL_TABLE_VISIBLE: true,
      IS_PRE_FSI_TABLE_VISIBLE: true,
      IS_PRIMARY_REASONS_TABLE_VISIBLE: true,
      IS_MEDIAN_TIME_TABLE_VISIBLE: true,
      AVG_SELECTED_GROUP: AMENDMENT_GROUP.GROUP_BY_TAU,
      SUBSTANTIAL_SELECTED_GROUP: AMENDMENT_GROUP.GROUP_BY_TAU,
      PRE_FSI_SELECTED_GROUP: AMENDMENT_GROUP.GROUP_BY_TAU,
      PRIMARY_REASONS_SELECTED_GROUP: AMENDMENT_GROUP.GROUP_BY_TAU,
      MEDIAN_TIME_SELECTED_GROUP: '',
      SUBSTANTIAL_SELECTED_TAB: 'Percentage',
      PRE_FSI_SELECTED_TAB: 'Percentage',
    },
    [GLOBAL_FILTER_SECTION_ID]: {
      GLOBAL_FILTER_VALUE,
    },
    [PORTFOLIO_DIVERSITY_TAB]: {
      ACTIVE_PORTFOLIO_DIVERSITY_TAB: 'RACE_TAB',
    },
    [ACTIVE_TRIAL_PERFORMANCE_SECTION_IDS.STUDY_RAG_BY_CATEGORY]: {
      GROUP_BY: STUDY_RAG_BY_CATEGORY_GROUP_OPTIONS[0].value,
      IS_DATATABLE_VISIBLE: false,
    },
    [ACTIVE_TRIAL_PERFORMANCE_SECTION_ID]: {
      TRIAL_PERFORMANCE_ACTIVE_CARD: '',
      IS_ACTIVATIONS_CARD_VISIBLE: false,
      IS_ENROLLMENTS_CARD_VISIBLE: false,
      ACTIVATIONS_SELECTED_TAB: 'Percentage',
      ENROLLMENTS_SELECTED_TAB: 'Percentage',
      ACTIVATIONS_ENROLLMENTS_SELECTED_TAB: 'Percentage',
      IS_ACTIVATION_DATATABLE_VISIBLE: false,
      IS_STUDY_RAG_OVER_TIME_TABLE_VISIBLE: false,
    },
    [PORTFOLIO_DIVERSITY_PAGE_SECTION_ID]: {
      ...getDiversityInitialState(),
    },
    [TRIAL_DELIVERY_GOALS_SECTION_ID]: {
      ACTIVE_TAB: 0,
    },
    [TRIAL_DELIVERY_GOALS_SECTION_IDS.GPT]: {
      ...TDG_GPT_INITIAL_STATE,
      CARD_GPT_GOAL_COMPLETED: {
        GROUP_BY: 'TAU',
        IS_DATATABLE_VISIBLE: true,
        GPT_GOAL: 'ALL',
        DATA_TABLE_STATUS_FILTER: 'ALL',
      },
      CARD_GPT_GOAL_PROJECTED: {
        GROUP_BY: 'TAU',
        IS_DATATABLE_VISIBLE: true,
        GPT_GOAL: 'ALL',
        DATA_TABLE_STATUS_FILTER: 'ALL',
      },
      CARD_VARIANCE_COMPLETED: {
        GROUP_BY: null,
        IS_DATATABLE_VISIBLE: true,
      },
      CARD_VARIANCE_PROJECTED: {
        GROUP_BY: null,
        IS_DATATABLE_VISIBLE: true,
      },
    },
    [TRIAL_DELIVERY_GOALS_SECTION_IDS.MILESTONES]: {
      ...TDG_MILESTONE_INITIAL_STATE,
      CARD_MILESTONE_COMPLETED: {
        GROUP_BY: 'OPTAU',
        IS_DATATABLE_VISIBLE: true,
        MILESTONES: ['PA', 'CSR'],
        DATA_TABLE_STATUS_FILTER: 'ALL',
      },
      CARD_MILESTONE_PROJECTED: {
        GROUP_BY: 'OPTAU',
        IS_DATATABLE_VISIBLE: true,
        MILESTONES: [],
        DATA_TABLE_STATUS_FILTER: 'ALL',
      },
      CARD_PROJECTED_COMPLETED: {
        GROUP_BY: null,
        IS_DATATABLE_VISIBLE: true,
      },
      CARD_PROJECTED_PROJECTED: {
        GROUP_BY: null,
        IS_DATATABLE_VISIBLE: true,
      },
    },
    [CYCLE_TIMES_SECTION_ID.RAG_STATUS_SECTION]: {
      GROUP: true,
      STAGE: '',
      MEET: false,
      IS_SIDEBAR_VISIBLE: false,
    },
    [CYCLE_TIMES_SECTION_ID.CYCLE_STAGE_PERFORMANCE_SECTION]: {
      GROUP: true,
      DATA_TABLE: false,
      TIER: null,
      PHASE: null,
      PREVIOUS_MILESTONE: null,
      NEXT_MILESTONE: null,
      BEGIN_DATE: null,
      END_DATE: null,
      DATE_HASH: null,
      PERFORMANCE_BY_OVER_TIME_ACTIVE_TAB: 0,
      CALENDAR_VIEW_BY: 'VIEW_BY_YEAR',
    },
    [CYCLE_TIMES_SECTION_ID.CYCLE_STAGE_CATEGORY_SECTION]: {
      GROUP: true,
      DATA_TABLE: false,
      TIER: null,
      PHASE: null,
      GROUP_BY: null,
      PREVIOUS_MILESTONE: null,
      NEXT_MILESTONE: null,
      MONTH: null,
    },
    [CYCLE_TIMES_SECTION_ID.CYCLE_STAGE_CYCLE_BY_CYCLE]: {
      GROUP: true,
      TIER: null,
      PHASE: null,
      PREVIOUS_MILESTONE: null,
      NEXT_MILESTONE: null,
      CALENDAR_VIEW_BY: 'VIEW_BY_YEAR',
      BEGIN_DATE: null,
      END_DATE: null,
      DATE_HASH: null,
    },
    [PORTFOLIO_DIVERSITY_TAB]: {
      ACTIVE_TAB: 0,
    },
    [PORTFOLIO_TRIAL_SUMMARY_TAB]: {
      BY_STATUS: false,
    },
  },
};

export default INITIAL_STATE;
