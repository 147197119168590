import axios from 'axios';
import { get, isNil } from 'lodash';
import { transformationFunctionWrapper } from 'utility/errors';
import generateHttpActions from '../generateHttpActions';
import { globalFilterSelector } from '../globalFilter.selector';
import { mockForecastPayload } from '../emptyResponses';

class ThunkGenerator {
  _resource = '';

  _secondaryResource = '';

  _isLogging = false;

  _isGlobalFiltering = false;

  _transform = (data) => data;

  _globalFilterSelector = globalFilterSelector;

  _syntheticResponse = null;

  _httpActions = generateHttpActions(null);

  constructor(name) {
    this._name = name;
    this._httpActions = generateHttpActions(this._name);
  }

  set resource(value) {
    this._resource = value;
  }

  get resource() {
    return this._resource;
  }

  set secondaryResource(value) {
    this._secondaryResource = value;
  }

  get secondaryResource() {
    return this._secondaryResource;
  }

  set syntheticResponse(response) {
    this._syntheticResponse = response;
  }

  get syntheticResponse() {
    return this._syntheticResponse;
  }

  set transform(transformFunction) {
    this._transform = transformationFunctionWrapper(transformFunction);
  }

  get transform() {
    return this._transform;
  }

  set isLogging(isLoggingEnabled) {
    this._isLogging = !!isLoggingEnabled;
  }

  get isLogging() {
    return this._isLogging;
  }

  set isGlobalFiltering(isGlobalFilteringEnabled) {
    this._isGlobalFiltering = !!isGlobalFilteringEnabled;
  }

  get isGlobalFiltering() {
    return this._isGlobalFiltering;
  }

  set globalFilterSelector(globalFilterSelectorFunction) {
    this._globalFilterSelector = globalFilterSelectorFunction;
  }

  get globalFilterSelector() {
    return this._globalFilterSelector;
  }

  _logger(...args) {
    if (!this._isLogging) return;
    const prefix = [this.constructor.name, ' 📡 ', 'Thunk |=>', this._name];
    const loggingItems = [...prefix, ...args];
    // eslint-disable-next-line no-console
    console.log(...loggingItems);
  }

  #waitFor(delay = 0) {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, delay);
    });
    return promise;
  }

  #getGlobalFilterParams(storeState) {
    let filterApiParams = {};
    if (!this._isGlobalFiltering) return filterApiParams;

    if (typeof this._globalFilterSelector === 'function') {
      const globalFilters = this._globalFilterSelector(storeState);
      filterApiParams = get(globalFilters, 'filterApiParams', {});
      this._logger({ filterApiParams });
    }
    return filterApiParams;
  }

  generateDispatcher(
    resource,
    requestParameters,
    additionalParams,
    secondaryResource,
  ) {
    const dispatchFn = async (dispatch, getState) => {
      if (additionalParams.BACKGROUND_START) {
        this._logger('STEP 1', 'BACKGROUND_START');
        dispatch(this._httpActions.backgroundStart());
      } else {
        this._logger('STEP 1', 'START');
        dispatch(this._httpActions.start());
      }

      // Global Filters
      const storeState = getState();
      const filterApiParams = this.#getGlobalFilterParams(storeState);
      this._logger('STEP 2', { filterApiParams });

      const params = {
        ...filterApiParams,
        ...requestParameters,
      };

      const delay = get(additionalParams, 'delay', 0);
      this._logger('STEP 3', `DELAY : ${delay} milliseconds`);

      try {
        await this.#waitFor(delay);
        this._logger('STEP 4', 'REQUESTING SERVER via AXIOS');
        let response;
        let data;
        if (secondaryResource === '' || isNil(secondaryResource)) {
          response = await axios.get(resource, { params });
          data = get(response, 'data.data', null);
          this._logger('STEP 5', { data });
        } else {
          response = await axios.get(resource, { params });
          let secondaryResponse;
          try {
            secondaryResponse = await axios.get(secondaryResource, { params });
          } catch (err) {
            secondaryResponse = mockForecastPayload;
          }
          const milestoneData = get(response, 'data.data', null);
          const forecastData = get(
            secondaryResponse,
            'data.data',
            mockForecastPayload,
          );
          data = { ...milestoneData, ...forecastData };
          this._logger('STEP 5', { data });
        }

        const transformedData = this._transform(
          data,
          requestParameters,
          additionalParams,
        );
        this._logger('STEP 6', { transformedData });
        dispatch(this._httpActions.success({ data: transformedData }));
        this._logger('STEP 7', 'COMPLETED');
      } catch (error) {
        if (isNil(this._syntheticResponse)) {
          dispatch(this._httpActions.error(error));
          this._logger('STEP ERROR', error);
        } else {
          const data = get(this._syntheticResponse, 'data.data', null);
          this._logger('SYNTHETIC SUCCESS', 'STEP 5', { data });

          try {
            const transformedData = this._transform(
              data,
              requestParameters,
              additionalParams,
            );
            this._logger('SYNTHETIC SUCCESS', 'STEP 6', { transformedData });

            dispatch(this._httpActions.success({ data: transformedData }));
            this._logger('SYNTHETIC SUCCESS', 'STEP 7', 'COMPLETED');
          } catch (transformError) {
            dispatch(this._httpActions.error(transformError));
            this._logger('STEP TRANSFORM ERROR');
          }
        }
      }
    };

    return dispatchFn;
  }
}

export default ThunkGenerator;
