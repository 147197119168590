import siteKeyFactsHttpRequest from './03_SiteKeyFacts.thunk';
import siteTrialEnrollmentAndScreeningHttpRequest from './05_SiteTrialEnrollmentAndScreening.thunk';
import protocolDeviationsDataHttpRequest from './07_protocolDeviations/07_protocolDeviations.thunk';
import enrollmentMetricsDataHttpRequest from './06_enrollmentMetrics/06_enrollmentMetrics.thunk';
import SiteHealthListHttpRequest from './02_SiteHealthList.thunk';
import SiteCountListHttpRequest from './06_SiteCount.thunk';
import SiteHealthFiltersHttpRequest from './01_SitehealthFilters.thunk';
import siteInfoKeyFactsHttpRequest from './12_crossSite/01_SiteInfoKeyFacts.thunk';
import siteInfoTrialFiltersHttpRequest from './12_crossSite/04_SiteInfoTrialFilters.thunk';
import keyPrincipalInvestigatorsHttpRequest from './12_crossSite/03_KeyPrincipalInvestigators.thunk';
import siteStartupHttpRequest from './04_SiteStartup.thunk';
import siteInfoTrialListHttpRequest from './12_crossSite/05_SiteInfoTrialList.thunk';
import siteInfoTrialSummaryHttpRequest from './12_crossSite/02_SiteInfoTrialSummary.thunk';
import edcSiteHeaderHttpRequest from './09_edcReadinessSite/00_edcHeader/edcHeader.thunk';
import edcSummaryCardsHttpRequest from './09_edcReadinessSite/01_edcSummaryCards/edcSummaryCards.thunk';
import queryAgingChartHttpRequest from './09_edcReadinessSite/03_edcDataCleaning/01_queryAging/queryAging.thunk';

import {
  listSavedFiltersHttpRequest,
  deleteSavedFiltersHttpRequest,
} from '../../../../store/thunks';
import crfEnteredChartHttpRequest from './09_edcReadinessSite/02_edcDataEntry/01_crfEntered/crfEntered.thunk';
import crfSignedChartHttpRequest from './09_edcReadinessSite/04_edcPreLock/01_crfSigned/crfSigned.thunk';

export {
  siteTrialEnrollmentAndScreeningHttpRequest,
  siteKeyFactsHttpRequest,
  protocolDeviationsDataHttpRequest,
  enrollmentMetricsDataHttpRequest,
  SiteHealthListHttpRequest,
  SiteHealthFiltersHttpRequest,
  siteInfoKeyFactsHttpRequest,
  siteInfoTrialFiltersHttpRequest,
  siteInfoTrialSummaryHttpRequest,
  siteInfoTrialListHttpRequest,
  keyPrincipalInvestigatorsHttpRequest,
  listSavedFiltersHttpRequest,
  deleteSavedFiltersHttpRequest,
  siteStartupHttpRequest,
  edcSiteHeaderHttpRequest,
  crfEnteredChartHttpRequest,
  edcSummaryCardsHttpRequest,
  queryAgingChartHttpRequest,
  crfSignedChartHttpRequest,
};

export const API_LIST = [
  siteTrialEnrollmentAndScreeningHttpRequest,
  siteKeyFactsHttpRequest,
  protocolDeviationsDataHttpRequest,
  enrollmentMetricsDataHttpRequest,
  SiteHealthListHttpRequest,
  SiteHealthFiltersHttpRequest,
  siteInfoKeyFactsHttpRequest,
  siteInfoTrialFiltersHttpRequest,
  siteInfoTrialSummaryHttpRequest,
  siteInfoTrialListHttpRequest,
  siteStartupHttpRequest,
  SiteCountListHttpRequest,
  edcSiteHeaderHttpRequest,
  crfEnteredChartHttpRequest,
  edcSummaryCardsHttpRequest,
  queryAgingChartHttpRequest,
  crfSignedChartHttpRequest,
];
