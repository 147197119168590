import {
  MENU_ID,
  SUB_MENU_PATH,
} from 'section/01_NavigationBarSection/constants/routeDetails';

const PAGE_VISIT_EVENT = 'page_visit';

class GoogleAnalytic {
  constructor(trackingId, userId) {
    const gaTagManagerScript = document.createElement('script');

    gaTagManagerScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${trackingId}`,
    );

    document.head.appendChild(gaTagManagerScript);

    window.gtag('js', new Date());
    if (trackingId) {
      window.gtag('config', trackingId, {
        user_id: userId,
        custom_map: {
          dimension2: 'page_path',
          dimension3: 'page_name',
          dimension4: 'user_group',
          dimension5: 'department',
        },
      });
    }
  }

  #routeProcess = (path) => {
    let route = null;
    Object.values(MENU_ID).forEach((currentSection) => {
      SUB_MENU_PATH[currentSection].forEach((currentRoute) => {
        const routeRegex = new RegExp(currentRoute.path);
        if (path.match(routeRegex)) {
          route = currentRoute.pathname;
        }
      });
    });
    return route;
  };

  sendGAPageVisit = (path) => {
    const pageVisitParams = {
      page_path: window.location.href,
      page_name: this.#routeProcess(path) || path,
    };
    window.gtag('event', PAGE_VISIT_EVENT, pageVisitParams);
  };

  setGAUserProperties = (userProperties) => {
    window.gtag('set', 'user_properties', userProperties);
  };
}

export default GoogleAnalytic;
