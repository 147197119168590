import { purple } from 'assets/stylesheets/base/_colors';
import Generator from './generator';

export default class ScreeningCurveGenerator extends Generator {
  static _lineChartConfig = {
    yAxis: 0,
    type: 'line',
    dashStyle: null,
    showInLegend: true,
    enableMouseTracking: true,
    customTags: ['screening'],
  };

  getScreeningSeriesData(objectArray) {
    const screeningSeriesData = this._getSeriesData(
      objectArray,
      'actualSubjectsScreenedCum',
    );

    return screeningSeriesData;
  }

  getSeries(objectArray) {
    const lineSeriesData = this.getScreeningSeriesData(objectArray);

    const screeningSeries = {
      id: 'screening',
      name: 'Screening (Actual)',
      data: lineSeriesData,
      color: purple,
      marker: { enabled: lineSeriesData.length === 1 },
      ...ScreeningCurveGenerator._lineChartConfig,
    };

    return [screeningSeries];
  }
}
