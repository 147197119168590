import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import transform from './transform/RaceTrendOverTime.transform';

const raceTrendOverTimeCalendarHttpRequest = (() => {
  const apiName = 'diversityTrendOverTimeCalendar';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/diversity-over-time`;
  generatedHttpThunk.transform = transform;
  return generatedHttpThunk.generateThunk();
})();

export default raceTrendOverTimeCalendarHttpRequest;
