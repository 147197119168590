import { gray3, gray6 } from 'assets/stylesheets/base/_colors';
import { get, isNil } from 'lodash';
import QuadrantLabel from './quadrantLabel.generator';
import ScatterPoint from './scatterPoint.generator';

class Generator {
  constructor(data) {
    this._data = data;
  }

  _getChart() {
    const chartConfig = {
      renderTo: 'container',
      defaultSeriesType: 'scatter',
      zoomType: 'xy',
      backgroundColor: gray6,
      panning: {
        enabled: true,
        type: 'xy',
      },
      spacingTop: 50,
      panKey: 'shift',
      resetZoomButton: {
        position: {
          verticalAlign: 'top',
          x: -40,
        },
        relativeTo: 'chart',
      },
    };
    return chartConfig;
  }

  _getTooltip() {
    const tooltip = {
      formatter() {
        const point = get(this, 'point', null);
        if (isNil(point)) {
          return null;
        }
        const {
          protocolNumberHarmonized,
          actualSiteActivated,
          plannedSiteActivatedToDate,
          actualPatientsEnrolled,
          plannedPatientsEnrolledToDate,
        } = point;
        const tooltipFormat = `<p>${protocolNumberHarmonized}
        <br/>Total active sites: ${actualSiteActivated}
        <br/>Planned sites: ${plannedSiteActivatedToDate}
        <br/>Total enrolled: ${actualPatientsEnrolled}

        <br/>Planned enrollment: ${plannedPatientsEnrolledToDate}</p`;
        return tooltipFormat;
      },
      useHTML: true,
    };
    return tooltip;
  }

  _getPlotOptions() {
    const plotOptionsConfig = {
      series: {
        shadow: false,
      },
      arearange: {
        marker: {
          enabled: false,
        },
        zIndex: 0,
        enableMouseTracking: false,
      },
      scatter: {
        marker: {
          symbol: 'circle',
        },
        zIndex: 1,
        lineColor: 'white',
        dataLabels: {
          useHTML: true,
          format:
            '<div class="d-flex align-items-end"><h1>{point.percentage}</h1>&nbsp;<p>{point.count}</p></div>',
          enabled: true,
          allowOverlap: true,
        },
      },
    };
    return plotOptionsConfig;
  }

  _getXAxis() {
    const xAxis = {
      lineColor: 'transparent',
      title: {
        text: 'Enrollments / plan to date (%)',
      },
      min: 0,
      max: 200,
      tickInterval: 25,
      tickLength: 0,
      showLastLabel: true,
      showFirstLabel: false,
      minorGridLineWidth: 0,
      plotLines: [
        {
          zIndex: 3,
          value: 100,
          width: 1,
          color: gray3,
          dashStyle: 'shortdash',
        },
      ],
    };
    return xAxis;
  }

  _getYAxis() {
    const yAxis = {
      labels: {
        enabled: false,
      },
      title: {
        text: 'Activations / plan to date (%)',
        rotation: 270,
        margin: 25,
      },
      min: 0,
      max: 200,
      tickInterval: 25,
      tickLength: 3,
      gridLineColor: 'transparent',
      plotLines: [
        {
          zIndex: 3,
          value: 100,
          width: 1,
          color: gray3,
          dashStyle: 'shortdash',
        },
      ],
    };
    return yAxis;
  }

  _getSeries() {
    const totalPointCount = this._data.length;
    const scatterPoint = new ScatterPoint(this._data);
    const aheadBehindCount = scatterPoint.aheadBehindScatterSeriesData.length;
    const aheadAheadCount = scatterPoint.aheadAheadScatterSeriesData.length;
    const behindBehindCount = scatterPoint.behindBehindScatterSeriesData.length;
    const behindAheadCount = scatterPoint.behindAheadScatterSeriesData.length;

    const quadrantLabel = new QuadrantLabel(
      [aheadBehindCount, aheadAheadCount, behindBehindCount, behindAheadCount],
      totalPointCount,
    );

    const quadrantLabelSeries = quadrantLabel.generate();
    const scatterPointSeries = scatterPoint.generate();
    const allSeries = [...scatterPointSeries, ...quadrantLabelSeries];
    return allSeries;
  }

  _getExporting() {
    return {
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen'],
          y: -30,
        },
      },
    };
  }

  generate() {
    const xAxis = this._getXAxis();
    const yAxis = this._getYAxis();
    const series = this._getSeries();
    const plotOptions = this._getPlotOptions();
    const tooltip = this._getTooltip();
    const chart = this._getChart();
    const exporting = this._getExporting();
    const chartData = {
      chart,
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      tooltip,
      plotOptions,
      xAxis,
      yAxis,
      series,
      exporting,
    };

    return chartData;
  }
}

export default Generator;
