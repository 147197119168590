import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Error from 'components/Error';
import Loader from 'components/Loader';
import SavedFiltersDisplay from 'components/SavedFiltersDisplay/SavedFiltersDisplay';
import { compassStore } from 'store';
import { listSavedFiltersHttpRequest } from 'store/thunks';
import { trialListFiltersHttpRequest } from 'dashboards/TrialManagement/store/thunks';
import { SafeHtmlElements } from 'components/SafeComponents';
import './MyFiltersSection.scss';
import { applySavedFilter } from 'components/UserPreference/utility/applySavedFilter';
import { useThunkSelector } from 'utility/redux';

function MyFiltersSection({ setSidebarVisible }) {
  const { dispatch } = compassStore;

  useEffect(() => {
    dispatch(listSavedFiltersHttpRequest());
  }, []);
  useEffect(() => {
    dispatch(trialListFiltersHttpRequest());
  }, []);

  const savedFiltersData = useThunkSelector('savedFilters.listSavedFilters');
  const trialListFilters = useThunkSelector('trialListFilters');

  return (
    <div className="row my-filters px-1">
      {savedFiltersData.isError && <Error error={savedFiltersData.error} />}
      {savedFiltersData.isLoading && <Loader className="my-5 py-5" />}
      {savedFiltersData.isSuccess && trialListFilters.isSuccess && (
        <div className="col-12">
          {savedFiltersData.payload.data.map(
            (moduleSavedFilters, index) =>
              moduleSavedFilters.module && (
                <div
                  className="row"
                  key={`module-${moduleSavedFilters.module}`}
                >
                  <SafeHtmlElements tagName="h4" className="module-header">
                    {moduleSavedFilters.label}
                  </SafeHtmlElements>
                  <div className="col-12 mt-3">
                    <SavedFiltersDisplay
                      moduleName={moduleSavedFilters.module}
                      viewFilter={({ savedFilter, currentDashboard }) => {
                        applySavedFilter({ savedFilter, currentDashboard });
                        setSidebarVisible(false);
                      }}
                      filterOptions={trialListFilters.payload}
                    />
                  </div>
                  {index < savedFiltersData.payload.data.length - 1 && (
                    <hr className="my-3" />
                  )}
                </div>
              ),
          )}
        </div>
      )}
    </div>
  );
}

MyFiltersSection.propTypes = {
  setSidebarVisible: PropTypes.func,
};

MyFiltersSection.defaultProps = {
  setSidebarVisible: () => {},
};
export default MyFiltersSection;
