import { get } from 'lodash';
import DateStringTransform from 'utility/date/dateString.utility';
import { SHORT_UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import { roundOff } from 'utility/math';
import addFileName from '../../../../../../utility/fileName.highcharts';
import { transformationFunctionWrapper } from '../../../../../../utility/errors';
import EnrollmentForecastSummaryTransform from './enrollmentForecastSummary.transform';

const transform = (forecastResponse) => {
  const { likelihoodByMonth, metrics } = forecastResponse;
  const enrollmentForecastSummaryTransform =
    new EnrollmentForecastSummaryTransform();

  enrollmentForecastSummaryTransform._metrics = metrics;
  enrollmentForecastSummaryTransform._likelihoodByMonth = likelihoodByMonth;

  let forecastMetrics = {};
  let forecastMetricsError = null;
  let forecastStartRangeDate = null;
  let forecastEndRangeDate = null;

  let likelihoodByMonthChartData = {};
  let likelihoodByMonthChartDataError = null;
  try {
    forecastMetrics =
      enrollmentForecastSummaryTransform._forecastMetricsValues();
    forecastStartRangeDate = get(
      metrics.find(
        (item) => get(item, 'metric') === 'FORECAST_LSI_RANGE_START',
      ),
      'value',
    );
    forecastEndRangeDate = get(
      metrics.find((item) => get(item, 'metric') === 'FORECAST_LSI_RANGE_END'),
      'value',
    );
  } catch (error) {
    forecastMetricsError = error;
  }
  try {
    likelihoodByMonthChartData =
      enrollmentForecastSummaryTransform._generateLikelihoodChartData(
        forecastStartRangeDate,
        forecastEndRangeDate,
      );
    likelihoodByMonthChartData = addFileName(
      likelihoodByMonthChartData,
      'likelihoodByMonth',
    );
  } catch (error) {
    likelihoodByMonthChartDataError = error;
  }
  const probabilityStartValue = get(
    metrics.find((item) => get(item, 'metric') === 'FORECAST_LOWER_BOUND'),
    'value',
  );
  const probabilityEndValue = get(
    metrics.find((item) => get(item, 'metric') === 'FORECAST_UPPER_BOUND'),
    'value',
  );
  const forecastLSIDate = get(
    metrics.find((item) => get(item, 'metric') === 'FORECAST_LSI'),
    'value',
  );
  const forecastLSIRange = {
    fromDate: forecastStartRangeDate,
    toDate: forecastEndRangeDate,
  };
  return {
    forecastLSIRange,
    likelihoodByMonthChartData,
    likelihoodByMonth,
    likelihoodByMonthChartDataError,
    forecastMetrics,
    forecastMetricsError,
    forecastLSIDate,
    forecastStartRangeDate: new DateStringTransform(
      forecastStartRangeDate,
    ).formatter(SHORT_UI_DISPLAY_DATE),
    forecastEndRangeDate: new DateStringTransform(
      forecastEndRangeDate,
    ).formatter(SHORT_UI_DISPLAY_DATE),
    forecastProbabilityStartValue: roundOff(probabilityStartValue, 0, null),
    forecastProbabilityEndValue: roundOff(probabilityEndValue, 0, null),
  };
};
export default transformationFunctionWrapper(transform);
