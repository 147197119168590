import { get } from 'lodash';
import {
  crfsCountPercentTranform,
  modifiedChartsAndSideCardData,
} from '../../../__utility__/crfsCountPercentTransform';
import { CHART_TYPES } from '../../../utility/constant';

function crfEnteredTransform(data) {
  const countYAxisLabel = 'CRFs signed';
  const percentYAxisLabel = '% CRFs signed';
  const countChartDataKey = 'count';
  const percentChartDataKey = 'percentage';
  const countGroupColKey = 'count';
  const percentGroupColKey = 'percent';
  const chartType = get(data, 'isBarChartStatusVisible')
    ? CHART_TYPES.LOW_HIGH
    : CHART_TYPES.SITES;

  const modifiedData = modifiedChartsAndSideCardData(
    data,
    chartType,
    countChartDataKey,
    percentChartDataKey,
  );

  return crfsCountPercentTranform(
    modifiedData,
    countYAxisLabel,
    percentYAxisLabel,
    countGroupColKey,
    percentGroupColKey,
  );
}

export default crfEnteredTransform;
