const ALL_GROUP_OPTIONS = [
  { label: 'Show all', value: '', title: 'Category' },
  { label: 'Group by Race', value: 'RACE', title: 'Race' },
  { label: 'Group by TAU', value: 'TAU', title: 'TAU' },
  { label: 'Group by OpTau', value: 'OPTAU', title: 'Optau' },
  { label: 'Group by Tier', value: 'TIER', title: 'Tier' },
  { label: 'Group by Phase', value: 'PHASE', title: 'Phase' },
  { label: 'Group by CRO', value: 'CRO', title: 'CRO' },
  { label: 'Group by Ethnicity', value: 'ETHNICITY', title: 'Ethnicity' },
  { label: 'Group by Gender', value: 'GENDER', title: 'Gender' },
  { label: 'Group by Age', value: 'AGE', title: 'Age' },
];

export const PORTFOLIO_DIVERSITY_MENU_OPTIONS = [
  {
    label: 'Race',
    value: 'RACE',
    groupingOptions: ALL_GROUP_OPTIONS.filter(({ value }) => value !== 'RACE'),
  },
  {
    label: 'Ethnicity',
    value: 'ETHNICITY',
    groupingOptions: ALL_GROUP_OPTIONS.filter(
      ({ value }) => value !== 'ETHNICITY',
    ),
  },
  {
    label: 'Gender',
    value: 'GENDER',
    groupingOptions: ALL_GROUP_OPTIONS.filter(
      ({ value }) => value !== 'GENDER',
    ),
  },
  {
    label: 'Age',
    value: 'AGE',
    groupingOptions: ALL_GROUP_OPTIONS.filter(({ value }) => value !== 'AGE'),
  },
];

export const DIVERSITY_FILTERS = {
  COUNTRY_DROPDOWN: {
    key: 'COUNTRIES',
    options: [
      { label: 'All countries', value: 'ALL' },
      { label: 'USA', value: 'USA' },
    ],
  },

  SWITCH_OPTIONS: {
    key: 'SWITCH_OPTIONS',
    options: [
      { label: 'Count', value: 'COUNT' },
      { label: 'Percentage', value: 'PERCENTAGE' },
    ],
  },

  VIEW_BY_OPTIONS: {
    key: 'VIEW_BY_OPTIONS',
    options: [
      { label: 'View by year', value: 'VIEW_BY_YEAR' },
      { label: 'View by quarter', value: 'VIEW_BY_QUARTER' },
    ],
  },
};

export function getDiversityInitialState() {
  const initialState = {};
  PORTFOLIO_DIVERSITY_MENU_OPTIONS.forEach((option) => {
    const defaultGroupingOption = option.groupingOptions[0].value;
    initialState[option.value] = {
      SELECTED_COUNTRY: DIVERSITY_FILTERS.COUNTRY_DROPDOWN.options[0].value,

      // Section 1
      DISTRIBUTION_BY_GROUP_GROUP_BY: defaultGroupingOption,
      DISTRIBUTION_BY_GROUP_CURRENT_VIEW:
        DIVERSITY_FILTERS.SWITCH_OPTIONS.options[0].value,
      DISTRIBUTION_BY_GROUP_IS_DATATABLE_VISIBLE: false,

      // Section 2
      ENROLLMENT_SECTION_CURRENT_VIEW:
        DIVERSITY_FILTERS.SWITCH_OPTIONS.options[0].value,
      ENROLLMENT_SECTION_IS_DATATABLE_VISIBLE: false,

      // Section 3
      TREND_OVER_TIME_CURRENT_VIEW:
        DIVERSITY_FILTERS.SWITCH_OPTIONS.options[0].value,
      TREND_OVER_TIME_CALENDAR: {
        START_YEAR: new Date().getFullYear() - 1,
        END_YEAR: new Date().getFullYear() + 1,
        START_QUARTER: 1,
        END_QUARTER: 4,
      },
      TREND_OVER_TIME_CALENDAR_VIEW_BY:
        DIVERSITY_FILTERS.VIEW_BY_OPTIONS.options[0].value,
      TREND_OVER_TIME_IS_DATATABLE_VISIBLE: false,
    };
  });
  return initialState;
}
