import { get } from 'lodash';
import { roundOff } from 'utility/math';
import transformTableData from './tableData.transform';
import transformChartData from './chartData.transform';
import { COMPLEX_TABLE_DATA_STRUCTURE } from '../constants/tableStructure';

// For COMPASSINS-1778: "Removing original plan"
const removeOriginalPlans = (plans) =>
  plans.filter(({ value }) => value !== 'Original plan');

const transform = (data) => {
  const filteredTimelineRecords = data.timelineRecords;

  const probabilityStartValue = get(
    get(data, 'metrics', []).find(
      (item) => get(item, 'metric') === 'FORECAST_LOWER_BOUND',
    ),
    'value',
  );
  const probabilityEndValue = get(
    get(data, 'metrics', []).find(
      (item) => get(item, 'metric') === 'FORECAST_UPPER_BOUND',
    ),
    'value',
  );

  const forecastLSIDate = get(
    get(data, 'metrics', []).find(
      (item) => get(item, 'metric') === 'FORECAST_LSI',
    ),
    'value',
  );
  const forecastConfidenceInterval = get(
    get(data, 'metrics', []).find(
      (item) => get(item, 'metric') === 'CONFIDENCE_INTERVAL',
    ),
    'value',
  );

  const forecastStartRangeDate = get(
    get(data, 'metrics', []).find(
      (item) => get(item, 'metric') === 'FORECAST_LSI_RANGE_START',
    ),
    'value',
  );
  const forecastEndRangeDate = get(
    get(data, 'metrics', []).find(
      (item) => get(item, 'metric') === 'FORECAST_LSI_RANGE_END',
    ),
    'value',
  );

  const timeLineData = filteredTimelineRecords.map((group) => ({
    tableData: transformTableData(group, COMPLEX_TABLE_DATA_STRUCTURE),
    chartData: transformChartData(group, forecastLSIDate),
    plans: removeOriginalPlans(data.plans),
    name: group.name,
    description: group.description,
  }));
  const transformedData = {
    timeLine: timeLineData,
    type: data.type,
    plans: removeOriginalPlans(data.plans),
    forecastLSIDate,
    forecastStartRangeDate,
    forecastEndRangeDate,
    forecastProbabilityStartValue: roundOff(probabilityStartValue, 1, 'N/A'),
    forecastProbabilityEndValue: roundOff(probabilityEndValue, 1, 'N/A'),
    forecastConfidenceInterval: roundOff(forecastConfidenceInterval, 1, 'N/A'),
  };
  return transformedData;
};

export default transform;
