import { sortBy } from 'lodash';
import generateSortComparatorWithNull from 'utility/generateSortComparatorWithNull';
import SiteDrillDownGenerator from './siteDrillDownGenerator.abstract';

class ScreeningGenerator extends SiteDrillDownGenerator {
  generateSeries(dataArray, dataKey) {
    const idFlags = { true: false, false: false };

    this.isDormantBasedBarColor = true;
    const siteSeries = super.generateSeries(dataArray, dataKey);

    const series = siteSeries.map((siteData) => {
      const isLinked = idFlags[siteData.isDormant];
      let linkedTo = 'Not Specified';
      let id = 'Not Specified';
      let name = 'Not Specified';

      if (siteData.isDormant === true) {
        linkedTo = 'Dormant';
        id = 'Dormant';
        name = 'Dormant';
      }
      if (siteData.isDormant === false) {
        name = 'Active';
        id = 'Active';
        linkedTo = 'Active';
      }

      idFlags[siteData.isDormant] = true;

      const item = {
        ...siteData,
        id: !isLinked ? id : null,
        name: !isLinked ? name : null,
        linkedTo: isLinked ? linkedTo : null,
        data: [siteData],
      };

      return item;
    });
    const sortedSeries = sortBy(series, generateSortComparatorWithNull('name'));
    return sortedSeries;
  }
}

export default ScreeningGenerator;
