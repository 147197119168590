import { gray3 } from 'assets/stylesheets/base/_colors';

const EMPTY_DONUT_CHART_CONFIG = {
  colors: [gray3],
  yAxis: {
    title: {
      text: null,
    },
  },
  plotOptions: {
    pie: {
      data: [0, 0],
      states: {
        hover: {
          halo: {
            size: 0,
          },
        },
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  series: [
    {
      type: 'pie',
    },
  ],
  chart: {
    type: 'pie',
    backgroundColor: 'transparent',
    margin: [0, 0, 0, 0],
    spacingTop: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    spacingRight: 0,
    height: '80%',
  },
};
export default EMPTY_DONUT_CHART_CONFIG;
