import {
  greenTrendUpArrow,
  downArrow,
  whiteDownArrow,
  whiteUpArrow,
  redRateUpArrow,
  greenRateDownArrow,
  grayTrendUpArrow,
  grayTrendDownArrow,
  downRedTrendArrow,
} from '../../../assets/icons';

export const UNBOXED_ICON_MAP = {
  true: {
    positive: greenTrendUpArrow,
    negative: redRateUpArrow,
  },
  false: {
    positive: greenRateDownArrow,
    negative: downArrow,
  },
};
export const BOXED_ICON_MAP = { true: whiteUpArrow, false: whiteDownArrow };

export const MOM_ICON_MAP = {
  positive: {
    red: redRateUpArrow,
    green: greenTrendUpArrow,
    gray: grayTrendUpArrow,
  },
  negative: {
    red: downRedTrendArrow,
    green: greenRateDownArrow,
    gray: grayTrendDownArrow,
  },
};
