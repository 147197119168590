import React from 'react';
import CompassIcon from './assets/compass.png';

function AppHeroSubSection() {
  return (
    <>
      <img src={CompassIcon} alt="icon" width={40} className="ms-2" />
      <h4 className="px-2">COMPASS</h4>
    </>
  );
}

export default AppHeroSubSection;
