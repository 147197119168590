import { gray3, ragGreen, ragRed } from 'assets/stylesheets/base/_colors';
import { get, isNil, map } from 'lodash';
import generateScrollbarEventHighchart from 'utility/highcharts/generateScrollbarEventHighchart';
import { roundOff } from 'utility/math/valueRounder';
import {
  medianLineSeriesTemplate,
  ragColumnSeriesTemplate,
} from './utility/chartSeriesTemplate';
import {
  dataLabelsFormatter,
  performanceOverTimeRagTooltipFormatter,
} from './utility/formatter';

const transform = (data, isYearly) => {
  const records = get(data, 'records', []);
  const trialRateMeet = records.map((record) => ({
    ...record,
    y: record.trialRateMeet,
  }));

  const trialRateNotMeet = records.map((record) => ({
    ...record,
    y: record.trialRateNotMeet,
  }));

  const cycleTime = records.map((record) => ({
    ...record,
    y: record.cycleTime,
  }));

  const trialRateNull = records.map((record) => ({
    ...record,
    y: !record.trialRateMeet && !record.trialRateNotMeet ? 100 : 0,
  }));

  const xAxisLabels = map(records, (record) => {
    const year = record.year ? roundOff(record.year, 1) : 'N/A';
    const quarter = record.quarter ? ` Q${roundOff(record.quarter, 1)}` : '';
    if (isYearly) {
      return year;
    }
    return `${year}${quarter}`;
  });

  const chartData = {
    title: { text: '' },
    xAxis: { categories: xAxisLabels },
    exporting: {
      sourceWidth: !isNil(records) ? 100 * records.length : 990,
      sourceHeight: 500,
      scale: 1,
    },
    yAxis: [
      {
        title: { text: '% trials' },
        stackLabels: { enabled: false },
        min: 0,
        max: 100,
      },
      {
        opposite: true,
        title: { text: 'Cycle time (days)' },
      },
    ],
    legend: { enabled: false },
    tooltip: {
      headerFormat: '',
      formatter: performanceOverTimeRagTooltipFormatter,
    },
    plotOptions: {
      column: { stacking: 'normal' },
      series: {
        dataLabels: {
          enabled: true,
          formatter: dataLabelsFormatter,
        },
      },
    },
    series: [],
    chart: {
      events: {
        ...generateScrollbarEventHighchart(15),
      },
      scrollablePlotArea: {
        minWidth: 75 * records.length,
      },
    },
  };
  const haveTrialRateNull = trialRateNull.some((record) => record.y !== 0);
  const haveTrialRate = !trialRateNull.every((record) => record.y === 100);
  if (haveTrialRate) {
    chartData.series.push(
      ragColumnSeriesTemplate({
        name: '% cycles meeting target',
        color: ragGreen,
        data: trialRateMeet,
      }),
      ragColumnSeriesTemplate({
        name: '% cycles not meeting target',
        color: ragRed,
        data: trialRateNotMeet,
      }),
    );
  }
  if (haveTrialRateNull) {
    chartData.series.push(
      ragColumnSeriesTemplate({
        name: 'RAG N/A',
        color: gray3,
        data: trialRateNull,
      }),
    );
  }
  chartData.series.push(
    medianLineSeriesTemplate({ name: 'Median cycle time', data: cycleTime }),
  );

  return chartData;
};

export default transform;
