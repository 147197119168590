import { DRILL_DOWN_TABLE_COLUMN } from '../configuration';

export const ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREENING =
  'ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREENING';
export const ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREEN_FAILURE =
  'ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREEN_FAILURE';
export const ENROLLMENT_DRIVERS_CURRENT_NUMBER_DROPOUT =
  'ENROLLMENT_DRIVERS_CURRENT_NUMBER_DROPOUT';
export const ENROLLMENT_DRIVERS_CURRENT_NUMBER_COMPLETION =
  'ENROLLMENT_DRIVERS_CURRENT_NUMBER_COMPLETION';
export const ENROLLMENT_DRIVERS_CURRENT_NUMBER_ENROLLMENT =
  'ENROLLMENT_DRIVERS_CURRENT_NUMBER_ENROLLMENT';

export const ENROLLMENT_DRIVERS_RATE_SCREENING =
  'ENROLLMENT_DRIVERS_RATE_SCREENING';
export const ENROLLMENT_DRIVERS_RATE_SCREEN_FAILURE =
  'ENROLLMENT_DRIVERS_RATE_SCREEN_FAILURE';
export const ENROLLMENT_DRIVERS_RATE_DROPOUT =
  'ENROLLMENT_DRIVERS_RATE_DROPOUT';
export const ENROLLMENT_DRIVERS_RATE_COMPLETION =
  'ENROLLMENT_DRIVERS_RATE_COMPLETION';
export const ENROLLMENT_DRIVERS_RATE_ENROLLMENT =
  'ENROLLMENT_DRIVERS_RATE_ENROLLMENT';

export const ENROLLMENT_DRILLDOWN_FILTER = 'ENROLLMENT_DRILLDOWN_FILTER';

export const ENROLLMENT_DRIVERS_SECTION_IDS = {
  'Screening events': {
    currentNumber: ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREENING,
    rate: ENROLLMENT_DRIVERS_RATE_SCREENING,
  },
  'Screen failure events': {
    currentNumber: ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREEN_FAILURE,
    rate: ENROLLMENT_DRIVERS_RATE_SCREEN_FAILURE,
  },
  Dropout: {
    currentNumber: ENROLLMENT_DRIVERS_CURRENT_NUMBER_DROPOUT,
    rate: ENROLLMENT_DRIVERS_RATE_DROPOUT,
  },
  Completion: {
    currentNumber: ENROLLMENT_DRIVERS_CURRENT_NUMBER_COMPLETION,
    rate: ENROLLMENT_DRIVERS_RATE_COMPLETION,
  },
};

export const ACTIVATION_DRIVERS_SECTION_ID = 'ACTIVATION_DRIVERS_SECTION';
export const SITE_ACTIVATION_CARD_ID = 'SITE_ACTIVATION_CARD';

export const enrollmentDriversDrilldown = [
  {
    id: 'screeningCard',
    currentNumber: {
      title: 'Screened events',
      sectionId: ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREENING,
      countryTableKeys:
        DRILL_DOWN_TABLE_COLUMN.SCREENING_COUNTRY_CURRENT_NO_TABLE,
      siteTableKeys: DRILL_DOWN_TABLE_COLUMN.SCREENING_SITE_CURRENT_NO_TABLE,
      dataKey: 'screening',
      defaultSort: 'screeningCount',
    },
    rate: {
      title: 'Screening event rate',
      sectionId: ENROLLMENT_DRIVERS_RATE_SCREENING,
      countryTableKeys: DRILL_DOWN_TABLE_COLUMN.SCREENING_COUNTRY_RATE_TABLE,
      siteTableKeys: DRILL_DOWN_TABLE_COLUMN.SCREENING_SITE_RATE_TABLE,
      dataKey: 'screening',
      defaultSort: 'screeningRate',
    },
  },
  {
    id: 'screenFailureCard',
    currentNumber: {
      title: 'Screen failure events',
      sectionId: ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREEN_FAILURE,
      countryTableKeys: DRILL_DOWN_TABLE_COLUMN.SCREEN_FAILURE_COUNTRY_TABLE,
      siteTableKeys: DRILL_DOWN_TABLE_COLUMN.SCREEN_FAILURE_SITE_TABLE,
      dataKey: 'screenFailure',
      defaultSort: 'screeningFailureCount',
    },
    rate: {
      title: 'Screening failure event rate',
      sectionId: ENROLLMENT_DRIVERS_RATE_SCREEN_FAILURE,
      countryTableKeys: DRILL_DOWN_TABLE_COLUMN.SCREEN_FAILURE_COUNTRY_TABLE,
      siteTableKeys: DRILL_DOWN_TABLE_COLUMN.SCREEN_FAILURE_SITE_TABLE,
      dataKey: 'screenFailure',
      defaultSort: 'screeningFailureRate',
    },
  },
  {
    id: 'enrollmentCard',
    currentNumber: {
      title: 'Enrollment',
      sectionId: ENROLLMENT_DRIVERS_CURRENT_NUMBER_ENROLLMENT,
      countryTableKeys:
        DRILL_DOWN_TABLE_COLUMN.ENROLLMENT_COUNTRY_CURRENT_NO_TABLE,
      siteTableKeys: DRILL_DOWN_TABLE_COLUMN.ENROLLMENT_SITE_CURRENT_NO_TABLE,
      dataKey: 'enrollment',
      currentPlanPartTableKeys:
        DRILL_DOWN_TABLE_COLUMN.ENROLLMENT_PART_CURRENT_NO_CURRENT_PLAN_TABLE,
      defaultSort: 'enrollmentCount',
    },
    rate: {
      title: 'Enrollment',
      sectionId: ENROLLMENT_DRIVERS_RATE_ENROLLMENT,
      countryTableKeys: DRILL_DOWN_TABLE_COLUMN.ENROLLMENT_COUNTRY_RATE_TABLE,
      siteTableKeys: DRILL_DOWN_TABLE_COLUMN.ENROLLMENT_SITE_RATE_TABLE,
      dataKey: 'enrollment',
      currentPlanPartTableKeys:
        DRILL_DOWN_TABLE_COLUMN.ENROLLMENT_PART_RATE_CURRENT_PLAN_TABLE,
      defaultSort: 'enrollmentRate',
    },
  },
  {
    id: 'dropoutCard',
    currentNumber: {
      title: 'Dropouts',
      sectionId: ENROLLMENT_DRIVERS_CURRENT_NUMBER_DROPOUT,
      countryTableKeys: DRILL_DOWN_TABLE_COLUMN.DROPOUT_COUNTRY_TABLE,
      siteTableKeys: DRILL_DOWN_TABLE_COLUMN.DROPOUT_SITE_TABLE,
      dataKey: 'dropout',
      defaultSort: 'dropoutCount',
    },
    rate: {
      title: 'Dropout rate',
      sectionId: ENROLLMENT_DRIVERS_RATE_DROPOUT,
      countryTableKeys: DRILL_DOWN_TABLE_COLUMN.DROPOUT_COUNTRY_TABLE,
      siteTableKeys: DRILL_DOWN_TABLE_COLUMN.DROPOUT_SITE_TABLE,
      dataKey: 'dropout',
      defaultSort: 'dropoutRate',
    },
  },
  {
    id: 'completionCard',
    currentNumber: {
      title: 'Completed',
      sectionId: ENROLLMENT_DRIVERS_CURRENT_NUMBER_COMPLETION,
      countryTableKeys: DRILL_DOWN_TABLE_COLUMN.COMPLETION_COUNTRY_TABLE,
      siteTableKeys: DRILL_DOWN_TABLE_COLUMN.COMPLETION_SITE_TABLE,
      dataKey: 'completion',
      defaultSort: 'completionCount',
    },
    rate: {
      title: 'Completion rate',
      sectionId: ENROLLMENT_DRIVERS_RATE_COMPLETION,
      countryTableKeys: DRILL_DOWN_TABLE_COLUMN.COMPLETION_COUNTRY_TABLE,
      siteTableKeys: DRILL_DOWN_TABLE_COLUMN.COMPLETION_SITE_TABLE,
      dataKey: 'completion',
      defaultSort: 'completionRate',
    },
  },
];
