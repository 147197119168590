import {
  crfsCountPercentTranform,
  modifiedChartsAndSideCardData,
} from '../../../__utility__/crfsCountPercentTransform';
import { CHART_TYPES } from '../../../utility/constant';

function crfEnteredTransform(data) {
  const countYAxisLabel = 'CRFs entered';
  const percentYAxisLabel = '% CRFs entered';
  const countChartDataKey = 'count';
  const percentChartDataKey = 'percent';
  const countGroupColKey = 'count';
  const percentGroupColKey = 'percent';
  const chartType = CHART_TYPES.LOW_HIGH;

  const modifiedData = modifiedChartsAndSideCardData(
    data,
    chartType,
    countChartDataKey,
    percentChartDataKey,
  );

  return crfsCountPercentTranform(
    modifiedData,
    countYAxisLabel,
    percentYAxisLabel,
    countGroupColKey,
    percentGroupColKey,
  );
}

export default crfEnteredTransform;
