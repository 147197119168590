import axios from 'axios';
import { forOwn } from 'lodash';
import { generateHttpActions } from 'utility';

function transformMyTrails(response) {
  const transformedRecords = response.data.records.map((trialData) => {
    const newObj = {};
    forOwn(trialData, (trialDet, key) => {
      if (key === 'isBookmarked') newObj[key] = trialDet;
      else newObj[key] = trialDet.label;
    });
    return newObj;
  });

  return { ...response.data, records: transformedRecords };
}

function myTrialsListHttpRequest() {
  const params = { search: '', filterUserTrials: true };

  const httpActions = generateHttpActions('myTrialsList');
  const resource = 'trial-management/trials';

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const response = await axios.get(resource, { params });

      const transformedResponse = transformMyTrails(response.data);

      dispatch(httpActions.success({ data: transformedResponse }));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default myTrialsListHttpRequest;
