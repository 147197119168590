import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const edcSummaryCardsHttpRequest = (() => {
  const apiName = 'edcSummaryCards';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/edc-summary-cards/site/:siteId/trial/:trialId`;
  return generatedHttpThunk.generateSiteTrialThunk();
})();

export default edcSummaryCardsHttpRequest;
