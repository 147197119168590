import { get, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { globalFilterSelector } from '../globalFilter.selector';
import { httpHashCompare } from '../httpComparison';

const useGlobalFilters = (filterItem = null) => {
  const globalFilters = useSelector(globalFilterSelector, httpHashCompare);
  if (isNil(filterItem)) return globalFilters;

  const itemKey = `globalActiveFilters.${filterItem}`;
  const itemValue = get(globalFilters, itemKey, undefined);

  return itemValue;
};

export default useGlobalFilters;
