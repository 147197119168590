import {
  gray1,
  gray2,
  ragGreen,
  ragRed,
  ragYellow,
} from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';
import { first, get, isNil, last, orderBy } from 'lodash';
import DateStringTransform from 'utility/date/dateString.utility';

import { SHORT_UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';

import { TrialSingularOrPlural } from 'utility/getPluralsOrSingular';
import { roundOff } from 'utility/math/valueRounder';
import { labelNullFormatter, valueNullFormatter } from 'utility/nullFormatter';

class Generator {
  constructor(data) {
    this._data = this._cleanData(data);
  }

  _cleanData(data) {
    if (!Array.isArray(data)) return [];
    const ragColors = ['green', 'amber', 'red', 'gray'];
    const sortedData = orderBy(data, ['date'], ['asc']);
    const removeNullValues = sortedData.filter(
      (ragData) => !isNil(ragData.date),
    );
    const formattedData = removeNullValues.map((ragData) => {
      const formattedDate = ragData.date.replace(/.{0,2}$/, '01');

      const formattedRagColorsData = ragColors.reduce((curr, ragColor) => {
        const countKey = `${ragColor}RagCount`;
        const percentageKey = `${ragColor}RagPercentage`;
        const count = ragData[countKey];
        const percentage = ragData[percentageKey];
        const formattedRagColorData = {
          [countKey]: roundOff(count, 1, null),
          [percentageKey]: roundOff(percentage, 1, null),
        };
        return { ...curr, ...formattedRagColorData };
      }, {});
      const updatedRagData = {
        ...ragData,
        ...formattedRagColorsData,
        date: formattedDate,
      };
      return updatedRagData;
    });
    return formattedData;
  }

  _generateCategories() {
    const categories = this._data.map((element) => {
      const formattedCategory = new DateStringTransform(element.date).formatter(
        SHORT_UI_DISPLAY_DATE,
      );
      return formattedCategory;
    });
    return categories;
  }

  _getTooltip() {
    const tooltip = {
      formatter() {
        const chart = this;
        const emptyRagDetails = {
          redRagCount: null,
          redRagPercentage: null,
          amberRagCount: null,
          amberRagPercentage: null,
          greenRagCount: null,
          greenRagPercentage: null,
          grayRagCount: null,
          grayRagPercentage: null,
        };
        const ragDetails = get(chart, 'point.custom', emptyRagDetails);

        Object.keys(ragDetails).forEach((key) => {
          if (isNil(ragDetails[key])) {
            ragDetails[key] = labelNullFormatter(get(ragDetails, key, null));
          }
        });

        const {
          redRagCount,
          redRagPercentage,
          amberRagCount,
          amberRagPercentage,
          greenRagCount,
          greenRagPercentage,
          grayRagCount,
          grayRagPercentage,
        } = ragDetails;

        const trialSingularOrPlural = new TrialSingularOrPlural();

        return `<p> 
        Red: ${redRagPercentage}% (${redRagCount}  ${trialSingularOrPlural.getWord(
          redRagCount,
        )})<br />
        Amber: ${amberRagPercentage}% (${amberRagCount} ${trialSingularOrPlural.getWord(
          amberRagCount,
        )})<br />
        Green: ${greenRagPercentage}% (${greenRagCount} ${trialSingularOrPlural.getWord(
          greenRagCount,
        )})<br />
        Gray: ${grayRagPercentage}% (${grayRagCount} ${trialSingularOrPlural.getWord(
          grayRagCount,
        )})<br />
        </p>`;
      },
      className: 'rag-overtime-tooltip',
      useHTML: true,
    };
    return tooltip;
  }

  _getChartData() {
    const chartData = {
      legend: {
        enabled: false,
      },
      plotOptions: {
        line: { marker: { symbol: 'circle' } },
      },
      credits: { enabled: false },
      title: { text: '' },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
      yAxis: {
        title: { text: '% trials' },
      },
      time: {
        useUTC: false,
      },
    };
    return chartData;
  }

  _getXAxis() {
    const xAxis = {
      type: 'datetime',
      tickInterval: 24 * 3600 * 1000 * 30,
      crosshair: true,
      visible: true,
      dateTimeLabelFormats: {
        month: '%b %Y',
      },
      title: {
        title: '',
      },
      labels: {
        format: '{value: %b %Y}',
        style: {
          ...styleComponent('sp'),
          color: gray2,
        },
      },
    };
    return xAxis;
  }

  _createSeriesData(data, key) {
    const seriesData = data.map((ragData) => {
      const countKey = `${key}RagCount`;
      const percentageKey = `${key}RagPercentage`;
      const transformedDate = new DateStringTransform().convertFormattedDate(
        ragData.date,
      );
      const formattedDate = +transformedDate;

      const seriesObject = {
        x: formattedDate,
        y: valueNullFormatter(ragData[percentageKey]),
        custom: {
          count: ragData[countKey],
          percentage: ragData[percentageKey],
          ...ragData,
        },
      };
      return seriesObject;
    });
    return seriesData;
  }

  _getSeries() {
    const lineSeriesConfig = {
      type: 'line',
      dashStyle: null,
      showInLegend: true,
      enableMouseTracking: true,

      marker: { enabled: true },
    };

    const amberRagSeries = {
      ...lineSeriesConfig,
      id: 'amberRagSeries',
      color: ragYellow,
      name: 'Amber',
      data: this._createSeriesData(this._data, 'amber'),
    };

    const greenRagSeries = {
      ...lineSeriesConfig,
      id: 'greenRagSeries',
      color: ragGreen,
      name: 'Green',
      data: this._createSeriesData(this._data, 'green'),
    };

    const redRagSeries = {
      ...lineSeriesConfig,
      id: 'redRagSeries',
      color: ragRed,
      name: 'Red',
      data: this._createSeriesData(this._data, 'red'),
    };

    const grayRagSeries = {
      ...lineSeriesConfig,
      id: 'grayRagSeries',
      color: gray1,
      name: 'Gray',
      data: this._createSeriesData(this._data, 'gray'),
    };

    const seriesData = [
      amberRagSeries,
      greenRagSeries,
      redRagSeries,
      grayRagSeries,
    ];
    return seriesData;
  }

  generate() {
    const chartData = this._getChartData();
    const seriesData = this._getSeries();
    const xAxis = this._getXAxis();
    const tooltip = this._getTooltip();
    const categories = this._generateCategories();
    const chartConfig = {
      ...chartData,
      categories,
      series: seriesData,
      xAxis,
      tooltip,
    };

    let minDate = null;
    let maxDate = null;
    if (!isNil(this._data)) {
      minDate = get(first(this._data), 'date', null);
      maxDate = get(last(this._data), 'date', null);
    }

    const transformedResponse = {
      chartData: chartConfig,
      tableData: this._data,
      minDate,
      maxDate,
    };
    return transformedResponse;
  }

  static transformData(data) {
    return new Generator(data).generate();
  }
}

export default Generator;
