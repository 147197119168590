import { black, gray2 } from 'assets/stylesheets/base/_colors';
import getContrastColour from 'assets/stylesheets/base/colorContrast';
import { get } from 'lodash';
import { roundOff } from 'utility/math';

export function queryAgingTooltipFormatter(chartType, showTotal) {
  function tooltipFormatter() {
    const secondaryCategory = get(this, 'point.metaData.dataItem.name');
    const seriesName = get(this.series, 'name', '');
    const seriesValue = this.y;

    const stackTotalValue = roundOff(
      get(this.point, 'metaData.totalValue', null),
      1,
    );
    const symbol = chartType === 'percentage' ? '%' : '';

    const seriesString = `<br/>${seriesName}: ${seriesValue} ${symbol}`;
    const totalString = `<br/>Total: ${stackTotalValue} ${symbol}`;

    let tooltipString = `<b>${secondaryCategory}</b>${seriesString}`;

    if (showTotal) tooltipString += totalString;
    return tooltipString;
  }
  return tooltipFormatter;
}

export function queryAgingLabelFormatter(chartType, isStackLabels, generator) {
  function formatter() {
    const symbol = chartType === 'percentage' ? '%' : '';
    const seriesValue = this.y;
    const displayValue = Math.abs(seriesValue / generator._maxTotal);
    if (isStackLabels && displayValue < 0.1) return '';
    const label = `${seriesValue}${symbol}`;
    const textColour = isStackLabels
      ? getContrastColour(this.color)
      : getContrastColour(black);

    const labelStyle = `color:${textColour}; font-size:10px;`;
    return `<p style='${labelStyle}'>${label}</p>`;
  }
  return formatter;
}

export function edcColumnTooltipFormatter() {
  function formatter() {
    const seriesName = get(this, 'point.label', null);
    const roundedOffSeriesValue = roundOff(this.y, 1);
    const seriesString = `<br/>${seriesName} <br/> <b>${roundedOffSeriesValue}</b>`;

    const tooltipString = `${seriesString}`;

    return tooltipString;
  }
  return formatter;
}

export function edcColumnLabelFormatter() {
  function formatter() {
    const labelStyle = `color:${gray2}; font-size: 10px; font-family: Open Sans`;

    const roundedOffValue = roundOff(this.y, 1);
    return `<span style='${labelStyle}'>${roundedOffValue}</span>`;
  }
  return formatter;
}
