import { gray3, aqua, gray2 } from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';
import DateStringTransform from 'utility/date/dateString.utility';
import { get, isNil } from 'lodash';

const COUNT_PERCENT_PRECISION = {
  count: 0,
  percent: 1,
};

class EdcLine {
  _legendStyleOptions = { ...styleComponent('cap'), color: gray2 };

  _xAxisKey = null;

  _yAxisKey = null;

  set xAxisKey(key) {
    if (typeof key === 'string') this._xAxisKey = key;
    else this._xAxisKey = '';
  }

  get xAxisKey() {
    return this._xAxisKey;
  }

  set yAxisKey(key) {
    if (typeof key === 'string') this._yAxisKey = key;
    else this._yAxisKey = '';
  }

  get yAxisKey() {
    return this._yAxisKey;
  }

  constructor(title) {
    this._title = title;
  }

  _getChartConfig(data) {
    const chartConfig = {
      tooltip: {
        shared: true,
        xDateFormat: '%B %Y',
      },
      chart: {
        height: 300,
      },
      yAxis: {
        title: {
          text: this._title,
        },
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen'],
          },
        },
      },
      legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'top',
        itemStyle: this._legendStyleOptions,
      },
      series: [
        {
          name: this._title,
          data: this._getSeriesData(data),
        },
      ],
    };

    return chartConfig;
  }

  _getXAxisOptions() {
    return {
      visible: true,
      crosshair: true,
      labels: {
        style: { color: gray3 },
      },
      dateTimeLabelFormats: {
        month: '%b %Y',
        day: '%b %Y',
        year: '%b %Y',
      },
      tickInterval: 24 * 3600 * 1000 * 30,
      type: 'datetime',
    };
  }

  _getPlotOptions(data) {
    const singlePoint = !isNil(data) && data.length === 1;

    return {
      series: {
        lineWidth: 3,
        marker: {
          enabled: singlePoint,
          symbol: 'circle',
        },
        label: {
          enabled: false,
        },
        color: aqua,
      },
    };
  }

  _getSeriesData(data) {
    return data.map((item) => {
      const yAxisValue = +get(item, this._yAxisKey, 0).toFixed(
        COUNT_PERCENT_PRECISION[this._yAxisKey],
      );
      const xAxisValue = +new DateStringTransform(get(item, this._xAxisKey))
        .dateObject;

      return {
        y: yAxisValue,
        x: xAxisValue,
      };
    });
  }

  generateChart(data) {
    const chartConfig = this._getChartConfig(data);
    const xAxisOptions = this._getXAxisOptions();
    const plotOptions = this._getPlotOptions(data);

    chartConfig.xAxis = xAxisOptions;
    chartConfig.plotOptions = plotOptions;

    return chartConfig;
  }
}

export default EdcLine;
