import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Bookmark from 'components/Bookmark/Bookmark';
import { compassStore } from 'store';
import { myTrialsListHttpRequest } from '../../../store/thunks';

function BookmarkTemplate(rowData) {
  const { protocolNumberHarmonized, isBookmarked } = rowData;

  const { dispatch } = compassStore;

  const onClose = useCallback((bookmarkCloseEvent) => {
    const { reload } = bookmarkCloseEvent;
    if (!reload) return;
    dispatch(myTrialsListHttpRequest());
  }, []);

  return (
    <Bookmark
      trialId={protocolNumberHarmonized}
      isBookmarked={isBookmarked.value}
      onClose={onClose}
    />
  );
}

BookmarkTemplate.propTypes = {
  isBookmarked: PropTypes.shape({
    label: PropTypes.bool,
    value: PropTypes.bool,
  }),
  protocolNumberHarmonized: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

BookmarkTemplate.defaultProps = {
  isBookmarked: { label: false, value: false },
};

export default BookmarkTemplate;
