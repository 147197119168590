/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { AxiosHttpError } from 'utility/errors';
import PropTypes from 'prop-types';
import { FALLBACK_ERROR_MESSAGE } from 'constants/error/clientError.constants';
import { errorIcon } from '../../assets/icons';

import '../universalComponents.scss';
import { SafeHtmlElements } from '../SafeComponents';

function RenderErrorPreview({ errorMessage, error, notFoundPreview }) {
  const errorMessageDisplay = error?.errorMessage;
  if (
    error instanceof AxiosHttpError &&
    error.statusCode === 404 &&
    notFoundPreview
  ) {
    return notFoundPreview;
  }

  let errorNode = (
    <SafeHtmlElements
      className="gray-message-text"
      tagName="p"
      placeholder={errorMessage}
    >
      {errorMessageDisplay}
    </SafeHtmlElements>
  );

  if (error instanceof AxiosHttpError) {
    errorNode = (
      <SafeHtmlElements className="gray-message-text" tagName="p">
        {errorMessageDisplay}
      </SafeHtmlElements>
    );
  }

  return (
    <div className="row universal-error" data-testid="error">
      <div className="col-12 py-3 d-flex justify-content-center">
        <img src={errorIcon} alt="Error" className="pe-2" />
        <div>{errorNode}</div>
      </div>
    </div>
  );
}

RenderErrorPreview.propTypes = {
  errorMessage: PropTypes.string,
  error: PropTypes.object.isRequired,
  notFoundPreview: PropTypes.node,
};

RenderErrorPreview.defaultProps = {
  errorMessage: FALLBACK_ERROR_MESSAGE,
  notFoundPreview: null,
};
export default RenderErrorPreview;
