class TransformationError extends Error {
  constructor(details) {
    super(TransformationError.errorCode);
    this.details = details;
    this.errorCode = 'TRANSFORMATION_ERROR';
  }
}

export const transformationFunctionWrapper = (transformationFunction) => {
  const errorHandledFunction = (...args) => {
    try {
      return transformationFunction(...args);
    } catch (error) {
      const transformationError = new TransformationError(error);
      throw transformationError;
    }
  };
  return errorHandledFunction;
};
export default TransformationError;
