import getStartupData from './03_startup';

const transform = (data) => {
  const startupData = getStartupData(data);
  const keyFactsData = data;
  return {
    startupData,
    keyFactsData,
  };
};
export default transform;
