import React, {
  memo,
  useCallback,
  useId,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Menu } from 'primereact/menu';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import useOnScroll from 'utility/hooks/useOnScroll';

const useElementWidth = (id) => {
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    const el = document.getElementById(id);
    setElementWidth(el?.offsetWidth || 0);
  }, [id]);

  return elementWidth;
};

function NavigationBarItem({ label, rootPath, dropdownItems }) {
  const id = useId();
  const location = useLocation();
  const menuRef = useRef(null);
  const [isDropdownOpening, setIsDropdownOpening] = useState(false);

  const isActive = rootPath && location.pathname.includes(rootPath);

  const hideMenu = useCallback(() => {
    const menuElement = menuRef.current.getTarget();
    if (menuElement) {
      menuRef.current.hide(menuRef.current);
    }
  }, []);

  useOnScroll(hideMenu);

  const parentWidth = useElementWidth(id);

  const hasDropdownMenu =
    Array.isArray(dropdownItems) && dropdownItems.length > 0;

  return (
    <>
      <div
        className="nav-menu-item"
        role="menuitem"
        tabIndex={0}
        aria-controls={id}
        id={id}
        onClick={(clickEvent) => {
          if (menuRef.current) {
            menuRef.current.toggle(clickEvent);
          }
        }}
      >
        <h4
          className={`${isActive ? 'active' : ''} ${
            isDropdownOpening ? 'open' : ''
          }`}
        >
          {label}
          {hasDropdownMenu && (
            <span
              className={`ms-1 p-submenu-icon pi pi-angle-down ${
                isDropdownOpening ? 'pi-angle-up' : 'pi-angle-down'
              }`}
            />
          )}
        </h4>
      </div>
      {hasDropdownMenu && (
        <Menu
          className="nav-menu-dropdown"
          model={dropdownItems}
          popup
          ref={menuRef}
          id={id}
          onShow={() => setIsDropdownOpening(true)}
          onHide={() => setIsDropdownOpening(false)}
          baseZIndex={10000}
          style={{
            minWidth: parentWidth,
          }}
        />
      )}
    </>
  );
}

NavigationBarItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  rootPath: PropTypes.string,
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
      className: PropTypes.string,
      command: PropTypes.func,
      template: PropTypes.func,
    }),
  ),
};
NavigationBarItem.defaultProps = {
  label: '',
  rootPath: '',
  dropdownItems: [],
};

export default memo(NavigationBarItem);
