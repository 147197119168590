import Axios from 'axios';

class TrialBookMarkHttpRequest {
  RESOURCE_PATH = `trial-management/bookmark/trial/:trialId`;

  _trialId = null;

  constructor(trialId) {
    this._trialId = trialId;
  }

  async mark() {
    const path = this.RESOURCE_PATH.replace(':trialId', this._trialId);
    const response = await Axios.post(path, {});
    return response;
  }

  async unMark() {
    const path = this.RESOURCE_PATH.replace(':trialId', this._trialId);
    const response = await Axios.delete(path, {});
    return response;
  }
}

export default TrialBookMarkHttpRequest;
