import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import siteStartupTransform from './utility/04_siteStartupTransform';

const siteStartupHttpRequest = (() => {
  const apiName = 'siteStartup';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/site/:siteId/trial/:trialId/startup`;
  generatedHttpThunk.transform = siteStartupTransform;
  return generatedHttpThunk.generateSiteTrialThunk();
})();

export default siteStartupHttpRequest;
