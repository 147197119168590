const METRICS_DATA_KEYS = [
  { metric: 'VARIANCE_TO_PLAN_LSI', key: 'varianceToPlanLSI' },
  { metric: 'FORECAST_LSI', key: 'forecastLSI' },
  {
    metric: 'PLANNED_LSI',
    key: 'plannedLSI',
  },
  {
    metric: 'TARGET_LSI',
    key: 'targetLSI',
  },
  {
    metric: 'STUDY_RAG',
    key: 'studyRag',
  },
  {
    metric: 'PLANNED_PATIENTS',
    key: 'plannedPatients',
  },
  {
    metric: 'PATIENTS_ENROLLED',
    key: 'patientsEnrolled',
  },
  {
    metric: 'MODEL_INPUT_SITES',
    key: 'modelInputSites',
  },
  {
    metric: 'MODEL_INPUT_SUBJECTS',
    key: 'modelInputSubjects',
  },
  {
    metric: 'FORECAST_LSI_10_PERCENTILE',
    key: 'forecastLSI10thPercentile',
  },
  {
    metric: 'FORECAST_LSI_99_PERCENTILE',
    key: 'forecastLSI99thPercentile',
  },
  {
    metric: 'RAG_DIRECTION',
    key: 'ragDirection',
  },
  {
    metric: 'FORECAST_LOWER_BOUND',
    key: 'forecastLowerBound',
  },
  {
    metric: 'FORECAST_UPPER_BOUND',
    key: 'forecastUpperBound',
  },
  {
    metric: 'FORECAST_LSI_RANGE_START',
    key: 'forecastLSIRangeStart',
  },
  {
    metric: 'FORECAST_LSI_RANGE_END',
    key: 'forecastLSIRangeEnd',
  },
  {
    metric: 'CONFIDENCE_INTERVAL',
    key: 'confidenceInterval',
  },
];
export default METRICS_DATA_KEYS;
