const BOLD = 700;
const SEMIBOLD = 600;
const REGULAR = 400;
const styleComponent = (component) => {
  const style = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
  };
  switch (component) {
    case 'h1': {
      return {
        ...style,
        fontSize: '24px',
        fontWeight: BOLD,
        lineHeight: '32px',
      };
    }
    case 'h2': {
      return {
        ...style,
        fontSize: '20px',
        fontWeight: BOLD,
        lineHeight: '28px',
      };
    }
    case 'h3': {
      return {
        ...style,
        fontSize: '16px',
        fontWeight: BOLD,
        lineHeight: '24px',
      };
    }
    case 'h4':
    case 'btn': {
      return {
        ...style,
        fontSize: '14px',
        fontWeight: SEMIBOLD,
        lineHeight: '20px',
      };
    }
    case 'tag': {
      return {
        ...style,
        fontSize: '12px',
        fontWeight: SEMIBOLD,
        lineHeight: '16px',
      };
    }
    case 'p': {
      return {
        ...style,
        fontSize: '14px',
        fontWeight: REGULAR,
        lineHeight: '20px',
      };
    }
    case 'link': {
      return {
        ...style,
        fontSize: '14px',
        fontWeight: BOLD,
        lineHeight: '20px',
      };
    }
    case 'sp': {
      return {
        ...style,
        fontSize: '12px',
        fontWeight: REGULAR,
        lineHeight: '16px',
      };
    }
    case 'cap': {
      return {
        ...style,
        fontSize: '10px',
        fontWeight: SEMIBOLD,
        lineHeight: '14px',
      };
    }
    case 'data': {
      return {
        ...style,
        fontSize: '32px',
        fontWeight: BOLD,
        lineHeight: '42px',
      };
    }
    default:
      return style;
  }
};
export default styleComponent;
