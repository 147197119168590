import { PERCENT_COUNT_TOGGLE_OPTIONS } from '../../../constants/trialDeliveryGoal.constants';

import {
  TDG_COMPLETED_CARD,
  TDG_PROJECTED_CARD,
  TDG_SECTION_CONTENT,
} from './03_tdgCard.constants';

const TDG_GPT_SECTION_CONTENT = {
  label: 'GPT trial delivery goals',
  key: 'GPT_TDG',
  value: TDG_SECTION_CONTENT,
  cards: [...TDG_COMPLETED_CARD, ...TDG_PROJECTED_CARD],
};

export const TDG_GPT_INITIAL_STATE = (() => {
  const { cards } = TDG_GPT_SECTION_CONTENT;
  const filters = {
    GROUP: TDG_GPT_SECTION_CONTENT.value.GROUP[1].value,
    DURATION: TDG_GPT_SECTION_CONTENT.value.GROUP[1].duration[0].value,
  };
  const cardSelection = { IS_ACTIVE: {}, ACTIVE_TOGGLE: {} };
  cards.forEach(({ key }) => {
    cardSelection.IS_ACTIVE[key] = true;
    cardSelection.ACTIVE_TOGGLE[key] = PERCENT_COUNT_TOGGLE_OPTIONS[0].value;
  });

  const initialState = {
    ...filters,
    ...cardSelection,
  };
  return initialState;
})();

export default TDG_GPT_SECTION_CONTENT;
