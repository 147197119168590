export const OKTA_GROUP_NAMES = {
  SPARK_DEV: 'G-AWS-GDO-SPARK-NON-PROD-R',
  SPARK_PROD: '',
  COMPASS: 'OKTA-COMPASS',
  COMPASS_DEV: 'OKTA-COMPASS-Dev',
};

export const APP_ENV = {
  development: 'development',
  test: 'test',
  production: 'production',
};

export const SPARK_DEV_ENV = 'https://spark-dev.onetakeda.com/spark/#/';
export const SPARK_TEST_ENV = 'https://spark-test.onetakeda.com/spark/#/';
export const SPARK_PROD_ENV = '';
