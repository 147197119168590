import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import DrilldownCycleTimesTransform from './transform/ragStatusSummary.transform';

const cycleTimesRagStatusTransform = (data) => {
  const transformedData = {
    cards: DrilldownCycleTimesTransform.transform(data),
  };
  return transformedData;
};

const cycleTimesRagStatusHttpRequest = (() => {
  const apiName = 'cycleTimesStatusByStageSummary';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/cycle-stage-charts`;
  generatedHttpThunk.transform = cycleTimesRagStatusTransform;
  return generatedHttpThunk.generateThunk();
})();

export default cycleTimesRagStatusHttpRequest;
