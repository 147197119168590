import { get, isNil } from 'lodash';
import QueryAging from './queryAgingChart.abstract';

function checkSort(value1, value2) {
  if (value1 === null && value2 === null) return 0;
  if (value1 === null) return -1;
  if (value2 === null) return 1;
  return value1 - value2;
}

function sortQueryAgingValues(values, p1, p2, p3, checkTotal = true) {
  const valuesSorted = values.sort((value1, value2) => {
    if (checkTotal) {
      const value1Total = Math.floor(value1[p1] + value1[p2] + value1[p3]);
      const value2Total = Math.floor(value2[p1] + value2[p2] + value2[p3]);
      const totalCompare = checkSort(value2Total, value1Total);
      if (totalCompare !== 0) {
        return totalCompare;
      }
    }
    const numQueriesGt28Compare = checkSort(value2[p1], value1[p1]);
    if (numQueriesGt28Compare !== 0) {
      return numQueriesGt28Compare;
    }
    const numQueries7to28Compare = checkSort(value2[p2], value1[p2]);
    if (numQueries7to28Compare !== 0) {
      return numQueries7to28Compare;
    }
    return checkSort(value2[p3], value1[p3]);
  });
  return valuesSorted;
}

function queryAgingScreening(values, p1, p2, p3, checkTotal = true) {
  const transformedData = sortQueryAgingValues(
    values,
    p1,
    p2,
    p3,
    checkTotal,
  ).map((value) => {
    const agingData = {
      name: get(value, 'siteName', ''),
      primaryCategoryName: get(value, 'siteNumberHarmonized', ''),
      primaryCategoryValues: [
        {
          secondaryCategory: 'Open 1-7 days',
          count: get(value, 'numQueries1to7', 0),
          percentage: get(value, 'percentQueries1to7', 0),
          name: get(value, 'siteName', ''),
        },
        {
          secondaryCategory: 'Open 8-28 days',
          count: get(value, 'numQueries7to28', 0),
          percentage: get(value, 'percentQueries7to28', 0),
          name: get(value, 'siteName', ''),
        },
        {
          secondaryCategory: 'Open > 28 days',
          count: get(value, 'numQueriesGt28', 0),
          percentage: get(value, 'percentQueriesGt28', 0),
          name: get(value, 'siteName', ''),
        },
      ],
    };
    return agingData;
  });
  return transformedData.filter((data) => data.primaryCategoryName !== '');
}

function stackedChartDataTransform(responseData, _, additionalParams) {
  const responseArrayPercentage = queryAgingScreening(
    get(responseData, 'values', []),
    'percentQueriesGt28',
    'percentQueries7to28',
    'percentQueries1to7',
    false,
  );
  const responseArrayCount = queryAgingScreening(
    get(responseData, 'values', []),
    'numQueriesGt28',
    'numQueries7to28',
    'numQueries1to7',
    true,
  );
  const groupBy = get(additionalParams, 'groupBy', null);

  // Generate Percentage Data
  const groupPercentage = new QueryAging('percentage');
  groupPercentage.yAxisLabel = '% Aging queries open';
  groupPercentage.xAxisLabel = 'Sites';
  groupPercentage.showTotal = !isNil(groupBy);
  const chartDataPercentage = groupPercentage.generateChart(
    responseArrayPercentage,
  );

  // Generate Count Data
  const groupCount = new QueryAging('count');
  groupCount.yAxisLabel = 'Aging queries open';
  groupCount.xAxisLabel = 'Sites';
  groupCount.showTotal = !isNil(groupBy);
  const chartDataCount = groupCount.generateChart(responseArrayCount);

  const returnData = {
    chartDataPercentage,
    chartDataCount,
    cardList: get(responseData, 'cardList', []),
  };
  return returnData;
}
export default stackedChartDataTransform;
