import { OktaAuth } from '@okta/okta-auth-js';
import config from '../oktaConfig';

const oktaAuth = new OktaAuth(config.oidc);

export default function getAccessToken() {
  const accessToken = oktaAuth.getAccessToken();
  if (!accessToken) {
    throw new Error('not logged in');
  }
  return accessToken;
}
