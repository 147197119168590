import {
  DATA_DURATION_OPTIONS_COMPLETED,
  DATA_DURATION_OPTIONS_PROJECTED,
} from '../../../constants/trialDeliveryGoal.constants';

export const CRITICAL_MILESTONES_COMPLETED_CARD = [
  {
    title: 'Clinical milestones completed on plan',
    key: 'CRITICAL_MILESTONES_COMPLETED_CARD_1',
  },
  {
    title: 'Median variance from plan',
    key: 'CRITICAL_MILESTONES_COMPLETED_CARD_2',
  },
];

export const CRITICAL_MILESTONES_PROJECTED_CARD = [
  {
    title: 'Clinical milestones with green RAG',
    key: 'CRITICAL_MILESTONES_PROJECTED_CARD_1',
  },
  {
    title: 'Median variance from plan',
    key: 'CRITICAL_MILESTONES_PROJECTED_CARD_2',
  },
];

export const CRITICAL_MILESTONES_SECTION_CONTENT = {
  GROUP: [
    {
      label: 'Completed',
      value: 'COMPLETED',
      duration: DATA_DURATION_OPTIONS_COMPLETED,
    },
    {
      label: 'Projected',
      value: 'PROJECTED',
      duration: DATA_DURATION_OPTIONS_PROJECTED,
    },
  ],
};
