import { get, isNil } from 'lodash';
import { CHART_TYPES } from './constant';

class EdcChartDataGenerator {
  #chartDataArray = null;

  #chartView = null;

  #chartType = null;

  #idFlags = { true: false, false: false };

  constructor(chartDataArray, chartView, chartType) {
    this.#chartView = chartView;
    this.#chartType = chartType;
    this.#chartDataArray = this.#filterNullValues(chartDataArray);
  }

  #filterNullValues(chartData) {
    return chartData.filter((data) => !isNil(data[this.#chartView]));
  }

  #getLowHighChartColumn(item, barHeight) {
    let isLow = true;
    if (item.status !== 'LOW') isLow = false;
    const modifiedItem = {
      ...item,
      isLow,
    };
    const isLinked = this.#idFlags[modifiedItem.isLow];
    let linkedTo = 'Not Specified';
    let id = 'Not Specified';
    let name = 'Not Specified';

    if (modifiedItem.isLow) {
      linkedTo = 'Low';
      id = 'Low';
      name = 'Low readiness site';
    } else if (!modifiedItem.isLow) {
      linkedTo = 'High';
      id = 'High';
      name = 'High or medium readiness site';
    }

    this.#idFlags[modifiedItem.isLow] = true;

    const siteName = get(item, 'siteName');
    const siteNumberHarmonized = get(item, 'siteNumberHarmonized');

    const barDetails = {
      y: barHeight,
      name: siteNumberHarmonized,
      label: siteName,
    };

    const barData = {
      ...modifiedItem,
      id: !isLinked ? id : null,
      name: !isLinked ? name : null,
      linkedTo: isLinked ? linkedTo : null,
      data: [barDetails],
    };
    return barData;
  }

  #getSitesChartColumn(item, barHeight) {
    const siteName = get(item, 'siteName');
    const siteNumberHarmonized = get(item, 'siteNumberHarmonized');

    const isLinked = this.#idFlags.true;

    this.#idFlags.true = true;

    const barDetails = {
      y: barHeight,
      name: siteNumberHarmonized,
      label: siteName,
    };

    const barData = {
      id: !isLinked ? 'Sites' : null,
      name: !isLinked ? 'All Sites' : null,
      linkedTo: isLinked ? 'Sites' : null,
      data: [barDetails],
    };
    return barData;
  }

  generate() {
    const chartData = this.#chartDataArray.map((item) => {
      const barHeight = get(item, this.#chartView);
      if (this.#chartType === CHART_TYPES.LOW_HIGH) {
        return this.#getLowHighChartColumn(item, barHeight);
      }
      return this.#getSitesChartColumn(item, barHeight);
    });
    return chartData;
  }
}

export default EdcChartDataGenerator;
