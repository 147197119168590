const addFileName = (chartData, filenameIdentifier, addDate = true) => {
  const dateString = new Date().toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  let filename = filenameIdentifier;

  if (addDate) filename = `${dateString}_${filename}`;

  return {
    ...chartData,

    exporting: {
      ...(chartData ? chartData.exporting : {}),
      filename,
    },
  };
};

export default addFileName;
