// import { aqua, gray3, gray4 } from 'assets/stylesheets/base/_colors';
import { aqua, gray3 } from 'assets/stylesheets/base/_colors';
import Generator from './generator';

export default class EnrolmentCurveGenerator extends Generator {
  static _lineChartConfig = {
    yAxis: 0,
    type: 'line',
    dashStyle: null,
    showInLegend: true,
    enableMouseTracking: true,
    customTags: ['enrollment'],
  };

  getEnrollmentSeriesData(objectArray) {
    const forecastedKey = 'forecastEnrollmentMidQuantile';
    const originalPlanKey = 'origPlanEnrolledCum';
    const currentPlanKey = 'currPlanEnrolledCum';
    const actualPlanKey = 'actualEnrolledCum';

    const enrolmentSeriesData = {
      originalPlan: this._getSeriesData(objectArray, originalPlanKey),
      currentPlan: this._getSeriesData(objectArray, currentPlanKey),
      actual: this._getSeriesData(objectArray, actualPlanKey),
      forecast: this._getSeriesData(objectArray, forecastedKey),
    };

    return enrolmentSeriesData;
  }

  getSeries(objectArray) {
    const lineSeriesData = this.getEnrollmentSeriesData(objectArray);

    // For COMPASSINS-1778: "Removing original plan"
    // const originalSeries = {
    //   id: 'originalEnrollment',
    //   name: 'Enrollment (Original Plan)',
    //   data: lineSeriesData.originalPlan,
    //   color: gray4,
    //   marker: { enabled: lineSeriesData.originalPlan.length === 1 },
    //   ...EnrolmentCurveGenerator._lineChartConfig,
    // };

    const plannedSeries = {
      id: 'plannedEnrollment',
      name: 'Enrollment (Current Plan)',
      data: lineSeriesData.currentPlan,
      color: gray3,
      marker: { enabled: lineSeriesData.currentPlan.length === 1 },
      ...EnrolmentCurveGenerator._lineChartConfig,
    };

    const actualAndForecastedSeries = {
      id: 'actualEnrollment',
      name: 'Enrollment (Actual / forecast)',
      data: lineSeriesData.actual,
      color: aqua,
      marker: { enabled: lineSeriesData.actual.length === 1 },
      ...EnrolmentCurveGenerator._lineChartConfig,
    };

    const enrolmentSeries = [
      // originalSeries,
      plannedSeries,
      actualAndForecastedSeries,
    ];

    return enrolmentSeries;
  }
}
