import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardRoute from 'pages/routes/routes';
import { LoginCallback } from '@okta/okta-react';
import useScrollToTopOnRouteChange from 'utility/hooks/useScrollToTopOnRouteChange';
import useAnalytics from 'utility/hooks/useAnalytic';
import SecureRoute from '../../components/okta/SecureRoute';
import NavigationBar from '../../section/01_NavigationBarSection/NavigationBar.section';
import './_dashboard.scss';
import errorComponent from './utility/errorComponent';

function Dashboards() {
  useScrollToTopOnRouteChange();
  /*
  Commenting for future reference
  const navigate = useNavigate();
  
  const restoreOriginalUri = async (_, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  */

  useAnalytics();
  return (
    <div>
      <Routes>
        <Route
          path="/login/callback"
          element={<LoginCallback errorComponent={errorComponent} />}
        />
      </Routes>

      <SecureRoute>
        <div className="row compass-nav-holder">
          <NavigationBar />
        </div>
        <div className="row compass-content" data-testid="content-root">
          <div className="col-12">
            <DashboardRoute />
          </div>
        </div>
      </SecureRoute>
    </div>
  );
}

export default Dashboards;
