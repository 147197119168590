import { roundOff } from './valueRounder';

const getSafePercentageValue = (
  numerator,
  denominator,
  isRoundedOff = false,
) => {
  let percentageValue;
  if (!denominator || !numerator || denominator === 0) {
    percentageValue = null;
    return percentageValue;
  }
  const originalPercentage = (numerator / denominator) * 100;
  const roundedOffPercentage = roundOff(originalPercentage);
  percentageValue = isRoundedOff ? roundedOffPercentage : originalPercentage;
  return percentageValue;
};

export default getSafePercentageValue;
