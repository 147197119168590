import { ragRed, gray5, aqua } from 'assets/stylesheets/base/_colors';
import { sortBy, get, isNil } from 'lodash';
import { roundOff } from 'utility/math/valueRounder';
import generateSortComparatorWithNull from 'utility/generateSortComparatorWithNull';

class SiteDrillDownGenerator {
  _isDormantBasedBarColor = false;

  get isDormantBasedBarColor() {
    return this._isDormantBasedBarColor;
  }

  set isDormantBasedBarColor(isDormantBased) {
    this._isDormantBasedBarColor = isDormantBased;
  }

  _getBarColor = (value) => {
    let color = gray5;
    if (this._isDormantBasedBarColor) {
      if (value === undefined) color = aqua;
      else if (value === true) color = ragRed;
      else if (value === false) color = aqua;
    } else {
      color = aqua;
    }
    return color;
  };

  generateSeries(dataArray, dataKey) {
    const series = dataArray.reduce((validData, element) => {
      const siteName = get(element, 'siteName.label', '');
      const dataValue = get(element, `${dataKey}.value`, null);
      const barHeight =
        typeof dataValue === 'number' ? roundOff(dataValue, 2) : null;
      const barColor = this._getBarColor(
        get(element, 'isDormant.value', gray5),
      );
      const dormantStatus = get(element, 'isDormant.value', null);
      if (!isNil(barHeight)) {
        const item = {
          name: siteName,
          y: barHeight,
          color: barColor,
          isDormant: dormantStatus,
        };
        return [...validData, item];
      }
      return validData;
    }, []);
    const isAcsOrderSort = false;
    const sortedSeries = sortBy(
      series,
      generateSortComparatorWithNull('y', isAcsOrderSort),
    );
    return sortedSeries;
  }
}

export default SiteDrillDownGenerator;
