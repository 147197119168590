import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const trialActivationDriversDataHttpRequest = (() => {
  const apiName = 'trialActivationDrivers';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/activation-drivers/trial/:trialId`;
  return generatedHttpThunk.generateTrialThunk();
})();

export default trialActivationDriversDataHttpRequest;
