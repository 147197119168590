import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import transform from './utility/03_siteKeyFactsTransform';

const siteKeyFactsHttpRequest = (() => {
  const apiName = 'siteKeyFacts';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/site/:siteId/trial/:trialId/key-facts`;
  generatedHttpThunk.transform = transform;
  return generatedHttpThunk.generateSiteTrialThunk();
})();

export default siteKeyFactsHttpRequest;
