import { round } from 'lodash';

/**
@description A utility function to round off the given value to the specified precision.

@param {number|null} value - The value to be rounded off.
@param {number} precisionDigits - The number of digits to which the value should be rounded off.
@param {string} errorPlaceHolder - The string to be returned in case of error or when the value is null.
@param {boolean} applyDefault - A boolean indicating whether to use default rounding if the absolute value is less than 0.1.
@returns {number} - The rounded off value if successful, otherwise errorPlaceHolder.
*/
export const roundOff = (
  value = null,
  precisionDigits = 0,
  errorPlaceHolder = 'NA',
) => {
  if (value === null) {
    return errorPlaceHolder;
  }
  const valueToBeRounded = Number(value);
  if (Number.isNaN(valueToBeRounded)) {
    return errorPlaceHolder;
  }
  return round(valueToBeRounded, precisionDigits);
};

export const roundAllValuesInArray = (
  dataArray,
  precision = 1,
  placeholder = null,
) => {
  let returnArray = [];
  if (Array.isArray(dataArray))
    returnArray = dataArray.map((dataPoint) =>
      roundOff(dataPoint, precision, placeholder),
    );
  return returnArray;
};
