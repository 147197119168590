import { gray1, gray2, gray3 } from '../../../assets/stylesheets/base/_colors';
import styleComponent from '../../../assets/stylesheets/base/_typography';

function relocateDatalabel() {
  const chart = this;
  chart.series[0].points.forEach((point) => {
    if (point.target) {
      const dataLabel = point.dataLabels[1];
      const target = point.targetGraphic;
      const x = dataLabel.translateX + 32;
      const y = target.getBBox().y - dataLabel.getBBox().height + 15;
      dataLabel.translate(x, y);
    }
  });
}

const protocolDeviationsOptions = {
  chart: {
    height: 270,
    events: {
      load() {
        relocateDatalabel.call(this);
      },
      redraw() {
        relocateDatalabel.call(this);
      },
    },
  },
  colors: [gray2],
  legend: {
    enabled: false,
  },
  yAxis: {
    title: {
      text: null,
      style: {
        ...styleComponent('sp'),
        color: gray3,
      },
    },
    labels: {
      style: {
        ...styleComponent('sp'),
        color: gray1,
      },
    },
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      pointWidth: 32,
      targetOptions: {
        width: 36,
        color: gray3,
      },
      dataLabels: [
        {
          enabled: true,
          style: {
            color: gray2,
            ...styleComponent('sp'),
          },
        },
        {
          enabled: true,
          formatter() {
            return this.point.target;
          },
          style: {
            color: gray2,
            ...styleComponent('sp'),
          },
        },
      ],
    },
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: null,
  },
  xAxis: {
    title: {
      text: 'Deviation category',
      style: {
        ...styleComponent('sp'),
        color: gray3,
        padding: 8,
      },
    },
    labels: {
      style: {
        ...styleComponent('sp'),
        color: gray1,
      },
    },
    type: 'category',
  },
  tooltip: {
    enabled: false,
  },
};
export default protocolDeviationsOptions;
