import { N_A } from 'dashboards/TrialManagement/components/ProtocolAmendments/constants/timeline';
import { get, map } from 'lodash';
import { UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';

const dateFormatter = (dateString) => {
  if (dateString === null) {
    return N_A;
  }
  return new DateStringTransform(dateString).formatter(UI_DISPLAY_DATE);
};

export const getTopRationales = (countryData) => {
  const latestAmendmentRationale = [];
  const separatorContent = ', ';
  const firstRationale = get(countryData, '[0].primaryRationale[0]', '');
  const secondRationale = get(countryData, '[1].primaryRationale[0]', '');
  const thirdRationale = get(countryData, '[2].primaryRationale[0]', '');
  if (firstRationale) {
    latestAmendmentRationale.push(
      `${firstRationale} (${countryData[0].value})`,
    );
  }
  if (secondRationale) {
    latestAmendmentRationale.push(
      `${secondRationale} (${countryData[1].value})`,
    );
  }
  if (thirdRationale) {
    latestAmendmentRationale.push(
      `${thirdRationale} (${countryData[2].value})`,
    );
  }
  if (!latestAmendmentRationale.length) {
    latestAmendmentRationale.push(N_A);
  }
  return latestAmendmentRationale.join(separatorContent);
};

const amendmentTableTransform = (rawTableDataArray) => {
  const amendmentDataArray = rawTableDataArray.filter(
    ({ type }) => type === 'AMENDMENT',
  );
  const amendmentTableFormattedData = amendmentDataArray.map((tableElement) => {
    let type = N_A;
    if (tableElement.substantial !== null) {
      type = tableElement.substantial ? 'Substantial' : 'Non-substantial';
    }

    const amendmentName = tableElement.amendmentNumber;
    const submission = dateFormatter(tableElement.date);
    const countries = map(tableElement.countries, 'value').join(', ');
    const latestAmendmentRationale = getTopRationales(tableElement.countries);
    const amendmentTableFormattedRow = {
      amendmentName,
      type,
      submission,
      submissionDate: tableElement.date,
      countries,
      latestAmendmentRationale,
    };
    return amendmentTableFormattedRow;
  });
  return amendmentTableFormattedData;
};

const transform = (timelineViewData) => {
  const amendmentTableData = amendmentTableTransform(timelineViewData);
  const timelineViewChartData = timelineViewData;
  return {
    amendmentTableData,
    timelineViewChartData,
  };
};

export default transform;
