import { CARD_ATTRIBUTES } from 'dashboards/PortfolioManagement/constants/protocolAmendments.constants';
import { merge } from 'lodash';
import groupPaToFirstAmendmentMediansData from './chart/paToFirstAmendmentMedians';
import { getColumnChartDataPoints } from './simpleBar';
import getColumnChartGeneratorFn from './simpleBarDrillDown';
import { getStackedChartDataPoints } from './stackedBar';
import getStackedChartGeneratorFn from './stackedBarDrillDown';
import getAmendmentData from './table/amendmentData';
import getMedianData from './table/medianDateToFirstAmendment';
import getMostFrequentReasonsData from './table/mostFrequentReasonsData';

const transform = (data) => {
  const protocolAmendmentCardData = data;

  const groupedPaToFirstAmendmentMedians = groupPaToFirstAmendmentMediansData(
    data.medianDateToFirstAmendment.drilldown.paToFirstAmendmentMedians,
  );

  const drillDownData = {
    averageAmendments: {
      chart: {
        generatorFn: getColumnChartGeneratorFn,
        data: getColumnChartDataPoints(
          CARD_ATTRIBUTES.AVG.dataKey,
          data,
          'Average',
        ),
      },
      tableData: getAmendmentData(CARD_ATTRIBUTES.AVG.dataKey, data),
    },
    substantialAmendments: {
      tableData: getAmendmentData(CARD_ATTRIBUTES.SUBSTANTIAL.dataKey, data),
      current: {
        chart: {
          generatorFn: getColumnChartGeneratorFn,
          data: getColumnChartDataPoints(
            CARD_ATTRIBUTES.SUBSTANTIAL.dataKey,
            data,
            'Substantial',
          ),
        },
      },
      percentage: {
        chart: {
          generatorFn: getColumnChartGeneratorFn,
          data: getColumnChartDataPoints(
            CARD_ATTRIBUTES.SUBSTANTIAL.dataKey,
            data,
            'Substantial',
            'metricPercentageValue',
            true,
          ),
        },
      },
    },
    preFSIAmendments: {
      tableData: getAmendmentData(CARD_ATTRIBUTES.PRE_FSI.dataKey, data),
      current: {
        chart: {
          generatorFn: getColumnChartGeneratorFn,
          data: getColumnChartDataPoints(
            CARD_ATTRIBUTES.PRE_FSI.dataKey,
            data,
            'Pre-FSI',
          ),
        },
      },
      percentage: {
        chart: {
          generatorFn: getColumnChartGeneratorFn,
          data: getColumnChartDataPoints(
            CARD_ATTRIBUTES.PRE_FSI.dataKey,
            data,
            'Pre-FSI',
            'metricPercentageValue',
            true,
          ),
        },
      },
    },
    mostFrequentReasons: {
      chart: {
        generatorFn: getStackedChartGeneratorFn,
        data: getStackedChartDataPoints(
          CARD_ATTRIBUTES.PRIMARY_REASONS.dataKey,
          data,
        ),
      },
      tableData: getMostFrequentReasonsData(
        CARD_ATTRIBUTES.PRIMARY_REASONS.dataKey,
        data,
      ),
    },
    medianDateToFirstAmendment: {
      chart: {
        generatorFn: (_data, cbFunction, selectedCategory) => {
          const extendedConfig = {
            chart: {
              scrollablePlotArea: {
                minWidth: null,
              },
              marginBottom: 80,
            },
            legend: {
              itemWidth: null,
            },
            xAxis: {
              labels: {
                formatter() {
                  return `${this.value}d`;
                },
              },
            },
            yAxis: {
              title: {
                text: 'Trials',
              },
            },
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: false,
                },
                groupPadding: 0,
                borderWidth: 1,
                pointPadding: 0,
                pointWidth: null,
                shadow: false,
                maxPointWidth: 220,
              },
              series: {
                pointWidth: null,
              },
            },
            title: {
              y: 15,
            },
          };
          const finalConfig = merge(
            getColumnChartGeneratorFn(_data, cbFunction, selectedCategory),
            extendedConfig,
          );
          return finalConfig;
        },
        data: {
          categories: groupedPaToFirstAmendmentMedians.map((item) => item.name),
          data: groupedPaToFirstAmendmentMedians.map((item) => item.value),
          legendLabel: 'Median time',
          xLabel: 'Days from PA to first amendment',
          isPercentage: false,
        },
      },
      tableData: getMedianData(CARD_ATTRIBUTES.MEDIAN_TIME.dataKey, data),
    },
  };

  return {
    drillDownData,
    protocolAmendmentCardData,
  };
};
export default transform;
