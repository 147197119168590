import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const siteInfoKeyFactsHttpRequest = (() => {
  const apiName = 'siteInfoKeyFacts';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/site/:siteId`;
  return generatedHttpThunk.generateSiteThunk();
})();

export default siteInfoKeyFactsHttpRequest;
