import {
  STAGE_LABELS,
  STARTUP_STATISTICS_LABEL,
  STARTUP_STATISTICS_UNITS,
} from 'constants/site-health/startup.constants';
import { get } from 'lodash';
import { UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';

export const getSiteStages = (siteStageData) =>
  siteStageData.map((siteState) => ({
    label: STAGE_LABELS[siteState.stage],
    formattedDate: siteState.date
      ? new DateStringTransform(siteState.date).formatter(UI_DISPLAY_DATE)
      : null,
  }));

export const pluralizeMonth = (monthString) => {
  if (!monthString) {
    return null;
  }
  const baseString = STARTUP_STATISTICS_UNITS;
  const monthNumber = parseInt(monthString, 10);
  const suffix = monthNumber > 1 ? 's' : '';
  return `${baseString}${suffix}`;
};

export const getStartupStatistics = (timeToActivate, approvalTime) => [
  {
    label: STARTUP_STATISTICS_LABEL.TIME_TO_ACTIVATE,
    dateValue: timeToActivate,
    dateUnits: pluralizeMonth(timeToActivate),
  },
  {
    label: STARTUP_STATISTICS_LABEL.APPROVAL_TIME,
    dateValue: approvalTime,
    dateUnits: pluralizeMonth(approvalTime),
  },
];

const getStartupData = (data) => {
  const startupData = get(data, 'startUp');
  const rawSiteStageData = get(startupData, 'siteStages', []);
  const timeToActivate = get(startupData, 'timeToActivate', null);
  const approvalTime = get(startupData, 'approvalTime', null);
  const siteStages = getSiteStages(rawSiteStageData);
  const startupStatistics = getStartupStatistics(timeToActivate, approvalTime);
  return {
    siteStages,
    startupStatistics,
  };
};

export default getStartupData;
