import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const edcSiteHeaderHttpRequest = (() => {
  const apiName = 'edcSiteHeader';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = 'site-health/edc-header/trial/:trialId';
  return generatedHttpThunk.generateSiteTrialThunk();
})();

export default edcSiteHeaderHttpRequest;
