import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { APPLICATION_VERSION } from 'constants/appVersion.constant';
import { generateHttpReducer } from '../utility';
import compassReducer from './reducers/reducer';

const compassStore = configureStore({
  reducer: combineReducers({
    uiState: compassReducer,
    http: combineReducers({
      savedFilters: combineReducers({
        listSavedFilters: generateHttpReducer('listSavedFilters'),
        createSavedFilters: generateHttpReducer('createSavedFilters'),
        deleteSavedFilters: generateHttpReducer('deleteSavedFilters'),
      }),
      moduleAccess: generateHttpReducer('moduleAccess'),
      myTrialsList: generateHttpReducer('myTrialsList'),
      dataSources: generateHttpReducer('dataSources'),
      trialListFilters: generateHttpReducer('trialListFilters'),
    }),
  }),
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== APPLICATION_VERSION.ENV.PROD,
});

export default compassStore;
