// import { aqua, gray3, gray4 } from 'assets/stylesheets/base/_colors';
import { aqua, gray3 } from 'assets/stylesheets/base/_colors';
import Generator from './generator';

export default class ActivationBarGenerator extends Generator {
  static _barChartConfig = {
    yAxis: 1,
    type: 'column',
    stacking: null,
    customTags: ['site_activation'],
  };

  _getActivationSeriesData(objectArray) {
    const originalPlanKey = 'origPlanSitesActivatedCum';
    const currentPlanKey = 'currPlanSitesActivatedCum';
    const actualPlanKey = 'actualSitesActivatedCum';

    const activationSeriesData = {
      originalPlan: this._getSeriesData(objectArray, originalPlanKey),
      currentPlan: this._getSeriesData(objectArray, currentPlanKey),
      actual: this._getSeriesData(objectArray, actualPlanKey),
    };
    return activationSeriesData;
  }

  getSeries(objectArray) {
    const columnSeriesData = this._getActivationSeriesData(objectArray);

    // For COMPASSINS-1778: "Removing original plan"
    // const originalPlanSeries = {
    //   name: 'Activations (Original Plan)',
    //   id: 'forecastedSiteActivation',
    //   data: columnSeriesData.originalPlan,
    //   color: gray4,
    //   ...ActivationBarGenerator._barChartConfig,
    // };

    const currentPlanSeries = {
      name: 'Activations (Current Plan)',
      id: 'currentSiteActivation',
      data: columnSeriesData.currentPlan,
      color: gray3,
      ...ActivationBarGenerator._barChartConfig,
    };

    const actualPlanSeries = {
      name: 'Activations (Actual)',
      id: 'actualSiteActivation',
      data: columnSeriesData.actual,
      color: aqua,
      ...ActivationBarGenerator._barChartConfig,
    };

    const activationSeries = [
      // originalPlanSeries,
      currentPlanSeries,
      actualPlanSeries,
    ];

    return activationSeries;
  }
}
