import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import transform from './transform/performanceOverTimeRag.transform';
import { addFileName } from '../../../../../utility';

const cycleTimesPerformanceOverTimeTransform = (data, requestParams) => {
  const { time } = requestParams;
  const transformedData = {
    chartData: addFileName(
      transform(data, time === 'yearly'),
      'cycleStagePerformanceOverTime',
    ),
    tableData: data,
  };
  return transformedData;
};

const performanceOverTimeCalendarRagHttpRequest = (() => {
  const apiName = 'cycleTimesPerformanceOverTimeCalendarRagView';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/over-time-rag-status`;
  generatedHttpThunk.transform = cycleTimesPerformanceOverTimeTransform;
  return generatedHttpThunk.generateThunk();
})();

export default performanceOverTimeCalendarRagHttpRequest;
