import { STAGE_LABELS } from 'constants/site-health/startup.constants';
import { get, sortBy, find } from 'lodash';
import { white, gray3 } from 'assets/stylesheets/base/_colors';
import getChartData from 'dashboards/SiteHealth/store/thunks/06_enrollmentMetrics/utility/chart';

const getMetricData = (data) => {
  const metricData = get(data, 'metrics', []);
  const trialMedianQuery = { metricName: 'TIME_TO_ACTIVATION_TRIAL_MEDIAN' };
  const trialMedianItem = find(metricData, trialMedianQuery);
  const trialMedianVal = get(trialMedianItem, 'metricValue', null);
  const currentQuery = { metricName: 'TIME_TO_ACTIVATION' };
  const currentItem = find(metricData, currentQuery);
  const currentVal = get(currentItem, 'metricValue', null);
  const numberChartData = getChartData(currentVal, trialMedianVal);
  const numberChartObject = {
    metricName: 'TIME_TO_ACTIVATION_NUMBER_CHART',
    metricValue: numberChartData,
    metricUnit: null,
  };
  metricData.push(numberChartObject);
  return metricData;
};

const transformTimelineData = (timelineDataArray, type) => {
  let isMilestonesSelected = false;
  if (type === 'milestones') isMilestonesSelected = true;
  const transformedDataArray = timelineDataArray.map((timelineData) => {
    let transformedObject = {};
    const milestoneName = get(timelineData, 'name', null);
    const metricName = get(timelineData, 'metricName', null);
    const stageLabelMetricName = get(STAGE_LABELS, metricName, null);
    const metricDate = timelineData.metricValue || timelineData.value;
    if (isMilestonesSelected) {
      transformedObject = {
        stageType: 'diamond',
        label: milestoneName,
        stageBorder: timelineData.ragColour || gray3,
        stageBg: white,
        isDotted: false,
      };
    } else {
      transformedObject = {
        stageType: 'box',
        label: stageLabelMetricName,
      };
    }
    transformedObject.formattedDate = metricDate;
    return transformedObject;
  });
  return transformedDataArray;
};

const getTimelineData = (data) => {
  const timelineData = get(data, 'timeline', []);
  const timelineMetrics = get(timelineData, 'metrics', []);
  const timelineMilestones = get(timelineData, 'milestones', []);
  const timelineMetricsTransformed = transformTimelineData(
    timelineMetrics,
    'metrics',
  );
  const timelineMilestonesTransformed = transformTimelineData(
    timelineMilestones,
    'milestones',
  );
  const combinedTimeline = [
    ...timelineMetricsTransformed,
    ...timelineMilestonesTransformed,
  ];
  const sortedData = sortBy(combinedTimeline, 'formattedDate');
  return sortedData;
};

const siteStartupTransform = (data) => {
  const startupMetricData = getMetricData(data);
  const startupTimelineData = getTimelineData(data);
  return {
    startupMetricData,
    startupTimelineData,
  };
};

export default siteStartupTransform;
