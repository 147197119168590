export const LEARNING_SITE_FROM_EDC =
  'https://mytakeda.sharepoint.com/sites/GDO/SitePages/EDC-Readiness.aspx';
export const LEARNING_SITE_FROM_HELP =
  'https://mytakeda.sharepoint.com/sites/GDO/SitePages/COMPASS-Intro.aspx';
export const LEARNING_SITE_FROM_RAG =
  'https://mytakeda.sharepoint.com/sites/GDO/SitePages/RAG-Definition.aspx';
export const LEARNING_SITE_FROM_ENROLMENT =
  'https://mytakeda.sharepoint.com/sites/GDO/SitePages/Enrollment-Forecasting.aspx';
export const LEARNING_SITE_TRUST_CENTER =
  'https://mytakeda.sharepoint.com/sites/GDO/SitePages/Trust-Center.aspx';
export const LEARNING_SITE_CYCLE_TIME =
  'https://mytakeda.sharepoint.com/sites/Central-PMO/SitePages/Cycle-Time.aspx';
export const LEARNING_SITE_ASPIRE = 'https://aspire.onetakeda.com/';
