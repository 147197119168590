import {
  PortfolioThunkGenerator,
  SiteThunkGenerator,
  SiteTrialThunkGenerator,
  TrialThunkGenerator,
} from './thunkGenerator';
import ThunkGenerator from './thunkGenerator/thunkGenerator.abstract';

export default class GeneratedHttpThunk extends ThunkGenerator {
  _resource = '';

  _secondaryResource = '';

  _isLogging = false;

  _syntheticResponse = null;

  _isGlobalFiltering = true;

  _transform = (data) => data;

  _globalFilterSelector = () => ({});

  constructor(name) {
    super();
    this._name = name;
  }

  _logger(...args) {
    if (!this._isLogging) return;
    const prefix = [' 📡 ', 'Thunk |=>', this._name];
    const loggingItems = [...prefix, ...args];
    // eslint-disable-next-line no-console
    console.log(...loggingItems);
  }

  generatePortfolioThunk() {
    const portfolioThunkGenerator = new PortfolioThunkGenerator(this._name);
    portfolioThunkGenerator.resource = this._resource;
    portfolioThunkGenerator.isLogging = this._isLogging;
    portfolioThunkGenerator.transform = this._transform;
    portfolioThunkGenerator.syntheticResponse = this._syntheticResponse;
    portfolioThunkGenerator.globalFilterSelector = this._globalFilterSelector;
    return portfolioThunkGenerator.generateThunk();
  }

  generateTrialThunk() {
    const trialThunkGenerator = new TrialThunkGenerator(this._name);
    trialThunkGenerator.resource = this._resource;
    trialThunkGenerator.secondaryResource = this._secondaryResource;
    trialThunkGenerator.isLogging = this._isLogging;
    trialThunkGenerator.transform = this._transform;
    trialThunkGenerator.syntheticResponse = this._syntheticResponse;
    trialThunkGenerator.globalFilterSelector = this._globalFilterSelector;
    return trialThunkGenerator.generateThunk();
  }

  generateSiteTrialThunk() {
    const siteTrialThunkGenerator = new SiteTrialThunkGenerator(this._name);
    siteTrialThunkGenerator.resource = this._resource;
    siteTrialThunkGenerator.isLogging = this._isLogging;
    siteTrialThunkGenerator.transform = this._transform;
    siteTrialThunkGenerator.syntheticResponse = this._syntheticResponse;
    siteTrialThunkGenerator.globalFilterSelector = this._globalFilterSelector;
    return siteTrialThunkGenerator.generateThunk();
  }

  generateSiteThunk() {
    const siteThunkGenerator = new SiteThunkGenerator(this._name);
    siteThunkGenerator.resource = this._resource;
    siteThunkGenerator.isLogging = this._isLogging;
    siteThunkGenerator.transform = this._transform;
    siteThunkGenerator.syntheticResponse = this._syntheticResponse;
    siteThunkGenerator.globalFilterSelector = this._globalFilterSelector;
    return siteThunkGenerator.generateThunk();
  }
}
