import PropTypes from 'prop-types';
import React from 'react';
import { isNil } from 'lodash';
import { NOT_AVAILABLE_PLACEHOLDER } from 'constants';
import {
  enableTrendIcon,
  getCommaData,
  getMagnitudeClass,
  getRoundedMagnitude,
  setTrendIconAndClass,
} from './utility/common';

function DisplayMeasurement(props) {
  const { magnitude, unit, precision } = props;
  const { tagName, placeholder, separator } = props;
  const { className, onClick, dataTestId, key } = props;

  const { isTrendingUp, showTrendIcon, isBoxed, showColor } = props;
  const { isTrendDynamic, dynamicTrendArrows } = props;
  const { enableAddCommas } = props;
  const { fixedTrailingPoints } = props;

  const TagName = tagName;

  const roundedMagnitude = getRoundedMagnitude(
    magnitude,
    precision,
    fixedTrailingPoints,
  );

  const magnitudeClass = getMagnitudeClass(roundedMagnitude);

  const { trendIcon, displayMeasurementClassName } = setTrendIconAndClass({
    isTrendDynamic,
    magnitudeClass,
    dynamicTrendArrows,
    className,
    isBoxed,
    isTrendingUp,
    showColor,
  });

  const absoluteRoundedMagnitude = Math.abs(roundedMagnitude);
  const displayedMagnitude = showColor
    ? absoluteRoundedMagnitude
    : roundedMagnitude;

  if (isNil(roundedMagnitude) && !isNil(unit)) {
    return (
      <TagName
        className={displayMeasurementClassName}
        onClick={onClick}
        data-testid={dataTestId}
        key={key}
      >
        <span className="magnitude placeholder">{placeholder}</span>
        <span className="separator">{separator}</span>
        <span className="unit">{unit}</span>
      </TagName>
    );
  }
  if (!isNil(roundedMagnitude) && isNil(unit)) {
    return (
      <TagName
        className={displayMeasurementClassName}
        onClick={onClick}
        data-testid={dataTestId}
        key={key}
      >
        {enableTrendIcon({ showTrendIcon, trendIcon, displayedMagnitude }) && (
          <img
            src={trendIcon}
            alt={magnitudeClass}
            className="align-self-center"
          />
        )}
        <span className="magnitude">
          {getCommaData(enableAddCommas, displayedMagnitude)}
        </span>
      </TagName>
    );
  }
  if (isNil(roundedMagnitude) && isNil(unit)) {
    return (
      <TagName
        className={displayMeasurementClassName}
        onClick={onClick}
        data-testid={dataTestId}
        key={key}
      >
        <span className="magnitude placeholder">{placeholder}</span>
      </TagName>
    );
  }
  return (
    <TagName
      className={displayMeasurementClassName}
      onClick={onClick}
      data-testid={dataTestId}
      key={key}
    >
      {enableTrendIcon({ showTrendIcon, trendIcon, displayedMagnitude }) && (
        <img
          src={trendIcon}
          alt={magnitudeClass}
          className="align-self-center"
        />
      )}
      <span className="magnitude">
        {getCommaData(enableAddCommas, displayedMagnitude)}
      </span>
      <span className="separator">{separator}</span>
      <span className="unit">{unit}</span>
    </TagName>
  );
}

DisplayMeasurement.propTypes = {
  magnitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  precision: PropTypes.number,
  unit: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  separator: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  key: PropTypes.string,
  showTrendIcon: PropTypes.bool,
  isBoxed: PropTypes.bool,
  showColor: PropTypes.bool,
  isTrendingUp: PropTypes.bool,
  tagName: PropTypes.string,
  isTrendDynamic: PropTypes.bool,
  dynamicTrendArrows: PropTypes.objectOf(
    PropTypes.shape({
      positive: PropTypes.string,
      negative: PropTypes.string,
      grey: PropTypes.string,
    }),
  ),
  enableAddCommas: PropTypes.bool,
  fixedTrailingPoints: PropTypes.number,
};
DisplayMeasurement.defaultProps = {
  magnitude: null,
  unit: null,
  precision: 1,
  placeholder: NOT_AVAILABLE_PLACEHOLDER,
  className: '',
  separator: <>&nbsp;</>,
  onClick: null,
  dataTestId: '',
  key: '',
  showTrendIcon: false,
  isBoxed: false,
  isTrendingUp: null,
  showColor: false,
  tagName: 'p',
  isTrendDynamic: false,
  dynamicTrendArrows: null,
  enableAddCommas: false,
  fixedTrailingPoints: null,
};
export default DisplayMeasurement;
