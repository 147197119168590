import { get } from 'lodash';
import {
  ragRed,
  white,
  ragGreen,
  ragYellow,
  black,
  aqua,
  gray1,
  gray3,
  aqua40,
  purple,
  purple40,
  aqua20,
  aqua60,
  blue,
  blue40,
  gray4,
  gray2,
  blackLabel,
} from './_colors';

const contrastMap = {
  [ragRed]: white,
  [ragGreen]: white,
  [ragYellow]: black,
  [aqua]: white,
  [gray1]: white,
  [gray2]: white,
  [gray3]: white,
  [aqua40]: black,
  [purple]: white,
  [purple40]: black,
  [blue]: white,
  [blue40]: black,
  [gray4]: black,
  [white]: black,
  [aqua20]: blackLabel,
  [aqua60]: blackLabel,
};

const getContrastColour = (bgColour) => {
  const contrastColour = get(contrastMap, bgColour, black);
  return contrastColour;
};
export default getContrastColour;
