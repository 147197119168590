import { get, sortBy, orderBy } from 'lodash';
import EdcColumn from 'utility/generateHighChart/columnChart/EdcColumn.abstract';
import EdcChartDataGenerator from '../utility/chartData.transform';

const modifiedChartsAndSideCardData = (
  data,
  chartType,
  countChartDataKey,
  percentChartDataKey,
) => {
  const responseArray = get(data, 'values', []);

  const count = new EdcChartDataGenerator(
    responseArray,
    countChartDataKey,
    chartType,
  ).generate();

  const percentage = new EdcChartDataGenerator(
    responseArray,
    percentChartDataKey,
    chartType,
  ).generate();

  return {
    count,
    percentage,
    cardList: get(data, 'cardList', []),
  };
};

const generatedCountChartData = (
  countGroupColKey,
  countYAxisLabel,
  modifiedCountData,
) => {
  const countGroupPercentage = new EdcColumn(countGroupColKey);
  countGroupPercentage.yAxisLabel = countYAxisLabel;
  countGroupPercentage.xAxisLabel = 'Sites';
  const sortedCountData = sortBy(modifiedCountData, (site) =>
    get(site, 'data[0].y', null),
  );
  const countChartData = countGroupPercentage.generateChart(sortedCountData);

  return countChartData;
};

const generatedPercentChartData = (
  percentGroupColKey,
  percentYAxisLabel,
  modifiedPercentData,
) => {
  const percentGroupPercentage = new EdcColumn(percentGroupColKey);
  percentGroupPercentage.yAxisLabel = percentYAxisLabel;
  percentGroupPercentage.xAxisLabel = 'Sites';
  const sortedPercentageData = orderBy(
    modifiedPercentData,
    [
      (site) => get(site, 'data[0].y', null),
      (siteName) => get(siteName, 'data[0].label', null),
    ],
    ['asc, asc'],
  );

  const percentChartData =
    percentGroupPercentage.generateChart(sortedPercentageData);

  return percentChartData;
};

const crfsCountPercentTranform = (
  modifiedData,
  countYAxisLabel,
  percentYAxisLabel,
  countGroupColKey,
  percentGroupColKey,
) => {
  const countChartData = generatedCountChartData(
    countGroupColKey,
    countYAxisLabel,
    modifiedData.count,
  );
  const percentChartData = generatedPercentChartData(
    percentGroupColKey,
    percentYAxisLabel,
    modifiedData.percentage,
  );

  return {
    countChartData,
    percentChartData,
    cardList: modifiedData.cardList,
  };
};

export { modifiedChartsAndSideCardData, crfsCountPercentTranform };
