export const DIVERSITY_FILTER_OPTIONS = {
  COUNTRY_DROPDOWN: [
    { label: 'All countries', value: 'ALL' },
    { label: 'USA', value: 'USA' },
  ],

  VIEW_TYPE: [
    { label: 'Count', value: 'COUNT' },
    { label: 'Percentage', value: 'PERCENTAGE' },
  ],
};

export const PLACEHOLDER_EXPORT = null;
