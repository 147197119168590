import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const edcHeaderHttpRequest = (() => {
  const apiName = 'edcHeader';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-header/trial/:trialId`;
  return generatedHttpThunk.generateTrialThunk();
})();

export default edcHeaderHttpRequest;
