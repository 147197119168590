import { isNil, sortBy } from 'lodash';
import { DaySingularOrPlural } from 'utility/getPluralsOrSingular';
import { roundOff } from 'utility/math/valueRounder';
import {
  UI_DISPLAY_DATE,
  SHORT_UI_DISPLAY_DATE,
} from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';

const transform = (longitudinalRagData) => {
  const daySingularOrPlural = new DaySingularOrPlural();
  const longitudinalRag = longitudinalRagData;

  const getVarianceText = (varianceDays, plannedDate, forecastDate) => {
    if (isNil(varianceDays) || isNil(plannedDate) || isNil(forecastDate))
      return null;
    const roundedOffVariance = roundOff(varianceDays, 1);
    const absoluteVariance = Math.abs(roundedOffVariance);
    const targetDifference = plannedDate >= forecastDate;
    const singleOrPluralDay = daySingularOrPlural.getWord(roundedOffVariance);
    const targetText = targetDifference
      ? `${absoluteVariance} ${singleOrPluralDay} ahead`
      : `${absoluteVariance} ${singleOrPluralDay} delay`;
    return targetText;
  };

  let tableData = longitudinalRag.map((rowData) => ({
    ...rowData,
    trialMonth: new DateStringTransform(rowData.trialMonth).formatter(
      SHORT_UI_DISPLAY_DATE,
    ),
    trialMonthDate: rowData.trialMonth,
    plannedDateModified: new DateStringTransform(rowData.plannedDate).formatter(
      UI_DISPLAY_DATE,
    ),
    forecastDateModified: new DateStringTransform(
      rowData.forecastDate,
    ).formatter(UI_DISPLAY_DATE),
    varianceDays: getVarianceText(
      rowData.varianceToPlan,
      rowData.plannedDate,
      rowData.forecastDate,
    ),
    modifiedReplanDate: new DateStringTransform(rowData.replanDate).formatter(
      UI_DISPLAY_DATE,
    ),
  }));
  tableData = sortBy(tableData, [(rowValue) => rowValue.trialMonthDate]);

  const returnData = { tableData };

  return returnData;
};

export default transform;
