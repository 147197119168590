import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import crfFrozenTransform from './utility/crfFrozen.transform';
import crfSortTransform from '../../__utility__/crfSort.transform';

export const crfFrozenTableHttpRequest = (() => {
  const apiName = 'crfFrozenTable';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource =
    'trial-management/edc-crfs-frozen-table/trial/:trialId';
  generatedHttpThunk.transform = crfSortTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export const crfFrozenChartHttpRequest = (() => {
  const apiName = 'crfsFrozenChart';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource =
    'trial-management/edc-crfs-frozen-chart/trial/:trialId';
  generatedHttpThunk.transform = crfFrozenTransform;
  return generatedHttpThunk.generateTrialThunk();
})();
