import { gray2, gray3, gray4, purple60 } from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';
import generateScrollbarEventHighchart from 'utility/highcharts/generateScrollbarEventHighchart';
import { roundOff } from 'utility/math';
import { get, isNil, map } from 'lodash';
import { performanceOvertTimeBoxTooltipFormatter } from './utility/formatter';

const transform = (data, isYearly) => {
  const records = get(data, 'records', []);
  const romeTarget = get(data, 'metadata.romeTarget', []);

  const boxData = records.map((record) => {
    const box = [
      record.min,
      record['25th'],
      record.median,
      record['75th'],
      record.max,
    ];
    return box;
  });

  const cycleTime = records.map((record) => ({
    ...record,
    y: roundOff(record.median, 1, null),
  }));

  const xAxisLabels = map(records, (record) => {
    const year = record.year ? roundOff(record.year, 1) : 'N/A';
    const quarter = record.quarter ? ` Q${roundOff(record.quarter, 1)}` : '';
    if (isYearly) {
      return year;
    }
    return `${year}${quarter}`;
  });
  const chartData = {
    tooltip: {
      useHTML: true,
    },
    exporting: {
      sourceWidth: !isNil(records) ? 120 * records.length : 990,
      sourceHeight: 500,
      scale: 1,
    },
    chart: {
      type: 'boxplot',
      events: {
        ...generateScrollbarEventHighchart(5),
      },
    },

    title: { text: '' },

    legend: { enabled: false },

    xAxis: { categories: xAxisLabels },

    yAxis: {
      title: { text: 'Cycle time (days)' },
      plotLines: [
        {
          value: romeTarget,
          color: purple60,
          width: 1,
          label: {
            text: `ROME target: ${roundOff(romeTarget, 1)} days`,
            align: 'left',
            style: {
              color: gray3,
              ...styleComponent('caption'),
            },
          },
        },
      ],
    },

    series: [
      {
        type: 'boxplot',
        name: 'Observations',
        data: boxData,
        color: gray2,
        medianWidth: 3,
        fillColor: gray4,
        lineWidth: 3,
        pointWidth: 50,
        tooltip: {
          headerFormat: '{point.key}<br>',
          pointFormatter: performanceOvertTimeBoxTooltipFormatter,
        },
      },
      {
        type: 'line',
        color: gray2,
        label: { enabled: false },
        marker: { symbol: 'diamond' },
        dataLabels: { enabled: false },
        name: 'Median cycle time',
        data: cycleTime,
      },
    ],
  };
  return chartData;
};

export default transform;
