import { gray3, aqua } from 'assets/stylesheets/base/_colors';
import { get } from 'lodash';
import DrillDown from './01_drilldown.abstract';

class PartDrillDown extends DrillDown {
  _partData = [];

  _xAxisCategories = [];

  _chartText = '';

  _rateChartType = null;

  _countChartType = null;

  get chartText() {
    return this._chartText;
  }

  set chartText(chartText) {
    this._chartText = chartText;
  }

  get rateChartType() {
    return this._rateChartType;
  }

  set rateChartType(chartType) {
    this._rateChartType = chartType;
  }

  get countChartType() {
    return this._countChartType;
  }

  set countChartType(chartType) {
    this._countChartType = chartType;
  }

  get partData() {
    return this._partData;
  }

  set partData(partData) {
    this._partData = partData;
  }

  _generateByPartSeries(dataKey, chartType) {
    const actualPlan = [];
    const planToDate = [];
    const selectedCard = this._selectedCard;
    const isRateKey = dataKey === `${selectedCard}Rate`;
    this._partData.forEach((eachPart) => {
      const actualRateData = get(eachPart, 'actualEnrollmentRate.value', null);
      const actualCountData = get(eachPart, 'actualEnrolledCount.value', null);
      const plannedRateData = get(eachPart, `${chartType}.value`, null);
      const plannedCountData = get(eachPart, `${chartType}.value`, null);
      const actualData = isRateKey ? actualRateData : actualCountData;
      const partName = eachPart.partName.value;
      const plannedData = isRateKey ? plannedRateData : plannedCountData;
      this._xAxisCategories.push(partName);
      actualPlan.push(actualData);
      planToDate.push(plannedData);
    });
    const chartSeriesData = [
      {
        name: 'Actual',
        data: actualPlan,
        color: aqua,
      },
      {
        name: this._chartText,
        data: planToDate,
        color: gray3,
      },
    ];
    return chartSeriesData;
  }

  _generateYAxis(dataKey) {
    const yAxis = {
      title: {
        text: this._generateYAxisTitle(dataKey),
      },
    };
    return yAxis;
  }

  _generatePartHighChart(dataKey, chartType) {
    const chart = {
      type: 'column',
      scrollablePlotArea: {
        minWidth: 30 * this._partData.length,
      },
      marginRight: 50,
    };
    const yAxis = this._generateYAxis(dataKey);
    const xAxis = {
      categories: this._xAxisCategories,
      title: {
        text: 'Parts',
      },
      crosshair: true,
    };
    const series = this._generateByPartSeries(dataKey, chartType);
    const chartData = {
      chart,
      accessibility: PartDrillDown._accessibility,
      title: PartDrillDown._title,
      xAxis,
      yAxis,
      legend: PartDrillDown._legend,
      tooltip: { enabled: false },
      exporting: { enabled: false },
      series,
    };
    return chartData;
  }

  generateGraph() {
    const highchartByRate = this._generatePartHighChart(
      this._rateKey,
      this._rateChartType,
    );
    const highchartByCount = this._generatePartHighChart(
      this._countKey,
      this._countChartType,
    );
    const graphs = {
      rate: highchartByRate,
      currentNumber: highchartByCount,
    };
    return graphs;
  }
}

export default PartDrillDown;
