import portfolioFiltersDataHttpRequest from './01_portfolioFilters.thunk';
import protocolAmendmentsDataHttpRequest from './05_protocolAmendments/protocolAmendments.thunk';

import trialPerformanceSummaryHttpRequest from './04_trialPerformance/trialSummary.thunk';
import activationsEnrollmentsToPlanHttpRequest from './04_trialPerformance/02_ActivationsEnrollmentsToPlan/activationEnrollmentsToPlan.thunk';

import numTrialsHttpRequest from './10_numTrials.thunk';
import cycleTimesFiltersHttpRequest from './06_cycleTimes/cycleTimes.filters.thunk';
import cycleTimesPerformanceByTrial from './06_cycleTimes/performanceByTrial.thunk';
import cycleTimesPerformanceByTrialCalendarHttpRequest from './06_cycleTimes/performanceByTrialCalendar.thunk';

import {
  listSavedFiltersHttpRequest,
  deleteSavedFiltersHttpRequest,
} from '../../../../store/thunks';

import { userTrialsFilterCheckHttpRequest } from '../../../TrialManagement/store/thunks';
import distributionByGroupDrilldownHttpRequest from './15_PortfolioDiversity/05_DistributionByGroupDrilldown.thunk';
import studyRagOverTimeHttpRequest from './04_trialPerformance/03_StudyRagOverTime/studyRagOverTime.thunk';

import gptGoalsSummaryDataHttpRequest from './08_TrialDeliveryGoals/02_gptGoalsSummary.thunk';
import clinicalMilestonesDataHttpRequest from './08_TrialDeliveryGoals/03_clinicalMilestones.thunk';
import gptGoalsDataHttpRequest from './08_TrialDeliveryGoals/04_gptGoals.thunk';
import clinicalMilestonesSummaryDataHttpRequest from './08_TrialDeliveryGoals/01_clinicalMilestonesSummary.thunk';
import cycleTimesRagStatusHttpRequest from './06_cycleTimes/ragStatus.thunk';
import distributionByGroupHttpRequest from './15_PortfolioDiversity/01_DistributionByGroup.thunk';
import raceEnrollmentFunnelHttpRequest from './15_PortfolioDiversity/02_RaceEnrollmentFunnel.thunk';
import raceTrendOverTimeCalendarHttpRequest from './15_PortfolioDiversity/03_RaceTrendOverTimeCalendar.thunk';
import raceTrendOverTimeHttpRequest from './15_PortfolioDiversity/04_RaceTrendOverTime.thunk';
import performanceOverTimeCalendarBoxHttpRequest from './06_cycleTimes/performanceOverTimeCalendar.box.thunk';
import performanceOverTimeCalendarRagHttpRequest from './06_cycleTimes/performanceOverTimeCalendar.rag.thunk';

export {
  portfolioFiltersDataHttpRequest,
  protocolAmendmentsDataHttpRequest,
  trialPerformanceSummaryHttpRequest,
  cycleTimesFiltersHttpRequest,
  cycleTimesPerformanceByTrialCalendarHttpRequest,
  performanceOverTimeCalendarBoxHttpRequest,
  performanceOverTimeCalendarRagHttpRequest,
  listSavedFiltersHttpRequest,
  deleteSavedFiltersHttpRequest,
  userTrialsFilterCheckHttpRequest,
  numTrialsHttpRequest,
  activationsEnrollmentsToPlanHttpRequest,
  distributionByGroupDrilldownHttpRequest,
  studyRagOverTimeHttpRequest,
  cycleTimesPerformanceByTrial,
  gptGoalsSummaryDataHttpRequest,
  clinicalMilestonesSummaryDataHttpRequest,
  clinicalMilestonesDataHttpRequest,
  gptGoalsDataHttpRequest,
  cycleTimesRagStatusHttpRequest,
  distributionByGroupHttpRequest,
  raceEnrollmentFunnelHttpRequest,
  raceTrendOverTimeCalendarHttpRequest,
  raceTrendOverTimeHttpRequest,
};

export const API_LIST = [
  portfolioFiltersDataHttpRequest,
  protocolAmendmentsDataHttpRequest,
  trialPerformanceSummaryHttpRequest,
  cycleTimesFiltersHttpRequest,
  numTrialsHttpRequest,
  activationsEnrollmentsToPlanHttpRequest,
  distributionByGroupDrilldownHttpRequest,
  gptGoalsSummaryDataHttpRequest,
  clinicalMilestonesSummaryDataHttpRequest,
  clinicalMilestonesDataHttpRequest,
  gptGoalsDataHttpRequest,
  cycleTimesRagStatusHttpRequest,
  distributionByGroupHttpRequest,
  raceEnrollmentFunnelHttpRequest,
  raceTrendOverTimeCalendarHttpRequest,
  raceTrendOverTimeHttpRequest,
];
