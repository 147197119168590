import { gray1, gray3 } from 'assets/stylesheets/base/_colors';

const drawViewIcon = (color) => {
  const svgString = `
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.855 2.64501L9.355 0.145008C9.30828 0.0986677 9.25287 0.062005 9.19195 0.0371227C9.13103 0.0122404 9.0658 -0.000372089 9 8.35715e-06H1C0.734784 8.35715e-06 0.48043 0.105365 0.292893 0.292902C0.105357 0.480438 0 0.734792 0 1.00001V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8947 0.734784 12 1 12H11C11.2652 12 11.5196 11.8947 11.7071 11.7071C11.8946 11.5196 12 11.2652 12 11V3.00001C12.0004 2.93421 11.9878 2.86897 11.9629 2.80806C11.938 2.74714 11.9013 2.69173 11.855 2.64501ZM4 1.00001H8V3.00001H4V1.00001ZM8 11H4V7.00001H8V11ZM9 11V7.00001C9 6.73479 8.89464 6.48044 8.70711 6.2929C8.51957 6.10537 8.26522 6.00001 8 6.00001H4C3.73478 6.00001 3.48043 6.10537 3.29289 6.2929C3.10536 6.48044 3 6.73479 3 7.00001V11H1V1.00001H3V3.00001C3 3.26523 3.10536 3.51958 3.29289 3.70712C3.48043 3.89465 3.73478 4.00001 4 4.00001H8C8.26522 4.00001 8.51957 3.89465 8.70711 3.70712C8.89464 3.51958 9 3.26523 9 3.00001V1.20501L11 3.20501V11H9Z" fill="${color}"/>
  </svg>
  
`;

  return `data:image/svg+xml;base64,${window.btoa(svgString)}`;
};

export const saveIcon = drawViewIcon(gray1);
export const saveIconDisabled = drawViewIcon(gray3);
