import { cloneDeep, get, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { jsonCompare } from '../httpComparison';

const useSectionState = (sectionId, itemKey = null) => {
  const sectionConfiguration = useSelector((currentState) => {
    const { preferencesConfigMap } = currentState.uiState;
    const preferencesConfig = isNil(sectionId)
      ? preferencesConfigMap
      : get(preferencesConfigMap, sectionId, {});

    if (isNil(itemKey)) return cloneDeep(preferencesConfig);

    const itemValue = get(preferencesConfig, itemKey, undefined);
    return cloneDeep(itemValue);
  }, jsonCompare);

  return sectionConfiguration;
};

export default useSectionState;
