import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { APPLICATION_VERSION } from 'constants/appVersion.constant';
import { generateHttpReducer } from '../../../utility';
import trialManagementReducer from './reducers/reducer';

const trialManagementStore = configureStore({
  reducer: combineReducers({
    uiState: trialManagementReducer,
    http: combineReducers({
      trialListFilters: generateHttpReducer('trialListFilters'),
      userTrialsFilterCheck: generateHttpReducer('userTrialsFilterCheck'),
      trialList: generateHttpReducer('trialList'),
      trialCardList: generateHttpReducer('trialCardList'),
      trialDeliveryGoalsPage: combineReducers({
        trialMilestone: generateHttpReducer('trialMilestone'),
        gptTrialDeliveryGoals: generateHttpReducer('gptTrialDeliveryGoals'),
      }),
      trialKeyFacts: generateHttpReducer('trialKeyFacts'),
      ragStatusDescription: generateHttpReducer('ragStatusDescription'),
      enrollmentCurve: generateHttpReducer('enrollmentCurve'),
      milestones: generateHttpReducer('milestones'),
      venders: generateHttpReducer('venders'),
      roster: generateHttpReducer('roster'),
      cycleTimes: generateHttpReducer('cycleTimes'),
      risksAndMitigation: generateHttpReducer('risksAndMitigation'),
      enrollmentDrivers: combineReducers({
        summary: generateHttpReducer('enrollmentDrivers-summary'),
        screening: generateHttpReducer('enrollmentDriversScreening'),
        screenFailure: generateHttpReducer('enrollmentDriversScreenFailure'),
        dropout: generateHttpReducer('enrollmentDriversDropout'),
        completion: generateHttpReducer('enrollmentDriversCompletion'),
        activationDetail: generateHttpReducer(
          'enrollmentDriversActivationDetail',
        ),
        activation: generateHttpReducer('enrollmentDriversActivation'),
        enrollment: generateHttpReducer('enrollmentDriversEnrollment'),
        trialEnrollmentDrivers: generateHttpReducer('trialEnrollmentDrivers'),
        trialActivationDrivers: generateHttpReducer('trialActivationDrivers'),
      }),
      edcReadiness: combineReducers({
        edcSummaryCards: generateHttpReducer('edcSummaryCards'),
        edcHeader: generateHttpReducer('edcHeader'),
        edcDataEntry: combineReducers({
          crfEntryTimeChart: generateHttpReducer('crfEntryTimeChart'),
          crfEnteredTable: generateHttpReducer('crfEnteredTable'),
          crfEntryTimeTable: generateHttpReducer('crfEntryTimeTable'),
          crfEnteredChart: generateHttpReducer('crfEnteredChart'),
        }),
        edcDataCleaning: combineReducers({
          queryStatusChart: generateHttpReducer('queryStatusChart'),
          queryStatusTable: generateHttpReducer('queryStatusTable'),
          queryAgingChart: generateHttpReducer('queryAgingChart'),
          queryAgingTable: generateHttpReducer('queryAgingTable'),
          queryResolutionTimeChart: generateHttpReducer(
            'queryResolutionTimeChart',
          ),
          queryResolutionTimeTable: generateHttpReducer(
            'queryResolutionTimeTable',
          ),
          crfsVerifiedChart: generateHttpReducer('crfsVerifiedChart'),
          crfsVerifiedTable: generateHttpReducer('crfsVerifiedTable'),
        }),
        edcPrelock: combineReducers({
          crfsSignedChart: generateHttpReducer('crfsSignedChart'),
          crfsFrozenTable: generateHttpReducer('crfFrozenTable'),
          crfsFrozenChart: generateHttpReducer('crfsFrozenChart'),
          crfsSignedTable: generateHttpReducer('crfsSignedTable'),
        }),
        edcSidePanel: generateHttpReducer('edcSidePanel'),
      }),
      protocolAmendments: combineReducers({
        timelineView: generateHttpReducer('protocolAmendmentsTimelineView'),
        summary: generateHttpReducer('protocolAmendmentsSummary'),
        topReasons: generateHttpReducer('protocolAmendmentsTopReasons'),
      }),
      enrollmentSummary: generateHttpReducer('enrollmentSummary'),
      siteList: generateHttpReducer('siteList'),
      complexMilestones: generateHttpReducer('complexMilestones'),
      enrollmentForecastSummary: generateHttpReducer(
        'enrollmentForecastSummary',
      ),
      savedFilters: combineReducers({
        listSavedFilters: generateHttpReducer('listSavedFilters'),
        createSavedFilters: generateHttpReducer('createSavedFilters'),
        deleteSavedFilters: generateHttpReducer('deleteSavedFilters'),
      }),
      bookmark: combineReducers({
        bookmarkTrials: generateHttpReducer('bookmarkTrials'),
        unBookmarkTrials: generateHttpReducer('unBookmarkTrials'),
      }),
      trialDataSources: generateHttpReducer('trialDataSources'),
      longitudinalRag: generateHttpReducer('longitudinalRag'),
      siteLeaderboard: generateHttpReducer('siteLeaderboard'),
      gptGoalsSidebar: generateHttpReducer('gptGoalsSidebar'),
      trialDiversity: generateHttpReducer('trialDiversity'),
      numTrials: generateHttpReducer('numTrials'),
    }),
  }),
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== APPLICATION_VERSION.ENV.PROD,
});

export default trialManagementStore;
