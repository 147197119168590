import { get } from 'lodash';

export const ERROR_MESSAGES = {
  400: 'Unable to process the request at the moment',
  401: 'Your are not authorized to access this application. Please try to login again',
  403: 'Your access is not authorized',
  404: 'No data available',
  500: 'Server encountered an error. Please try again after some time',
  502: 'The server encountered an error. Please try again after some time',
  503: 'The server encountered an error. Please try again after some time',
  504: 'Service took too long to respond. Please try again after some time',
};

class AxiosHttpError extends Error {
  constructor(details) {
    super(AxiosHttpError.errorCode);

    const statusCode = get(details, 'response.status', 500);

    this.details = details;
    this.ts = +new Date();
    this.errorCode = 'HTTP_ERROR';
    this.statusCode = statusCode;
    this.errorMessage = ERROR_MESSAGES[statusCode];
  }
}

export default AxiosHttpError;
