import { find } from '@okta/okta-auth-js';
import getContrastColour from 'assets/stylesheets/base/colorContrast';
import {
  gray3,
  aqua40,
  gray4,
  gray1,
  aqua,
  purple,
  purple40,
  blue,
  blue40,
  white,
} from 'assets/stylesheets/base/_colors';
import styleComponent from 'assets/stylesheets/base/_typography';
import { flattenDeep, get, map, max, sortBy, sum, sumBy, uniq } from 'lodash';
import { labelNullFormatter, valueNullFormatter } from 'utility/nullFormatter';
import { roundOff } from 'utility/math';
import generateSortComparatorWithNull from 'utility/generateSortComparatorWithNull';
import generateScrollbarEventHighchart from 'utility/highcharts/generateScrollbarEventHighchart';

class StackedColumn {
  _legendStyleOptions = { ...styleComponent('cap'), color: gray1 };

  _CHART_CONFIGURATION = {
    title: { text: '' },
    chart: {
      events: {
        ...generateScrollbarEventHighchart(5),
      },
      type: 'column',
    },

    legend: {
      enabled: true,
      align: 'left',
      verticalAlign: 'top',
      margin: 30,
      itemStyle: this._legendStyleOptions,
    },
  };

  isStacked = true;

  _isStackLabels = false;

  _xAxisLabel = null;

  _yAxisLabel = null;

  _colorList = [aqua, gray3, aqua40, gray4, purple, purple40, blue, blue40];

  #isLogging = false;

  #sortByOrderKey = '';

  #sortByOrderList = [];

  #showTotal = true;

  get sortByOrderList() {
    return this.#sortByOrderList;
  }

  set sortByOrderList(list) {
    this.#sortByOrderList = list;
  }

  get sortByOrderKey() {
    return this.#sortByOrderKey;
  }

  set sortByOrderKey(key) {
    this.#sortByOrderKey = key;
  }

  #getSeriesInOrder(series) {
    const findCaseInsensitiveIndex = (array, target) => {
      const targetLower =
        typeof target === 'string' ? target.toLowerCase() : target;
      for (let index = 0; index < array.length; index += 1) {
        const currentLower = array[index].toLowerCase();
        if (currentLower === targetLower) {
          return index;
        }
      }
      return -1;
    };

    const sortInOrderComparator = (nextObject, prevObject) => {
      const nextValue = get(nextObject, this.#sortByOrderKey);
      const prevValue = get(prevObject, this.#sortByOrderKey);
      const nextValueIndex = findCaseInsensitiveIndex(
        this.#sortByOrderList,
        nextValue,
      );
      const prevValueIndex = findCaseInsensitiveIndex(
        this.#sortByOrderList,
        prevValue,
      );

      return nextValueIndex - prevValueIndex;
    };

    const sortedObj = sortBy(series, sortInOrderComparator);
    return sortedObj;
  }

  #sortSeries(series) {
    const isAcsOrderSort = false;
    const sortSeriesDesc = sortBy(
      series,
      generateSortComparatorWithNull('seriesTotal', isAcsOrderSort),
    );
    const sortSeriesInOrder = this.#getSeriesInOrder(sortSeriesDesc);
    return sortSeriesInOrder;
  }

  set colorList(colorList) {
    this._colorList = colorList;
  }

  get colorList() {
    return this._colorList;
  }

  set isStackLabels(value) {
    this._isStackLabels = typeof value === 'boolean' ? value : false;
  }

  get isStackLabels() {
    return this._isStackLabels;
  }

  set xAxisLabel(name) {
    if (typeof name === 'string') this._xAxisLabel = name;
    else this._xAxisLabel = '';
  }

  get xAxisLabel() {
    return this._xAxisLabel;
  }

  set yAxisLabel(name) {
    if (typeof name === 'string') this._yAxisLabel = name;
    else this._yAxisLabel = '';
  }

  get yAxisLabel() {
    return this._yAxisLabel;
  }

  set isLogging(value) {
    this.#isLogging = value;
  }

  get isLogging() {
    return this.#isLogging;
  }

  set showTotal(value) {
    this.#showTotal = value;
  }

  get showTotal() {
    return this.#showTotal;
  }

  #logger(...args) {
    if (!this.#isLogging) return;
    console.log(...args);
  }

  static _getCategoryName(data, categoryType) {
    const categoryNames = map(data, (category) =>
      labelNullFormatter(get(category, categoryType, null)),
    );
    return categoryNames;
  }

  constructor(chartType) {
    this._chartType = chartType;
  }

  _getUniquePrimaryCategories(data) {
    const uniqueValues = StackedColumn._getCategoryName(
      data,
      'primaryCategoryName',
    );

    return uniqueValues;
  }

  _getUniqueSecondaryCategories(data) {
    const uniqueValues = data.map((item) => {
      const primaryValues = get(item, 'primaryCategoryValues', []);
      const secondaryCategories = StackedColumn._getCategoryName(
        primaryValues,
        'secondaryCategory',
      );
      return secondaryCategories;
    });

    return uniq(flattenDeep(uniqueValues));
  }

  getTooltipFormatter(chartType) {
    const showTotal = this.#showTotal;

    function tooltipFormatter() {
      const secondaryCategory = this.x;
      const seriesName = this.series.name;
      const seriesValue = this.y;

      const stackTotalValue = roundOff(
        get(this.point, 'metaData.totalValue', null),
        1,
      );
      const symbol = chartType === 'percentage' ? '%' : '';

      const seriesString = `<br/>${seriesName}: ${seriesValue} ${symbol}`;
      const totalString = `<br/>Total: ${stackTotalValue} ${symbol}`;

      let tooltipString = `<b>${secondaryCategory}</b>${seriesString}`;

      if (showTotal) tooltipString += totalString;
      return tooltipString;
    }
    return tooltipFormatter;
  }

  _getLabelFormatter(chartType) {
    const isStackLabels = this.isStacked;
    const that = this;

    function formatter() {
      const symbol = chartType === 'percentage' ? '%' : '';
      const seriesValue = this.y;
      const displayValue = Math.abs(seriesValue / that._maxTotal);

      if (isStackLabels && displayValue < 0.1) return '';

      const label = `${seriesValue} ${symbol}`;
      const textColour = isStackLabels
        ? getContrastColour(this.color)
        : getContrastColour(white);
      const labelStyle = `color:${textColour};`;
      return `<p style='${labelStyle}'>${label}</p>`;
    }
    return formatter;
  }

  _getXAxis(data) {
    const categories = this._getUniquePrimaryCategories(data);

    const xAxis = {
      categories,
      title: { text: this._xAxisLabel },
      labels: { enabled: true },
    };
    return xAxis;
  }

  _getTableRows(data, type) {
    const uniqueSecondaryCategories = this._getUniqueSecondaryCategories(data);

    const dataRows = data.map((dataItem) => {
      const primaryCategoryName = get(dataItem, 'primaryCategoryName', null);
      const primaryCategoryValues = get(dataItem, 'primaryCategoryValues', []);
      const row = { Category: primaryCategoryName };

      uniqueSecondaryCategories.forEach((secondaryCategory) => {
        const filterCondition = { secondaryCategory };
        const primaryValueItem = find(primaryCategoryValues, filterCondition);
        const numericValue = get(primaryValueItem, type, null);
        row[secondaryCategory] = numericValue;
      });
      return row;
    });
    return dataRows;
  }

  _getExportConfig() {
    const exporting = {
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen'],
        },
      },
    };
    return exporting;
  }

  _getYAxis() {
    const yAxis = {
      title: { text: this._yAxisLabel },
      stackLabels: { enabled: this._isStackLabels },
      max: this._maxTotal + this._maxTotal * 0.1,
    };

    return yAxis;
  }

  _getSeries(data, type) {
    const uniqueSecondaryCategories = this._getUniqueSecondaryCategories(data);

    const series = uniqueSecondaryCategories.map((secondaryCategory) => {
      const seriesData = data.map((item) => {
        const primaryCategoryValues = get(item, 'primaryCategoryValues', []);
        const filterCondition = { secondaryCategory };
        const primaryValueItem = find(primaryCategoryValues, filterCondition);
        let numericValue = valueNullFormatter(get(primaryValueItem, type, 0));
        numericValue = roundOff(numericValue, 1);
        const totalValue = sum(map(primaryCategoryValues, type, 0));

        const metaData = { secondaryCategory, totalValue, dataItem: item };
        const point = { y: numericValue, metaData };
        return point;
      });

      const seriesTotal = sumBy(seriesData, 'y');

      const name = secondaryCategory;
      const seriesItem = { name, data: seriesData, seriesTotal };
      return seriesItem;
    });

    const sortedSeries = this.#sortSeries(series);
    const updatedSeries = sortedSeries.map((category, index) => {
      const categoryName = get(category, 'name');
      const color = this._getColorForChart(categoryName, index);
      const dataWithColor = { ...category, color };
      return dataWithColor;
    });
    return updatedSeries;
  }

  _getColorForChart(secondaryCategory, index) {
    return this._colorList[index % this._colorList.length];
  }

  _initializeMaxTotal(data) {
    const allBarHeights = data.map((category) => {
      const primaryCategoryValues = get(category, 'primaryCategoryValues', []);
      const barHeight = primaryCategoryValues.reduce(
        (prevValuesSum, categoryValues) =>
          prevValuesSum + Math.abs(categoryValues[this._chartType]),
        0,
      );
      return barHeight;
    });
    const maxBarHeight = max(allBarHeights);
    this._maxTotal = maxBarHeight;
  }

  generateTable(data) {
    const tableRows = this._getTableRows(data, this._chartType);
    const tableHeaders = this._getUniqueSecondaryCategories(data);
    return { tableRows, tableHeaders };
  }

  generateChart(data) {
    this._initializeMaxTotal(data);
    const xAxis = this._getXAxis(data);
    const yAxis = this._getYAxis();
    const series = this._getSeries(data, this._chartType);
    const exporting = this._getExportConfig();

    this.#logger(this._chartType, { xAxis });
    this.#logger(this._chartType, { yAxis });
    this.#logger(this._chartType, { series });

    const tooltipFormatter = this.getTooltipFormatter(this._chartType);
    const labelFormatter = this._getLabelFormatter(this._chartType);

    const chartData = {
      ...this._CHART_CONFIGURATION,
      exporting,
      xAxis,
      yAxis,
      series,
      tooltip: { formatter: tooltipFormatter },
      plotOptions: {
        column: { stacking: this.isStacked ? 'normal' : null },
        series: {
          dataLabels: {
            enabled: true,
            useHTML: true,
            formatter: labelFormatter,
          },
        },
      },
    };
    return chartData;
  }
}

export default StackedColumn;
