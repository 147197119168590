import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';

const gptGoalsSummaryDataHttpRequest = (() => {
  const apiName = 'gptGoalsSummary';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/gpt-goals-summary`;
  return generatedHttpThunk.generateThunk();
})();

export default gptGoalsSummaryDataHttpRequest;
