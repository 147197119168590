import { crossIcon, rightArrow } from 'assets/icons';
import Error from 'components/Error';
import Loader from 'components/Loader';
import { SafeHtmlElements } from 'components/SafeComponents';
import {
  PORTFOLIO_MANAGEMENT_MODULE,
  SITE_HEALTH_MODULE,
  TRIAL_MANAGEMENT_MODULE,
} from 'constants/savedFilters/savedFilters.constants';
import { keys, get } from 'lodash';
import { Chip } from 'primereact/chip';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import formatHttpPostErrorMessage from 'utility/errors/formatHttpPostErrorMessage';
import { useThunkSelector } from 'utility/redux';
import './SavedFiltersDisplay.scss';

const getCurrentModule = (path) => {
  switch (true) {
    case path.includes('/trial-management'):
      return {
        currentDashboard: TRIAL_MANAGEMENT_MODULE,
        filterPath: '/trial-management/overview',
      };
    case path.includes('/trial-portfolio'):
      return {
        currentDashboard: PORTFOLIO_MANAGEMENT_MODULE,
        filterPath: '/trial-portfolio',
      };
    case path.includes('/site-health'):
      return {
        currentDashboard: SITE_HEALTH_MODULE,
        filterPath: '/site-health',
      };
    default:
      return {
        currentDashboard: null,
        filterPath: null,
      };
  }
};

const generateSavedFilters = (filterJson, filterOptions) => {
  const filterKeys = keys(filterJson);
  const transformedLabels = [];
  filterKeys.forEach((key) => {
    const foundFilterValues = filterOptions.find(
      (columnName) => columnName.columnDisplayValue === key,
    );
    const columnFilterValues = get(foundFilterValues, 'columnValues');
    if (columnFilterValues) {
      filterJson[key].forEach((filterValue) => {
        const foundFilterLabel = columnFilterValues.find(
          (filterObject) => filterObject.value === filterValue,
        );
        const columnFilterLabel = get(foundFilterLabel, 'label');
        if (columnFilterLabel) transformedLabels.push(columnFilterLabel);
      });
    }
  });
  return transformedLabels;
};

function SavedFiltersDisplay({
  isClearEnabled,
  viewFilter,
  clearSavedFilter,
  moduleName,
  globalFilters,
  filterOptions,
}) {
  const [clearIntiated, setClearIntiated] = useState(false);
  const listSavedFiltersData = useThunkSelector(
    'savedFilters.listSavedFilters',
  );

  const deleteSavedFiltersData = useSelector((currentState) => {
    const { deleteSavedFilters } = currentState.http.savedFilters;
    return deleteSavedFilters;
  }, shallowEqual);

  const location = useLocation();
  const { currentDashboard } = getCurrentModule(location.pathname);

  let savedFiltersList = [];

  if (listSavedFiltersData.isSuccess) {
    savedFiltersList = listSavedFiltersData.payload.data.find(
      (moduleFilters) => moduleFilters.module === moduleName,
    ).filters;
  }

  const errorToast = useRef(null);
  const showErrorToast = (detail) => {
    errorToast.current.show({ severity: 'error', summary: 'Error', detail });
  };

  useEffect(() => {
    if (deleteSavedFiltersData.isError) {
      setTimeout(() => {
        showErrorToast(formatHttpPostErrorMessage(deleteSavedFiltersData));
      });
    }
  }, [deleteSavedFiltersData]);

  const enableView = moduleName === currentDashboard;

  return (
    <div className="row">
      <Toast ref={errorToast} />
      {listSavedFiltersData.isError && (
        <Error error={listSavedFiltersData.error} />
      )}
      {(listSavedFiltersData.isLoading ||
        (clearIntiated && deleteSavedFiltersData.isLoading)) && (
        <Loader className="my-5 py-5" />
      )}
      {listSavedFiltersData.isSuccess &&
        (!clearIntiated || !deleteSavedFiltersData.isLoading) && (
          <div>
            {savedFiltersList.length === 0 ? (
              <p className="no-saved-filters">No saved filters found</p>
            ) : (
              <div className="col-12 saved-filters">
                {savedFiltersList.map((savedFilter) => (
                  <div
                    className={`row w-100 align-items-start ${
                      savedFilter.validFilter ? '' : 'invalid-filter'
                    }`}
                    key={savedFilter.filterName}
                  >
                    <div className="col-lg-2 col-md-2 col-3 saved-filter-name">
                      <SafeHtmlElements tagName="h4">
                        {savedFilter.filterName}
                      </SafeHtmlElements>
                    </div>
                    <div className="col-lg-8 col-md-7 col-5 d-flex flex-wrap">
                      {generateSavedFilters(
                        savedFilter.filterJson,
                        filterOptions,
                      ).map((filterText) => (
                        <Chip
                          data-testid="filter-chip"
                          label={
                            <div className="d-flex justify-content-between filter-label">
                              <SafeHtmlElements tagName="p">
                                {filterText}
                              </SafeHtmlElements>
                            </div>
                          }
                          className="mb-2 me-2 d-flex justify-content-between selected-filter"
                          key={`${savedFilter}-${filterText}`}
                        />
                      ))}
                    </div>

                    <div className="col-lg-2 col-md-3 col-4 d-flex">
                      {isClearEnabled && (
                        <div
                          className="d-flex align-items-center cursor-pointer"
                          onClick={() => {
                            setClearIntiated(true);
                            clearSavedFilter({
                              filterName: savedFilter.filterName,
                              module: moduleName,
                            });
                          }}
                        >
                          <img src={crossIcon} alt="icon" role="presentation" />
                          <p className="ps-2 action-btn-text">Clear</p>
                        </div>
                      )}
                      {enableView && savedFilter.validFilter && (
                        <div
                          className="d-flex align-items-center cursor-pointer mx-2"
                          onClick={() => {
                            viewFilter({ savedFilter, currentDashboard });
                          }}
                          data-testid={`${moduleName}-${savedFilter.filterName}`}
                        >
                          <p className="pe-2 action-btn-text">
                            View&nbsp;{globalFilters && `all`}
                          </p>

                          <img
                            src={rightArrow}
                            alt="View"
                            role="presentation"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
    </div>
  );
}

SavedFiltersDisplay.propTypes = {
  isClearEnabled: PropTypes.bool,
  viewFilter: PropTypes.func,
  clearSavedFilter: PropTypes.func,
  moduleName: PropTypes.string.isRequired,
  globalFilters: PropTypes.bool,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      columnDisplayName: PropTypes.string,
      columnDisplayValue: PropTypes.string,
      columnValues: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          isSelected: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
};

SavedFiltersDisplay.defaultProps = {
  isClearEnabled: false,
  viewFilter: () => {},
  clearSavedFilter: () => {},
  globalFilters: false,
};

export default SavedFiltersDisplay;
