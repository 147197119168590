import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import amendmentTableTransform from './utility/09_protocolAmendments/amendmentsTable.transform';
import topReasonsChartDataTransform from './utility/09_protocolAmendments/topReasonsChartData.transform';

export const protocolAmendmentsDataHttpRequest = (() => {
  const apiName = 'protocolAmendmentsTimelineView';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/protocol-amendments/trial/:trialId`;
  generatedHttpThunk.transform = amendmentTableTransform;
  return generatedHttpThunk.generateTrialThunk();
})();

export const protocolAmendmentsSummaryDataHttpRequest = (() => {
  const apiName = 'protocolAmendmentsSummary';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/protocol-amendments-summary/trial/:trialId`;
  return generatedHttpThunk.generateTrialThunk();
})();

export const protocolAmendmentsTopReasonsDataHttpRequest = (() => {
  const apiName = 'protocolAmendmentsTopReasons';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/protocol-amendments-top-reasons/trial/:trialId`;
  generatedHttpThunk.transform = topReasonsChartDataTransform;
  return generatedHttpThunk.generateTrialThunk();
})();
