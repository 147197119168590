import { gray3 } from 'assets/stylesheets/base/_colors';

const drawCrossIcon = (color) => {
  const svgString = `
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 0.7L7.3 0L4 3.3L0.7 0L0 0.7L3.3 4L0 7.3L0.7 8L4 4.7L7.3 8L8 7.3L4.7 4L8 0.7Z" fill="${color}"/>
    </svg>
  `;
  return `data:image/svg+xml;base64,${window.btoa(svgString)}`;
};

export const crossIcon = drawCrossIcon('#15141F');
export const crossIconDisabled = drawCrossIcon(gray3);
