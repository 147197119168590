import {
  DATA_DURATION_OPTIONS_COMPLETED,
  DATA_DURATION_OPTIONS_PROJECTED,
} from '../../../constants/trialDeliveryGoal.constants';

export const TDG_COMPLETED_CARD = [
  {
    title: 'Goals achieved on time or early',
    key: 'TDG_COMPLETED_CARD_1',
  },
  {
    title: 'Median variance from target',
    key: 'TDG_COMPLETED_CARD_2',
  },
];

export const TDG_PROJECTED_CARD = [
  {
    title: 'Goals on track',
    key: 'TDG_PROJECTED_CARD_1',
  },
];

export const TDG_SECTION_CONTENT = {
  GROUP: [
    {
      label: 'Completed',
      value: 'COMPLETED',
      duration: DATA_DURATION_OPTIONS_COMPLETED,
    },
    {
      label: 'Projected',
      value: 'PROJECTED',
      duration: DATA_DURATION_OPTIONS_PROJECTED,
    },
  ],
};
