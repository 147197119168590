import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const risksAndMitigationHttpRequest = (() => {
  const apiName = 'risksAndMitigation';

  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/risks-mitigations/trial/:trialId`;
  return generatedHttpThunk.generateTrialThunk();
})();

export default risksAndMitigationHttpRequest;
