import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const trialDataSourcesHttpRequest = (() => {
  const apiName = 'trialDataSources';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/sources/trial/:trialId`;
  return generatedHttpThunk.generateTrialThunk();
})();

export default trialDataSourcesHttpRequest;
