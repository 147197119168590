import { get } from 'lodash';

const generateScrollbarEventHighchart = (numBar) => {
  const scrollbarEvent = {
    load() {
      const minWidth =
        (this.plotSizeX / numBar) *
        get(this, 'userOptions.series[0].data.length', 0);

      this.update(
        {
          chart: {
            scrollablePlotArea: {
              scrollPositionX: 0,
              minWidth,
            },
          },
        },
        false,
      );

      this.xAxis[0].update({
        width: '100%',
      });
    },
  };
  return scrollbarEvent;
};

export default generateScrollbarEventHighchart;
