import { keyBy, merge, orderBy, values } from 'lodash';
import EnrolmentCurveAndTable from './enrolmentCurveAndTableClass';

class EnrolmentTableTransform extends EnrolmentCurveAndTable {
  constructor() {
    super();
    this._groupName = '';
  }

  set groupName(dataItem) {
    this._groupName = dataItem;
  }

  get groupName() {
    return this._groupName;
  }

  set groupDescription(dataItem) {
    this._groupDescription = dataItem;
  }

  get groupDescription() {
    return this._groupDescription;
  }

  _generateTableData() {
    const REQUIRED_KEYS = {
      origPlanSitesActivatedCum: null,
      currPlanSitesActivatedCum: null,
      actualSitesActivatedCum: null,
      currPlanEnrolledCum: null,
      actualEnrolledCum: null,
      origPlanEnrolledCum: null,
      forecastEnrollmentLowerQuartile: null,
      forecastEnrollmentMidQuantile: null,
      forecastEnrollmentUpperQuartile: null,
      isEnrollmentApi: false,
      isActivationApi: false,
      isRange: false,
      isForecastApi: false,
      actualSubjectsScreenedCum: null,
      actualDropoutCum: null,
      lowerBound: this._forecastLSILowerBound,
      upperBound: this._forecastLSIUpperBound,
      forecastConfidenceInterval: this._forecastLSIConfidenceInterval,
    };
    const combinedData = merge(
      keyBy(this._enrolmentData, 'trialMonth'),
      keyBy(this._siteActivationData, 'trialMonth'),
      keyBy(this._forecastOverTime, 'trialMonth'),
      keyBy(this._probabilityRange, 'trialMonth'),
      keyBy(this._screeningData, 'trialMonth'),
      keyBy(this._dropoutData, 'trialMonth'),
    );
    const combinedValues = values(combinedData);
    const flattenedCombinedValues = combinedValues.map((value) => ({
      ...REQUIRED_KEYS,
      ...value,
    }));
    const sortedData = orderBy(
      flattenedCombinedValues,
      ['trialMonth'],
      ['asc'],
    );
    const returnData = {
      data: sortedData,
      groupName: this._groupName,
      groupDescription: this._groupDescription,
    };

    return returnData;
  }
}

export default EnrolmentTableTransform;
