import { GPT_GOALS_STATUS_DESCRIPTION } from 'dashboards/TrialManagement/constants/GPTGoalsStatus.constant';
import { get, isNil, orderBy } from 'lodash';

const generateGptGoalOptions = (tableData) => {
  const filterList = [];
  if (Array.isArray(tableData)) {
    tableData.forEach((record) => {
      const goalStatus = get(record, 'goalStatus', null);
      if (!isNil(goalStatus)) {
        const temp = {
          value: goalStatus,
          label: get(GPT_GOALS_STATUS_DESCRIPTION, goalStatus, null),
        };
        const existingObject = filterList.find(
          (obj) => obj.label === temp.label && obj.value === temp.value,
        );
        if (!existingObject) {
          filterList.push(temp);
        }
      }
    });
    return orderBy(filterList, 'value', 'asc');
  }
  return filterList;
};

export default generateGptGoalOptions;
