import { get } from 'lodash';
import { UI_DISPLAY_DATE } from 'constants/date/dateFormats.constant';
import DateStringTransform from 'utility/date/dateString.utility';

import { getCategoryAndIndexByPaToFirstAmendmentDays } from '../chart/paToFirstAmendmentMedians';

export const formatDateColumn = (value) =>
  value ? new DateStringTransform(value).formatter(UI_DISPLAY_DATE) : null;

const getMedianData = (key, data) => {
  const medianData = get(data, `${key}.drilldown.trials`);
  if (!Array.isArray(medianData) || medianData.length === 0) {
    return [];
  }
  const tableData = medianData.map((trialData) => {
    const { categoryName } = getCategoryAndIndexByPaToFirstAmendmentDays(
      trialData.paToFirstAmendmentDays,
    );
    return {
      ...trialData,
      ...{
        firstAmendmentDate: formatDateColumn(trialData.firstAmendmentDate),
        paDate: formatDateColumn(trialData.paDate),
      },
      categoryName,
    };
  });
  return tableData;
};

export default getMedianData;
