const PROTOCOL_AMENDMENT_TIMELINE_ELEMENT_TYPE = {
  AMENDMENT: 'AMENDMENT',
  MILESTONE: 'MILESTONE',
};

const PROTOCOL_AMENDMENT_TYPE = {
  SUBSTANTIAL: 'Substantial',
  NON_SUBSTANTIAL: 'Non-Substantial',
  NOT_SPECIFIED: 'Not Specified',
};

const PROTOCOL_AMENDMENT_SCOPE = {
  GLOBAL: 'GLOBAL',
  LOCAL: 'LOCAL',
};

const N_A = '--';

const PROTOCOL_AMENDMENT_TIMELINE_CHART_ID = 'hhhdsdasd232131e2jbib343';

export {
  PROTOCOL_AMENDMENT_TIMELINE_ELEMENT_TYPE,
  PROTOCOL_AMENDMENT_TYPE,
  PROTOCOL_AMENDMENT_SCOPE,
  PROTOCOL_AMENDMENT_TIMELINE_CHART_ID,
  N_A,
};
