import Error from 'components/Error';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { compassStore } from 'store';
import { moduleAccessHttpRequest } from 'store/thunks';
import { useThunkSelector } from 'utility/redux';
import SiteHealthRoute from './SiteHealth.routes';
import TrialManagementRoute from './TrialManagement.routes';
import TrialPortfolioRoute from './TrialPortfolio.routes';
import UserManagementRoute from './UserManagement.routes';

function DashboardRoute() {
  const { dispatch } = compassStore;

  const pageAccess = useThunkSelector('moduleAccess');

  useEffect(() => {
    dispatch(moduleAccessHttpRequest());
  }, [dispatch]);

  const routerConfig = (
    <Routes>
      <Route path="/trial-portfolio/*" element={<TrialPortfolioRoute />} />

      <Route path="/trial-management/*" element={<TrialManagementRoute />} />
      <Route path="/site-health/*" element={<SiteHealthRoute />} />

      <Route path="/user-management/*" element={<UserManagementRoute />} />

      <Route
        path="*"
        element={
          <>
            {pageAccess.isError && <Error error={pageAccess.error} />}
            {pageAccess.isSuccess && (
              <Navigate replace to="/trial-management/overview" />
            )}
          </>
        }
      />
    </Routes>
  );

  return routerConfig;
}

export default DashboardRoute;
