import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import './ConfirmationDialog.scss';

function ConfirmationDialog(props) {
  const { heading, content } = props;
  const { children } = props;
  const { onConfirmation, onCancel, isLoading, isConfirmDisabled } = props;

  const handleConfirmation = () => {
    onConfirmation();
    document.body.classList.remove('p-overflow-hidden');
  };

  const handleCancel = () => {
    onCancel();
    document.body.classList.remove('p-overflow-hidden');
  };

  return (
    <Dialog
      modal
      visible
      onHide={handleCancel}
      draggable={false}
      className="confirmation-dialog"
      blockScroll
    >
      {isLoading ? (
        <div className="row g-0 py-5 my-5">
          <div className="col-12">
            <Loader />
          </div>
        </div>
      ) : (
        <div className="row g-0 mx-4 my-4">
          <div className="col-12">
            <h2 className="mb-3">{heading}</h2>
          </div>
          <div className="col-12">
            {typeof content === 'string' ? (
              <p className="mb-2">{content}</p>
            ) : (
              content
            )}
          </div>
          <div className="col-12"> {children}</div>
          <div className="col-12">
            <Button
              type="submit"
              onClick={handleConfirmation}
              label="Confirm"
              className="col-6"
              data-testid="confirm-btn"
              disabled={isConfirmDisabled}
            />
            <Button
              className="p-button-secondary col-6"
              onClick={handleCancel}
              label="Cancel"
              data-testid="cancel-btn"
            />
          </div>
        </div>
      )}
    </Dialog>
  );
}
ConfirmationDialog.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.node.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.element,
  isConfirmDisabled: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  heading: '',
  children: null,
  isConfirmDisabled: false,
};

export default ConfirmationDialog;
