import displayApplicationVersion from 'constants/appVersion.constant';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import Dashboards from 'pages/dashboards/Dashboards';
import config from './oktaConfig';
import { setupAxios } from './utility';
import { compassStore } from './store';

function App() {
  displayApplicationVersion();
  setupAxios();
  const oktaAuth = new OktaAuth(config.oidc);
  return (
    <div className="root-container container-xxl px-4 px-xxl-0">
      <div className="row">
        <div className="col-12">
          <BrowserRouter>
            <Provider store={compassStore}>
              <Security
                oktaAuth={oktaAuth}
                restoreOriginalUri={(...args) => {
                  console.log({ args });
                }}
              >
                <Dashboards />
              </Security>
            </Provider>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}

export default App;
