import { get, map } from 'lodash';
import { ExcelExporter, extractOnlyGivenProperties } from 'utility';
import DRILLDOWN_SHEET_KEYS from './exportKeys.constant';

const exportDrilldown = (
  data,
  selectedCard,
  isRateType = false,
  drilldownType = 'country',
) => {
  const dateString = new Date().toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const sheetKeys = get(
    DRILLDOWN_SHEET_KEYS,
    `${selectedCard}.${isRateType ? 'rate' : 'count'}.${drilldownType}`,
    [],
  );
  const exportData = extractOnlyGivenProperties(data, sheetKeys);

  const filename = `${dateString}_${drilldownType}`;
  const excelExporter = new ExcelExporter(filename, true);
  const sheetOptions = {
    '!cols': map(sheetKeys, 'colWidth'),
  };
  excelExporter.appendSheet(exportData, `${drilldownType}`, sheetOptions, true);
  excelExporter.download();
};
export default exportDrilldown;
