import { get, isNil, map, uniq } from 'lodash';
import ClinicalMilestonesChart from './utility/clinicalMilestoneChart.abstract';

const milestoneDataTransform = (milestoneData, requestParams) => {
  const milestoneRecords = get(milestoneData, 'records', []);
  const milestoneStatistics = get(milestoneData, 'statistics', []);

  const milestoneGoalStatus = map(milestoneRecords, 'status', []);
  const categories = map(milestoneRecords, 'primaryCategoryName', []);

  const uniqueMilestoneGoalStatus = uniq(milestoneGoalStatus)
    .filter(
      (milestoneStatus) =>
        !isNil(milestoneStatus) && milestoneStatus.label !== '',
    )
    .sort(
      (firstMilestoneStatusValue, secondMilestoneStatusValue) =>
        firstMilestoneStatusValue - secondMilestoneStatusValue,
    )
    .map((milestoneStatus) => ({
      label: milestoneStatus,
      value: milestoneStatus,
    }));

  const uniqueCatagories = uniq(categories)
    .filter((category) => !isNil(category) && category.label !== '')
    .sort(
      (firstCategoryValue, secondCategoryValue) =>
        firstCategoryValue - secondCategoryValue,
    )
    .map((category) => ({
      label: category,
      value: category,
    }));

  const chartType = 'count';
  const milestonesChartData = new ClinicalMilestonesChart(chartType);
  milestonesChartData.isCompleted = requestParams.completed;
  const chartData = milestonesChartData.generateChart(milestoneStatistics);

  const transformedMilestoneData = {
    ...milestoneData,
    chartData,
    uniqueMilestoneGoalStatus,
    uniqueCatagories,
  };

  return transformedMilestoneData;
};

export default milestoneDataTransform;
