import { get, isNil } from 'lodash';
import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import DiversityByGroup from './utility/DiversityByGroup.abstract';

export const diversityTransform = (responseData, _, additionalParams) => {
  const responseArray = responseData.values;
  const activeTabValue = get(additionalParams, 'activeTabValue', null);
  const groupBy = get(additionalParams, 'groupBy', null);

  // Generate Percentage Data
  const groupPercentage = new DiversityByGroup('percentage');
  groupPercentage.xAxisLabel = isNil(groupBy) ? activeTabValue : groupBy;
  groupPercentage.yAxisLabel = 'Percentage';
  groupPercentage.showTotal = !isNil(groupBy);
  const chartDataPercentage = groupPercentage.generateChart(responseArray);
  const tableDataPercentage = groupPercentage.generateTable(responseArray);

  // Generate Count Data
  const groupCount = new DiversityByGroup('count');
  groupCount.xAxisLabel = isNil(groupBy) ? activeTabValue : groupBy;
  groupCount.yAxisLabel = 'Count';
  groupCount.showTotal = !isNil(groupBy);
  const chartDataCount = groupCount.generateChart(responseArray);
  const tableDataCount = groupCount.generateTable(responseArray);

  const returnData = {
    chartDataPercentage,
    chartDataCount,
    tableDataPercentage,
    tableDataCount,
  };
  return returnData;
};

const distributionByGroupHttpRequest = (() => {
  const apiName = 'diversityDistributionByGroup';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/diversity-grouped-distribution`;
  generatedHttpThunk.transform = diversityTransform;
  return generatedHttpThunk.generateThunk();
})();

export default distributionByGroupHttpRequest;
