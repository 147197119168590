import crfsCountPercentTransform from '../../../__utility__/crfsCountPercentTransform';

const crfSignedTransform = (data) => {
  const countYAxisLabel = 'CRFs signed';
  const percentYAxisLabel = '% CRFs signed';

  return crfsCountPercentTransform(data, countYAxisLabel, percentYAxisLabel);
};

export default crfSignedTransform;
