class GenerateSingularOrPlural {
  singularWord = null;

  pluralWord = null;

  #getSingular() {
    return this.singularWord;
  }

  #getPlural() {
    return this.pluralWord;
  }

  getWord(value) {
    if (typeof value === 'number') {
      const magnitude = Math.abs(value);
      if (magnitude <= 1) {
        return this.#getSingular();
      }
      return this.#getPlural();
    }
    return null;
  }
}

export default GenerateSingularOrPlural;
