import { filter, find, get, isArray } from 'lodash';
import { transformationFunctionWrapper } from 'utility/errors';
import { roundOff } from 'utility/math';
import addFileName from '../../../../../../utility/fileName.highcharts';
import EnrolmentCurveChartTransform from './enrolmentCurveChart.transform';
import EnrolmentCurveTableTransform from './enrolmentTable.transform';

const transformCurve = (
  enrolmentDataValues,
  forecastedRecords,
  activationDataValues,
  screeningDataValues,
  dropoutDataValues,
) => {
  const enrolmentCurveChartTransform = new EnrolmentCurveChartTransform();
  let enrolmentCurve = {};
  let enrolmentCurveError = null;

  const aggregateEnrolmentData = filter(enrolmentDataValues, {
    name: 'Aggregate',
  });
  const aggregateEnrolmentRecords = get(
    aggregateEnrolmentData,
    '[0].monthlyEnrollmentRecords',
    [],
  );

  const aggregateForecastedRecords = get(
    forecastedRecords,
    'forecastedEnrollmentOverTime[0].monthlyEnrollmentRecords',
    [],
  );

  try {
    const { metrics } = forecastedRecords;

    enrolmentCurveChartTransform.enrolmentData = aggregateEnrolmentRecords;
    enrolmentCurveChartTransform.siteActivationData = activationDataValues;
    enrolmentCurveChartTransform.screeningData = screeningDataValues;
    enrolmentCurveChartTransform.dropoutData = dropoutDataValues;

    enrolmentCurveChartTransform.forecastMetrics = metrics;
    enrolmentCurveChartTransform.forecastOverTime = aggregateForecastedRecords;
    enrolmentCurve = enrolmentCurveChartTransform._generateChart();
    enrolmentCurve = addFileName(enrolmentCurve, 'enrolmentCurve');
  } catch (error) {
    enrolmentCurveError = error;
  }

  if (enrolmentCurveError) throw new Error(enrolmentCurveError);
  return enrolmentCurve;
};

const transformTable = (
  enrolmentDataValues,
  forecastedRecords,
  activationDataValues,
  screeningDataValues,
  dropoutDataValues,
) => {
  const enrolmentCurveTableTransform = new EnrolmentCurveTableTransform();
  let enrolmentCurveMultiTableData = { data: [] };
  let enrolmentCurveTableError = null;

  try {
    enrolmentCurveTableTransform.siteActivationData = activationDataValues;
    enrolmentCurveTableTransform.screeningData = screeningDataValues;
    enrolmentCurveTableTransform.dropoutData = dropoutDataValues;

    const forecastedEnrollmentOverTimeData = get(
      forecastedRecords,
      'forecastedEnrollmentOverTime',
    );
    enrolmentCurveMultiTableData = enrolmentDataValues.map((part) => {
      const partName = get(part, 'name');
      const partDescription = get(part, 'description', null);
      const filterDataPart = filter(forecastedEnrollmentOverTimeData, {
        name: partName,
      });
      const { metrics } = forecastedRecords;
      const forecastLowerBound = 'FORECAST_LOWER_BOUND';
      const forecastUpperBound = 'FORECAST_UPPER_BOUND';
      const forecastConfidenceInterval = 'CONFIDENCE_INTERVAL';
      const lowerValue = get(
        find(metrics, ['metric', forecastLowerBound]),
        'value',
      );
      const upperValue = get(
        find(metrics, ['metric', forecastUpperBound]),
        'value',
      );
      const middleValue = get(
        find(metrics, ['metric', forecastConfidenceInterval]),
        'value',
      );
      enrolmentCurveTableTransform.forecastLSILowerBound = roundOff(
        lowerValue,
        1,
        null,
      );
      enrolmentCurveTableTransform.forecastLSIUpperBound = roundOff(
        upperValue,
        1,
        null,
      );
      enrolmentCurveTableTransform.forecastLSIConfidenceInterval = roundOff(
        middleValue,
        1,
        null,
      );
      enrolmentCurveTableTransform.forecastOverTime = get(
        filterDataPart,
        '[0].monthlyEnrollmentRecords',
      );
      enrolmentCurveTableTransform.probabilityRange = get(
        filterDataPart,
        '[0].monthlyEnrollmentRecords',
      );
      enrolmentCurveTableTransform.enrolmentData = get(
        part,
        'monthlyEnrollmentRecords',
      );
      enrolmentCurveTableTransform.groupName = partName;
      enrolmentCurveTableTransform.groupDescription = partDescription;
      const enrolmentCurveTableData =
        enrolmentCurveTableTransform._generateTableData();
      return enrolmentCurveTableData;
    });
  } catch (error) {
    enrolmentCurveTableError = error;
  }
  if (enrolmentCurveTableError) throw new Error(enrolmentCurveTableError);
  return enrolmentCurveMultiTableData;
};

const transform = (
  enrolmentData,
  activationData,
  forecastData,
  screeningData,
  dropoutData,
) => {
  const enrolmentDataValues = get(enrolmentData, 'data');
  const forecastedRecords = get(forecastData, 'data');
  const activationDataValues = get(activationData, 'data');
  const screeningDataValues = get(screeningData, 'data');
  const dropoutDataValues = get(dropoutData, 'data');

  const chartData = transformCurve(
    enrolmentDataValues,
    forecastedRecords,
    activationDataValues,
    screeningDataValues,
    dropoutDataValues,
  );

  const multiTableData = transformTable(
    enrolmentDataValues,
    forecastedRecords,
    activationDataValues,
    screeningDataValues,
    dropoutDataValues,
  );
  const isMultiPart =
    isArray(enrolmentDataValues) && enrolmentDataValues.length > 1;

  const returnData = {
    enrolmentCurveData: {
      chartData,
      multiTableData,
      isMultiPart,
    },
  };
  return returnData;
};

export default transformationFunctionWrapper(transform);
