export const PERCENT_COUNT_TOGGLE_OPTIONS = [
  { label: 'Count', value: 'count' },
  { label: 'Percentage', value: 'percentage' },
];

export const DATA_DURATION_OPTIONS_COMPLETED = [
  { label: 'Last 6 months', value: 6 },
  { label: 'Last 12 months', value: 12 },
  { label: 'Last 24 months', value: 24 },
];

export const DATA_DURATION_OPTIONS_PROJECTED = [
  { label: 'Next 6 months', value: 6 },
  { label: 'Next 12 months', value: 12 },
  { label: 'Next 24 months', value: 24 },
];

export const GROUPING_OPTIONS = [
  {
    label: 'Group by OpTAU',
    value: 'OPTAU',
    title: 'OpTAU',
    multiSelectPlaceholder: 'All OpTAUs',
  },
  {
    label: 'Group by TAU',
    value: 'TAU',
    title: 'TAU',
    multiSelectPlaceholder: 'All TAUs',
  },
  {
    label: 'Group by Tier',
    value: 'TIER',
    title: 'Tier',
    multiSelectPlaceholder: 'All Tiers',
  },
  {
    label: 'Group by Phase',
    value: 'PHASE',
    title: 'Phase',
    multiSelectPlaceholder: 'All Phases',
  },
  {
    label: 'Group by CRO',
    value: 'CRO',
    title: 'CRO',
    multiSelectPlaceholder: 'All CROs',
  },
];
export const MILESTONE_OPTIONS = [
  { label: 'PS', value: 'PS' },
  { label: 'PA', value: 'PA' },
  { label: 'FSA', value: 'FSA' },
  { label: 'FSS', value: 'FSS' },
  { label: 'FSI', value: 'FSI' },
  { label: 'LSI', value: 'LSI' },
  { label: 'LSO', value: 'LSO' },
  { label: 'DBL', value: 'DBL' },
  { label: 'KA', value: 'KA' },
  { label: 'TLF', value: 'TLF' },
  { label: 'CSR', value: 'CSR' },
];

export const GPT_GOAL_OPTIONS = [
  { label: 'All goals', value: 'ALL' },
  { label: 'GPT goal', value: 'GPT_GOAL' },
  { label: 'Major milestone', value: 'MAJOR_MILESTONE' },
  { label: 'Critical inflection', value: 'CRITICAL_INFLECTION' },
];
