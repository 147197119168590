import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

export const edcSidePanelHttpRequest = (() => {
  const apiName = 'edcSidePanel';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/edc-readiness-thresholds/trial/:trialId`;
  return generatedHttpThunk.generateTrialThunk();
})();

export default edcSidePanelHttpRequest;
