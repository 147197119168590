import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const drillDownActivationDataHttpRequest = (() => {
  const apiName = 'enrollmentDriversActivation';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource =
    'trial-management/enrollment-drivers-activation/trial/:trialId';
  return generatedHttpThunk.generateTrialThunk();
})();

export default drillDownActivationDataHttpRequest;
