import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';

const numTrialsHttpRequest = (() => {
  const apiName = 'numTrials';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/portfolio-trial-count`;
  return generatedHttpThunk.generateThunk();
})();

export default numTrialsHttpRequest;
