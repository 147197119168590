import { PortfolioThunkGenerator } from 'utility/redux/thunkGenerator';
import transform from './transform/RaceTrendOverTime.transform';

const raceTrendOverTimeHttpRequest = (() => {
  const apiName = 'diversityTrendOverTime';
  const generatedHttpThunk = new PortfolioThunkGenerator(apiName);
  generatedHttpThunk.resource = `portfolio-health/diversity-over-time`;
  generatedHttpThunk.transform = transform;
  return generatedHttpThunk.generateThunk();
})();

export default raceTrendOverTimeHttpRequest;
