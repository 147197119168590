import {
  PORTFOLIO_MANAGEMENT_MODULE,
  SITE_HEALTH_MODULE,
  TRIAL_MANAGEMENT_MODULE,
} from 'constants/savedFilters/savedFilters.constants';
import { GLOBAL_FILTER_SECTION_ID as PORTFOLIO_GLOBAL_FILTER_SECTION_ID } from 'dashboards/PortfolioManagement/constants/uiConfiguration.constants';
import { GLOBAL_FILTER_SECTION_ID as TRIAL_GLOBAL_FILTER_SECTION_ID } from 'dashboards/TrialManagement/constants/uiConfiguration.constants';
import { GLOBAL_FILTER_SECTION_ID as SITE_GLOBAL_FILTER_SECTION_ID } from 'dashboards/SiteHealth/constants/uiConfiguration.constants';
import {
  portfolioManagementActions,
  portfolioManagementStore,
} from 'dashboards/PortfolioManagement/store';
import { portfolioFiltersDataHttpRequest } from 'dashboards/PortfolioManagement/store/thunks';
import {
  siteHealthActions,
  siteHealthStore,
} from 'dashboards/SiteHealth/store';
import { SiteHealthFiltersHttpRequest } from 'dashboards/SiteHealth/store/thunks';
import {
  trialManagementActions,
  trialManagementStore,
} from 'dashboards/TrialManagement/store';
import { trialListFiltersHttpRequest } from 'dashboards/TrialManagement/store/thunks';

const { cloneDeep } = require('lodash');

export const applySavedFilterByModule = (
  savedFilter,
  moduleStore,
  { type, sectionId },
  actions,
  updateFilterAction,
) => {
  const currentFiltersState = cloneDeep(
    moduleStore.getState().uiState.preferencesConfigMap[sectionId][type],
  );

  const { filterJson } = savedFilter;
  currentFiltersState.activeFilters = filterJson;

  const preferencesConfig = {
    type,
    value: currentFiltersState,
    sectionId,
  };

  const { dispatch: moduleDispatch } = moduleStore;

  moduleDispatch(actions.preferencesConfigAction(preferencesConfig));

  moduleDispatch(updateFilterAction());
};

export const applySavedFilter = ({ savedFilter, currentDashboard }) => {
  if (currentDashboard === TRIAL_MANAGEMENT_MODULE) {
    applySavedFilterByModule(
      savedFilter,
      trialManagementStore,
      {
        type: 'GLOBAL_FILTER_VALUE',
        sectionId: TRIAL_GLOBAL_FILTER_SECTION_ID,
      },
      trialManagementActions,
      trialListFiltersHttpRequest,
    );
  } else if (currentDashboard === PORTFOLIO_MANAGEMENT_MODULE) {
    applySavedFilterByModule(
      savedFilter,
      portfolioManagementStore,
      {
        type: 'GLOBAL_FILTER_VALUE',
        sectionId: PORTFOLIO_GLOBAL_FILTER_SECTION_ID,
      },
      portfolioManagementActions,
      portfolioFiltersDataHttpRequest,
    );
  } else if (currentDashboard === SITE_HEALTH_MODULE) {
    applySavedFilterByModule(
      savedFilter,
      siteHealthStore,
      { type: 'GLOBAL_FILTER_VALUE', sectionId: SITE_GLOBAL_FILTER_SECTION_ID },
      siteHealthActions,
      SiteHealthFiltersHttpRequest,
    );
  }
};
