import { QUADRANT } from 'dashboards/PortfolioManagement/dataTables/ActivationsEnrollmentsToPlanTable/constants';
import { get, isNil } from 'lodash';
import getColorByRagStatus from 'utility/getColorByRagStatus';

class ScatterPoint {
  _aheadBehindScatterSeriesData = [];

  _behindAheadScatterSeriesData = [];

  _behindBehindScatterSeriesData = [];

  _aheadAheadScatterSeriesData = [];

  constructor(scatterPoints) {
    scatterPoints.forEach((point) => {
      const ragStatus = get(point, 'ragStatus', null);

      const color = getColorByRagStatus(ragStatus);
      const quadrantValue = get(point, 'quadrant.value', '');
      const xValue = get(point, 'x', null);
      const yValue = get(point, 'y', null);
      if (isNil(xValue) || isNil(yValue)) {
        return;
      }
      const addedPoint = { ...point, color };
      switch (quadrantValue) {
        case QUADRANT.AHEAD_AHEAD.value:
          this._aheadAheadScatterSeriesData.push(addedPoint);
          break;
        case QUADRANT.BEHIND_BEHIND.value:
          this._behindBehindScatterSeriesData.push(addedPoint);
          break;
        case QUADRANT.BEHIND_AHEAD.value:
          this._behindAheadScatterSeriesData.push(addedPoint);
          break;
        case QUADRANT.AHEAD_BEHIND.value:
          this._aheadBehindScatterSeriesData.push(addedPoint);
          break;
        default:
      }
    });
  }

  get aheadBehindScatterSeriesData() {
    return this._aheadBehindScatterSeriesData;
  }

  get behindAheadScatterSeriesData() {
    return this._behindAheadScatterSeriesData;
  }

  get behindBehindScatterSeriesData() {
    return this._behindBehindScatterSeriesData;
  }

  get aheadAheadScatterSeriesData() {
    return this._aheadAheadScatterSeriesData;
  }

  generate() {
    const scatterPointSeries = [
      {
        data: this._aheadAheadScatterSeriesData,
      },
      {
        data: this._behindBehindScatterSeriesData,
      },
      {
        data: this._behindAheadScatterSeriesData,
      },
      {
        data: this._aheadBehindScatterSeriesData,
      },
    ];
    return scatterPointSeries;
  }
}

export default ScatterPoint;
