import {
  transformDeviationsChartData,
  transformDeviationsPerPatientChartData,
} from './protocolDeviationsChartTransform';

const transform = (data) => {
  const transformedData = {
    tableData: data.allDeviationData,
    numberOfDeviationsChartData: transformDeviationsChartData(
      data.categoryMetrics,
    ),
    numberPerPatientsChartData: transformDeviationsPerPatientChartData(
      data.categoryMetrics,
    ),
  };
  return transformedData;
};

export default transform;
