import { get, isNil, replace } from 'lodash';
import { roundOff } from 'utility/math/valueRounder';

const getVarianceDays = (cycleTimesData, stageId) => {
  const { romeTarget } = cycleTimesData[stageId];
  const actualDays = cycleTimesData[stageId].actualCycleTime;
  if (isNil(romeTarget) || isNil(actualDays))
    return { varianceDays: null, status: null };

  let targetDifference = romeTarget - actualDays;
  targetDifference = roundOff(targetDifference, 1);
  const targetText = targetDifference < 0 ? 'delay' : 'ahead';
  let status = targetDifference < 0 ? 'RED' : 'GREEN';

  let varianceDays = `${Math.abs(targetDifference)} days ${targetText}`;
  if (targetDifference === 0) {
    varianceDays = `0 days`;
    status = 'GREEN';
  }
  return { varianceDays, status };
};

const transform = (cycleTimesData) => {
  const tableData = cycleTimesData.map((rowData, index) => {
    const stage = replace(get(rowData, `stage`, null), '_', ' - ');
    const stageValue = stage && `${stage}`;
    const modifiedRowValue = {
      ...rowData,
      varianceActualAndTarget: getVarianceDays(cycleTimesData, index)
        .varianceDays,
      titleStage: stageValue,
    };
    return modifiedRowValue;
  });

  const returnData = { tableData };
  return returnData;
};

export default transform;
