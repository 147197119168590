import { APPLICATION_VERSION } from 'constants/appVersion.constant';

function getAppKey() {
  const currentEnv = process.env.REACT_APP_ENV;
  if (currentEnv === APPLICATION_VERSION.ENV.TEST) return 'AD-AAB-ABY-VPF';
  if (currentEnv === APPLICATION_VERSION.ENV.PROD) return 'AD-AAB-ACC-PTA';
  return null;
}

function loadAppDynamics(userID) {
  window['adrum-start-time'] = new Date().getTime();

  (function (appConfig) {
    const config = appConfig;
    config.appKey = getAppKey();
    config.adrumExtUrlHttp = 'https://cdn.appdynamics.com';
    config.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
    config.beaconUrlHttp = 'https://pdx-col.eum-appdynamics.com';
    config.beaconUrlHttps = 'https://pdx-col.eum-appdynamics.com';
    config.resTiming = { bufSize: 200, clearResTimingOnBeaconSend: true };
    config.maxUrlLength = 512;
    config.spa = { spa2: true };
    config.xd = {
      enable: true,
    };
    config.isZonePromise = true;

    window['adrum-config'] = {
      ...config,
      userEventInfo: {
        PageView() {
          return {
            ...config,
            userData: { userID },
          };
        },
        Ajax() {
          return {
            ...config,
            userData: { userID },
          };
        },
        VPageView() {
          return {
            ...config,
            userData: { userID },
          };
        },
      },
    };
  })(window['adrum-config'] || (window['adrum-config'] = {}));

  const adrumScript = document.createElement('script');
  adrumScript.type = 'text/javascript';
  adrumScript.src = 'https://cdn.appdynamics.com/adrum/adrum-22.9.0.3923.js';
  document.head.appendChild(adrumScript);
}

export default loadAppDynamics;
