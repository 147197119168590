import axios from 'axios';
import { addFileName, generateHttpActions } from '../../../../../utility';
import transform from './utility/activationDetail.transform';

const drillDownActivationDetailHttpRequest = (
  trialId,
  countryCode,
  informationCardData,
) => {
  const httpActions = generateHttpActions('enrollmentDriversActivationDetail');
  const resource = `trial-management/enrollment-drivers-activation/trial/${trialId}`;

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const activationDetailResponse = await axios.get(resource, {
        params: {
          country_code: countryCode,
        },
      });

      const { stepChart, tableData } = transform(activationDetailResponse.data);
      const transformedData = {
        data: {
          stepChart: addFileName(stepChart, 'countryActivation'),
          tableData,
          informationCardData,
        },
      };
      dispatch(httpActions.success(transformedData));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
};

export default drillDownActivationDetailHttpRequest;
