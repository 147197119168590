import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import transformDrillDownData from './utility/transformDrilldown';

const transformData = (selectedDriverCard) => (data, params) => {
  const updatedParams = {
    ...params,
    selectedDriverCard,
  };
  return transformDrillDownData(data, updatedParams);
};

export const drillDownDropoutDataHttpRequest = (() => {
  const apiName = 'enrollmentDriversDropout';
  const selectedDriverCard = 'dropout';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/enrollment-drivers-dropout/trial/:trialId`;
  generatedHttpThunk.transform = transformData(selectedDriverCard);
  return generatedHttpThunk.generateTrialThunk();
})();
export const drillDownScreeningDataHttpRequest = (() => {
  const apiName = 'enrollmentDriversScreening';
  const selectedDriverCard = 'screening';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/enrollment-drivers-screening/trial/:trialId`;
  generatedHttpThunk.transform = transformData(selectedDriverCard);
  return generatedHttpThunk.generateTrialThunk();
})();
export const drillDownScreeningFailureDataHttpRequest = (() => {
  const apiName = 'enrollmentDriversScreenFailure';
  const selectedDriverCard = 'screeningFailure';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/enrollment-drivers-screen-failure/trial/:trialId`;
  generatedHttpThunk.transform = transformData(selectedDriverCard);
  return generatedHttpThunk.generateTrialThunk();
})();

export const drillDownEnrollmentDataHttpRequest = (() => {
  const apiName = 'enrollmentDriversEnrollment';
  const selectedDriverCard = 'enrollment';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource =
    'trial-management/enrollment-drivers-drilldown/trial/:trialId';
  generatedHttpThunk.transform = transformData(selectedDriverCard);
  return generatedHttpThunk.generateTrialThunk();
})();

export const drillDownCompletionDataHttpRequest = (() => {
  const apiName = 'enrollmentDriversCompletion';
  const selectedDriverCard = 'completion';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/enrollment-drivers-completion/trial/:trialId`;
  generatedHttpThunk.transform = transformData(selectedDriverCard);
  return generatedHttpThunk.generateTrialThunk();
})();
